import { combineReducers } from "redux";
import DemoArticleReducer from "./DemoArticleReducer";
import DemoListReducer from "./DemoListReducer";
import CounterReducers from "./CounterReducers";
import LoanReducers from "./LoanReducers";
import UserProfileReducers from "./UserProfileReducers";
import SidebarBackToListReducer from "./DefaultSidebarBackToListReducers";
import IsStaticMasterDataAvailableReducer from "./IsStaticMasterDataAvailableReducer";
import SidebarRefreshReducer from "./SidebarRefreshReducer";

const rootReducer = combineReducers({
  DemoArticleReducer,
  DemoListReducer,
  CounterReducers,
  LoanReducers,
  UserProfileReducers,
  SidebarBackToListReducer,
  IsStaticMasterDataAvailableReducer,
  SidebarRefreshReducer,
});

export default rootReducer;
