import AccessControlIcon from "assets/img/sidebar/Access_Control.svg";
import ApprovalRequestIcon from "assets/img/sidebar/Approval_Request.svg";
import AuditHistoryIcon from "assets/img/sidebar/Audit_History_Listing.svg";
import BankIcon from "assets/img/sidebar/Banks.svg";
import DocumentTypeIcon from "assets/img/sidebar/Document_Type.svg";
import HomeIcon from "assets/img/sidebar/Home_Icon.svg";
import IndustryIcon from "assets/img/sidebar/Industry.svg";
import LocationIcon from "assets/img/sidebar/Locations.svg";
import OrgStructureIcon from "assets/img/sidebar/Org_Structure.svg";
import PartnerManagementIcon from "assets/img/sidebar/Partner_Management.svg";
import ProductConfigurationIcon from "assets/img/sidebar/Product_Configuration.svg";
import StaticMasterIcon from "assets/img/sidebar/Static_Master.svg";
import SystemConfigurationIcon from "assets/img/sidebar/System_Configuration.svg";
import SystemDateIcon from "assets/img/sidebar/System_Date.svg";
import UserManagementIcon from "assets/img/sidebar/User_Management.svg";
import {
  linkAccessControl,
  linkApprovalRequests,
  linkCity,
  linkOrganizationApproval,
  linkOrganizationBranch,
  linkOrganizationDepartment,
  linkOrganizationLevel,
  linkOrganizationReporting,
  linkOrganizationReportingDesignation,
  linkPartnerOnboarding,
  linkPartnerUser,
  linkPincode,
  linkProductConfigurationBusinessRule,
  linkProductConfigurationChecklist,
  linkProductConfigurationDeviation,
  linkProductConfigurationDeviationCategory,
  linkProductConfigurationFees,
  linkProductConfigurationIncomeScheme,
  linkProductConfigurationProducts,
  linkProductConfigurationTransaction,
  linkProductIncomeSchemeList,
  linkState,
  linkStaticMaster,
  linkStaticMasterData,
  linkStaticMasterTypes,
  linkSystemConfig,
  linkSystemDate,
  linkUserManagement,
} from "routes";
import BaseApiService from "services/BaseApiService";
import { setLocalStorage } from "utils/CommonUtil";
import { LOCAL_STORAGE, STAGE_CODE_SCREENS, STAGE_TABS_CODE } from "utils/Constant";
import {
  LINK_APPLICATION_MEDIA,
  LINK_CAM,
  LINK_COMPARISON,
  LINK_DDE_CREDIT_QUERY,
  LINK_DDE_LOGIN,
  LINK_DDE_QC,
  LINK_DDE_STAGE_AND_STATUS,
  LINK_DEDUPE,
  LINK_FEES_DEPOSIT,
  LINK_FEES_RECEIPT,
  LINK_FEES_SCREEN,
  LINK_FINAL_APPROVAL,
  LINK_FINANCIAL_APPROVAL,
  LINK_IN_PRINCIPLE_APPROVAL,
  LINK_LEGAL,
  LINK_LEGAL_DEVIATIONS,
  LINK_LEGAL_DOCUMENT_EXCHANGE,
  LINK_LOAN_LIST,
  LINK_NOTIFICATIONS,
  LINK_PARTNER_TASK_LIST,
  LINK_PD,
  LINK_POINT_OF_INTEREST,
  LINK_QDE,
  LINK_RCU,
  LINK_RCU_DEVIATION,
  LINK_RCU_DOCUMENT_EXCHANGE,
  LINK_RECONCILIATION,
  LINK_REPORT,
  LINK_RE_APPEAL,
  LINK_SUMMARY,
  LINK_TASK_LIST,
  LINK_TASK_QC_NON_INITIATED,
  LINK_TECHNICAL,
  LINK_TECHNICAL_DEVIATION,
  LINK_TECHNICAL_DOCUMENT_EXCHANGE,
} from "../../transactionRoutes";

const DEFAULT_PATH = "/custom/api/menu";

class MenuService extends BaseApiService {
  getMenu = async (code: string, loanUid?: string) => {
    let url = "";
    if (loanUid) {
      url = `${DEFAULT_PATH}/list?root=${code}&loanUid=${loanUid}`;
    } else {
      url = `${DEFAULT_PATH}/list?root=${code}`;
    }
    try {
      const res = await this.makeGetRequestWithAuth(url);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };
}

const prepareMenu = async (menuList: any[]) => {
  const menuItems: any[] = [];

  menuList.forEach((menu: any) => {
    let overRidePath = "";

    STAGE_CODE_SCREENS.forEach((screen) => {
      if (menu.code.includes(screen)) {
        // @ts-ignore: will fix later
        overRidePath = getPathForMultipleStageStage()[screen];
      }
    });

    const menuObject = {
      label: menu.name,
      // @ts-ignore: will fix later
      icon: !menu.parent && menu.root ? getIcon()[menu?.code] : "",
      className: menu.parent && menu.root ? "text-xs ml-10  " : "text-xs ",
      // command: () => {},
      // @ts-ignore: will fix later
      path: overRidePath ? overRidePath : getPath()[menu.code] ?? "",
      showPanel: false,
      ...menu,
    };
    if (menu.root === null) {
      menuItems.push(menuObject);
    }
    if (menu.parent === null && menu.root) {
      const rootIndex = menuItems.findIndex((item: any) => item.code === menu.root.code);
      if (rootIndex !== -1) {
        menuItems[rootIndex].items = [...(menuItems[rootIndex].items || []), menuObject];
      }
      menuItems.push(menuObject);
    }
    if (menu.parent && menu.root && !menu.isTab) {
      const parentIndex = menuItems.findIndex((item: any) => item.code === menu.parent.code);
      if (parentIndex !== -1) {
        menuItems[parentIndex].items = [...(menuItems[parentIndex]?.items || []), menuObject];
      } else {
        menuItems.push({ ...menuObject, className: "text-xs" });
      }
    }
  });
  return menuItems;
};
/**
 * Prepares the path with code for each menu item in the menu list.
 *
 * @param menuList - The list of menu items.
 * @returns An array of menu items with the path and other properties prepared.
 */
export const preparePathWithCode = (menuList: any[]) => {
  // console.log(menuList, "menuList");

  let menuItems: any[] = [];
  menuList.forEach((menu: any) => {
    let overRidePath = "";

    STAGE_CODE_SCREENS.forEach((screen) => {
      if (STAGE_TABS_CODE.includes(menu.code) && menu.code.includes(screen)) {
        // @ts-ignore: will fix later
        overRidePath = getTabsMapping()[menu.code];
      }
    });

    const menuObject = {
      label: menu.name,
      // @ts-ignore: will fix later
      path: overRidePath ? overRidePath : getPath()[menu.code] ?? "",
      showPanel: false,
      ...menu,
    };
    menuItems.push(menuObject);
  });
  // ACCESS_CONTROL FROM FE
  const roleFeature = {
    create: true,
    read: true,
    update: true,
    approve: true,
    delete: true,
  };
  const accessPathFromFE = [
    {
      label: "QDE",
      path: LINK_QDE,
      showPanel: false,
      code: "QDE",
      roleFeature,
    },
    {
      label: "Notification",
      path: LINK_NOTIFICATIONS,
      showPanel: false,
      code: "Notification",
      roleFeature,
    },
  ];
  menuItems = [...menuItems, ...accessPathFromFE];
  return menuItems;
};

const getTabsMapping = () => {
  return {
    LEGAL_REPORT_TAB: LINK_LEGAL,
    LEGAL_DOCUMENT_EXCHANGE_TAB: LINK_LEGAL_DOCUMENT_EXCHANGE,
    LEGAL_DEVIATIONS_TAB: LINK_LEGAL_DEVIATIONS,
    TECHNICAL_REPORT_TAB: LINK_TECHNICAL,
    TECHNICAL_DOCUMENT_EXCHANGE_TAB: LINK_TECHNICAL_DOCUMENT_EXCHANGE,
    TECHNICAL_DEVIATIONS_TAB: LINK_TECHNICAL_DEVIATION,
    RCU_REPORT_TAB: LINK_RCU,
    RCU_DOCUMENT_EXCHANGE_TAB: LINK_RCU_DOCUMENT_EXCHANGE,
    RCU_DEVIATIONS_TAB: LINK_RCU_DEVIATION,
  };
};

const getIcon = () => {
  return {
    ACCESS_CONTROL: AccessControlIcon,
    SYSTEM_DATE_PARENT: SystemDateIcon,
    APPROVAL_REQUEST_PARENT: ApprovalRequestIcon,
    BANK: BankIcon,
    INDUSTRY: IndustryIcon,
    DOC_TYPE: DocumentTypeIcon,
    HOME: HomeIcon,
    LOCATION: LocationIcon,
    ORG_STRUCTURE: OrgStructureIcon,
    PARTNER_MANAGEMENT: PartnerManagementIcon,
    PRODUCT_CONFIGURATION: ProductConfigurationIcon,
    STATIC_MASTER: StaticMasterIcon,
    SYSTEM_CONFIG: SystemConfigurationIcon,
    SYSTEM_DATE: SystemDateIcon,
    AUDIT_HISTORY_LOG: AuditHistoryIcon,
    USER_MANAGEMENT: UserManagementIcon,
  };
};
const getPath = () => {
  return {
    APPROVAL_REQUEST: linkApprovalRequests,
    STATIC_MASTER: linkStaticMaster,
    STATIC_MASTER_TYPE: linkStaticMasterTypes,
    STATIC_MASTER_DATA: linkStaticMasterData,
    SYSTEM_DATE: linkSystemDate,
    SYSTEM_CONFIG_LIST: linkSystemConfig,
    ORG_LEVEL_TYPE: linkOrganizationLevel,
    ORG_REPORTING_LEVEL: linkOrganizationReporting,
    BRANCH: linkOrganizationBranch,
    DEPARTMENT: linkOrganizationDepartment,
    DESIGNATION: linkOrganizationReportingDesignation,
    BANK: "BANK",
    BANK_REALTIONSHIP: "BANK_REALTIONSHIP",
    IFSC: "IFSC",
    DEPOSIT_ACCOUNT: "DEPOSIT_ACCOUNT",
    STATE: linkState,
    CITY: linkCity,
    PINCODE: linkPincode,
    PARTNER: "PARTNER",
    PARTNER_ONBOARDING: linkPartnerOnboarding,
    PARTNER_USER: linkPartnerUser,
    USER_PROFILE: linkUserManagement,
    BUSINESS_RULE: linkProductConfigurationBusinessRule,
    DEVIATION: linkProductConfigurationDeviation,
    DEVIATION_CATEGORY: linkProductConfigurationDeviationCategory,
    CHECKLIST: linkProductConfigurationChecklist,
    PRODUCT: linkProductConfigurationProducts,
    TRANSACTION_APPROVAL_RULE: linkProductConfigurationTransaction,
    INCOME_SCHEME: linkProductConfigurationIncomeScheme,
    INCOME_SCHEME_PRODUCT: linkProductIncomeSchemeList,
    FEE: linkProductConfigurationFees,
    DOCUMENT_TYPE: "DOCUMENT_TYPE",
    APPROVAL_LEVEL: linkOrganizationApproval,
    ROLE: linkAccessControl,

    LOAN: LINK_LOAN_LIST,
    PARTNER_TASK_PIPELINE: LINK_PARTNER_TASK_LIST,
    TASKS_PIPELINE: LINK_TASK_LIST,
    QC_NON_INITIATED: LINK_TASK_QC_NON_INITIATED,
    REPORTS: LINK_REPORT,
    RECEIPTS: LINK_FEES_RECEIPT,
    DEPOSITS: LINK_FEES_DEPOSIT,
    RECONCILIATION: LINK_RECONCILIATION,
    POINT_OF_INTEREST_PARENT: LINK_POINT_OF_INTEREST,
    POINT_OF_INTEREST: LINK_POINT_OF_INTEREST,
    LOGIN: LINK_DDE_LOGIN,
    LOGIN_ASSIGNEE_NAME: LINK_DDE_LOGIN,
    QC: LINK_DDE_QC,
    QC_ASSIGNEE_NAME: LINK_DDE_QC,
    STAGE_AND_STATUS: LINK_DDE_STAGE_AND_STATUS,
    CREDIT_AND_QUERY: LINK_DDE_CREDIT_QUERY,
    LEGAL_REPORT: LINK_LEGAL,
    LEGAL_REPORT_ASSIGNEE_NAME: LINK_LEGAL,
    PD: LINK_PD,
    PD_ASSIGNEE_NAME: LINK_PD,
    COMPARISON_VIEW: LINK_COMPARISON,
    TECHNICAL_DOCUMENT_EXCHANGE: LINK_TECHNICAL_DOCUMENT_EXCHANGE,
    TECHNICAL_DEVIATIONS: LINK_TECHNICAL_DEVIATION,
    FEES_AND_RECEIPT: LINK_FEES_SCREEN,
    SUMMARY: LINK_SUMMARY,
    APPLICATION_MEDIA:LINK_APPLICATION_MEDIA
  };
};
const getPathForMultipleStageStage = () => {
  return {
    PD_: LINK_PD,
    LEGAL_: LINK_LEGAL,
    RCU_REPORT_: LINK_RCU,
    TECHNICAL_REPORT_: LINK_TECHNICAL,
    FINAL_APPROVAL_: LINK_FINAL_APPROVAL,
    IN_PRINCIPAL_APPROVAL_: LINK_IN_PRINCIPLE_APPROVAL,
    FINANCIAL_APPROVAL_: LINK_FINANCIAL_APPROVAL,
    DEDUPE_: LINK_DEDUPE,
    CAM_AND_RECOMMENDATION: LINK_CAM,
    CREDIT_AND_QUERY_: LINK_DDE_CREDIT_QUERY,
    REAPPEAL_: LINK_RE_APPEAL,
  };
};

export const getMenu = async (code: string, loanUid?: string) => {
  const menuService = new MenuService();
  // console.log(menuService, code);
  const itemsFormAPI = await menuService.getMenu(code, loanUid);
  const allScreenMenu = preparePathWithCode(itemsFormAPI.filter((item: any) => item.isScreen));
  // console.log(allScreenMenu, "allScreenMenu");
  setLocalStorage(LOCAL_STORAGE.MENU_LIST_MAPPING, JSON.stringify(allScreenMenu));
  const menuItems = await prepareMenu(itemsFormAPI);
  // console.log(menuItems, "menuItems");
  return menuItems;
};

export default new MenuService();
