import { IRouteAccessModel } from "components/interface/common";
import { ACL_SESSION_STORAGE, IS_ACL_ENABLED, IS_ROUTE_ENABLED } from "./Constant";

export const isAclApplicable = (): boolean => {
  const ACL_STATUS = sessionStorage.getItem(ACL_SESSION_STORAGE.IS_ACL_ENABLED) ?? IS_ACL_ENABLED.N;
  return ACL_STATUS === IS_ACL_ENABLED.Y;
};
export const isRouteGuardApplicable = (): boolean => {
  const ROUTE_GUARD_STATUS =
    sessionStorage.getItem(ACL_SESSION_STORAGE.IS_ROUTE_GUARD) ?? IS_ROUTE_ENABLED.N;
  return ROUTE_GUARD_STATUS === IS_ROUTE_ENABLED.Y;
};

export const getAccessLevel = (): IRouteAccessModel => {
  if (sessionStorage.getItem(ACL_SESSION_STORAGE.ACL_GUARD)) {
    const routeAccessModelObj = JSON.parse(
      sessionStorage.getItem(ACL_SESSION_STORAGE.ACL_GUARD) ?? ""
    );
    return routeAccessModelObj;
  }
  return {
    read: false,
    approve: false,
    update: false,
    create: false,
    delete: false,
    path: "",
    softDelete: false,
  };
};

export const saveRouteAccessControlToStorage = (routeAccessControl: IRouteAccessModel) => {
  sessionStorage.setItem(ACL_SESSION_STORAGE.ACL_GUARD, JSON.stringify(routeAccessControl));
};

export const saveAclGuardStatusToStorage = (aclGuardName: string, status: string) => {
  sessionStorage.setItem(
    `${ACL_SESSION_STORAGE.ACL_GUARD_DIRTY_STATUS_PREFIX}_${aclGuardName}`,
    status
  );
};
export const deleteAclGuardStatusFromStorage = (aclGuardName: string) => {
  sessionStorage.removeItem(`${ACL_SESSION_STORAGE.ACL_GUARD_DIRTY_STATUS_PREFIX}_${aclGuardName}`);
};
export const getAllAclGuardStatus = () => {
  console.log("getAllAclGuardStatus");
};

export const createNewRouteAccessModel = (): IRouteAccessModel => {
  return {
    read: getAccessLevel().read,
    approve: getAccessLevel().approve,
    update: getAccessLevel().update,
    create: getAccessLevel().create,
    delete: getAccessLevel().delete,
    path: "",
    softDelete: getAccessLevel().softDelete,
  };
};
