import { BUTTON_BOX_STYLES } from "assets/base-theme";
import chatDisable from "assets/chatSvg/chatDisable.svg";
import plusIcon from "assets/chatSvg/plusIcon.svg";
import sendButtonDisabled from "assets/chatSvg/replyButtonDisabled.svg";
import sendButton from "assets/chatSvg/replyButtonNormal.svg";
import TickRight from "assets/img/ReApeal/TickRight.svg";
import addIcon from "assets/img/buttonIcons/Add_Icon.svg";
import addIconDisabled from "assets/img/buttonIcons/Add_Icon_Disabled.svg";
import approveIcon from "assets/img/buttonIcons/Approve.svg";
import approveIconDisabled from "assets/img/buttonIcons/ApproveDisabled.svg";
import verifyIcon from "assets/img/buttonIcons/Approve_Verification.svg";
import cancelIcon from "assets/img/buttonIcons/Cancel.svg";
import cancelIconDisabled from "assets/img/buttonIcons/Cancel_disabled.svg";
import collectIcon from "assets/img/buttonIcons/Collect.svg";
import confirmIcon from "assets/img/buttonIcons/Confirm.svg";
import createIcon from "assets/img/buttonIcons/Create.svg";
import createDisabledIcon from "assets/img/buttonIcons/Create_disabled.svg";
import editIcon from "assets/img/buttonIcons/Edit.svg";
import filterIcon from "assets/img/buttonIcons/Filter.svg";
import resetIcon from "assets/img/buttonIcons/RESET.svg";
import reAppealIcon from "assets/img/buttonIcons/Re_Appeal_Icon.svg";
import reAppealDisabledIcon from "assets/img/buttonIcons/re-appeal-disabled.svg";
import rejectIcon from "assets/img/buttonIcons/Reject.svg";
import cancelSmallDarkDisabledIcon from "assets/img/buttonIcons/RejectDisabled.svg";
import rejectDisabledIcon from "assets/img/buttonIcons/Reject_Disabled.svg";
import resetIconDisabled from "assets/img/buttonIcons/Reset_disabled.svg";
import saveIcon from "assets/img/buttonIcons/Save.svg";
import saveIconLoading from "assets/img/buttonIcons/Save_Loading.svg";
import saveAndContinueIcon from "assets/img/buttonIcons/Save_continue.svg";
import searchIcon from "assets/img/buttonIcons/Search.svg";
import searchIconDisabled from "assets/img/buttonIcons/Search_disabled.svg";
import sendApprovalIcon from "assets/img/buttonIcons/Send_Approval.svg";
import sendApprovalIconLoading from "assets/img/buttonIcons/Send_Approval_Loading.svg";
import sendApprovalIconDisabled from "assets/img/buttonIcons/Send_Approve_Disabled.svg";
import StageIcon from "assets/img/buttonIcons/StageTick.svg";
import submitIcon from "assets/img/buttonIcons/Submit.svg";
import deleteIcon from "assets/img/buttonIcons/Table_Row_Delete.svg";
import uploadIcon from "assets/img/buttonIcons/Upload.svg";
import cancelSmallDarkIcon from "assets/img/buttonIcons/cancel-small-dark.svg";
import cancelSmallIcon from "assets/img/buttonIcons/cancel-small.svg";
import menuIcon from "assets/img/buttonIcons/kebab-menu.svg";
import menuIconHover from "assets/img/buttonIcons/kebab-menu_hover.svg";
import makeDepositDisabledIcon from "assets/img/buttonIcons/make-deposit-disabled.svg";
import makeDepositIcon from "assets/img/buttonIcons/make-deposit.svg";
import saveDisableIcon from "assets/img/buttonIcons/saveDisable.png";
import saveAndContinueDisabledIcon from "assets/img/buttonIcons/savenContinueDisable.png";
import shareDisabledIcon from "assets/img/buttonIcons/share-disabled.svg";
import shareIcon from "assets/img/buttonIcons/share.svg";
import StageDisabledIcon from "assets/img/buttonIcons/stageTickDisabled.svg";
import Text from "components/Text/Text";
import BaseButton, { IBaseButtonProps } from "components/base/BaseButton";
import { IButtonCommonStyleProps } from "components/interface/Button";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BUTTON_COLOR_TYPE, BUTTON_STYLE_TYPE } from "utils/Constant";
import { generateClassesForButton } from "utils/Util";
import editIconDisabled from "assets/img/buttonIcons/Save_disabled.svg";
import smallSubmitBlueIcon from "assets/img/buttonIcons/submit-small-icon-blue.svg";
import smallSubmitGrayIcon from "assets/img/buttonIcons/submit-small-icon-gray.svg";

interface IButtonBoxProps extends IBaseButtonProps, IButtonCommonStyleProps {
  size?: "LOAN_DATA" | "MASTER_DATA";
}

const BUTTON_OBJECT = {
  CREATE: {
    label: "Create",
    icon: <img src={createIcon} alt="Create Icon" />,
    disabledIcon: <img src={createDisabledIcon} alt="Create Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    loadingIcon: "pi pi-arrow-right",
    isCreate: true,
  },
  NEW_CONVERSATION: {
    label: false,
    icon: <img src={plusIcon} alt="plus Icon" />,
    disabledIcon: <img src={chatDisable} alt="Create Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "",
    loadingIcon: "pi pi-arrow-right",
    isCreate: true,
  },
  REPLY_CONVERSATION: {
    label: " ",
    icon: <img src={sendButton} alt="send Button" width={"48px"} height={"48px"} />,
    disabledIcon: <img src={sendButtonDisabled} alt="Create Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "",
    loadingIcon: "pi pi-arrow-right",
    isCreate: true,
  },

  CREATE_NEW_RECEIPT: {
    label: "New Rcpt.",
    icon: <img src={createIcon} alt="New Rcpt." />,
    disabledIcon: <img src={createDisabledIcon} alt="New Rcpt." />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    loadingIcon: "pi pi-arrow-right",
  },
  CREATE_NEW_DEPOSIT: {
    label: "New Deposit",
    icon: <img src={createIcon} alt="New Deposit" />,
    disabledIcon: <img src={createDisabledIcon} alt="New Deposit" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    loadingIcon: "pi pi-arrow-right",
  },
  MAKE_DEPOSIT: {
    label: "Ma. Dpst.",
    icon: <img src={makeDepositIcon} alt="Ma. Dpst." />,
    disabledIcon: <img src={makeDepositDisabledIcon} alt="Ma. Dpst." />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    loadingIcon: "pi pi-arrow-right",
  },
  SEND_FOR_APPROVAL: {
    label: "Snd.Apprv.",
    icon: <img src={sendApprovalIcon} alt="Approve Icon" />,
    disabledIcon: <img src={sendApprovalIconDisabled} alt="Approve Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    loadingIcon: <img src={sendApprovalIconLoading} alt="Approve Icon" />,
    tooltip: "Send For Approval",
    tooltipOptions: {
      position: "top",
      className: "text-xs font-Roboto bg-black text-white !p-1 rounded-md ",
      showDelay: "1000,",
      hideDelay: "300",
    },
    isApprove: true,
  },
  SAVE_AND_CONTINUE: {
    label: "Sa. Cont.",
    icon: <img src={saveAndContinueIcon} alt="Save and Continue Icon" />,
    disabledIcon: <img src={saveAndContinueDisabledIcon} alt="Save and Continue Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    loadingIcon: <img src={sendApprovalIconLoading} alt="Save and Continue Icon" />,
    tooltip: "Save and Continue",
    tooltipOptions: {
      position: "top",
      className: "text-xs font-Roboto bg-black text-white !p-1 rounded-md ",
      showDelay: "1000,",
      hideDelay: "300",
    },
    isCreate: true,
  },
  APPROVE: {
    label: "Approve",
    icon: <img src={approveIcon} alt="Approve Icon" />,
    disabledIcon: <img src={approveIconDisabled} alt="Approve Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },
  APPROVE_PRIMARY: {
    label: "Approve",
    icon: <img src={approveIcon} alt="Approve Icon" />,
    disabledIcon: <img src={approveIconDisabled} alt="Approve Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
  },
  REJECT_PRIMARY: {
    label: "Reject",
    icon: <img src={rejectIcon} alt="Reject Icon" />,
    disabledIcon: <img src={rejectDisabledIcon} alt="Reject Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.crimson,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },
  SEARCH_OUTLINE: {
    label: "Search",
    icon: <img src={searchIcon} alt="Approve Icon" />,
    disabledIcon: <img src={searchIconDisabled} alt="Approve Icon" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    isEnableInReadMode: true,
  },
  BACK_OUTLINE: {
    label: "Back",
    icon: "pi pi-arrow-left",
    disabledIcon: "pi pi-arrow-left",
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    isEnableInReadMode: true,
  },
  RESET_OUTLINE: {
    label: "Reset",
    icon: <img src={resetIcon} alt="Approve Icon" />,
    disabledIcon: <img src={resetIconDisabled} alt="Approve Icon" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
    isEnableInReadMode: true,
  },
  EDIT_OUTLINE: {
    label: "Edit",
    icon: <img src={editIcon} alt="Edit Icon" />,
    disabledIcon: <img src={editIconDisabled} alt="Edit Icon" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
    isUpdate: true,
  },
  REJECT_OUTLINE: {
    label: "Reject",
    icon: <img src={rejectIcon} alt="Reject Icon" />,
    disabledIcon: <img src={rejectDisabledIcon} alt="Reject Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
  },
  CANCEL_OUTLINE: {
    label: "Cancel",
    icon: <img src={cancelIcon} alt="Cancel Icon" />,
    disabledIcon: <img src={cancelIconDisabled} alt="Cancel Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
    isEnableInReadMode: true,
  },
  CANCEL_SMALL_VARIANT: {
    label: "Cancel",
    icon: <img src={cancelSmallIcon} alt="Cancel Icon" />,
    disabledIcon: <img src={cancelSmallIcon} alt="Cancel Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.crimson,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },
  NO_OUTLINE: {
    label: "No",
    icon: <img src={cancelIcon} alt="No Icon" />,
    disabledIcon: <img src={cancelIconDisabled} alt="No Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
  },
  CLOSE_OUTLINE: {
    label: "Close",
    icon: <img src={cancelIcon} alt="Cancel Icon" />,
    disabledIcon: <img src={cancelIconDisabled} alt="Cancel Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
  },
  SAVE_OUTLINE: {
    label: "Save",
    icon: <img src={saveIcon} alt="Save Icon" />,
    disabledIcon: <img src={saveDisableIcon} alt="Save Icon" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    loadingIcon: <img src={saveIconLoading} alt="Save Icon Loading" />,
    isCreate: true,
    isUpdate: true,
  },
  SHARE_OUTLINE: {
    label: "Share Pymt.",
    icon: <img src={shareIcon} alt="Share Icon" />,
    disabledIcon: <img src={shareDisabledIcon} alt="Share Icon" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    // loadingIcon: <img src={saveIconLoading} alt="Save Icon Loading" />,
  },

  SUBMIT_OUTLINE: {
    label: "Submit",
    icon: <img src={submitIcon} alt="Submit Icon" />,
    disabledIcon: <img src={submitIcon} alt="Submit Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    tooltip: "",
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    // loadingIcon: <img src={saveIconLoading} alt="Submit Icon Loading" />,
  },
  SUBMIT_REAPPEAL: {
    label: "Submit",
    icon: <img src={submitIcon} alt="Submit Icon" />,
    disabledIcon: <img src={submitIcon} alt="Submit Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    tooltip: "",
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    // loadingIcon: <img src={saveIconLoading} alt="Submit Icon Loading" />,
  },
  SUBMIT: {
    label: "Submit",
    icon: <img src={sendApprovalIcon} alt="Submit Icon" />,
    disabledIcon: <img src={sendApprovalIconDisabled} alt="Submit Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    tooltip: "",
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    loadingIcon: <img src={saveIconLoading} alt="Submit Icon Loading" />,
  },
  MENU: {
    icon: <img src={menuIconHover} alt="Save Icon" />,
    disabledIcon: <img src={menuIcon} alt="Disabled Save Icon" />,
    color: BUTTON_COLOR_TYPE.icon,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "justify-center",
    isUpdate:true,
    // isCreate:true
  },
  FILTER_OUTLINE: {
    label: "Filter",
    icon: <img src={filterIcon} alt="Filter Icon" />,
    disabledIcon: <img src={filterIcon} alt="Filter Icon" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    isEnableInReadMode: true,
  },
  ICON_TEXT_ROW: {
    label: "Add",
    icon: <img src={addIcon} alt="upload Icon" />,
    disabledIcon: <img src={addIconDisabled} alt="add Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.iconTextRow,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-row",
    isLabelLink: true,
    isCreate: true,
    isUpdate: true,
  },
  ICON_TEXT_ROW_SUBMIT: {
    label: "Submit",
    icon: <img src={smallSubmitBlueIcon} alt="upload Icon"  className="w-3 h-3"/>,
    disabledIcon: <img src={smallSubmitGrayIcon} alt="add Icon Disabled" className="w-3 h-3"/>,
    color: BUTTON_COLOR_TYPE.iconTextRow,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-row",
    isLabelLink: true,
    isCreate: true,
    isUpdate: true,
  },
  ICON_TEXT_BACK_TO_LOGIN: {
    label: "Back To Login",
    icon: "pi pi-chevron-left",
    disabledIcon: "pi pi-chevron-left",
    color: BUTTON_COLOR_TYPE.iconTextBackToLogin,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-row",
    isLabelLink: true,
  },
  ICON_TEXT_NEW_VALUE: {
    label: "New Value",
    icon: "pi pi-external-link",
    disabledIcon: "pi pi-external-link",
    color: BUTTON_COLOR_TYPE.newValue,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-row-reverse",
    isLabelLink: true,
  },
  TEXT_CHANGE_EMAIL: {
    label: undefined,
    icon: undefined,
    disabledIcon: "",
    color: BUTTON_COLOR_TYPE.textButton,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "",
    labelText: "Change Email Id",
  },
  TEXT_RESENT_OTP: {
    label: undefined,
    icon: undefined,
    disabledIcon: "",
    color: BUTTON_COLOR_TYPE.textButton,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "",
    labelText: "Resent OTP",
  },
  ICON_BUTTON_SMALL_CROSS: {
    label: undefined,
    icon: "pi pi-times",
    disabledIcon: "pi pi-times",
    color: BUTTON_COLOR_TYPE.icon,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
  },
  ICON_BUTTON_SMALL_EYE: {
    label: undefined,
    icon: "pi pi-apple",
    disabledIcon: "pi pi-apple",
    color: BUTTON_COLOR_TYPE.icon,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
  },
  ICON_BUTTON_ADD: {
    label: undefined,
    icon: "pi pi-plus",
    disabledIcon: "pi pi-plus",
    color: BUTTON_COLOR_TYPE.iconTextRow,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-row",
    isCreate: true,
    isUpdate: true,
  },
  STAGE_COMPLETE: {
    label: "Stg Complete",
    icon: <img src={StageIcon} alt="Save Icon" />,
    disabledIcon: <img src={StageDisabledIcon} alt="Save Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    isCreate: true,
    isUpdate: true,
  },
  CONFIRM: {
    label: "Confirm",
    icon: <img src={confirmIcon} alt="Confirm Icon" />,
    disabledIcon: <img src={confirmIcon} alt="Confirm Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
    isCreate: true,
    isUpdate: true,
  },
  COLLECT: {
    label: "Collect",
    icon: <img src={collectIcon} alt="Collect Icon" />,
    disabledIcon: <img src={collectIcon} alt="Collect Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },
  INITIATE: {
    label: "Initiate",
    icon: <img src={submitIcon} alt="submit Icon" />,
    disabledIcon: <img src={submitIcon} alt="Submit Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    isCreate: true,
    isUpdate: true,
  },
  DELEGATE: {
    label: "Delegate",
    icon: <img src={submitIcon} alt="Delegate Icon" />,
    disabledIcon: <img src={submitIcon} alt="Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },
  RE_OPEN: {
    label: "Re-Open",
    icon: <img src={submitIcon} alt="Re-Open Icon" />,
    disabledIcon: <img src={submitIcon} alt="Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },
  RE_APPEAL_SUBMIT: {
    label: "Submit",
    icon: <img src={TickRight} alt="Re-Open Icon" />,
    disabledIcon: <img src={TickRight} alt="Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.red,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },

  RE_APPEAL: {
    label: "Re-apl.",
    icon: <img src={reAppealIcon} alt="Re-Appeal Icon" />,
    disabledIcon: <img src={reAppealDisabledIcon} alt="Disabled Icon" className="reappeal-disabled"  />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    tooltip: "Re-appeal",
    tooltipOptions: {
      position: "top",
      className: "text-xs font-Roboto bg-black text-white !p-1 rounded-md ",
      showDelay: "1000,",
      hideDelay: "300",
    },
    flexClassName: "flex-col",
    isCreate: true,
    isUpdate:true
  },

  REJECT_APPLICATION: {
    label: "Rej.App",
    icon: <img src={cancelSmallDarkIcon} alt="Reject-Application Icon" />,
    disabledIcon: <img src={cancelSmallDarkDisabledIcon} alt="Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    tooltip: "Reject Application",
    tooltipOptions: {
      position: "top",
      className: "text-xs font-Roboto bg-black text-white !p-1 rounded-md ",
      showDelay: "1000,",
      hideDelay: "300",
    },
    flexClassName: "flex-col",
    isCreate: true,
    isUpdate:true
  },

  YES: {
    label: "Yes",
    icon: <img src={confirmIcon} alt="Yes Icon" />,
    disabledIcon: <img src={confirmIcon} alt="Yes Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.crimson,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
    isCreate: true,
    isUpdate: true,
  },

  NO: {
    label: "No",
    icon: <img src={cancelIcon} alt="No Icon" />,
    disabledIcon: <img src={cancelIconDisabled} alt="No Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.outline,
    variant: BUTTON_STYLE_TYPE.SECONDARY,
    flexClassName: "flex-col",
  },

  UPLOAD_PRIMARY_WITHOUT_LABEL: {
    label: "",
    icon: <img src={uploadIcon} alt="upload Icon" />,
    disabledIcon: <img src={uploadIcon} alt="upload Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },

  VALIDATE: {
    label: "",
    icon: "",
    disabledIcon: "",
    color: "",
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },

  ADD_ASSET: {
    label: "Add",
    icon: <img src={addIcon} alt="upload Icon" />,
    disabledIcon: <img src={addIconDisabled} alt="add Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.iconTextRow,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-row",
  },

  ICON_DELETE: {
    label: "",
    icon: <img src={deleteIcon} alt="upload Icon" />,
    disabledIcon: <img src={deleteIcon} alt="upload Icon Disabled" />,
    color: BUTTON_COLOR_TYPE.iconTextRow,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-row border-none",
  },
  VERIFY: {
    label: "Verify",
    icon: <img src={verifyIcon} alt="Verify Icon" />,
    disabledIcon: <img src={verifyIcon} alt="Verify Disabled Icon" />,
    color: BUTTON_COLOR_TYPE.plum,
    variant: BUTTON_STYLE_TYPE.PRIMARY,
    flexClassName: "flex-col",
  },
};

const ButtonBox: React.FC<IButtonBoxProps> = (props: IButtonBoxProps) => {
  const [showLoader, setShowLoader] = useState(false);
  const buttonType: keyof typeof BUTTON_OBJECT = props.buttonType ?? "CREATE";
  const buttonElement: any = { ...BUTTON_OBJECT[buttonType] };
  const { color, variant, onClickWithLoader, args, iconButtonLabel, ...primeReactProps } = props;
  const getClassNames = () => {
    const styleObject = {
      ...BUTTON_BOX_STYLES,
      overrideStyle: props.styleObj,
      overrideClasses: props.className,
      isDisabled: props.disabled,
      removeStyleProperty: [],
      removeClasses: [],
    };

    return generateClassesForButton(styleObject, buttonElement.variant, buttonElement.color);
  };

  const { value, name, label, iconPos, onClick, disabled } = primeReactProps;

  const { t } = useTranslation();
  let translatedLabel = t(label!, args);

  if (buttonElement.labelText) {
    translatedLabel = t(buttonElement.labelText!, args);
  }

  const onHandlerClick = async (e: any) => {
    if (onClick) {
      onClick(e);
    } else if (onClickWithLoader) {
      const res = onClickWithLoader();
      if (res && Promise.resolve(res) === res) {
        setShowLoader(true);
        res
          .then(() => {
            setShowLoader(false);
          })
          .catch(() => {
            setShowLoader(false);
          });
      }
    }
  };
  if (props.customLabel) {
    buttonElement.label = props.customLabel;
  }
  let classNames = getClassNames();
  if (props.size === "LOAN_DATA") {
    classNames = classNames.replace("h-12", "h-8");
    classNames = classNames.replace("w-12", "w-8");
  }

  return (
    <div
      className={`flex items-center ${buttonElement.label ? "gap-1.5" : ""} ${
        buttonElement.flexClassName
      }`}
    >
      <div className="flex items-center h-full">
        <BaseButton
          {...primeReactProps}
          color={color}
          className={classNames}
          value={value}
          name={name}
          label={translatedLabel}
          loadingIcon={buttonElement.loadingIcon}
          iconPos={iconPos ?? "right"}
          icon={disabled ? buttonElement.disabledIcon : buttonElement.icon}
          tooltip={buttonElement.tooltip}
          tooltipOptions={buttonElement.tooltipOptions}
          onClick={onHandlerClick}
          loading={showLoader}
          disabled={showLoader || disabled}
          isEnableInReadMode={buttonElement.isEnableInReadMode}
          buttonType={buttonType}
          disabledIcon={buttonElement.disabledIcon}
          buttonElement={buttonElement}
        />
      </div>

      {buttonElement.label ? (
        <div className="flex items-center">
          <Text
            label={iconButtonLabel ?? buttonElement.label}
            className={
              buttonElement.isLabelLink
                ? "text-xs text-left text-primary-ptext-1000 cursor-pointer"
                : "text-xs text-left text-primary-ptext-1000"
            }
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

// Memoization

// redux wiring

// exports
export default memo(ButtonBox);
