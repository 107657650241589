/**
 * Author : Kaviraj RM
 * Created on : 20 May, 2023
 **/

import React, { useEffect, useRef } from "react";
import { INPUT_BOX_STYLES } from "assets/base-theme";
import { generateClasses } from "utils/Util";
import { useTranslation } from "react-i18next";
import BasePassword, { IBaseInputPasswordProps } from "components/base/BasePassword";

const InputPassword: React.FC<IBaseInputPasswordProps> = (props: any) => {
  const { onPasswordValidityChange } = props;
  const getClassNames = () => {
    const styleObject = {
      ...INPUT_BOX_STYLES,
      overrideStyle: props.styleObj,
      overrideClasses: props.className,
      isDisabled: props.disabled,
      isInvalid: props.validation ? true : false,
      removeStyleProperty: [],
      removeClasses: [],
    };

    return generateClasses(styleObject, props.variant);
  };

  const {
    value,
    onChange,
    onChangeTextState,
    name,
    placeholder,
    label,
    validation,
    disabled,
    showPasswordVerification,
    ref,
  } = props;

  const passwordRef = useRef(null);

  useEffect(() => {
    // @ts-ignore :we will do it later
    const isPasswordValid = passwordRef.current?.getPasswordValidity();

    if (onPasswordValidityChange) {
      onPasswordValidityChange(isPasswordValid);
    }
  }, [value]);

  const { t } = useTranslation();
  const placeholderLabel = t(placeholder);

  return (
    <BasePassword
      {...props}
      className={getClassNames()}
      value={value}
      promptLabel="Password strength"
      mediumLabel="Good"
      onChange={onChange}
      onChangeTextState={onChangeTextState}
      name={name}
      placeholder={placeholderLabel}
      disabled={disabled}
      label={label}
      validation={validation}
      showPasswordVerification={showPasswordVerification}
      ref={passwordRef}
    />
  );
};

// ----------- memoization -------------------- //

// ----------- redux wiring ------------------- //

// ----------- exports ------------------------ //
export default React.memo(InputPassword);
