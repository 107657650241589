/**
 * Author : Kaviraj RM
 * Created on : 25 May, 2023
 **/

/*
    --------------------------------------------------------------------------------------------------------------------
    Imports:
    --------------------------------------------------------------------------------------------------------------------
  */
import passwordSuccess from "assets/img/auth/Tick_Graphics_Icon.svg";
import sahayyaIllustration from "assets/img/common/Sahayya_Illustration.svg";
import sahayyaLogo from "assets/img/common/Sahayya_Logo.svg";
import SimpleButton from "components/SimpleButton";
import Text from "components/Text/Text";
import { RouteProps, withRouter } from "hoc/withRouter";
import { useNavigate } from "react-router-dom";
import { linkPageLogin } from "routes";
import { BUTTON_STYLE_TYPE } from "utils/Constant";

// -------------------------------------------------- IProps ----------------------------------------------------- //
interface IProps extends RouteProps {}

function PasswordResetSuccess(props: IProps) {
  /*
    ----------------------------------------------------------------------------------------------------------------------
    States
    ----------------------------------------------------------------------------------------------------------------------
  */

    const navigate = useNavigate()

  //Password

  /*
    ----------------------------------------------------------------------------------------------------------------------
    Handlers: Event handler for onChange, onBlur etc
    ----------------------------------------------------------------------------------------------------------------------
  */

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Validators:
    -----------------------------------------------------------------------------------------------------------------------
  */

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Helper Functions:
    -----------------------------------------------------------------------------------------------------------------------
  */

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Submit / Save Data Functions:
    -----------------------------------------------------------------------------------------------------------------------
  */

  const onLogin = async (e: any) => {
    e.preventDefault();
    navigate(linkPageLogin)
  };

  return (
    <div className="flex justify-center relative h-screen">
      <div className="absolute left-6 top-6">
        <img src={sahayyaLogo} alt="Sahayya Logo" />
      </div>
      <div className="w-76.5 z-10 px-9 mt-49 mb-35">
        <div className="flex justify-center items-center">
          <img src={passwordSuccess} alt="Sahayya Logo" />
        </div>
        <div className="flex justify-center mt-6">
          <Text
            label="heading.auth.resetPasswordSuccess"
            className="text-primary-primaryTextColor text-h1 font-Roboto font-bold"
          />
        </div>
        <div className="my-6 flex justify-center text-center">
          <Text
            label="text.auth.passwordResetSuccess"
            className="text-primary-primaryTextColor text-xs font-Roboto"
          />
        </div>

        <div className="mt-4 w-full ">
          <SimpleButton
            label="component.button.logIn"
            variant={BUTTON_STYLE_TYPE.PRIMARY}
            onClick={onLogin}
          />
        </div>
      </div>
      <div className="absolute -bottom-64 ">
        <img src={sahayyaIllustration} alt="search-icon" className="max-w-fit w-full h-61" />
      </div>
    </div>
  );
}

export default withRouter(PasswordResetSuccess);
