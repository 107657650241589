import React from "react";
import { FadeLoader } from "react-spinners";
interface ILoaderProps {
  loading?: boolean;
  color?: string;
  css?: string;
  speedMultiplier?: number;
}
function Loader(props: ILoaderProps) {
  const { loading, color, css, speedMultiplier } = props;

  return (
    <div className="grid place-items-center h-screen">
      <FadeLoader
        {...props}
        loading={loading}
        color={color}
        css={css}
        speedMultiplier={speedMultiplier}
      />
    </div>
  );
}

// memoization
export default React.memo(Loader);
