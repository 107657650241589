import { TFileUploadStatusFromOs } from "components/FileUploadCustom/FileUploadCustom";
import {
  LINK_CAM,
  LINK_DDE_CREDIT_QUERY,
  LINK_DDE_CRIF_DETAILED_REPORT,
  LINK_DDE_LOGIN,
  LINK_DDE_QC,
  LINK_DDE_STAGE_AND_STATUS,
  LINK_DEDUPE,
  LINK_FEES_SCREEN,
  LINK_FINAL_APPROVAL,
  LINK_FINANCIAL_APPROVAL,
  LINK_IN_PRINCIPLE_APPROVAL,
  LINK_LEGAL,
  LINK_PD,
  LINK_POINT_OF_INTEREST,
  LINK_RCU,
  LINK_RE_APPEAL,
  LINK_SUMMARY,
  LINK_TECHNICAL,
} from "transactionRoutes";
import {
  TCamChargeTypeCode,
  TCamConfigurationTypeCode,
  TCamFeeStatus,
} from "views/transaction/PageCAM/Sections/SchemeDetails/SchemeDetails";
import { TDeviationType } from "views/transaction/PageDDE/Legal/PageDeviations/PageDeviations";
import { TLegalTextEditorSection } from "views/transaction/PageDDE/Legal/Sections/SaveContentTextEditor/SaveContentTextEditor";
import {
  ILegalEditableTableColumnConfig,
  ILegalEditableTableConfig,
  TypeLegalEditableTableColumn,
  TypeLegalEditableTableSection,
  TypeLegalTableColumnFormat,
} from "views/transaction/PageDDE/Legal/Sections/SaveLegalEditableTable/SaveEditableTable";
import { TLoginReceiptStatusType } from "views/transaction/PageDDE/Login/PageFees/PageFees";
import {
  DocumentCheckTableRecordType,
  DocumentCheckType,
  IDocumentCheckConfig,
} from "views/transaction/PageDDE/QC/PageAssetDocumentCheck/DocumentCheck/DocumentCheck";

export const LOCAL_STORAGE = {
  USER_INFO: "userInfo",
  STAGE_DETAIL: "stageDetail",
  MENU_LIST_MAPPING: "menuListMapping",
  SYSTEM_DATE: "systemDate",
  STATIC_MASTER: "staticMaster",
  IS_WEB_ACCESS: "isWebAccess",
};

export const SESSION_STORAGE = {
  IS_WEB_VIEW: "isWebView",
};

export const LANGUAGES = {
  EN: "en",
  FR: "fr",
};
export const LOCALE = {
  IN: "en-IN",
  US: "en-US",
};
export const DEFAULT_LOCALE_CONST = {
  LANG: "defaultLanguage",
  CURRENCY: "defaultCurrency",
  LOCALE: "defaultLocale",
};
export const ROUNDING_MODE = {
  ROUND: Math.round,
  FLOOR: Math.floor,
  CEIL: Math.ceil,
};

export const INPUT_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
};

export const INPUT_NUMBER_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const AUTOCOMPLETE_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const OTP_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const LABEL_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  RIGHT: "RIGHT",
};

export const DROPDOWN_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
  QUATERNARY: "QUATERNARY",
};

export const IS_ACTIVE = [
  {
    label: "Yes",
    value: "YES",
  },
  {
    label: "No",
    value: "NO",
  },
  {
    label: "Both",
    value: "",
  },
];
export const APPROVAL_REQUEST_STATUS = [
  {
    label: "REJECTED",
    value: "REJECTED",
  },
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "APPROVED",
    value: "APPROVED",
  },
];

export const BANKS = [
  {
    label: "Yes Bank",
    value: "YES",
  },
  {
    label: "HDFC",
    value: "HDFC",
  },
  {
    label: "ICICI",
    value: "ICICI",
  },
];

export const INPUT_ERROR_TYPE = {
  BORDER: "BORDER",
  DEFAULT: "DEFAULT",
};

export const LIMIT_TYPE = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};

export const AUTOCOMPLETE_MESSAGES = {
  NO_Banks: "No banks found",
  NO_Rules: "No Rules Found",
  NO_City: "No City Found",
  NO_State: "No State Found",
  NO_Pincode: "No Pincode Found",
  NO_Branch: "No Branch Found",
  NO_OrgReportingLevel: "No Organisation Found",
  NO_IfscCode: "No IFSC code",
};

export const CHECKBOX_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const TOAST_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
  QUATERNARY: "QUATERNARY",
};

export const BUTTON_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
};

export const BUTTON_TYPE = {
  CREATE: "CREATE",
  CREATE_NEW_RECEIPT: "CREATE_NEW_RECEIPT",
  CREATE_NEW_DEPOSIT: "CREATE_NEW_DEPOSIT",
  MENU: "MENU",
  SEND_FOR_APPROVAL: "SEND_FOR_APPROVAL",
  SHARE_OUTLINE: "SHARE_OUTLINE",
  APPROVE: "APPROVE",
  APPROVE_PRIMARY: "APPROVE_PRIMARY",
  REJECT_PRIMARY: "REJECT_PRIMARY",
  SEARCH_OUTLINE: "SEARCH_OUTLINE",
  RESET_OUTLINE: "RESET_OUTLINE",
  REJECT_OUTLINE: "REJECT_OUTLINE",
  CANCEL_OUTLINE: "CANCEL_OUTLINE",
  CLOSE_OUTLINE: "CLOSE_OUTLINE",
  SAVE_OUTLINE: "SAVE_OUTLINE",
  SAVE_AND_CONTINUE: "SAVE_AND_CONTINUE",
  SUBMIT_OUTLINE: "SUBMIT_OUTLINE",
  SUBMIT_SMALL: "SUBMIT_SMALL",
  SUBMIT: "SUBMIT",
  FILTER_OUTLINE: "FILTER_OUTLINE",
  MAKE_DEPOSIT: "MAKE_DEPOSIT",
  BACK_OUTLINE: "BACK_OUTLINE",
  EDIT_OUTLINE: "EDIT_OUTLINE",
  ICON_TEXT_ROW: "ICON_TEXT_ROW",
  ICON_TEXT_ROW_SUBMIT: "ICON_TEXT_ROW_SUBMIT",
  ICON_TEXT_BACK_TO_LOGIN: "ICON_TEXT_BACK_TO_LOGIN",
  ICON_TEXT_NEW_VALUE: "ICON_TEXT_NEW_VALUE",
  TEXT_CHANGE_EMAIL: "TEXT_CHANGE_EMAIL",
  TEXT_RESENT_OTP: "TEXT_RESENT_OTP",
  ICON_BUTTON_SMALL_CROSS: "ICON_BUTTON_SMALL_CROSS",
  ICON_BUTTON_SMALL_EYE: "ICON_BUTTON_SMALL_EYE",
  ICON_BUTTON_ADD: "ICON_BUTTON_ADD",
  STAGE_COMPLETE: "STAGE_COMPLETE",
  CONFIRM: "CONFIRM",
  COLLECT: "COLLECT",
  NO_OUTLINE: "NO_OUTLINE",
  YES: "YES",
  NO: "NO",
  CANCEL_SMALL_VARIANT: "CANCEL_SMALL_VARIANT",
  INITIATE: "INITIATE",
  DELEGATE: "DELEGATE",
  RE_OPEN: "RE_OPEN",
  RE_APPEAL: "RE_APPEAL",
  REJECT_APPLICATION: "REJECT_APPLICATION",
  RE_APPEAL_SUBMIT: "RE_APPEAL_SUBMIT",
  UPLOAD_PRIMARY_WITHOUT_LABEL: "UPLOAD_PRIMARY_WITHOUT_LABEL",
  VALIDATE: "VALIDATE",
  ADD_ASSET: "ADD_ASSET",
  ICON_DELETE: "ICON_DELETE",
  VERIFY: "VERIFY",
};

export const BUTTON_COLOR_TYPE = {
  plum: "plum",
  outline: "outline",
  iconTextRow: "iconTextRow",
  iconTextBackToLogin: "iconTextBackToLogin",
  newValue: "newValue",
  textButton: "textButton",
  icon: "icon",
  red: "red",
  crimson: "crimson",
};

export const COLOR_STYLE_TYPE = {
  RED: "RED",
  GRAY: "GRAY",
  PLUM: "PLUM",
};

export const POSITION = {
  TOP: "TOP",
  BOTTOM: "BOTTOM",
  RIGHT: "RIGHT",
  LEFT: "LEFT",
};

export const LOADER_POSITION = {
  RIGHT: "right",
  LEFT: "left",
};

export const MIME_TYPE = {
  JSON: "application/json",
  PLAIN: "text/plain",
};

export const IS_ROUTE_GUARD = {
  Y: "Y",
  N: "N",
};

export const PAGE_MODE = {
  CREATE: "CREATE",
  VIEW: "VIEW",
  EDIT: "EDIT",
  REVIEW: "REVIEW",
  REVIEW_CREATE: "REVIEW_CREATE",
};

export const REVIEW_MODE = {
  OLD: "OLD",
  NEW: "NEW",
};

export const STATIC_MASTER_TYPE_TOAST_TEXT = {
  SENT_FOR_APPROVAL: "Sent for Approval",
  SAVED: "Saved Successfully",
  ACTIVATED: "Activated Successfully",
  DEACTIVATED: "Deactivated Successfully",
  ERROR: "Please fix the error(s) on screen before saving",
  REJECTED: "Request Rejected",
  APPROVED: "Request Approved",
  REMOVED: "Removed Successfully",
  INITIATED: "Initiated Successfully",
  STAGE_COMPLETED: "Stage Completed Successfully",
};

export const CUSTOM_TOAST_MESSAGES = {
  HISTORICALFINDETAILS_VALIDATION: "Either Turnover, Profit or Capital/Networth must be completed",
};

export const APPROVAL_REQUEST_TOAST_TEXT = {
  REJECTED: "Request Rejected",
  APPROVED: "Request Approved",
};

export const EDITABLE_TABLE_ACTIONS = {
  IS_ADDED: "isAdded",
  IS_UPDATED: "isUpdated",
  IS_INACTIVE: "isInactive",
};

export const OPERATION_TYPE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const ACTIVE_TYPE = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};
export enum BRANCH_TYPE {
  SPOKE = "SPOKE",
  BRANCH = "BRANCH",
  RO = "RO",
  HO = "HO",
}

export enum ENTITY {
  SYSTEM_DATE = "SYSTEM_DATE",
  SYSTEM_CONFIGURATION = "SYSTEM_CONFIGURATION",
  STATIC_MASTER_TYPE = "STATIC_MASTER_TYPE",
  STATIC_MASTER_DATA = "STATIC_MASTER_DATA",
  ORG_LEVEL_TYPE = "ORG_LEVEL_TYPE",
  ORG_REPORTING_LEVEL = "ORG_REPORTING_LEVEL",
  BRANCH = "BRANCH",
  DEPARTMENT = "DEPARTMENT",
  DESIGNATION = "DESIGNATION",
  BANK = "BANK",
  BANK_REALTIONSHIP = "BANK_REALTIONSHIP",
  IFSC = "IFSC",
  DEPOSIT_ACCOUNT = "DEPOSIT_ACCOUNT",
  STATE = "STATE",
  CITY = "CITY",
  PINCODE = "PINCODE",
  PARTNER = "PARTNER",
  PARTNER_USER = "PARTNER_USER",
  USER_PROFILE = "USER_PROFILE",
  BUSINESS_RULE = "BUSINESS_RULE",
  DEVIATION = "DEVIATION",
  DEVIATION_CATEGORY = "DEVIATION_CATEGORY",
  CHECKLIST = "CHECKLIST",
  PRODUCT = "PRODUCT",
  TRANSACTION_APPROVAL_RULE = "TRANSACTION_APPROVAL_RULE",
  INCOME_SCHEME = "INCOME_SCHEME",
  FEE = "FEE",
  DOCUMENT_TYPE = "DOCUMENT_TYPE",
  APPROVAL_LEVEL = "APPROVAL_LEVEL",
  APPROVAL_REQUEST = "APPROVAL_REQUEST",
  INDUSTRY_TYPE = "INDUSTRY_TYPE",
  ACL_ROLE = "ACL_ROLE",
}

export const STATUS_LIST = [
  { label: "Active", value: "ACTIVE" },
  { label: "In Active", value: "IN_ACTIVE" },
];

// ENVIRONMENT VARIABLE CONSTANTS
export const ENV_VAR = {
  VITE_BASE_API_URL: "VITE_BASE_API_URL",
  VITE_ENABLE_PWA_MODE: "VITE_ENABLE_PWA_MODE",
  VITE_LOCAL_DB_NAME: "VITE_LOCAL_DB_NAME",
  VITE_IS_ACL_ENABLED: "VITE_IS_ACL_ENABLED",
  VITE_IS_ROUTE_GUARD: "VITE_IS_ROUTE_GUARD",
  VITE_SHOW_LOGS: "VITE_SHOW_LOGS",
  VITE_GOOGL_MAP_KEY: "VITE_GOOGL_MAP_KEY",
  VITE_NOTIFICATION_POLLING: "VITE_NOTIFICATION_POLLING",
  VITE_MESSAGE_CONVERSATION_TIMING: "VITE_MESSAGE_CONVERSATION_TIMING",
  VITE_MESSAGE_LIST_TIMING: "VITE_MESSAGE_LIST_TIMING",
};

export const SIDE_MENU_ROOT_CODES = {
  MASTER_DATA: "MASTER_DATA",
  LOAN_DATA: "LOAN_DATA",
  LOAN_LISTING: "LOAN_LISTING",
  LOAN_APPLICATION_MENU: "LOAN_APPLICATION_MENU",
};

export const SEARCH_OPTION = {
  QUICK_SEARCH: { label: "Quick Search", value: "QUICK_SEARCH" },
  ADVANCE_SEARCH: { label: "Advance Search", value: "ADVANCE_SEARCH" },
};

export const QUICK_SEARCH_PIPELINE_OPTIONS = [
  { label: "Login", value: "LOGIN" },
  { label: "Credit", value: "CREDIT_AND_QUERY" },
  { label: "QC", value: "QC" },
  { label: "PD", value: "PD" },
  { label: "Legal", value: "LEGAL_REPORT" },
  { label: "Technical", value: "TECHNICAL_REPORT" },
  { label: "RCU", value: "RCU_REPORT" },
  { label: "Financial Approval", value: "FINANCIAL_APPROVAL" },
  { label: "In-Principle Approval", value: "IN_PRINCIPAL_APPROVAL" },
  { label: "Final Approval", value: "FINAL_APPROVAL" },
  { label: "Docket", value: "DOCKET" },
  { label: "Operations", value: "OPERATIONS" },
  { label: "Disbursement", value: "DISBURSEMENT" },
  { label: "Reappeal", value: "REAPPEAL" },
  { label: "All", value: "ALL" },
];
export const STAGE_STATUS_PARTNER_TASK = {
  // no update
  INITIATED: "Initiated",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  RE_OPEN: "Re-open",
  IN_REVIEW: "In Review",
  PAUSED: "Paused",
  REJECTED: "Rejected",
  APPROVED: "Approved",
};

export const QUICK_SEARCH_PARTNER_TASKS = [
  { label: "Initiated", value: STAGE_STATUS_PARTNER_TASK.INITIATED },
  { label: "Completed", value: STAGE_STATUS_PARTNER_TASK.COMPLETED },
  { label: "In Review", value: STAGE_STATUS_PARTNER_TASK.IN_REVIEW },
  { label: "All", value: "ALL" },
];

export const ADVANCE_SEARCH_PARTNER_TASKS = [
  { label: "Initiated", value: STAGE_STATUS_PARTNER_TASK.INITIATED },
  { label: "Completed", value: STAGE_STATUS_PARTNER_TASK.COMPLETED },
  { label: "In Review", value: STAGE_STATUS_PARTNER_TASK.IN_REVIEW },
  { label: "Re Open", value: STAGE_STATUS_PARTNER_TASK.RE_OPEN },
  { label: "Paused", value: STAGE_STATUS_PARTNER_TASK.PAUSED },
  { label: "Cancelled", value: STAGE_STATUS_PARTNER_TASK.CANCELLED },
  { label: "Approved", value: STAGE_STATUS_PARTNER_TASK.APPROVED },
  { label: "Rejected", value: STAGE_STATUS_PARTNER_TASK.REJECTED },
  { label: "All", value: "ALL" },
];

//DOCUMENT CATEGORY
export const DOCUMENT_CATEGORY = {
  DOCUMENT_CATEGORY_KYC: "DOCUMENT_CATEGORY_KYC",
  DOCUMENT_CATEGORY_ADDRESS_PROOF: "DOCUMENT_CATEGORY_ADDRESS_PROOF",
  DOCUMENT_CATEGORY_BUSINESS_PROOF: "DOCUMENT_CATEGORY_BUSINESS_PROOF",
  DOCUMENT_CATEGORY_DOB_PROOF: "DOCUMENT_CATEGORY_DOB_PROOF",
  DOCUMENT_CATEGORY_BANK_ACCOUNT_DOCUMENT: "DOCUMENT_CATEGORY_BANK_ACCOUNT_DOCUMENT",
  DOCUMENT_CATEGORY_INVESTMENT_PROOF: "DOCUMENT_CATEGORY_INVESTMENT_PROOF",
  DOCUMENT_CATEGORY_CATEGORY_PROOF: "DOCUMENT_CATEGORY_CATEGORY_PROOF",
  DOCUMENT_CATEGORY_FEE_RECEIPT: "DOCUMENT_CATEGORY_FEE_RECEIPT",
  DOCUMENT_CATEGORY_EXCHANGE_DOCUMENTS: "DOCUMENT_CATEGORY_EXCHANGE_DOCUMENTS",
  DOCUMENT_CATEGORY_COLLATERAL_PROPERTY: "DOCUMENT_CATEGORY_COLLATERAL_PROPERTY",
  DOCUMENT_CATEGORY_SALARIED_SALARY_PROOF: "DOCUMENT_CATEGORY_SALARIED_SALARY_PROOF",
  DOCUMENT_CATEGORY_BUSINESS_PROOF_PD: "DOCUMENT_CATEGORY_BUSINESS_PROOF_PD",
  DOCUMENT_CATEGORY_EMPLOYMENT_RETAILS_RECORDING: "DOCUMENT_CATEGORY_EMPLOYMENT_RETAILS_RECORDING",
  DOCUMENT_CATEGORY_LEGAL_DOCUMENT: "DOCUMENT_CATEGORY_LEGAL_DOCUMENT",
  DOCUMENT_CATEGORY_OTHER_DOCUMENTS: "DOCUMENT_CATEGORY_OTHER_DOCUMENTS",
  DOCUMENT_CATEGORY_OPS_DOCUMENT: "DOCUMENT_CATEGORY_OPS_DOCUMENT",
  DOCUMENT_CATEGORY_RCU_DOCUMENT: "DOCUMENT_CATEGORY_RCU_DOCUMENT",
  DOCUMENT_CATEGORY_RCU_ADDITIONAL_DOCUMENTS: "DOCUMENT_CATEGORY_RCU_ADDITIONAL_DOCUMENTS",
  DOCUMENT_CATEGORY_TECHNICAL_DOCUMENT: "DOCUMENT_CATEGORY_TECHNICAL_DOCUMENT",
  DOCUMENT_CATEGORY_PROPERTY_PICTURES: "DOCUMENT_CATEGORY_PROPERTY_PICTURES",
  DOCUMENT_CATEGORY_FEE_DEPOSIT: "DOCUMENT_CATEGORY_FEE_DEPOSIT",
  DOCUMENT_CATEGORY_CREDIT_DOCUMENTS: "DOCUMENT_CATEGORY_CREDIT_DOCUMENTS",
  DOCUMENT_CATEGORY_BANK_STATEMENTS_ANALYSIS: "DOCUMENT_CATEGORY_BANK_STATEMENTS_ANALYSIS",
  DOCUMENT_CATEGORY_REAPPEAL_DOCUMENTS: "DOCUMENT_CATEGORY_REAPPEAL_DOCUMENTS",
};

//DOCUMENT SECTIONS
export const DOCUMENT_SECTION = {
  DOCUMENT_SECTIONS_FRONT_PAGE: "DOCUMENT_SECTIONS_FRONT_PAGE",
  DOCUMENT_SECTIONS_BACK_PAGE: "DOCUMENT_SECTIONS_BACK_PAGE",
  DOCUMENT_SECTIONS_FILE_1: "DOCUMENT_SECTIONS_FILE_1",
};

export const DOCUMENT_TYPE = {
  FORM_60: "FORM_60",
  PAN: "PAN",
  AADHAR_CARD: "AADHAR_CARD",
  VOTER_ID: "VOTER_ID",
  PASSPORT: "PASSPORT",
  DRIVERS_LICENSE: "DRIVERS_LICENSE",
  UTILITY_BILL_ELECTRICITY_BILL: "UTILITY_BILL_ELECTRICITY_BILL",
  GST_STATEMENTS: "GST_STATEMENTS",
  ITR: "ITR",
  BANK_ACCOUNT_STATEMENT: "BANK_ACCOUNT_STATEMENT",
};

export const FILE_TYPE_OPTIONS = [
  { label: "Image", value: "image" },
  { label: "Document", value: "application" },
  { label: "Audio", value: "audio" },
  { label: "Video", value: "video" },
];

export const APPLICANT_TYPE = {
  PRIMARY: "PRIMARY",
  CO_APPLICANT: "CO_APPLICANT",
  GUARANTOR: "GUARANTOR",
  DEPENDANT: "DEPENDANT",
  REFERENCE: "REFERENCE",
  DIRECTOR: "DIRECTOR",
  CORPORATE_CO_APPLICANT: "CORPORATE_CO_APPLICANT",
  CORPORATE_GUARANTOR: "CORPORATE_GUARANTOR",
};

export const APPLICANT_TYPE_LABEL = {
  PRIMARY: "Applicant",
  CO_APPLICANT: "Co-Applicant",
  GUARANTOR: "Guarantor",
  DEPENDANT: "Dependant",
  REFERENCE: "Reference",
  DIRECTOR: "Director",
  CORPORATE_CO_APPLICANT: "Corporate Co-Applicant",
  CORPORATE_GUARANTOR: "Corporate Guarantor",
};

export const LOAN_STATUS = [
  { label: "Pending", value: "PENDING" },
  { label: "Approved", value: "APPROVED" },
  { label: "Partially Disbursed", value: "DISBURSED_PARTIAL" },
  { label: "Fully Disbursed", value: "DISBURSED_FULL" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Expired", value: "EXPIRED" },
  { label: "Processing", value: "PROCESSION" },
  { label: "Re-appeal", value: "RE_APPEAL" },
  { label: "Draft", value: "DRAFT" },
];

export const STAGE_NAME = [
  { label: "Login", value: "LOGIN" },
  { label: "QC", value: "QC" },
  { label: "PD", value: "PD" },
  { label: "Legal", value: "LEGAL_REPORT" },
  { label: "Technical", value: "TECHNICAL_REPORT" },
  { label: "RCU", value: "RCU_REPORT" },
  { label: "Dedupe", value: "DEDUPE" },
  // { label: "CAM", value: "CAM_AND_RECOMMENDATION" },
  { label: "Financial Approval", value: "FINANCIAL_APPROVAL" },
  { label: "In-Principle Approval", value: "IN_PRINCIPAL_APPROVAL" },
  { label: "Final Approval", value: "FINAL_APPROVAL" },
  { label: "Re-appeal", value: "REAPPEAL" },
  { label: "Credit", value: "CREDIT_AND_QUERY" },
];

export const SUB_STAGE = {
  QDE: "qde",
  DDE: "dde",
};

export const STAGE_STATUS_OPTIONS = [
  { label: "Initiated", value: "INITIATED" },
  { label: "Completed", value: "COMPLETED" },
  { label: "Cancelled", value: "CANCELLED" },
  { label: "Re-open", value: "RE-OPEN" },
  { label: "In Review", value: "In Review" },
  { label: "Paused", value: "PAUSED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Approved", value: "APPROVED" },
];

export const LOAN_STATUS_ITEM = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DISBURSED_PARTIAL: "DISBURSED_PARTIAL",
  DISBURSED_FULL: "DISBURSED_FULL",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
  PROCESSION: "PROCESSION",
  RE_APPEAL: "RE_APPEAL",
  DRAFT: "DRAFT",
};

export const LOAN_STATUS_ITEM_LABEL = {
  PENDING: "Pending",
  APPROVED: "Approved",
  DISBURSED_PARTIAL: "Partially Disbursed",
  DISBURSED_FULL: "Fully Disbursed",
  REJECTED: "Rejected",
  EXPIRED: "Expired",
  PROCESSION: "Procession",
  RE_APPEAL: "Re-appeal",
  DRAFT: "Draft",
};

export const ADDRESS_TYPE = [
  { label: "RESIDENTIAL", value: "RESIDENTIAL" },
  { label: "PERMANENT", value: "PERMANENT" },
  { label: "OFFICE", value: "OFFICE" },
  { label: "EMPLOYEMENT_ADDRESS", value: "EMPLOYEMENT_ADDRESS" },
  { label: "COLLATERAL_PROPERTY", value: "COLLATERAL_PROPERTY" },
];

export const VARIANTS = Object.freeze({
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
  QUATERNARY: "QUATERNARY",
});

export const LOAN_SECTION_KEY = {
  DOB: "DOB",
  CASTE_CATEGORY: "CASTE_CATEGORY",
  EMPLOYEMENT: "EMPLOYEMENT",
  BANKING: "BANKING",
  OTHER_DOCUMENTS: "OTHER_DOCUMENTS",
  FINANCIAL: "FINANCIAL",
  DOCUMENT_EXCHANGE: "DOCUMENT_EXCHANGE",
  ADDRESS_PROOF: "ADDRESS_PROOF",
  COLLATERAL_PROPERTY: "COLLATERAL_PROPERTY",
  REFERENCE: "REFERENCE",
  PERMANENT_ADDRESS_PROOF: "PERMANENT_ADDRESS_PROOF",
  RECEIPT: "RECEIPT",
  CORPORATE_BUSINESS_PROOF: "CORPORATE_BUSINESS_PROOF",
  CORPORATE_ADDRESS_PROOF: "CORPORATE_ADDRESS_PROOF",
  DIRECTOR: "DIRECTOR",
  CAM_CREDIT_DOCUMENT: "CAM_CREDIT_DOCUMENT",
  CAM_OPS_DOCUMENT: "CAM_OPS_DOCUMENT",
};

export const ADDRESS_TYPE_OBJ = Object.freeze({
  EMPLOYEMENT_ADDRESS: "EMPLOYEMENT_ADDRESS",
});

export const ADDRESS_TYPE_VALUES = {
  PERMANENT: "PERMANENT",
  RESIDENTIAL: "RESIDENTIAL",
  COLLATERAL_PROPERTY: "COLLATERAL_PROPERTY",
  CORPORATE: "CORPORATE",
  OFFICE: "OFFICE",
  EMPLOYEMENT_ADDRESS: "EMPLOYEMENT_ADDRESS",
};

export const ADDRESS_TYPE_LABEL = {
  PERMANENT: "Permanent",
  RESIDENTIAL: "Residential",
  COLLATERAL_PROPERTY: "Collateral",
  CORPORATE: "Corporate",
  OFFICE: "Office",
  EMPLOYEMENT_ADDRESS: "Employee",
};

export const PAGE_TYPE = Object.freeze({
  DETAILS: "DETAILS",
  APPLICANT: "APPLICANT",
  CO_APPLICANT: "CO_APPLICANT",
  GUARANTOR: "GUARANTOR",
  DEPENDANT: "DEPENDANT",
  REFERENCE: "REFERENCE",
  CORPORATE_CO_APPLICANT: "CORPORATE_CO_APPLICANT",
  DIRECTOR: "DIRECTOR",
  CORPORATE_GUARANTOR: "CORPORATE_GUARANTOR",
});

export const LOADING_TYPE = Object.freeze({
  SAVING: { type: "SAVING", message: "Saving data ..." },
  DELETING: { type: "DELETING", message: "Deleting..." },
  UPDATING: { type: "UPDATING", message: "Please wait until changes are updated" },
});

export const DROPDOWN_LIST_CODE = {
  ASSET_TYPE: "ASSET_TYPE",
  ADDRESS_PROOF: "ADDRESS_PROOF",
  FINANCIAL_YEAR: "FINANCIAL_YEAR",
  CUSTOMER_TYPE: "CUSTOMER_TYPE",
  BANK_FINANCIER_TYPE: "BANK_FINANCIER_TYPE",
  AREA_TYPE: "AREA_TYPE",
  CONSTRUCTION_TYPE: "CONSTRUCTION_TYPE",
};

export const DOCUMENT_TOAST_TEXT = {
  NO_META: "Please Save the document meta data",
};

export const STORY_BOARD = Object.freeze({
  SALES_STORY_BOARD: "SALES_STORY_BOARD",
  SALES_RECOMMENDATION: "SALES_RECOMMENDATION",
});

export const OTHER_DETAILS_PAGE: { APPLICANT: "APPLICANT"; CO_APPLICANT: "CO_APPLICANT" } = {
  APPLICANT: "APPLICANT",
  CO_APPLICANT: "CO_APPLICANT",
};

export const SHARE_BUTTON: { COPY: "COPY"; MAIL: "MAIL"; SMS: "SMS"; LINK: "LINK" } = {
  COPY: "COPY",
  MAIL: "MAIL",
  SMS: "SMS",
  LINK: "LINK",
};

export const FEE_MODE = {
  CASH: "FEE_MODE_CASH",
  CHEQUE: "FEE_MODE_CHEQUE",
  UPI: "FEE_MODE_NEFT_RTGS_UPI",
};

export const FEE_RECEIPT_STATUS: Record<
  Exclude<TLoginReceiptStatusType, "INVALID/FAILED">,
  TLoginReceiptStatusType
> = {
  PENDING_DEPOSIT: "PENDING_DEPOSIT",
  DEPOSITED: "DEPOSITED",
  CLEARED: "CLEARED",
  INVALID: "INVALID/FAILED",
  BOUNCED: "BOUNCED",
};

export const FEE_RECEIPT_STATUS_LABEL: Record<
  Exclude<TLoginReceiptStatusType, "INVALID/FAILED">,
  string
> = {
  PENDING_DEPOSIT: "Pending Deposit",
  DEPOSITED: "Deposited",
  CLEARED: "Cleared",
  INVALID: "Invalid/Failed",
  BOUNCED: "Bounced",
};

export const FEE_RECEIPT_STATUS_OPTIONS: { code: TLoginReceiptStatusType; name: string }[] = [
  { code: FEE_RECEIPT_STATUS.PENDING_DEPOSIT, name: FEE_RECEIPT_STATUS_LABEL.PENDING_DEPOSIT },
  { code: FEE_RECEIPT_STATUS.DEPOSITED, name: FEE_RECEIPT_STATUS_LABEL.DEPOSITED },
  { code: FEE_RECEIPT_STATUS.CLEARED, name: FEE_RECEIPT_STATUS_LABEL.CLEARED },
  { code: FEE_RECEIPT_STATUS.INVALID, name: FEE_RECEIPT_STATUS_LABEL.INVALID },
  { code: FEE_RECEIPT_STATUS.BOUNCED, name: FEE_RECEIPT_STATUS_LABEL.BOUNCED },
];

export const FEE_MODE_LABEL = {
  [FEE_MODE.CASH]: "Cash",
  [FEE_MODE.CHEQUE]: "Cheque",
  [FEE_MODE.UPI]: "NEFT/RTGS/UPI",
};

export const YES_NO = {
  YES: "YES",
  NO: "NO",
};

export const YES_NO_OPTIONS = [
  { label: "Yes", value: YES_NO.YES },
  { label: "No", value: YES_NO.NO },
];

export const GST_REGISTRATION = [
  { label: "Single", value: "SINGLE" },
  { label: "Multiple", value: "MULTIPLE" },
];

export const SOURCE_AND_BRANCH_CHANNEL = {
  DIRECT: "CHANNEL_TYPE_DIRECT",
  DSA: "PARTNER_TYPE_DSA",
  CONNECTOR: "PARTNER_TYPE_CONNECTOR",
  DIGITAL: "PARTNER_TYPE_DIGITAL",
  BC_MODEL: "CHANNEL_TYPE_BC_MODEL",
  TECHNICAL: "PARTNER_TYPE_TECHNICAL",
  LEGAL: "PARTNER_TYPE_LEGAL",
  RCU: "PARTNER_TYPE_RCU",
  BC: "PARTNER_TYPE_BC_PARTNER",
};

export const PARTNER_TYPE = {
  DSA: "PARTNER_TYPE_DSA",
  CONNECTOR: "PARTNER_TYPE_CONNECTOR",
  DIGITAL: "PARTNER_TYPE_DIGITAL",
  BC_MODEL: "PARTNER_TYPE_BC_PARTNER",
};

export const STAGE_TYPE = {
  LOGIN: "LOGIN",
  QC: "QC",
  PD: "PD",
  LEGAL_REPORT: "LEGAL_REPORT",
  TECHNICAL_REPORT: "TECHNICAL_REPORT",
  RCU_REPORT: "RCU_REPORT",
  DEDUPE: "DEDUPE",
  CAM_AND_RECOMMENDATION: "CAM_AND_RECOMMENDATION",
  FINANCIAL_APPROVAL: "FINANCIAL_APPROVAL",
  IN_PRINCIPAL_APPROVAL: "IN_PRINCIPAL_APPROVAL",
  FINAL_APPROVAL: "FINAL_APPROVAL",
  REAPPEAL: "REAPPEAL",
  CREDIT_AND_QUERY: "CREDIT_AND_QUERY",
  FEES_AND_RECEIPT: "FEES_AND_RECEIPT",
  POINT_OF_INTEREST: "POINT_OF_INTEREST",
  STAGE_AND_STATUS: "STAGE_AND_STATUS",
};

export const STAGE_OPTIONS = {
  LOGIN: { label: "Login", value: STAGE_TYPE.LOGIN },
  QC: { label: "QC", value: STAGE_TYPE.QC },
  PD: { label: "PD", value: STAGE_TYPE.PD },
  LEGAL_REPORT: { label: "Legal", value: STAGE_TYPE.LEGAL_REPORT },
  TECHNICAL_REPORT: { label: "Technical", value: STAGE_TYPE.TECHNICAL_REPORT },
  RCU_REPORT: { label: "RCU", value: STAGE_TYPE.RCU_REPORT },
  DEDUPE: { label: "Dedupe", value: STAGE_TYPE.DEDUPE },
  CAM_AND_RECOMMENDATION: { label: "CAM", value: STAGE_TYPE.CAM_AND_RECOMMENDATION },
  FINANCIAL_APPROVAL: { label: "Financial Approval", value: STAGE_TYPE.FINANCIAL_APPROVAL },
  IN_PRINCIPAL_APPROVAL: {
    label: "In-Principle Approval",
    value: STAGE_TYPE.IN_PRINCIPAL_APPROVAL,
  },
  FINAL_APPROVAL: { label: "Final Approval", value: STAGE_TYPE.FINAL_APPROVAL },
  REAPPEAL: { label: "Re-appeal", value: STAGE_TYPE.REAPPEAL },
  CREDIT_AND_QUERY: { label: "Credit", value: STAGE_TYPE.CREDIT_AND_QUERY },
};

export const INITIATE_STAGE_OPTIONS = [
  STAGE_OPTIONS.QC,
  STAGE_OPTIONS.DEDUPE,
  STAGE_OPTIONS.PD,
  STAGE_OPTIONS.LEGAL_REPORT,
  STAGE_OPTIONS.TECHNICAL_REPORT,
  STAGE_OPTIONS.RCU_REPORT,
  STAGE_OPTIONS.FINANCIAL_APPROVAL,
  STAGE_OPTIONS.IN_PRINCIPAL_APPROVAL,
  STAGE_OPTIONS.FINAL_APPROVAL,
];

export const CURRENT_STAGE_STATUS = [
  { label: "Positive", value: "POSITIVE" },
  { label: "Negative", value: "NEGATIVE" },
];

export const DEDUPE_CHECK = [
  { label: "Valid", value: "VALID" },
  { label: "Invalid", value: "INVALID" },
];

export const QC_TABS_TYPE = {
  APPLICANT: "APPLICANT",
  CO_APPLICANT: "CO_APPLICANT",
  COLLATERAL_RISK_CHECK: "Collateral_Risk_Check",
};

export const QC_DOCUMENTS = {
  APPLICANT: "APPLICANT",
  CO_APPLICANT: "CO_APPLICANT",
  COLLATERAL_RISK_CHECK: "Collateral_Risk_Check",
};

export const QC_DOCUMENT_TABLE_DATA_FORMAT: Record<
  DocumentCheckTableRecordType,
  DocumentCheckTableRecordType
> = {
  STRING: "STRING",
  DATE: "DATE",
  UNSIGNED_IMAGE: "UNSIGNED_IMAGE",
  BASE64_IMAGE: "BASE64_IMAGE",
  GENDER: "GENDER",
  ADDRESS: "ADDRESS",
  BANK_TXN_STATUS: "BANK_TXN_STATUS",
};
export const DOCUMENT_CHECK_TABLE_FIELDS = {
  //{ name : display Name , value : field name ,format : how to  show the data   }
  PHOTO: { NAME: "Photo", VALUE: "photo", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  NAME: { NAME: "Name", VALUE: "name", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  MASKED_AADHAAR_NUMBER: {
    NAME: "Masked Aadhaar Number",
    VALUE: "maskedAadhaarNumber",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
  DOB: { NAME: "DOB", VALUE: "dob", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.DATE },
  FATHERS_NAME: {
    NAME: "Fathers Name",
    VALUE: "fathersName",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
  ADDRESS: { NAME: "Address", VALUE: "address", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.ADDRESS },
  GENDER: { NAME: "Gender", VALUE: "gender", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  AGE: { NAME: "Age", VALUE: "age", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  DISTRICT: { NAME: "District", VALUE: "district", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  PINCODE: { NAME: "Pincode", VALUE: "pincode", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  STATE: { NAME: "State", VALUE: "state", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  SURNAME: { NAME: "Surname", VALUE: "surname", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  PASSPORT_NUMBER: {
    NAME: "Passport number",
    VALUE: "passportNumber",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
  DATE_OF_ISSUE: {
    NAME: "Date of Issue",
    VALUE: "dateOfIssue",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
  FATHER_OR_HUSBAND: {
    NAME: "Father/Husband",
    VALUE: "fatherOrHusband",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
  DL_NUMBER: { NAME: "DL Number", VALUE: "dlNumber", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  ACCOUNT_HOLDER_NAME: {
    NAME: "Account Holder Name",
    VALUE: "accountHolderName",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
  ACCOUNT_NUMBER: {
    NAME: "Account No",
    VALUE: "accountNo",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
  IFSC_CODE: { NAME: "IFSC Code", VALUE: "ifscCode", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  BANK_TXN_STATUS: {
    NAME: "Bank Transaction Status",
    VALUE: "bankTxnStatus",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
  BANK_RESPONSE: {
    NAME: "Bank Response",
    VALUE: "bankResponse",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
  MOBILE_NO: { NAME: "Mobile No", VALUE: "mobileNo", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  EMAIL: { NAME: "Email", VALUE: "email", FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING },
  MEMBER_STATUS: {
    NAME: "Member Status",
    VALUE: "memberStatus",
    FORMAT: QC_DOCUMENT_TABLE_DATA_FORMAT.STRING,
  },
};

export const DOCUMENT_CHECK_TYPE: Record<DocumentCheckType, DocumentCheckType> = {
  PAN: "PAN",
  ADHAAR: "ADHAAR",
  VOTER_ID: "VOTER_ID",
  PASSPORT: "PASSPORT",
  DRIVERS_LICENSE: "DRIVERS_LICENSE",
  ELECTRICITY_BILL: "ELECTRICITY_BILL",
  GST: "GST",
  ITR: "ITR",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  MOBILE: "MOBILE",
  EPF: "EPF",
  CA: "CA",
  GOOGLE_SEARCH: "GOOGLE_SEARCH",
};

export const DOCUMENT_CHECK_CONFIG: Record<keyof typeof DOCUMENT_CHECK_TYPE, IDocumentCheckConfig> =
  {
    PAN: {
      CODE: DOCUMENT_CHECK_TYPE.PAN,
      NAME: "PAN Authentication",
      ROWS: [DOCUMENT_CHECK_TABLE_FIELDS.NAME],
    },
    ADHAAR: {
      CODE: DOCUMENT_CHECK_TYPE.ADHAAR,
      NAME: "Aadhaar",
      ROWS: [
        DOCUMENT_CHECK_TABLE_FIELDS.PHOTO,
        DOCUMENT_CHECK_TABLE_FIELDS.MASKED_AADHAAR_NUMBER,
        DOCUMENT_CHECK_TABLE_FIELDS.NAME,
        DOCUMENT_CHECK_TABLE_FIELDS.DOB,
        DOCUMENT_CHECK_TABLE_FIELDS.GENDER,
        DOCUMENT_CHECK_TABLE_FIELDS.ADDRESS,
        DOCUMENT_CHECK_TABLE_FIELDS.DISTRICT,
        DOCUMENT_CHECK_TABLE_FIELDS.STATE,
        DOCUMENT_CHECK_TABLE_FIELDS.PINCODE,
      ],
    },
    VOTER_ID: {
      CODE: DOCUMENT_CHECK_TYPE.VOTER_ID,
      NAME: "Voter ID",
      ROWS: [
        DOCUMENT_CHECK_TABLE_FIELDS.NAME,
        DOCUMENT_CHECK_TABLE_FIELDS.DOB,
        DOCUMENT_CHECK_TABLE_FIELDS.GENDER,
        DOCUMENT_CHECK_TABLE_FIELDS.DISTRICT,
        DOCUMENT_CHECK_TABLE_FIELDS.STATE,
        DOCUMENT_CHECK_TABLE_FIELDS.PINCODE,
      ],
    },
    PASSPORT: {
      CODE: DOCUMENT_CHECK_TYPE.PASSPORT,
      NAME: "Passport Verification",
      ROWS: [
        DOCUMENT_CHECK_TABLE_FIELDS.NAME,
        DOCUMENT_CHECK_TABLE_FIELDS.SURNAME,
        DOCUMENT_CHECK_TABLE_FIELDS.PASSPORT_NUMBER,
        DOCUMENT_CHECK_TABLE_FIELDS.DATE_OF_ISSUE,
      ],
    },
    DRIVERS_LICENSE: {
      CODE: DOCUMENT_CHECK_TYPE.DRIVERS_LICENSE,
      NAME: "Drivers License",
      ROWS: [
        DOCUMENT_CHECK_TABLE_FIELDS.PHOTO,
        DOCUMENT_CHECK_TABLE_FIELDS.DL_NUMBER,
        DOCUMENT_CHECK_TABLE_FIELDS.NAME,
        DOCUMENT_CHECK_TABLE_FIELDS.DOB,
        DOCUMENT_CHECK_TABLE_FIELDS.ADDRESS,
      ],
    },
    MOBILE: {
      CODE: DOCUMENT_CHECK_TYPE.MOBILE,
      NAME: "Mobile Authentication",
      ROWS: [
        DOCUMENT_CHECK_TABLE_FIELDS.NAME,
        DOCUMENT_CHECK_TABLE_FIELDS.GENDER,
        DOCUMENT_CHECK_TABLE_FIELDS.DOB,
        DOCUMENT_CHECK_TABLE_FIELDS.ADDRESS,
        DOCUMENT_CHECK_TABLE_FIELDS.MOBILE_NO,
      ],
      HIDE_API_CHECK: true,
    },
    GOOGLE_SEARCH: {
      CODE: DOCUMENT_CHECK_TYPE.GOOGLE_SEARCH,
      NAME: "Google Search",
      ROWS: [],
      SHOW_EDITOR: true,
      HIDE_STATUS_AND_REMARK: true,
      HIDE_API_CHECK: true,
    },
    ITR: { CODE: DOCUMENT_CHECK_TYPE.ITR, NAME: "ITR", ROWS: [], SHOW_EDITOR: true },
    GST: {
      CODE: DOCUMENT_CHECK_TYPE.GST,
      NAME: "GST Authentication",
      ROWS: [],
      SHOW_EDITOR: true,
    },
    ELECTRICITY_BILL: {
      CODE: DOCUMENT_CHECK_TYPE.ELECTRICITY_BILL,
      NAME: "Electricity Bill Authentication",
      ROWS: [],
      SHOW_EDITOR: true,
    },
    EPF: { CODE: DOCUMENT_CHECK_TYPE.EPF, NAME: "EPF Authentication", ROWS: [], SHOW_EDITOR: true },
    CA: {
      CODE: DOCUMENT_CHECK_TYPE.CA,
      NAME: "CA Authentication",
      ROWS: [
        DOCUMENT_CHECK_TABLE_FIELDS.NAME,
        DOCUMENT_CHECK_TABLE_FIELDS.GENDER,
        DOCUMENT_CHECK_TABLE_FIELDS.MOBILE_NO,
        DOCUMENT_CHECK_TABLE_FIELDS.EMAIL,
        DOCUMENT_CHECK_TABLE_FIELDS.MEMBER_STATUS,
        DOCUMENT_CHECK_TABLE_FIELDS.ADDRESS,
      ],
      SHOW_EDITOR: true,
    },
    BANK_ACCOUNT: {
      CODE: DOCUMENT_CHECK_TYPE.BANK_ACCOUNT,
      NAME: "Bank Account Check",
      ROWS: [
        DOCUMENT_CHECK_TABLE_FIELDS.ACCOUNT_HOLDER_NAME,
        DOCUMENT_CHECK_TABLE_FIELDS.ACCOUNT_NUMBER,
        DOCUMENT_CHECK_TABLE_FIELDS.IFSC_CODE,
        DOCUMENT_CHECK_TABLE_FIELDS.BANK_RESPONSE,
        DOCUMENT_CHECK_TABLE_FIELDS.BANK_TXN_STATUS,
      ],
    },
  };

export const GENDER: Record<string, any> = {
  GENDER_MALE: { LABEL: "M" },
  GENDER_FEMALE: { LABEL: "F" },
  GENDER_OTHER: { LABEL: "OTHERS" },
};

export const STAGE_STATUS = {
  COMPLETED: { value: "COMPLETED", name: "Completed" },
  CANCELED: { value: "CANCELED", name: "Cancelled" },
  INITIATED: { value: "INITIATED", name: "Initiated" },
  RE_OPEN: { value: "RE_OPEN", name: "Re-open" },
  IN_REVIEW: { value: "IN_REVIEW", name: "In Review" },
  PAUSED: { value: "PAUSED", name: "Paused" },
  REJECTED: { value: "REJECTED", name: "Rejected" },
  APPROVED: { value: "APPROVED", name: "Approved" },
};

export const LOAN_STAGE_STATUS = {
  INITIATED: "Initiated",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  RE_OPEN: "Re-open",
  IN_REVIEW: "In Review",
  PAUSED: "Paused",
  REJECTED: "Rejected",
  APPROVED: "Approved",
};

export const QC_STATUS = {
  POSITIVE: "POSITIVE",
  NEGATIVE: "NEGATIVE",
};

export const QC_STATUS_LIST = [
  { value: QC_STATUS.POSITIVE, label: "Positive" },
  { value: QC_STATUS.NEGATIVE, label: "Negative" },
];

export const TOAST_POSITION = {
  BOTTOM_RIGHT: "bottom-right",
};

export const STATUS = {
  TRUE: true,
  FALSE: false,
};

export const EMPLOYEMENT_NATURE = {
  SELF_EMPLOYED: "EMPLOYMENT_NATURE_SELF_EMPLOYED",
  SELF_EMPLOYED_RENTAL_INCOME: "EMPLOYMENT_NATURE_SELF_EMPLOYED_RENTAL_INCOME",
  SALARIED: "EMPLOYMENT_NATURE_SALARIED",
};

export const LINKAGE_TYPE = {
  USER: "USER",
};

export const SECTION_NAME = {
  BASIC_INFO: "BASIC_INFO",
  ADDRESS: "ADDRESS",
  REFERENCE: "REFERENCE",
  DOCUMENTS: "DOCUMENTS",
};

export const LEGAL_TABLE_COLUMN_FORMAT: Record<
  TypeLegalTableColumnFormat,
  TypeLegalTableColumnFormat
> = {
  DATE: "DATE",
  INPUT_FIELD: "INPUT_FIELD",
  DROPDOWN: "DROPDOWN",
  CHECK_BOX: "CHECK_BOX",
  TEXT_AREA: "TEXT_AREA",
};

export const LEGAL_EDITABLE_TABLE_SECTION: Record<
  TypeLegalEditableTableSection,
  TypeLegalEditableTableSection
> = {
  DOCUMENTS_SCRUTINIZED: "DOCUMENTS_SCRUTINIZED",
  DOCUMENTS_COLLECTED_PRIOR_DISBURSEMENT: "DOCUMENTS_COLLECTED_PRIOR_DISBURSEMENT",
  DOCUMENTS_COLLECTED_POST_DISBURSEMENT: "DOCUMENTS_COLLECTED_POST_DISBURSEMENT",
  DEVIATIONS: "DEVIATIONS",
};

export const LEGAL_EDITABLE_TABLE_COLUMN: Record<
  TypeLegalEditableTableColumn,
  ILegalEditableTableColumnConfig
> = {
  DATE_OF_DOCUMENT: {
    name: "Date of Document",
    field: "dateOfDocument",
    mandatory: false,
    placeholder: "",
    format: LEGAL_TABLE_COLUMN_FORMAT.DATE,
  },
  NAME_OF_DOCUMENT: {
    name: "Name of Document",
    field: "nameOfDocument",
    mandatory: true,
    placeholder: "",
    format: LEGAL_TABLE_COLUMN_FORMAT.INPUT_FIELD,
  },
  DOCUMENT_COPY_TYPE: {
    name: "Document Copy Type",
    field: "documentCopyType",
    mandatory: true,
    placeholder: "",
    format: LEGAL_TABLE_COLUMN_FORMAT.DROPDOWN,
  },
  PRE_DISBURSEMENT_COLLECTION: {
    name: `Pre Disbursement \n Collection`,
    field: "preDisbursementCollection",
    mandatory: false,
    placeholder: "",
    format: LEGAL_TABLE_COLUMN_FORMAT.CHECK_BOX,
    headerInfo: true,
    tooltipContent: `<p>Please check if the same </p> <p>is to be collected Pre </p> <p> Disbursement</p>`,
  },
  POST_DISBURSEMENT_COLLECTION: {
    name: `Post Disbursement \n Collection`,
    mandatory: false,
    field: "postDisbursementCollection",
    placeholder: "",
    format: LEGAL_TABLE_COLUMN_FORMAT.CHECK_BOX,
    headerInfo: true,
    tooltipContent:
      "<p>Please check if the same </p> <p> is to be collected Post </p> <p> Disbursement </p>",
  },
  TYPE_OF_DEVIATIONS: {
    name: "Type of Deviations",
    mandatory: false,
    field: "typeOfDeviation",
    placeholder: "",
    format: LEGAL_TABLE_COLUMN_FORMAT.DROPDOWN,
  },
  DEVIATIONS: {
    name: "Deviations",
    mandatory: false,
    field: "deviation",
    placeholder: "",
    format: LEGAL_TABLE_COLUMN_FORMAT.DROPDOWN,
  },
  ACTUAL_DEVIATIONS: {
    name: "Actual Deviation",
    field: "actualDeviation",
    mandatory: false,
    placeholder: "",
    format: LEGAL_TABLE_COLUMN_FORMAT.TEXT_AREA,
  },
  MITIGATES: {
    name: "Mitigates",
    mandatory: false,
    field: "mitigates",
    placeholder: "",
    format: LEGAL_TABLE_COLUMN_FORMAT.TEXT_AREA,
  },
};
export const LEGAL_EDITABLE_TABLE: Record<
  TypeLegalEditableTableSection,
  ILegalEditableTableConfig
> = {
  DOCUMENTS_SCRUTINIZED: {
    PRE_POPULATE: false,
    COLUMNS: [
      LEGAL_EDITABLE_TABLE_COLUMN.DATE_OF_DOCUMENT,
      LEGAL_EDITABLE_TABLE_COLUMN.NAME_OF_DOCUMENT,
      LEGAL_EDITABLE_TABLE_COLUMN.DOCUMENT_COPY_TYPE,
      LEGAL_EDITABLE_TABLE_COLUMN.PRE_DISBURSEMENT_COLLECTION,
      LEGAL_EDITABLE_TABLE_COLUMN.POST_DISBURSEMENT_COLLECTION,
    ],
    ADD_BUTTON_LABEL: "label.legal.addRow",
  },
  DOCUMENTS_COLLECTED_PRIOR_DISBURSEMENT: {
    PRE_POPULATE: false,
    COLUMNS: [
      LEGAL_EDITABLE_TABLE_COLUMN.DATE_OF_DOCUMENT,
      LEGAL_EDITABLE_TABLE_COLUMN.NAME_OF_DOCUMENT,
      LEGAL_EDITABLE_TABLE_COLUMN.DOCUMENT_COPY_TYPE,
    ],
    ADD_BUTTON_LABEL: "label.legal.addRow",
  },
  DOCUMENTS_COLLECTED_POST_DISBURSEMENT: {
    PRE_POPULATE: false,
    COLUMNS: [
      LEGAL_EDITABLE_TABLE_COLUMN.DATE_OF_DOCUMENT,
      LEGAL_EDITABLE_TABLE_COLUMN.NAME_OF_DOCUMENT,
      LEGAL_EDITABLE_TABLE_COLUMN.DOCUMENT_COPY_TYPE,
    ],
    ADD_BUTTON_LABEL: "label.legal.addRow",
  },
  DEVIATIONS: {
    PRE_POPULATE: true,
    COLUMNS: [
      LEGAL_EDITABLE_TABLE_COLUMN.TYPE_OF_DEVIATIONS,
      LEGAL_EDITABLE_TABLE_COLUMN.DEVIATIONS,
      LEGAL_EDITABLE_TABLE_COLUMN.ACTUAL_DEVIATIONS,
      LEGAL_EDITABLE_TABLE_COLUMN.MITIGATES,
    ],
    ADD_BUTTON_LABEL: "label.legal.addRow",
  },
};

export const PLACE_OF_VISIT_OPTION = [
  {
    name: "Workplace",
    code: "PLACE_OF_DISCUSSION_WORKPLACE",
  },
  {
    name: "Residence",
    code: "PLACE_OF_DISCUSSION_RESIDENCE",
  },
  {
    name: "Collateral Property",
    code: "PLACE_OF_DISCUSSION_COLLATERAL_PROPERTY",
  },
];

export const LEGAL_TEXT_EDITOR_SECTIONS: Record<TLegalTextEditorSection, TLegalTextEditorSection> =
  {
    TITLE_OBSERVATION: "TITLE_OBSERVATION",
    TITLE_SEARCH: "TITLE_SEARCH",
  };

export const LEGAL_DOCUMENT_SCRUTINIZED_MESSAGE = {
  PRE_DISBURSEMENT:
    "<p>Please check if the same </p> <p>is to be collected Pre </p> <p> Disbursement</p>",
  POST_DISBURSEMENT:
    "<p>Please check if the same </p> <p> is to be collected Post </p> <p> Disbursement </p>",
};

export const BUSINESS_TYPE = {
  SALARIED: "SALARIED",
  SELF_EMPLOYED: "SELF_EMPLOYED",
  RENTAL_INCOME: "RENTAL_INCOME",
};

export const OCCUPATION_TYPE = {
  OCCUPATION_TYPE_SALARIED: "OCCUPATION_TYPE_SALARIED",
  OCCUPATION_TYPE_RENTAL_INCOME: "OCCUPATION_TYPE_RENTAL_INCOME",
};

export const RENT_MODE = {
  RENT_MODE_ELECTRONIC: "RENT_MODE_ELECTRONIC",
  RENT_MODE_CASH: "RENT_MODE_CASH",
};

export const FINANCIER_TYPE = {
  BANK_FINANCIER_TYPE_BANK: "BANK_FINANCIER_TYPE_BANK",
  BANK_FINANCIER_TYPE_NBFC: "BANK_FINANCIER_TYPE_NBFC",
};

export const INDUSTRY_TYPE = {
  RENTAL_INCOME_PROPERTY_LEASING: "RENTAL_INCOME_PROPERTY_LEASING",
};

export const TYPE_OF_DEVIATION: Record<TDeviationType, TDeviationType> = {
  TYPE_OF_DEVIATION_AUTOMATIC: "TYPE_OF_DEVIATION_AUTOMATIC",
  TYPE_OF_DEVIATION_MANUAL: "TYPE_OF_DEVIATION_MANUAL",
};

export const DEVIATION_CATEGORY = {
  LEGAL_DEVIATIONS: "Legal",
  RCU_DEVIATIONS: "RCU",
  TECHNICAL_DEVIATIONS: "Technical",
};

export const BUSINESS_TYPE_OPTION = {
  SALARIED: { name: "Salaried", code: BUSINESS_TYPE.SALARIED },
  SELF_EMPLOYED: { name: "Self Employed", code: BUSINESS_TYPE.SELF_EMPLOYED },
  RENTAL_INCOME: { name: "Rental Income", code: BUSINESS_TYPE.RENTAL_INCOME },
};

export const PD_EMP_BUSINESS_TYPE_OPTION_LIST = [
  BUSINESS_TYPE_OPTION.SALARIED,
  BUSINESS_TYPE_OPTION.SELF_EMPLOYED,
  BUSINESS_TYPE_OPTION.RENTAL_INCOME,
];

export const EMPLOYMENT_TYPE = {
  EMPLOYMENT_TYPE_SALARIED: "EMPLOYMENT_TYPE_SALARIED",
  EMPLOYMENT_TYPE_SELF_EMPLOYED: "EMPLOYMENT_TYPE_SELF_EMPLOYED",
  EMPLOYMENT_TYPE_SELF_EMPLOYED_RENTAL_INCOME: "EMPLOYMENT_TYPE_SELF_EMPLOYED_RENTAL_INCOME",
};

export const STAGE_CODE_SCREENS = [
  "LOGIN_",
  "PD_",
  "LEGAL_",
  "RCU_REPORT_",
  "TECHNICAL_REPORT_",
  "FINAL_APPROVAL_",
  "IN_PRINCIPAL_APPROVAL_",
  "FINANCIAL_APPROVAL_",
  "DEDUPE_",
  "CAM_AND_RECOMMENDATION",
  "CREDIT_AND_QUERY_",
  "REAPPEAL_",
  "QC_",
  "APPLICATION_MEDIA_",
];

export const STAGE_TABS_CODE = [
  "LEGAL_REPORT_TAB",
  "LEGAL_DOCUMENT_EXCHANGE_TAB",
  "LEGAL_DEVIATIONS_TAB",
  "TECHNICAL_REPORT_TAB",
  "TECHNICAL_DOCUMENT_EXCHANGE_TAB",
  "TECHNICAL_DEVIATIONS_TAB",
  "RCU_REPORT_TAB",
  "RCU_DOCUMENT_EXCHANGE_TAB",
  "RCU_DEVIATIONS_TAB",
];

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const LOAN_PURPOSE = {
  HOME: "HOME",
  PERSONAL: "PERSONAL",
};

export const LOAN_PURPOSE_LIST = {
  HOME: { name: "Home", value: LOAN_PURPOSE.HOME },
  PERSONAL: { name: "Personal", value: LOAN_PURPOSE.PERSONAL },
};

export const LOAN_PURPOSE_OPTIONS = [LOAN_PURPOSE_LIST.HOME, LOAN_PURPOSE_LIST.PERSONAL];

export const ACTION_TYPE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  REMOVE: "REMOVE",
};

export const OCR_STAGE_NAMES = {
  LOGIN: "login",
};

export const EXISTING_DOC_TYPES = {
  LOAN_DOCUMENT: "LOAN_DOCUMENT",
  KYC_DOCUMENT: "KYC_DOCUMENT",
};

export const EXISTING_DOC_TYPE_OPTIONS = {
  LOAN_DOCUMENT: { name: "Loan Documents", value: "LOAN_DOCUMENT" }, // do not use "label" key for this
  KYC_DOCUMENT: { name: "KYC Documents", value: "KYC_DOCUMENT" },
};

export const EXISTING_DOC_TOGGLE_OPTIONS = [
  EXISTING_DOC_TYPE_OPTIONS.LOAN_DOCUMENT,
  EXISTING_DOC_TYPE_OPTIONS.KYC_DOCUMENT,
];

export const SORT_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC",
};

export const VENDOR_TYPE_OPTIONS = {
  VENDOR_TYPE_INTERNAL: "VENDOR_TYPE_INTERNAL",
  VENDOR_TYPE_EXTERNAL: "VENDOR_TYPE_EXTERNAL",
};

export const RCU_TYPE = {
  RCU_TYPE_SAMPLING: "RCU_TYPE_SAMPLING",
  RCU_TYPE_SCREENING: "RCU_TYPE_SCREENING",
};
export const PROPERTY_ANNOTATION: Record<number, string> = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
  12: "M",
  13: "N",
  14: "O",
  15: "P",
  16: "Q",
  17: "R",
  18: "S",
  19: "T",
  20: "U",
  21: "V",
  22: "W",
  23: "X",
  24: "Y",
  25: "Z",
};

export const IS_TRUE = {
  YES: "YES",
  NO: "NO",
};

export const YesOrNoOptions = [
  { code: "YES", name: "Yes" },
  { code: "NO", name: "No" },
];

export const YesOrNoOptionsWithBooleanValue = [
  { code: true, name: "Yes" },
  { code: false, name: "No" },
];
export const REPORTING_TYPES = {
  REPORT_ORGANIZATION_REPORTING_LEVELS: "REPORT_ORGANIZATION_REPORTING_LEVELS",
  REPORT_BRANCH: "REPORT_BRANCH",
};

export const VERIFICATION_STATUS = [
  { label: "Positive", value: "POSITIVE" },
  { label: "Negative", value: "NEGATIVE" },
];

export const SAHAYYA_USER_TYPE = {
  PARTNER_USER: "PARTNER_USER",
  EMPLOYEE_USER: "EMPLOYEE_USER",
};

export const STAGE_MAPPING = {
  [STAGE_TYPE.LOGIN]: LINK_DDE_LOGIN,
  [STAGE_TYPE.QC]: LINK_DDE_QC,
  [STAGE_TYPE.LEGAL_REPORT]: LINK_LEGAL,
  [STAGE_TYPE.RCU_REPORT]: LINK_RCU,
  [STAGE_TYPE.TECHNICAL_REPORT]: LINK_TECHNICAL,
  [STAGE_TYPE.PD]: LINK_PD,
  [STAGE_TYPE.DEDUPE]: LINK_DEDUPE,
  [STAGE_TYPE.CAM_AND_RECOMMENDATION]: LINK_CAM,
  [STAGE_TYPE.FINANCIAL_APPROVAL]: LINK_FINANCIAL_APPROVAL,
  [STAGE_TYPE.IN_PRINCIPAL_APPROVAL]: LINK_IN_PRINCIPLE_APPROVAL,
  [STAGE_TYPE.FINAL_APPROVAL]: LINK_FINAL_APPROVAL,
  [STAGE_TYPE.REAPPEAL]: LINK_RE_APPEAL,
  [STAGE_TYPE.CREDIT_AND_QUERY]: LINK_DDE_CREDIT_QUERY,
  [STAGE_TYPE.POINT_OF_INTEREST]: LINK_POINT_OF_INTEREST,
  [STAGE_TYPE.FEES_AND_RECEIPT]: LINK_FEES_SCREEN,
  [STAGE_TYPE.STAGE_AND_STATUS]: LINK_DDE_STAGE_AND_STATUS,
};

export const NON_STAGE_MAPPING = {
  [STAGE_TYPE.STAGE_AND_STATUS]: LINK_DDE_STAGE_AND_STATUS,
  [STAGE_TYPE.FEES_AND_RECEIPT]: LINK_FEES_SCREEN,
  [STAGE_TYPE.POINT_OF_INTEREST]: LINK_POINT_OF_INTEREST,
};

export type TCamApplicantType = "PRIMARY" | "CO_APPLICANT" | "GUARANTOR";
export const CAM_APPLICANT_TYPE: Record<TCamApplicantType, TCamApplicantType> = {
  PRIMARY: "PRIMARY",
  CO_APPLICANT: "CO_APPLICANT",
  GUARANTOR: "GUARANTOR",
};

export const CAM_APPLICANT_TYPE_LABEL: Record<TCamApplicantType, string> = {
  PRIMARY: "Applicant",
  CO_APPLICANT: "Co-Applicant",
  GUARANTOR: "Guarantor",
};

export const CAM_APPLICANT_TYPE_OPTIONS: { name: string; code: TCamApplicantType }[] = [
  {
    name: "Applicant",
    code: CAM_APPLICANT_TYPE.PRIMARY,
  },
  {
    name: "Co-Applicant",
    code: CAM_APPLICANT_TYPE.CO_APPLICANT,
  },
  {
    name: "Guarantor",
    code: CAM_APPLICANT_TYPE.GUARANTOR,
  },
];

export enum LOAN_QUERY_STATUS {
  IN_PROGRESS = "In Progress",
  CLOSED = "Closed",
}

export const QUERY_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  CLOSED: "CLOSED",
  OPEN: "OPEN",
};
export const CAM_CHARGE_TYPE: Record<
  TCamChargeTypeCode,
  { code: TCamChargeTypeCode; label: string }
> = {
  CHARGE_TYPE_SYSTEM_DEFINED: {
    code: "CHARGE_TYPE_SYSTEM_DEFINED",
    label: "System Defined",
  },
  CHARGE_TYPE_USER_DEFINED: {
    code: "CHARGE_TYPE_USER_DEFINED",
    label: "User Defined",
  },
};

export const CAM_CHARGE_CONFIGURATION_CODE: Record<
  TCamConfigurationTypeCode,
  TCamConfigurationTypeCode
> = {
  CONFIGURATION_FIXED: "CONFIGURATION_FIXED",
  CONFIGURATION_VARIABLE: "CONFIGURATION_VARIABLE",
};

export const CAM_FEE_STATUS: Record<TCamFeeStatus, TCamFeeStatus> = {
  APPLICABLE: "APPLICABLE",
};

export const CAM_AND_RECOMMENDATION_RISK_STRENGTH = {
  RISK: "RISK",
  STRENGTH: "STRENGTH",
};

export type TCamPropertyValuationOperation = "AVERAGE" | "SUM" | "MINIMUM" | "MAXIMUM";
export const PROPERTY_VALUATION_OPERATION: Record<
  TCamPropertyValuationOperation,
  TCamPropertyValuationOperation
> = {
  AVERAGE: "AVERAGE",
  SUM: "SUM",
  MAXIMUM: "MAXIMUM",
  MINIMUM: "MINIMUM",
};

export const PROPERTY_VALUATION_OPERATION_OPTIONS: {
  name: string;
  code: TCamPropertyValuationOperation;
}[] = [
  { name: "Average", code: "AVERAGE" },
  { name: "Sum", code: "SUM" },
  { name: "Minimum", code: "MINIMUM" },
  { name: "Maximum", code: "MAXIMUM" },
];

export type TCamMonthlyBankingAnalysisMonths =
  | "JAN"
  | "FEB"
  | "MAR"
  | "APR"
  | "MAY"
  | "JUN"
  | "JUL"
  | "AUG"
  | "SEP"
  | "OCT"
  | "NOV"
  | "DEC";
export const CAM_MONTHLY_BANKING_ANALYSIS_MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export type TCamMonthlyBankingAnalysisDay = 5 | 15 | 25;
export const CAM_MONTHLY_BANKING_ANALYSIS_DAYS = [5, 15, 25];

export const WEB_VIEW_SCREEN = {
  FINANCIAL_APPROVAL: "FINANCIAL_APPROVAL_ASSIGNEE_NAME",
  IN_PRINCIPAL_APPROVAL: "IN_PRINCIPAL_APPROVAL_ASSIGNEE_NAME",
  FINAL_APPROVAL: "FINAL_APPROVAL_ASSIGNEE_NAME",
  SUMMARY: "SUMMARY",
  HIGHMARK_DETAIL_REPORT: "HIGHMARK_DETAIL_REPORT",
};

export const WEB_VIEW_SCREEN_PATH = {
  FINANCIAL_APPROVAL_ASSIGNEE_NAME: LINK_FINANCIAL_APPROVAL,
  IN_PRINCIPAL_APPROVAL_ASSIGNEE_NAME: LINK_IN_PRINCIPLE_APPROVAL,
  FINAL_APPROVAL_ASSIGNEE_NAME: LINK_FINAL_APPROVAL,
  SUMMARY: LINK_SUMMARY,
  HIGHMARK_DETAILED_REPORT: LINK_DDE_CRIF_DETAILED_REPORT,
};

export const DEDUPE_MATCHING_TYPE = {
  SAHAYYA_LIST: "Sahayya List",
  BLACKLIST: "Blacklist",
};

export const TO_AND_FROM_DATE_MESSAGE = {
  BANK_OF_ACCOUNT_OPENING: {
    value: "BANK_OF_ACCOUNT_OPENING",
    label: "Should be less then bank statement from",
  },
  BANK_STATEMENT_FROM: {
    value: "BANK_STATEMENT_FROM",
    label: "Should be less then bank statement to",
  },
  BANK_STATEMENT_TO: {
    value: "BANK_STATEMENT_TO",
    label: "Should be greater then bank statement from",
  },
};

export const TECHNICAL_OCCUPANCY_DETAILS = {
  STATUS_OF_OCCUPANCY_VACANT: "STATUS_OF_OCCUPANCY_VACANT",
};

export const MENU_KEBAB_IDENTIFIER = "menu-kebab-image";

export const BRANCH = "BRANCH";

export const FILE_TYPE_EXCEL = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
];

export const FILE_TYPE_WORD = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.ms-word.template.macroEnabled.12",
];

export const FILE_TYPE_ZIP = [
  "application/x-7z-compressed",
  "application/x-rar-compressed",
  "application/zip",
  "application/x-zip-compressed",
  "application/x-zip",
  "application/vnd.rar",
];

export const FILE_UPLOAD_STATUS_FROM_OS: Record<TFileUploadStatusFromOs, TFileUploadStatusFromOs> =
  {
    INITIATED: "INITIATED",
    CANCELLED: "CANCELLED",
    COMPLETED: "COMPLETED",
  };

export const LOAN_DISBURSEMENT_VALUE = {
  DISBURSED_PARTIAL: "DISBURSED_PARTIAL",
  DISBURSED_FULL: "DISBURSED_FULL",
};

export const LOAN_DISBURSEMENT_OPTIONS = [
  {
    label: "Partially Disbursed",
    value: LOAN_DISBURSEMENT_VALUE.DISBURSED_PARTIAL,
  },
  {
    label: "Fully Disbursed",
    value: LOAN_DISBURSEMENT_VALUE.DISBURSED_FULL,
  },
];
