//eslint-disable-next-line
import React from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import * as routes from "routes";
import PageValidate from "views/PageValidate/PageValidate";
import PageLoginLoader from "views/auth/PageLoginLoader/PageLoginLoader";
import PageNewPassword from "views/auth/PageNewPassword/PageNewPassword";
import PagePasswordResetSuccess from "views/auth/PagePasswordResetSuccess/PagePasswordResetSuccess";
import PageSetUp from "views/transaction/PageSetUp/PageSetUp";
import Loader from "./components/Loader";
import store, { persistedStore } from "./store";

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Page404 = React.lazy(() => import("./views/Page404"));
const PageLogin = React.lazy(() => import("./views/auth/PageLogin/PageLogin"));

const PagePasswordReset = React.lazy(() => import("./views/auth/PagePasswordReset"));

const PagePasswordResetOTP = React.lazy(() => import("./views/auth/PagePasswordResetOTP"));

/* eslint-disable */

function App(props: any) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <Router basename={routes.linkBasePath}>
          <React.Suspense fallback={<Loader />}>
            <Routes>
              <Route path="*" element={<Page404 />} />
              <Route path={routes.linkPageBase} element={<PageLogin />} />
              <Route path={routes.linkPageLogin} element={<PageLogin />} />
              <Route path={routes.linkPagePasswordReset} element={<PagePasswordReset />} />
              <Route path={routes.linkPagePasswordResetOTP} element={<PagePasswordResetOTP />} />
              <Route path={routes.linkPageNewPassword} element={<PageNewPassword />} />
              <Route path={routes.linkPageValidateWebView} element={<PageValidate />} />
              <Route path={routes.linkPageSetup} element={<PageSetUp />} />
              <Route path="/loading" element={<PageLoginLoader />} />
              <Route
                path={routes.linkPagePasswordResetSuccess}
                element={<PagePasswordResetSuccess />}
              />
              <Route path={`${routes.linkAuthRoute}/*`} element={<DefaultLayout />} />
            </Routes>
          </React.Suspense>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
