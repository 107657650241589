import { memo } from "react";
import { Trans } from "react-i18next";

interface IText {
  label?: any;
  className?: string;
  htmlFor?: string;
  args?: any;
  mandatory?: boolean;
}
const Text = (props: IText) => {
  const { label, htmlFor, className, mandatory, ...restProps } = props;
  return (
    <label htmlFor={htmlFor} className={` ${className}`} {...restProps}>
      <Trans values={restProps.args}>{label}</Trans>
      {mandatory && <span className="text-primary-ptext-1000">*</span>}
    </label>
  );
};

export default memo(Text);
