import { LINK_LOAN_LIST } from "transactionRoutes";
import {
  IS_STATIC_MASTER_AVAILABLE_BEGIN,
  IS_STATIC_MASTER_AVAILABLE_SUCCESS,
  IS_STATIC_MASTER_AVAILABLE_FAILURE,
} from "../actions/1_ActionConstants";

const initialState = {
  loading: false,
  error: null,
  profileObj: {},
};

export default function IsStaticMasterDataAvailableReducer(state = initialState, action) {
  switch (action.type) {
    case IS_STATIC_MASTER_AVAILABLE_BEGIN:
      return {
        ...state,
      };

    case IS_STATIC_MASTER_AVAILABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStaticMasterDataAvailable: action.payload,
      };

    case IS_STATIC_MASTER_AVAILABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "FAILED TO LOAD,..,.", //action.payload.error,
        isStaticMasterDataAvailable: false,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
