import apiError from "assets/img/Dialog/Iconly-Light-Danger-Circle.svg";
import ButtonBox from "components/ButtonBox/ButtonBox";
import DialogBox from "components/Dialog/Dialog";
import Label from "components/Label/Label";
import Text from "components/Text/Text";
import { useState } from "react";
import { copyData } from "utils/CommonUtil";
import { BUTTON_TYPE, INPUT_STYLE_TYPE } from "utils/Constant";

const ErrorDialog = (props: any) => {
  const [isCopied, setIsCopied] = useState(false);

  const { onHide, visible, errorMessage, errorDivClassName } = props;

  const copyErrorData = () => {
    setIsCopied(true);
    copyData(errorMessage);
  };

  const isMessageArray = Array.isArray(errorMessage);
  return (
    <DialogBox
      onHide={onHide}
      visible={visible}
      modal
      closable={false}
      dismissableMask={true}
      variant={INPUT_STYLE_TYPE.SECONDARY}
    >
      <div className="flex items-center justify-center">
        <img src={apiError} alt="API Error icon" className="w-9 h-9" />
      </div>

      <div className="flex ml-4 mt-2">
        <Label label="dialog.apiError.details" />
      </div>
      <div
        className={` ${
          errorDivClassName ?? ""
        }  border flex flex-col border-secondary-gray-200 rounded-md overflow-auto w-120 h-56 p-4 text-xs text-secondary-gray-500`}
        id="errorDiv"
      >
        {isMessageArray ? (
          <ul className="">
            {errorMessage.map((error: any, index: number) => {
              return (
                <li key={`error-item-${index + 1}`}>
                  {error?.isHeading ? (
                    <b className="text-secondary-steelBlue-900 text-xs font-normal">{error.text}</b>
                  ) : (
                    <span className="text-secondary-gray-400 text-xs ml-2">{error}</span>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          errorMessage
        )}
      </div>
      <div className="flex flex-row-reverse">
        <div onClick={copyErrorData}>
          <Text
            label={isCopied ? "Copied" : "Copy"}
            className={
              isCopied
                ? "text-primary-copiedTextColor cursor-pointer text-xs"
                : " text-primary-copyTextColor cursor-pointer text-xs"
            }
          />
        </div>
      </div>
      <div className="flex justify-center ">
        <ButtonBox buttonType={BUTTON_TYPE.CANCEL_OUTLINE} onClick={onHide} />
      </div>
    </DialogBox>
  );
};

export default ErrorDialog;
