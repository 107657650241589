import { numberFormatter } from "locale";
// import { showCurrencyIncludeNull } from "views/transaction/PageCAM/Helper";
import {
  FILE_TYPE_EXCEL,
  FILE_TYPE_WORD,
  FILE_TYPE_ZIP,
  LOCAL_STORAGE,
  TO_AND_FROM_DATE_MESSAGE,
} from "./Constant";



export const isFormattedHighmarkAmount=(value:any)=>{
  if(isNaN(value)){
     return true;
  }
  return false;
}

/// test
export const getEnvVariableValue = (key: any) => {
  if (!key) throw new Error("Please pass the key to get value");
  if (window?.env && window?.env?.[key]) return window?.env?.[key];

  return import.meta.env[key];
};

/**
 *
 * @returns oject which contains device information
 */
// export const getDeviceInformation = () => {
//   return {
//     isMobile: navigator.userAgentData.mobile,
//     platform: navigator.userAgentData.platform,
//   };
// };

/**
 *
 * @param {any} data
 * @returns encoded string
 */
export const encode = (data: string) => {
  return btoa(data);
};

/**
 *
 * @param {string} data // encrypted data
 * @returns decoded string
 */
export const decode = (data: string) => {
  return atob(data);
};

/**
 *
 * @param {function} onSuccessCallBack
 * @param {function} onErrorCallBack
 */
export const getGeoLocation = (onSuccessCallBack: any, onErrorCallBack: any) => {
  if (!("geolocation" in navigator)) {
    onErrorCallBack({
      code: 0,
      message: "Geolocation not supported",
    });
  }

  navigator.geolocation.getCurrentPosition(onSuccessCallBack, onErrorCallBack);
};

export const getLocalLanguage = () => {
  const localStorageObject = JSON.parse(getLocalStorage("defaultLocale"));
  return localStorageObject.defaultLanguage;
};

/**
 *
 * @param {string} data
 */
export const copyData = (data: any) => {
  navigator.clipboard
    .writeText(data)
    .then(() => {
      console.log(">> Copied!!");
    })
    .catch(() => {
      console.log(">> Fail to copy");
    });
};

/**
 *
 * @param {string} key
 * read data from local storage
 */
export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key) ?? "";
};
/**
 *
 * @param {string} key
 * @param {strnig | object} value
 */
export const setLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

/**
 *
 * @returns boolean value of the network status
 */
export const isOnline = () => {
  return navigator.onLine;
};

// multiple date formats
function addZero(i: any) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
/**
 *
 * @param {integer} month
 * @returns previous date based on parameter passed or it default retrun on month back date
 */
export const getPrevMonthDate = (month = 1) => {
  const prevDate = new Date();
  prevDate.setMonth(prevDate.getMonth() - month);
  return prevDate;
};
/**
 *
 * @param {Date | string} date
 * @returns fromdated Time With AM / PM
 */
export const dateFormatAMPM = (dateInString: string) => {
  const date = new Date(dateInString);
  let hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const parsedDateFromAPIValue = (date: string) => {
  if (!date) return new Date(getLocalStorage(LOCAL_STORAGE.SYSTEM_DATE));
  const applicableFromDate = new Date(date);
  const parsedYear = applicableFromDate.getFullYear();
  const parsedMonth = applicableFromDate.getMonth();
  const parsedDay = applicableFromDate.getDate();
  const parsedDate = new Date(parsedYear, parsedMonth, parsedDay);
  return parsedDate;
};
/**
 *
 * @param {string} dateToFormat
 * @returns  formated string from date object
 */
export const formattedDate = (dateToFormat: any) => {
  if (!dateToFormat) return "";
  const myDate = new Date(dateToFormat);
  const month = addZero(myDate.getMonth() + 1);
  const date = addZero(myDate.getDate());
  const year = myDate.getFullYear();
  const hh = addZero(myDate.getHours());
  const mm = addZero(myDate.getMinutes());
  const ss = addZero(myDate.getSeconds());
  return date + "/" + month + "/" + year + " " + hh + ":" + mm + ":" + ss;
};

export const convertDdMmmYyyyToDate = (dateString: string) => {
  const parts = dateString.split("-");
  const monthNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const day = parseInt(parts[0]);
  const monthIndex = monthNames.indexOf(parts[1].toLowerCase());
  const year = parseInt(parts[2]);
  const dateObj = new Date(year, monthIndex, day);

  if (isValidDate(dateObj)) {
    return dateObj;
  } else {
    return null;
  }
};

export const convertYyyyToDate = (dateString: string) => {
  const year = parseInt(dateString);
  const dateObj = new Date(year, 0);

  if (isValidDate(dateObj)) {
    return dateObj;
  } else {
    return null;
  }
};

export const convertISOToLongFormat = (isoDate: any) => {
  const date = new Date(isoDate);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const timeZone = date.toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2];

  const longFormatDate = `${date.toDateString()} ${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} GMT${timeZone}`;

  return longFormatDate;
};

export const stringifyObject = (obj: any) => {
  return JSON.stringify(obj);
};

export function YearMonthDayDifference(dt2: Date, dt1: Date): string {
  const dateDifferenceObj: { years: number; months: number; days: number } =
    calculateDateDifference(dt2, dt1);

  const { years, months, days } = dateDifferenceObj;

  let result = "";
  if (years > 0) {
    result += years + (years === 1 ? " year " : " years ");
  }
  if (months > 0) {
    result += months + (months === 1 ? " month " : " months ");
  }
  if (days > 0) {
    result += days + (days === 1 ? " day " : " days ");
  }

  return toTitleCase(result);
}

export function calculateDateDifference(
  dt2: Date,
  dt1: Date
): { years: number; months: number; days: number } {
  const diffInMilliseconds = dt2?.getTime() - dt1?.getTime();
  const diffInYears = Math.floor(diffInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));
  const remainingMilliseconds = diffInMilliseconds - diffInYears * 365.25 * 24 * 60 * 60 * 1000;
  const diffInMonths = Math.floor(remainingMilliseconds / (30.44 * 24 * 60 * 60 * 1000));
  const remainingMilliseconds2 = remainingMilliseconds - diffInMonths * 30.44 * 24 * 60 * 60 * 1000;
  const diffInDays = Math.floor(remainingMilliseconds2 / (24 * 60 * 60 * 1000));

  return {
    years: diffInYears,
    months: diffInMonths,
    days: diffInDays,
  };
}

export function YearDifference(dt2: Date, dt1: Date) {
  let diff = (dt2?.getTime() - dt1?.getTime()) / 1000;

  diff /= 60 * 60 * 24;

  return Math.abs(Math.round(diff / 365.25));
}

export const formattedDateStartingFromZero = (dateToFormat: any) => {
  if (!dateToFormat) return "";
  const myDate = new Date(dateToFormat);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[myDate.getMonth()];
  const date = addZero(myDate.getDate());
  const year = myDate.getFullYear();
  const hh = "00"; // Set hours to "00"
  const mm = "00"; // Set minutes to "00"
  return date + "-" + month + "-" + year + " " + hh + ":" + mm;
};

export const formattedDateLong = (dateToFormat: any) => {
  if (!dateToFormat) return "";
  const myDate = new Date(dateToFormat);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[myDate.getMonth()];
  const date = addZero(myDate.getDate());
  const year = myDate.getFullYear();
  const hh = addZero(myDate.getHours());
  const mm = addZero(myDate.getMinutes());
  const ss = addZero(myDate.getSeconds());
  return date + "-" + month + "-" + year + " " + hh + ":" + mm;
};

export const formatDate = (dateToFormat: any) => {
  if (!dateToFormat) return "";
  const myDate = new Date(dateToFormat);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[myDate.getMonth()];
  const date = addZero(myDate.getDate());
  const year = myDate.getFullYear();
  return date + " - " + month + " - " + year;
};
export const formatDateForChats = (dateToFormat: any) => {
  if (!dateToFormat) return "";
  const myDate = new Date(dateToFormat);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[myDate.getMonth()];
  const date = addZero(myDate.getDate());
  const year = myDate.getFullYear();
  return date + "-" + month + "-" + year;
};
export const formatDateWithTime = (inputString: string) => {
  try {
    // Create a new Date object from the input string
    const dateObj = new Date(inputString);

    // Extract date components
    const year = dateObj.getUTCFullYear();
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const hours = dateObj.getUTCHours().toString().padStart(2, "0");
    const minutes = dateObj.getUTCMinutes().toString().padStart(2, "0");

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Format the date and time in the desired output format
    const formattedDate = `${day}-${monthNames[parseInt(month) - 1]}-${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} | ${formattedTime}`;
  } catch (error) {
    console.error("Error formatting date and time:", error);
    return null;
  }
};

export const convertDateToISO = (dateToConvert: string) => {
  const myDate = new Date(dateToConvert);
  return myDate.toISOString();
};

// ---- Standard Validator ----
/**
 * Standard Validator functions to be used with simple-react-validator package
 * @returns validation object
 */
export const getStandardValidator = () => {
  return {
    mandatory: {
      message: "Mandatory",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (params[0] === "num") {
          // console.log(params, val, "params");
          if (parseFloat(val) > 0) {
            return true;
          }
          return false;
        } else {
          if (val.toString()) {
            return true;
          } else {
            return false;
          }
        }
      },
      required: true, // optional
    },
    mandatoryElementInArray: {
      message: "Mandatory",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val?.length > 0) {
          return true;
        }
        return false;
      },
      required: true, // optional
    },

    checked: {
      message: "Mandatory",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val) {
          return true;
        } else {
          return false;
        }
      },
      required: true, // optional
    },

    autocomplete: {
      message: "Select Valid Entry",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val.toString()) {
          return true;
        } else {
          return false;
        }
      },
      required: true, // optional
    },
    alphaNumericUnderscore: {
      message: "Must contain only Alphabets, Numbers, Underscores",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const regex = /^[A-Za-z0-9_]+$/;
        return regex.test(val);
      },
      required: true, // optional
    },
    capitalLetters: {
      message: "Only capital letters allowed",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const regex = /^[A-Z0-9_]+$/;
        return regex.test(val);
      },
      required: true, // optional
    },

    checkString: {
      message: "Enter Text",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (isNaN(val)) {
          return true;
        } else {
          return false;
        }
      },
      required: true,
    },

    onlyPositive: {
      message: "Negative values are not allowed",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val) {
          const isPositive = Math.sign(Number(val));
          if (isPositive < 0) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      },
    },

    checkNumber: {
      message: "Enter Number",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (isNaN(val)) {
          return false;
        } else {
          return true;
        }
      },
      required: true,
    },

    onlyInteger: {
      message: "Decimals not allowed",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val) {
          const numericValue = Number(val);
          if (!(numericValue || numericValue === 0) && !isNaN(numericValue)) {
            return false;
          }
          const splitValue = val.toString().split(".");
          if (splitValue) {
            if (splitValue.length > 1) {
              return false;
            }
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
      messageReplace: (message: string, params?: any) => {
        // return message.replace(":type", "AA");
      },
    },

    onlyWholeNumber: {
      message: ":type",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val?.trim?.() === "") {
          return false;
        }
        const numericValue = Number(val);
        if (isNaN(numericValue)) {
          return false;
        }
        if (numericValue < 0) {
          return false;
        }
        const splitValue = val.toString().split(".");

        if (splitValue.length > 1) {
          return false;
        }
        return true;
      },

      messageReplace: (message: string, params?: any) => {
        if (params[0] === "onlyNumberMsg") {
          return message.replace(":type", `Enter Number`);
        }
        return message.replace(":type", `Enter Whole Number`);
      },
    },

    phoneNumber: {
      message: "Number should be 10 digits",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val?.length === 10) {
          return true;
        } else {
          return false;
        }
      },
    },

    max: {
      message: ":type",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (params[1] === "num") {
          if (parseFloat(val) > parseFloat(params[0])) {
            return false;
          } else {
            return true;
          }
        } else if (val.length > parseFloat(params[0])) {
          return false;
        } else {
          return true;
        }
      },
      messageReplace: (message: string, params?: any) => {
        if (params[1] === "num") {
          return message.replace(
            ":type",
            `Maximum value is ${numberFormatter(params[0], window.locale?.defaultLocale, 0)}`
          );
        } else if (params[1] === "number") {
          return message.replace(":type", `Max length ${params[0]} digits`);
        } else {
          return message.replace(":type", `Max length ${params[0]} chars`);
        }
      },
    },

    min: {
      message: ":type",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (params[1] === "num") {
          if (parseFloat(val) < parseFloat(params[0])) {
            return false;
          } else {
            return true;
          }
        } else if (val.length < parseFloat(params[0])) {
          return false;
        } else {
          return true;
        }
      },
      messageReplace: (message: string, params?: any) => {
        if (params[1] === "num") {
          return message.replace(
            ":type",
            `Minimum value is ${numberFormatter(params[0], window.locale?.defaultLocale, 0)}`
          );
        } else if (params[1] === "number") {
          return message.replace(":type", `Max length ${params[0]} digits`);
        } else {
          return message.replace(":type", `Min length ${params[0]} chars`);
        }
      },
    },

    maxDecimal: {
      message: "Enter Only Two Decimal Values",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const re = new RegExp(`^\\d+\\.?\\d{0,${params[0]}}$`);

        if (re.test(val)) {
          return true;
        } else {
          return false;
        }
      },
    },

    lessThanApplicableFrom: {
      message: "Applicable To cannot be less than Applicable From",
      rule: (val: any, params?: any, validator?: any): boolean => {
        // console.log(val);
        // console.log(params[0]);
        if (new Date(val) < new Date(params[0])) {
          return false;
        } else {
          return true;
        }
      },
    },

    maxDigits: {
      message: "Exceeded maximum number allowed",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val.length > params[0]) {
          return false;
        } else {
          return true;
        }
      },
    },

    maxValue: {
      message: "Exceeded max value",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (parseFloat(val) > parseFloat(params[0])) {
          return false;
        } else {
          return true;
        }
      },
    },

    toAndFromDate: {
      message: ":type",
      rule: (val: any, params?: any, validator?: any): boolean => {
        // val is
        // params[0] is second date type
        // params[1] is message For field enum
        // params[2] is second date
        if (val === "" || params[2] === "") {
          return true;
        }
        if (params[0] === "to") {
          if (new Date(val) < new Date(params[2])) {
            return true;
          }
          return false;
        } else if (params[0] === "opening") {
          if (new Date(val) < new Date(params[2])) {
            return true;
          }
          return false;
        } else {
          // in this case second date type is "from"
          if (new Date(val) > new Date(params[2])) {
            return true;
          }
          return false;
        }
      },
      messageReplace: (message: string, params?: any) => {
        const messageFor = params[1];
        if (messageFor) {
          return message.replace(
            ":type",
            `${
              TO_AND_FROM_DATE_MESSAGE?.[messageFor as keyof typeof TO_AND_FROM_DATE_MESSAGE]?.label
            }`
          );
        } else {
          return message.replace(":type", `Invalid Date`);
        }
      },
    },

    onlyAlphaNumUnderscoreUpperScore: {
      message: "Only Alpha, Num, _, Uppercase allowed",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const re = /^[A-Z0-9_]*$/;
        if (re.test(val)) {
          return true;
        } else {
          return false;
        }
      },
    },

    aadhaar: {
      message: "Enter Valid Aadhaar Number",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const regex = /^[2-9]\d{3}\s\d{4}\s\d{4}$/;
        if (isNaN(val.split(" ").join("")) || regex.test(val) !== true) {
          return false;
        } else {
          return true;
        }
      },
    },

    pan: {
      message: "Enter Valid PAN",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const regex = /[A-Z]{5}\d{4}[A-Z]/;
        if (regex.test(val) !== true) {
          return false;
        } else {
          if (val?.length === 10) {
            return true;
          }
          return false;
        }
      },
    },

    latitude: {
      message: "Invalid (Range: -90 to 90).",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const num = parseFloat(val);
        if (isFinite(num) && Math.abs(num) <= 90) {
          return true;
        } else {
          return false;
        }
      },
    },

    longitude: {
      message: "Invalid (Range: -180 to 180).",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const num = parseFloat(val);
        if (isFinite(num) && Math.abs(num) <= 180) {
          return true;
        } else {
          return false;
        }
      },
    },

    dob: {
      message: "Minimum age 18 years",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (isValidDate(val)) {
          const ageDetails = calculateDateDifference(new Date(), val);

          if (ageDetails?.years >= 18) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      },
    },

    aadharCard: {
      message: "Enter Valid Aadhar",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const verifiedMaskedPattern = /^XXXX XXXX \d{4}$/;
        const verifiedMaskedPatternWithoutExtraSpace = /^XXXXXXXX\d{4}$/;
        const ocrMaskedPattern = /^\*{8}\d{4}$/;
        const regex = /^\d{12}$/;
        if (verifiedMaskedPattern.test(val)) return true;
        else if (ocrMaskedPattern.test(val)) return true;
        else if (verifiedMaskedPatternWithoutExtraSpace.test(val)) return true;
        else if (regex.test(val) !== true) return false;
        return true;
      },
    },

    receiptAmount: {
      message: "Value cannot be zero",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val?.trim?.() === "") {
          return false;
        }
        const numericValue = Number(val);
        if (isNaN(numericValue)) {
          return false;
        }
        if (numericValue > 0) {
          return true;
        }
        return false;
      },
    },

    chequeNo: {
      message: "Invalid Cheque No",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const regex = /^[0-9]{6}$/;
        if (regex.test(val) !== true) {
          return false;
        }

        return true;
      },
    },

    TLDCheck: {
      message: "Enter valid URL",
      rule: (val: any, params?: any, validator?: any): boolean => {
        // Define a regular expression pattern to check for a ".com" or any other TLD
        const pattern = /\S+\.(com|net|org|gov|edu|io|co|xyz|yourtld)$/i;

        // Check if the URL matches the pattern
        return pattern.test(val);
      },
    },
    accountNumber: {
      message: "Invalid Account Number",
      rule: (val: any, params?: any, validator?: any): boolean => {
        // Define a regular expression pattern to check for a account number
        const pattern = /^\w{9,18}$/;

        // Check if the account number matches the pattern
        return pattern.test(val);
      },
    },
    optional: {
      message: "",
      rule: (val: any, params?: any, validator?: any): boolean => {
        return true;
      },
    },
    arrayMinOneReceipt: {
      message: "At least one receipt should be selected",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val > 0) {
          return true;
        }
        return false;
      },
    },
    lessThanSanctionTenor: {
      message: "value should be less then sanction tenor",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (parseFloat(val) >= parseFloat(params?.[0])) {
          return false;
        }
        return true;
      },
    },
    maxLoanAmount: {
      message: "Max Loan Amount cannot be less than Min Loan Amount",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (parseFloat(val) < parseFloat(params?.[0])) {
          return false;
        }
        return true;
      },
    },
    minFinLoanNumber: {
      message: "Invalid Number",
      rule: (val: any, params?: any, validator?: any): boolean => {
        // Define a regular expression pattern to check for a account number
        const pattern = /^[a-zA-Z0-9]{15,15}$/;

        // Check if the account number matches the pattern
        return pattern.test(val);
      },
    },
  };
};

/**
 * Message Replacement
 * @returns
 */
export const messageReplace = () => {
  return {
    messages: {
      alpha_num: "Special chars are not allowed",
      alpha_num_dash: "Special chars except dash (-) are not allowed",
      numeric: "Only numbers are allowed",
      // required: "validator.mandatory",
    },
  };
};

/**
 * parse the error message from object
 * @param {object} errorMessageObj
 * @returns formatted error string
 */
export const parseStandardAPIErrorMessage = (errorMessageObj: any) => {
  const errorMessage = errorMessageObj?.response?.data?.message;

  if (errorMessage?.length > 0) {
    return errorMessage;
  }
  if (!errorMessage) {
    return `Something Went Wrong!!!`;
  }
};
export const parseStandardAPIErrorMessageForRemovingCode = (errorMessageObj: any) => {
  const errorMessage = errorMessageObj?.response?.data?.message;

  if (errorMessage.length > 0) {
    const substrings = errorMessage[0].split(":");

    return [substrings[1]];
  }
  if (!errorMessage) {
    return `Something Went Wrong!!!`;
  }
};
export const parseStandardAPIErrorMessageNew = (errorMessageObj: any) => {
  const errorMessage = errorMessageObj?.response?.data?.message;

  const errorDetail = [];
  if (errorMessage.length > 0) {
    const parsedErrorMessage = JSON.parse(errorMessage);
    const keys = Object.keys(parsedErrorMessage);
    for (let i = 0; i < keys.length; i++) {
      const element = keys[i];
      // errorDetail += `${element} :  \n`;
      // errorDetail.push("---------------------------------------------------------");
      errorDetail.push({
        isHeading: true,
        text: element,
      });
      // errorDetail.push("---------------------------------------------------------");

      for (let j = 0; j < parsedErrorMessage[element].length; j++) {
        const detailElement = parsedErrorMessage[element][j];
        // errorDetail += `${detailElement} \n`;
        errorDetail.push(detailElement);
      }
    }
    return errorDetail;
  }
  if (!errorMessage) {
    return `Something Went Wrong!!!`;
  }
};

export function validateGetAPIResponse(res: any) {
  if ((res?.status === 200 || res?.status === 304) && res.data) return true;  //304 allow to show cached version

  throw new Error();
}

export function validatePostAPIResponse(res: any) {
  if ((res?.status === 201 || res?.status === 304) && res.data) return true; 

  throw new Error();
}

export function validatePutAPIResponse(res: any) {
  if ((res?.status === 200 || res?.status === 304) && res.data) return true;

  throw new Error();
}



//PasswordBox

export const verifyUpperCase = (string: string): boolean => {
  const pattern3 = /[A-Z]/;
  if (!string) return false;
  if (string.match(pattern3)) {
    return true;
  } else {
    return false;
  }
};

export const verifyNumber = (string: string): boolean => {
  const pattern1 = /[0-9]/;
  if (!string) return false;
  if (string.match(pattern1)) {
    return true;
  }
  return false;
};

export const verifyLowerCase = (string: string): boolean => {
  const pattern2 = /[a-z]/;
  if (!string) return false;
  if (string.match(pattern2)) {
    return true;
  } else {
    return false;
  }
};

export const verifyCharacterLimit = (string: string, lowerLimit: number) => {
  if (!string) return false;
  if (string.length >= lowerLimit) {
    return true;
  }
  return false;
};

export function toTitleCase(str: string) {
  return str.toLowerCase().replace(/(?:^|\s|-)\S/g, function (match) {
    return match.toUpperCase();
  });
}

export function convertToIndianNumbering(number: number) {
  function number2text(value: any) {
    const fraction = Math.round(frac(value) * 100);
    let f_text = "";

    if (fraction > 0) {
      f_text = "AND " + convert_number(fraction) + " PAISE";
    }

    return convert_number(value) + " RUPEE " + f_text + " ONLY";
  }

  function frac(f: any) {
    return f % 1;
  }
  function convert_number(number: number) {
    if (number < 0 || number > 999999999) {
      return "NUMBER OUT OF RANGE!";
    }
    const Gn = Math.floor(number / 10000000); /* Crore */
    number -= Gn * 10000000;
    const kn = Math.floor(number / 100000); /* lakhs */
    number -= kn * 100000;
    const Hn = Math.floor(number / 1000); /* thousand */
    number -= Hn * 1000;
    const Dn = Math.floor(number / 100); /* Tens (deca) */
    number = number % 100; /* Ones */
    const tn = Math.floor(number / 10);
    const one = Math.floor(number % 10);
    let res = "";

    if (Gn > 0) {
      res += convert_number(Gn) + " CRORE";
    }
    if (kn > 0) {
      res += (res == "" ? "" : " ") + convert_number(kn) + " LAKH";
    }
    if (Hn > 0) {
      res += (res == "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
    }

    if (Dn) {
      res += (res == "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
    }

    const ones = [
      "",
      "ONE",
      "TWO",
      "THREE",
      "FOUR",
      "FIVE",
      "SIX",
      "SEVEN",
      "EIGHT",
      "NINE",
      "TEN",
      "ELEVEN",
      "TWELVE",
      "THIRTEEN",
      "FOURTEEN",
      "FIFTEEN",
      "SIXTEEN",
      "SEVENTEEN",
      "EIGHTEEN",
      "NINETEEN",
    ];
    const tens = [
      "",
      "",
      "TWENTY",
      "THIRTY",
      "FOURTY",
      "FIFTY",
      "SIXTY",
      "SEVENTY",
      "EIGHTY",
      "NINETY",
    ];

    if (tn > 0 || one > 0) {
      if (!(res == "")) {
        res += " AND ";
      }
      if (tn < 2) {
        res += ones[tn * 10 + one];
      } else {
        res += tens[tn];
        if (one > 0) {
          res += "-" + ones[one];
        }
      }
    }

    if (res == "") {
      res = "zero";
    }
    return res;
  }
  const result = number2text(number);
  return toTitleCase(`${result}`);
}

export function convertMonthsToYearsAndMonths(months: number) {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  let result = "";
  if (years > 0) {
    result += years + (years === 1 ? " year" : " years");
  }
  if (remainingMonths > 0) {
    if (years > 0) {
      result += " ";
    }
    result += remainingMonths + (remainingMonths === 1 ? " month" : " months");
  }

  return toTitleCase(result);
}

export const loadScript = (src: string, id: string, callBack?: () => void) => {
  console.log("loadScript");
  if (document.getElementById(id)) {
    callBack && callBack();
    return;
  }
  const script = document.createElement("script");
  script.src = `${src}`;
  document.body.appendChild(script);
  script.onload = () => {
    if (callBack) callBack();
  };
};

export const currencyFormatToNumber = (input: string) => {
  if (!input) {
    return 0;
  }
  if (!input.toString().includes(",")) {
    return parseFloat(input);
  }
  // Remove all commas from the input string
  const strippedInput = input.replace?.(/,/g, "");

  // Parse the stripped input as a number
  const output = parseFloat(strippedInput);

  // Check if the output is a valid number
  if (!isNaN(output)) {
    return output;
  } else {
    return 0;
  }
};

export const currencyFormatToNumberIncludeNull = (input: string | number | null) => {
  // if(input==="."){
  //   return "."
  // };
  if (input === 0 || input === ".") {
    return 0;
  }

  if (!input) {
    return null;
  }

  if (typeof input === "number") {
    if (!isNaN(input)) {
      return input;
    } else {
      return null;
    }
  }

  if (!input.toString().includes(",")) {
    const output = parseFloat(input);
    if (!isNaN(output)) {
      return output;
    } else {
      return null;
    }
  }

  // Remove all commas from the input string
  const strippedInput = input.replace?.(/,/g, "");

  // Parse the stripped input as a number
  const output = parseFloat(strippedInput);

  // Check if the output is a valid number
  if (!isNaN(output)) {
    return output;
  } else {
    return null;
  }
};

export const currencyFormatToNumberForFilter = (input: string) => {
  if (!input) {
    return "";
  }

  // Remove all commas from the input string
  const strippedInput = input.replace?.(/,/g, "");

  // Parse the stripped input as a number
  const output = parseFloat(strippedInput);

  // Check if the output is a valid number
  if (!isNaN(output)) {
    return output;
  } else {
    return 0;
  }
};

export const formatIndianRupees = (amount: number) => {
  if (isNaN(amount)) {
    return 0;
  }

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(amount);
};

/*
-----------------------------------------------------------------------------------------------------------
function to get the year from date range value
-----------------------------------------------------------------------------------------------------------
*/

export function getYear(value: string) {
  const date = new Date(String(value));

  // Get the year from the Date object
  const year = date.getFullYear();
  return year;
}

export function getDateString(value: number | string) {
  // Create a new Date object with the year
  const date = new Date(Number(value), 0, 1, 0, 0, 0, 0);

  // Format the Date object as a string
  const dateString =
    date.toDateString() + " " + date.toTimeString() + " GMT+0530 (India Standard Time)";

  return new Date(dateString);
}

export function isInvalidString(value: any) {
  if (!value) {
    return true;
  }
  if (typeof value === "string" && value?.length > 0) {
    return false;
  }
  return true;
}

export const getFilteredDocs = (doc: any[]) => {
  return doc.filter((item: any) => item.url !== "");
};

export function checkDataHasValue(obj: any) {
  for (const key in obj) {
    const value = obj[key];
    if (
      value !== "" &&
      value !== undefined &&
      value !== null &&
      value !== false &&
      (Array.isArray(value) ? value.length !== 0 : true) &&
      (typeof value !== "object" || Object.keys(value).length !== 0)
    ) {
      return true;
    }
  }

  return false;
}

export function formatNumberToAadhaar(value: string) {
  const regex = /(\d{4})(?=\d)/g;
  return value.replace(regex, "$1 ");
}

export function isValidEmail(email: string) {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidDate(value: any) {
  // const date = new Date(value);
  return value instanceof Date && !isNaN(value?.getTime());
}

export function capitalizeWords(inputString: string) {
  return inputString.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function formatJSON(obj: { [key: string]: any }, indentLevel = 0) {
  const spaces: string = " ".repeat(indentLevel * 2);
  const entries: string = Object.entries(obj)
    .map(
      ([key, value]) =>
        `${spaces}"${key}": ${
          typeof value === "object" && value !== undefined && value !== null
            ? formatJSON(value, indentLevel + 1)
            : breakTextIntoLines(JSON.stringify(value), 50)
        }`
    )
    .join(",\n\n");
  return `{\n\n${entries}\n${spaces}\n}`;
}

export function breakTextIntoLines(value: any, lineLength: number, format?: string) {
  if (typeof value !== "string" || value.length <= lineLength) {
    return value;
  }
  let formatBy = " \n \t ";
  const regex = new RegExp(`.{1,${lineLength}}`, "g");
  if (format) {
    formatBy = format;
  }
  const formattedValue = value?.match(regex)?.join(formatBy);
  return `${formattedValue}`;
}

export const getDocumentCodeWithoutNumber = (section: string) => {
  if (typeof section !== "string") {
    return section;
  }
  // if(DOCUMENT_CHECK_TYPE.hasOwnProperty(section)){
  //    return section;
  // }
  return section.replace(/_\d+$/, "");
};

export const stringToHTML = (htmlString: string) => {
  const parser = new DOMParser();
  const html = parser.parseFromString(htmlString, "text/html");
  return "";
};

export const removeSpacesInString = (value: string) => {
  return value.replace(/\s+/g, "");
};

export const getNextAvailableKey = (inputArr: any[], keyName: string) => {
  // Calculate the next available key
  const existingKeys = inputArr.map((item: any) => parseInt(item[keyName]) || 0); // Parse integers or use 0 if key is not numeric
  const newKey = Math.max(...existingKeys) + 1;
  return newKey;
};

export const getAgeFromDob = (value: string) => {
  if (!value) {
    return 0;
  }
  const today = new Date();
  const birthDate = new Date(value);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getMonthFromYear = (value: any) => {
  if (!value || Number.isNaN(parseInt(value))) {
    return 0;
  }
  const months = parseInt(value);
  const years = Math.floor(months / 12);
  return years;
};

export const getNumberWithMaxTwoDecimalPlaces = (data: number) => {
  if (!data) {
    return "";
  }
  const dataInString = data?.toString();
  const numberSplitByDot = dataInString.split(".");
  return `${numberSplitByDot?.[0] ?? ""}.${numberSplitByDot?.[1]?.slice(0, 2) ?? ""}`;
};

export const getStaticMasterNames = (key: any) => {
  const localStorageData = JSON.parse(getLocalStorage("staticMaster"));
  // eslint-disable-next-line no-prototype-builtins
  if (localStorageData && key in localStorageData) {
    return localStorageData[key];
  } else {
    return key;
  }
};

export const convertEnumValueFromUpperCaseToFirstLetterUpperCase = (value: string): string => {
  return value
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, function (a: any) {
      return a.toUpperCase();
    });
};

export const removeHtmlTags = (html: any) => {
  if (html) {
    // Remove HTML tags
    let cleanedHtml = html.replace(/<[^>]*>/g, "");
    // Remove HTML entities
    cleanedHtml = cleanedHtml.replace(/&[^;]+;/g, "");
    // Trim leading and trailing spaces
    cleanedHtml = cleanedHtml.trim();
    // Replace multiple spaces with a single space
    cleanedHtml = cleanedHtml.replace(/\s\s+/g, " ");
    return cleanedHtml;
  } else {
    return "";
  }
};

export const msDocumentType = {
  WORD: "WORD",
  EXCEL: "EXCEL",
};

export const isExcelOrWord: (docType: string) => string = (docType: string) => {
  const excelType = FILE_TYPE_EXCEL?.find((type: string) => type === docType);
  const wordType = FILE_TYPE_WORD?.find((type: string) => type === docType);
  return excelType ? msDocumentType.EXCEL : wordType ? msDocumentType.WORD : "";
};

export const isZipFile: (docType: string) => boolean = (docType: string) => {
  const zipType = FILE_TYPE_ZIP?.find((type: string) => type === docType);
  return zipType ? true : false;
};

export const getFileNameFromUrl: (url: string,splitUrlBy?:string) => { fileName: string; extension: string } = (
  url: string,
  splitUrlBy?:string
) => {

  if(url?.length ===0){
    return  { fileName: "", extension: "" };
  }
  const splitByValue: string[] = url?.split(splitUrlBy??"/image/");
  
  if(splitByValue?.[1]?.length ===0){
    return { fileName: "", extension: "" };
  }
  const extension = extractFileExtensionWithoutName(splitByValue?.[1]);

  const nameWithoutTimestamp =  extractFileNameWithoutTimestamp(splitByValue?.[1]);
  return { fileName: nameWithoutTimestamp, extension: extension };
};


export const isCode: (value: string) => boolean = (
  value: string
) => {
  if(value.includes("_")){
    return true;
  }
  return false;
};

export const extractFileNameWithoutTimestamp: (value: string) => string = (
  value: string
) => {
   const lastUnderScoreIndex= value.lastIndexOf("_");
   return  value?.slice(0,lastUnderScoreIndex);
};

export const extractFileNameWithoutExtension: (value: string) => string = (
  value: string
) => {
   const lastUnderScoreIndex= value.lastIndexOf(".");
   return  value?.slice(0,lastUnderScoreIndex);
};

export const extractFileExtensionWithoutName: (value: string) => string = (
  value: string
) => {
   const lastUnderScoreIndex= value.lastIndexOf(".");
   return  value?.slice(lastUnderScoreIndex+1,value?.length);
};


