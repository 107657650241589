/**
 * Author : Kaviraj RM
 * Created on : 22 May, 2023
 **/
import InputError from "components/InputError";
import Label from "components/Label";
import { IBaseInputCommonProps } from "components/interface/Input";
import passwordSuccess from "assets/img/auth/Tick_Mark.svg";
import { InputTextProps } from "primereact/inputtext";
import { Password } from "primereact/password";
import React, { useEffect, useImperativeHandle, useState } from "react";
import {
  verifyCharacterLimit,
  verifyLowerCase,
  verifyNumber,
  verifyUpperCase,
} from "utils/CommonUtil";
import Text from "components/Text/Text";

export interface IBaseInputPasswordProps extends InputTextProps, IBaseInputCommonProps {
  feedback?: boolean;
  toggleMask?: boolean;
  type?: string;
  onChangeTextState?: any;
  showPasswordVerification?: any;
  ref?: any;
  onPasswordValidityChange?: any;
}

const BasePassword: React.FC<IBaseInputPasswordProps> = React.forwardRef((props: any, ref: any) => {
  const {
    hideLabel,
    hideErrorRow,
    label,
    validation,
    hideError,
    labelClassNames,
    labelStyleObj,
    errorClassNames,
    ...basePrimeReactProps
  } = props;

  const {
    className,
    value,
    style,
    onChange,
    name,
    placeholder,
    onChangeTextState,
    toggleMask,
    showPasswordVerification,
    ...primeReactProps
  } = basePrimeReactProps;

  const [checkPasswordConditions, setCheckPasswordConditions] = useState({
    verifyMinChar: false,
    verifyNumeric: false,
    verifyLowCase: false,
    verifyUppCase: false,
  });

  // const [isPasswordValid, setIsPasswordValid] = useState(false);

  const updatePasswordCondition = (text: string, state: boolean) => {
    setCheckPasswordConditions((prevState) => {
      return { ...prevState, [text]: state };
    });
  };

  useImperativeHandle(ref, () => ({
    getPasswordValidity,
  }));

  const getPasswordValidity = () => {
    const { verifyMinChar, verifyNumeric, verifyLowCase, verifyUppCase } = checkPasswordConditions;
    if (verifyMinChar && verifyNumeric && verifyLowCase && verifyUppCase) {
      return true;
    } else {
      return false;
    }
  };
  // console.log(isPasswordValid,"isPasswordValidOutSide")

  const handleChange = (e: any) => {
    //Verifying the character limit
    if (verifyCharacterLimit(e.target.value, 8)) {
      updatePasswordCondition("verifyMinChar", true);
    } else {
      updatePasswordCondition("verifyMinChar", false);
    }

    // Verify presence of number
    if (verifyNumber(e.target.value)) {
      updatePasswordCondition("verifyNumeric", true);
    } else {
      updatePasswordCondition("verifyNumeric", false);
    }

    // Verify presence of lower case

    if (verifyLowerCase(e.target.value)) {
      updatePasswordCondition("verifyLowCase", true);
    } else {
      updatePasswordCondition("verifyLowCase", false);
    }

    // Verify presence of upper case
    if (verifyUpperCase(e.target.value)) {
      updatePasswordCondition("verifyUppCase", true);
    } else {
      updatePasswordCondition("verifyUppCase", false);
    }

    onChange(e);
  };

  const { verifyMinChar, verifyNumeric, verifyLowCase, verifyUppCase } = checkPasswordConditions;
  return (
    <div className="group grid">
      <div className="relative">
        {!hideLabel && (
          <div className="inputLabel">
            <Label
              label={label}
              labelClassNames={labelClassNames}
              labelStyleObj={labelStyleObj}
              isInvalid={validation}
            />
          </div>
        )}
        <Password
          toggleMask
          inputClassName={className + " pr-10 "}
          className="w-full"
          value={value}
          style={style}
          onChange={handleChange}
          name={name}
          placeholder={placeholder}
          feedback={false}
          onChangeTextState={onChangeTextState}
          {...primeReactProps}
        />
      </div>

      {!hideError && (
        <InputError
          validation={validation}
          hideErrorRow={hideErrorRow}
          errorClassNames={errorClassNames + " mt-errorMargin"}
        />
      )}

      {showPasswordVerification && (
        <div className="mt-6 text-xs font-Roboto mb-7">
          <ul className=" leading-7">
            <li
              className={
                verifyMinChar ? "text-primary-labelColor" : "text-primary-disabledButtonTextColor "
              }
            >
              <Text label="label.text.password.characters" />
              <div className="float-right">
                {verifyMinChar && (
                  <img src={passwordSuccess} alt="Password not success icon" className="w-6 h-6" />
                )}
              </div>
            </li>
            <li
              className={
                verifyLowCase ? "text-primary-labelColor" : "text-primary-disabledButtonTextColor "
              }
            >
              <Text label="label.text.password.smallLetter" />
              {verifyLowCase && (
                <img
                  src={passwordSuccess}
                  alt="Password not success icon"
                  className="float-right"
                />
              )}
            </li>
            <li
              className={
                verifyUppCase ? "text-primary-labelColor" : "text-primary-disabledButtonTextColor "
              }
            >
              <Text label="label.text.password.capitalLetter" />
              {verifyUppCase && (
                <img
                  src={passwordSuccess}
                  alt="Password not success icon"
                  className="float-right"
                />
              )}
            </li>
            <li
              className={
                verifyNumeric ? "text-primary-labelColor" : "text-primary-disabledButtonTextColor "
              }
            >
              <Text label="label.text.password.number" />
              {verifyNumeric && (
                <img
                  src={passwordSuccess}
                  alt="Password not success icon"
                  className="float-right"
                />
              )}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
});

export default BasePassword;
