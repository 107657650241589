import {
  USER_PROFILE_BEGIN,
  USER_PROFILE_FAILURE,
  USER_PROFILE_SUCCESS,
} from "./1_ActionConstants";

export const userProfileBegin = () => ({
  type: USER_PROFILE_BEGIN,
});

export const userProfileSuccess = (profileObj) => ({
  type: USER_PROFILE_SUCCESS,
  payload: profileObj,
});

export const userProfileFailure = (error) => ({
  type: USER_PROFILE_FAILURE,
  payload: { error },
});

export function saveUserProfile(profileObj) {
  return (dispatch) => {
    //redux passes dispatch & getState as args into thunk functions
    dispatch(userProfileBegin());
    if (profileObj) {
      dispatch(userProfileSuccess(profileObj));
      return true;
    } else {
      dispatch(userProfileFailure("error"));
      return false;
    }
  };
}
