import React, { memo, useState } from "react";

// ------ ------ //
import Text from "components/Text";

// ------ ------ //
import { COMMON_ERROR_STYLE } from "assets/base-theme";
import SimpleToolTip from "components/SimpleToolTip/SimpleToolTip";
import { IInputErrorProps } from "components/interface/Input";
import { generateClasses } from "utils/Util";
import { Tooltip } from "primereact/tooltip";

const InputError: React.FC<IInputErrorProps> = (props: IInputErrorProps) => {
  const [message, setMessage] = useState<any>("");
  const { errorClassNames, errorStyleObj, hideErrorRow, validation, errorMessage } = props;
  const getClassNames = () => {
    const styleOBJECT = {
      primaryStyle: COMMON_ERROR_STYLE,
      overrideStyle: errorStyleObj,
      overrideClasses: errorClassNames,
    };

    return generateClasses(styleOBJECT);
  };
  let errorContent = null;

  if (!hideErrorRow) {
    if (validation || errorMessage) {
      errorContent = (
        <div
          className={`flex flex-row text-sm items-center error-tooltip  mt-1 h-4 truncate ${getClassNames()}`}
          data-pr-tooltip={message}
        >
          <div
            className={`text-left leading-tight truncate`}
            onMouseOver={(e: any) => {
              setMessage(e.target.innerHTML);
            }}
          >
            <Text label={validation || errorMessage} className={`leading-4`} />
          </div>
          {message && (
            <Tooltip
              target=".error-tooltip"
              position="bottom"
              className="text-xs font-Roboto bg-black text-white !p-1 rounded-md"
            />
          )}
        </div>
      );
    } else {
      errorContent = (
        <div
          className={`h-4 mt-1 text-sm text-left text-error leading-tight truncate ${getClassNames()} `}
        />
      );
    }
  }

  return <React.Fragment>{errorContent}</React.Fragment>;
};

export default memo(InputError);
