import { IRouteObj } from "interface/common";
import React from "react";

// Path
export const LINK_LOAN_DATA = "/loan-data";
export const LINK_LOAN_LIST = LINK_LOAN_DATA + "/loan-list";

export const LINK_TASK_LIST = LINK_LOAN_DATA + "/task-list";
export const LINK_TASK_DETAIL = LINK_LOAN_DATA + "/task-detail";
export const LINK_PARTNER_TASK_LIST = LINK_LOAN_DATA + "/partner-task-list";
export const LINK_PARTNER_TASK_DETAIL = LINK_LOAN_DATA + "/partner-task-detail";
export const LINK_TASK_QC_NON_INITIATED = LINK_LOAN_DATA + "/non-initiated";

export const LINK_REPORT = LINK_LOAN_DATA + "/report";

export const LINK_FEES = LINK_LOAN_DATA + "/fees";
export const LINK_FEES_RECEIPT = LINK_FEES + "/receipt";
export const LINK_FEES_DEPOSIT = LINK_FEES + "/deposit";
export const LINK_FEES_DEPOSIT_RECONCILIATION = LINK_FEES + "/deposit-from-reconciliation";
export const LINK_FEES_DEPOSIT_CREATE_EDIT = LINK_FEES_DEPOSIT + "/create-edit";
export const LINK_FEES_DEPOSIT_CREATE_EDIT_FROM_RECONCILIATION =
  LINK_FEES_DEPOSIT_RECONCILIATION + "/create-edit";
export const LINK_RECONCILIATION = LINK_FEES + "/reconciliation";

export const LINK_QDE = LINK_LOAN_DATA + "/qde";

export const LINK_LOAN_DETAIL = LINK_LOAN_DATA + "/loan-detail";

export const LINK_DDE_TEST1 = LINK_LOAN_DETAIL + "/test1";

export const LINK_DDE_LOGIN = "/dde-login";
export const LINK_DDE_DETAIL = "/";
export const LINK_DDE_APPLICANT = "/applicant";
export const LINK_DDE_CO_APPLICANT = "/co-applicant";
export const LINK_DDE_CORPORATE_CO_APPLICANT = "/corporate-co-applicant";
export const LINK_DDE_GUARANTORS = "/guarantors";
export const LINK_DDE_CORPORATE_GUARANTORS = "/corporate-guarantors";
export const LINK_DDE_DEPENDENTS = "/dependents";
export const LINK_DDE_COLLATERAL_PROPERTY = "/collateral-property";
export const LINK_DDE_DOCUMENTS = "/documents";
export const LINK_DDE_FEES = "/fees";
export const LINK_DDE_CRIF_DETAILED_REPORT = "/crif-detailed-report";
export const LINK_DDE_CBIL_DETAILED_REPORT = "/cibil-detailed-report";

export const LINK_POINT_OF_INTEREST = "/point-of-interest";

export const LINK_DDE_QC = "/qc";
export const LINK_DDE_QC_CHECKLIST = "/";
export const LINK_DDE_QC_DOCUMENTS = "/qc-documents";
export const LINK_DDE_QC_DUPLICATE = "/duplicate";

export const LINK_DDE_STAGE_AND_STATUS = "/stage-status";
export const LINK_DDE_STAGE_AND_STATUS_STAGES = "/";
export const LINK_DDE_STAGE_AND_STATUS_HISTORY = "/history";
export const LINK_DDE_STAGES_INITIATE = LINK_DDE_STAGE_AND_STATUS_STAGES + "/initiate";

export const LINK_DDE_CREDIT_QUERY = "/credit-query";

export const LINK_PD = "/pd";
export const LINK_PD_SCREEN = "/";

export const LINK_SUMMARY = "/summary";
// export const LINK_SUMMARY_SCREEN = "/";

export const LINK_LEGAL = "/legal";
export const LINK_LEGAL_REPORT = "/";
export const LINK_LEGAL_DOCUMENT_EXCHANGE = "/document-exchange";
export const LINK_LEGAL_DEVIATIONS = "/deviations";

export const LINK_RCU = "/rcu";
export const LINK_RCU_REPORT = "/";
export const LINK_RCU_DOCUMENT_EXCHANGE = "/document-exchange";
export const LINK_RCU_DEVIATION = "/deviation";

export const LINK_TECHNICAL = "/technical";
export const LINK_TECHNICAL_REPORT = "/";
export const LINK_TECHNICAL_DOCUMENT_EXCHANGE = "/document-exchange";
export const LINK_TECHNICAL_DEVIATION = "/deviation";

export const LINK_CAM = "/cam";
export const LINK_CAM_DOCUMENT_CHECK = "/";
export const LINK_CAM_INCOME_EXPENSE = "/income-expense";
export const LINK_CAM_LOAN_DETAILS = "/loan-details";
export const LINK_CAM_RECOMMENDATION = "/recommendation";
export const LINK_CAM_CREDIT_DOCUMENT = "/credit-document";
export const LINK_CAM_QUERY = "/query";
export const LINK_CAM_OPS = "/ops-documents";

export const LINK_DEDUPE = "/dedupe";
export const LINK_DEDUPE_CUSTOMER_DEDUPE = "/";
export const LINK_DEDUPE_ADDRESS_DEDUPE = "/address-dedupe";
export const LINK_DEDUPE_PROPERTY_LOAN = "/property-loan";
export const LINK_DEDUPE_CUSTOMER_LOAN = "/customer-loan";
export const LINK_COMPARISON = "/comparison";
export const LINK_APPLICATION_MEDIA="/application-media"

export const LINK_FINAL_APPROVAL = "/final-approval";
export const LINK_IN_PRINCIPLE_APPROVAL = "/in-principal-approval";
export const LINK_FINANCIAL_APPROVAL = "/financial-approval";

export const LINK_FEES_SCREEN = "/fees-receipt";

export const LINK_NOTIFICATIONS = LINK_LOAN_DATA + "/notifications";

export const LINK_RE_APPEAL = "/re-appeal";

// export const;
// Component import with lazy loading
const PageLoanListing = React.lazy(
  () => import("./views/transaction/PageLoanListing/PageLoanListing")
);
const PageQDE = React.lazy(() => import("./views/transaction/PageQDE/PageQDE"));
const PagePointOfInterest = React.lazy(
  () => import("./views/transaction/PagePointOfInterest/PagePointOfInterest")
);
const Login = React.lazy(() => import("./views/transaction/PageDDE/Login/Login"));
const QC = React.lazy(() => import("./views/transaction/PageDDE/QC/Qc"));
const StageStatus = React.lazy(() => import("./views/transaction/PageDDE/StageAndStatus"));
const PageTaskList = React.lazy(() => import("./views/transaction/task/PageTaskList/PageTaskList"));
const PagePartnerTaskList = React.lazy(
  () => import("./views/transaction/task/PagePartnerTaskList/PagePartnerTaskList")
);
const PageQCNonInitiated = React.lazy(() => import("./views/transaction/task/PageQCNonInitiated"));

// import page from views folder whe
const PageReport = React.lazy(() => import("./views/transaction/PageReport"));

const PageDeposit = React.lazy(() => import("./views/transaction/fees/PageDeposit"));
const PageDepositCreateOrEdit = React.lazy(
  () => import("./views/transaction/fees/PageDepositCreateOrEdit")
);
const PageReconciliation = React.lazy(() => import("./views/transaction/fees/PageReconciliation"));
const PageReceipts = React.lazy(() => import("./views/transaction/fees/PageReceipts"));

const PageCreditQuery = React.lazy(() => import("./views/transaction/PageDDE/CreditQuery"));
const PD = React.lazy(() => import("./views/transaction/PageDDE/PD"));

const Legal = React.lazy(() => import("./views/transaction/PageDDE/Legal"));

const RCU = React.lazy(() => import("./views/transaction/PageRCUReport/PageRCU"));

const Technical = React.lazy(() => import("./views/transaction/PageTechnical"));

const PageCAM = React.lazy(() => import("./views/transaction/PageCAM"));

const Dedupe = React.lazy(() => import("./views/transaction/PageDDE/Dedupe"));
const PageComparison = React.lazy(
  () => import("./views/transaction/comparisonview/PageComparisonView/PageComparisonView")
);
const PageApplicationMedia = React.lazy(
  () => import("./views/transaction/PageApplicationMedia/PageApplicationMedia")
);

const Summary = React.lazy(() => import("./views/transaction/PageSummary"));

const PageFees = React.lazy(() => import("./views/transaction/PageDDE/Login/PageFees/PageFees"));

const PageNotifications = React.lazy(
  () => import("./views/transaction/PageNotifications/PageNotifications")
);

const PageReappeal = React.lazy(() => import("./views/transaction/PageReappeal/PageReappeal"));

export const ddeLoginRoutes: IRouteObj[] = [
  {
    path: LINK_DDE_DETAIL,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Login/PageDetails/PageDetails")
    ),
  },
  {
    path: LINK_DDE_APPLICANT,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Login/PageApplicant/PageApplicant")
    ),
  },
  {
    path: LINK_DDE_CO_APPLICANT,
    component: React.lazy(() => import("./views/transaction/PageDDE/Login/PageCoApplicants")),
  },
  {
    path: LINK_DDE_CORPORATE_CO_APPLICANT,
    component: React.lazy(
      () =>
        import(
          "./views/transaction/PageDDE/Login/PageCorporateCoApplicants/PageCorporateCoApplicants"
        )
    ),
  },
  {
    path: LINK_DDE_GUARANTORS,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Login/PageGuarantors/PageGuarantors")
    ),
  },
  {
    path: LINK_DDE_CORPORATE_GUARANTORS,
    component: React.lazy(
      () =>
        import("./views/transaction/PageDDE/Login/PageCorporateGuarantors/PageCorporateGuarantors")
    ),
  },
  {
    path: LINK_DDE_DEPENDENTS,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Login/PageDependents/PageDependents")
    ),
  },
  {
    path: LINK_DDE_COLLATERAL_PROPERTY,
    component: React.lazy(
      () =>
        import("./views/transaction/PageDDE/Login/PageCollateralProperty/PageCollateralProperty")
    ),
  },
  {
    path: LINK_DDE_DOCUMENTS,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Login/PageDocuments/PageDocuments")
    ),
  },
  {
    path: LINK_DDE_CBIL_DETAILED_REPORT,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Login/PageDetailedReport/PageCibilDetailReport")
    ),
  },
  {
    path: LINK_DDE_FEES,
    component: React.lazy(() => import("./views/transaction/PageDDE/Login/PageFees/PageFees")),
  },
];

export const qcLoginRoutes: IRouteObj[] = [
  {
    path: LINK_DDE_QC_CHECKLIST,
    component: React.lazy(() => import("./views/transaction/PageDDE/QC/PageCheckList")),
  },
  {
    path: LINK_DDE_QC_DUPLICATE,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/QC/PageDuplicateCheck/PageDuplicateCheck")
    ),
  },
  {
    path: LINK_DDE_QC_DOCUMENTS,
    component: React.lazy(() => import("./views/transaction/PageDDE/QC/PageAssetDocumentCheck")),
  },
];

export const stageStatusLoginRoutes: IRouteObj[] = [
  {
    path: LINK_DDE_STAGE_AND_STATUS_STAGES,
    component: React.lazy(() => import("./views/transaction/PageDDE/StageAndStatus/PageStages")),
  },
  {
    path: LINK_DDE_STAGE_AND_STATUS_HISTORY,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/StageAndStatus/PageStageHistory")
    ),
  },
  {
    path: LINK_DDE_STAGES_INITIATE,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/StageAndStatus/PageInitiateStage")
    ),
  },
];

export const PDLoginRoutes: IRouteObj[] = [
  {
    path: LINK_PD_SCREEN,
    component: React.lazy(() => import("./views/transaction/PageDDE/PD/PagePD")),
  },
];

export const legalRoutes: IRouteObj[] = [
  {
    path: LINK_LEGAL_REPORT,
    altPath: LINK_LEGAL,
    component: React.lazy(() => import("./views/transaction/PageDDE/Legal/PageReport")),
  },
  {
    path: LINK_LEGAL_DOCUMENT_EXCHANGE,
    component: React.lazy(() => import("./views/transaction/PageDDE/Legal/PageDocumentExchange")),
  },
  {
    path: LINK_LEGAL_DEVIATIONS,
    component: React.lazy(() => import("./views/transaction/PageDDE/Legal/PageDeviations")),
  },
];

export const rcuRoutes: IRouteObj[] = [
  {
    path: LINK_RCU_REPORT,
    altPath: LINK_RCU,
    component: React.lazy(() => import("./views/transaction/PageRCUReport/PageReport")),
  },
  {
    path: LINK_RCU_DOCUMENT_EXCHANGE,
    component: React.lazy(() => import("./views/transaction/PageRCUReport/PageDocumentExchange")),
  },
  {
    path: LINK_RCU_DEVIATION,
    component: React.lazy(() => import("./views/transaction/PageRCUReport/PageDeviations")),
  },
];

export const technicalRoutes: IRouteObj[] = [
  {
    path: LINK_TECHNICAL_REPORT,
    altPath: LINK_TECHNICAL,
    component: React.lazy(() => import("./views/transaction/PageTechnical/PageReport")),
  },
  {
    path: LINK_TECHNICAL_DOCUMENT_EXCHANGE,
    component: React.lazy(() => import("./views/transaction/PageTechnical/PageDocumentExchange")),
  },
  {
    path: LINK_TECHNICAL_DEVIATION,
    component: React.lazy(() => import("./views/transaction/PageTechnical/PageDeviations")),
  },
];

export const camRoutes: IRouteObj[] = [
  {
    path: LINK_CAM_DOCUMENT_CHECK,
    component: React.lazy(() => import("./views/transaction/PageCAM/PageDocumentCheck")),
  },
  {
    path: LINK_CAM_INCOME_EXPENSE,
    component: React.lazy(() => import("./views/transaction/PageCAM/PageIncomeExpenseDetail")),
  },
  {
    path: LINK_CAM_LOAN_DETAILS,
    component: React.lazy(() => import("./views/transaction/PageCAM/PageLoanDetails")),
  },
  {
    path: LINK_CAM_RECOMMENDATION,
    component: React.lazy(() => import("./views/transaction/PageCAM/PageRecommendations")),
  },
  {
    path: LINK_CAM_CREDIT_DOCUMENT,
    component: React.lazy(() => import("./views/transaction/PageCAM/PageCreditDocument")),
  },
  {
    path: LINK_CAM_QUERY,
    component: React.lazy(() => import("./views/transaction/PageCAM/PageQuery")),
  },
  {
    path: LINK_CAM_OPS,
    component: React.lazy(() => import("./views/transaction/PageCAM/PageOps/PageOps")),
  },
];

export const dedupeRoutes: IRouteObj[] = [
  {
    path: LINK_DEDUPE_CUSTOMER_DEDUPE,
    component: React.lazy(() => import("./views/transaction/PageDDE/Dedupe/PageCustomerDedupe")),
  },
  {
    path: LINK_DEDUPE_ADDRESS_DEDUPE,
    component: React.lazy(() => import("./views/transaction/PageDDE/Dedupe/PageAddressDedupe")),
  },
  {
    path: LINK_DEDUPE_PROPERTY_LOAN,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Dedupe/PagePropertyLoanLinking")
    ),
  },
  {
    path: LINK_DEDUPE_CUSTOMER_LOAN,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Dedupe/PageCustomerLoanLinking")
    ),
  },
];

// Route
const transactionRoutes: IRouteObj[] = [
  {
    path: LINK_LOAN_LIST,
    component: PageLoanListing,
  },
  {
    path: LINK_QDE,
    component: PageQDE,
  },
  {
    path: LINK_TASK_LIST,
    component: PageTaskList,
  },
  {
    path: LINK_PARTNER_TASK_LIST,
    component: PagePartnerTaskList,
  },
  {
    path: LINK_TASK_QC_NON_INITIATED,
    component: PageQCNonInitiated,
  },
  {
    path: LINK_REPORT,
    component: PageReport,
  },
  {
    path: LINK_FEES_DEPOSIT,
    component: PageDeposit,
  },
  // {
  //   path: LINK_FEES_DEPOSIT,
  //   component: PageDeposit,
  // },
  {
    path: LINK_FEES_DEPOSIT_CREATE_EDIT,
    component: PageDepositCreateOrEdit,
  },
  {
    path: `${LINK_FEES_DEPOSIT_CREATE_EDIT}/:depositUid`,
    component: PageDepositCreateOrEdit,
  },
  // {
  //   path: LINK_FEES_DEPOSIT_CREATE_EDIT_FROM_RECONCILIATION,
  //   component: PageDepositCreateOrEdit,
  // },
  // {
  //   path: `${LINK_FEES_DEPOSIT_CREATE_EDIT_FROM_RECONCILIATION}/:depositUid`,
  //   component: PageDepositCreateOrEdit,
  // },
  {
    path: LINK_RECONCILIATION,
    component: PageReconciliation,
  },
  {
    path: LINK_FEES_RECEIPT,
    component: PageReceipts,
  },
  {
    path: LINK_NOTIFICATIONS,
    component: PageNotifications,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_DDE_LOGIN}/:loanUid/:stageUid/*`,
    component: Login,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_POINT_OF_INTEREST}/:loanUid/*`,
    component: PagePointOfInterest,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_DDE_QC}/:loanUid/:stageUid/*`,
    component: QC,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_DDE_STAGE_AND_STATUS}/:loanUid/*`,
    component: StageStatus,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_DDE_CREDIT_QUERY}/:loanUid/*`,
    component: PageCreditQuery,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_FEES_SCREEN}/:loanUid/*`,
    component: PageFees,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_PD}/:loanUid/:stageUid/*`,
    component: PD,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_LEGAL}/:loanUid/:stageUid/*`,
    component: Legal,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_RCU}/:loanUid/:stageUid/*`,
    component: RCU,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_TECHNICAL}/:loanUid/:stageUid/*`,
    component: Technical,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_CAM}/:loanUid/:stageUid/*`,
    component: PageCAM,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_DEDUPE}/:loanUid/:stageUid/*`,
    component: Dedupe,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_SUMMARY}/:loanUid/*`,
    component: Summary,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_COMPARISON}/:loanUid/*`,
    component: PageComparison,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_APPLICATION_MEDIA}/:loanUid/*`,
    component: PageApplicationMedia,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_RE_APPEAL}/:loanUid/:stageUid/*`,
    component: PageReappeal,
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_FINAL_APPROVAL}/:loanUid/:stageUid/*`,
    component: React.lazy(() => import("./views/transaction/approval/FinalApproval")),
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_IN_PRINCIPLE_APPROVAL}/:loanUid/:stageUid/*`,
    component: React.lazy(() => import("./views/transaction/approval/InPrincipalApproval")),
  },
  {
    path: `${LINK_LOAN_DETAIL}${LINK_FINANCIAL_APPROVAL}/:loanUid/:stageUid/*`,
    component: React.lazy(() => import("./views/transaction/approval/FinancialApproval")),
  },
  {
    path:  `${LINK_LOAN_DETAIL}${LINK_DDE_CRIF_DETAILED_REPORT}/:loanUid/:applicantUid/:applicantName`,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Login/PageDetailedReport/PageCRIFReport")
    ),
  },
  {
    path:  `${LINK_LOAN_DETAIL}${LINK_DDE_CRIF_DETAILED_REPORT}/:loanUid/:applicantUid`,
    component: React.lazy(
      () => import("./views/transaction/PageDDE/Login/PageDetailedReport/PageCRIFReport")
    ),
  },
];

export default transactionRoutes;
