import { IRouteObj } from "interface/common";
import React from "react";
import transactionRoutes from "./transactionRoutes";
//base
export const linkBasePath = "/dashboard";

//No Auth Routes
export const linkPageBase = "/";
export const linkPageLogin = "/login";
export const linkNamePageLoginLoader = "Login Loader";
export const linkPagePasswordReset = "/password-reset";
export const linkPagePasswordResetOTP = linkPagePasswordReset + "/otp";
export const linkPageNewPassword = linkPagePasswordReset + "/new-password";
export const linkPagePasswordResetSuccess = linkPagePasswordReset + "/success";
export const linkPageValidateWebView = "/validate-web-view";

export const linkPageSetup = "/setup";

export const linkMasterDataBase = "/master-data";
//Master Data
export const linkMasterData = "/";
//Static Master
export const linkStaticMaster = linkMasterDataBase + "/staticMaster";
export const linkStaticMasterTypes = linkStaticMaster + "/types";
export const linkStaticMasterTypesCreateViewEdit = linkStaticMasterTypes + "/create-view-edit";

export const linkStaticMasterData = linkStaticMaster + "/data";
export const linkStaticMasterDataCreateViewEdit = linkStaticMasterData + "/create-view-edit";

//Approval Request
export const linkApprovalRequests = linkMasterDataBase + "/approvalRequests";
export const linkApprovalRequest = linkMasterDataBase + "/approvalRequest";
// System Config

export const linkSystemConfigBase = "/";
export const linkSystemConfig = linkMasterDataBase + "/system-configuration";
export const linkSystemConfigCreateViewEdit = linkSystemConfig + "/create-view-edit";

// Product Config
export const linkProductConfigBase = "/";
export const linkProductConfiguration = linkMasterDataBase + "/productConfiguration";

export const linkProductIncomeSchemeList = linkProductConfiguration + "/income-scheme-list";
export const linkProductIncomeSchemeCM = linkProductIncomeSchemeList + "/create-view-edit";

export const linkProductConfigurationFees = linkProductConfiguration + "/fees";
export const linkProductConfigurationFeesCreateViewEdit =
  linkProductConfigurationFees + "/create-view-edit";

export const linkProductConfigurationDeviation = linkProductConfiguration + "/deviation";
export const linkProductConfigurationDeviationCreateViewEdit =
  linkProductConfigurationDeviation + "/create-view-edit";
export const linkProductConfigurationDeviationCategory =
  linkProductConfigurationDeviation + "/category";
export const linkProductConfigurationDeviationCategoryCreateViewEdit =
  linkProductConfigurationDeviationCategory + "/create-view-edit";

export const linkProductConfigurationBusinessRule = linkProductConfiguration + "/businessrule";
export const linkProductConfigurationBusinessRuleCreateViewEdit =
  linkProductConfigurationBusinessRule + "/create-view-edit";

export const linkProductConfigurationChecklist = linkProductConfiguration + "/checklist";
export const linkProductConfigurationChecklistCreateViewEdit =
  linkProductConfigurationChecklist + "/create-view-edit";

export const linkProductConfigurationTransaction = linkProductConfiguration + "/transaction";
export const linkProductConfigurationTransactionCreateViewEdit =
  linkProductConfigurationTransaction + "/create-view-edit";

export const linkProductConfigurationProducts = linkProductConfiguration + "/products";
export const linkProductConfigurationProductsCreateViewEdit =
  linkProductConfigurationProducts + "/create-view-edit";

export const linkProductConfigurationIncomeScheme = linkProductConfiguration + "/incomescheme";
export const linkProductConfigurationIncomeSchemeCreateViewEdit =
  linkProductConfigurationIncomeScheme + "/create-view-edit";

// System Date
export const linkSystemDateBase = "/";
export const linkSystemDate = linkMasterDataBase + "/system-date";
export const linkSystemDateCreateViewEdit = linkSystemDate + "/create-view-edit";

// Partner User

export const linkPartnerUserBase = "/";
export const linkPartnerUser = linkMasterDataBase + "/partner-user";
export const linkPartnerUserCreateViewEdit = linkPartnerUser + "/create-view-edit";

// Partner Onboarding

export const linkPartnerOnboardingBase = "/";
export const linkPartnerOnboarding = linkMasterDataBase + "/partner-onboarding";
export const linkPartnerOnboardingCreateViewEdit = linkPartnerOnboarding + "create-view-edit";
// State

export const linkStateBase = "/";
export const linkLocation = linkMasterDataBase + "/location";
export const linkState = linkLocation + "/state";
export const linkStateCreateViewEdit = linkState + "/create-view-edit";

// City

export const linkCity = linkMasterDataBase + "/city";
export const linkCityCreateViewEdit = linkCity + "/create-view-edit";

// Pincode

export const linkPincode = linkMasterDataBase + "/pincode";
export const linkPincodeCreateViewEdit = linkPincode + "/create-view-edit";

// Organization Level

export const linkOrganizationStructureBase = "/";
export const linkOrganizationStructure = linkMasterDataBase + "/organization-structure";

export const linkOrganizationLevel = linkOrganizationStructure + "/level";
export const linkOrganizationLevelCreateViewEdit = linkOrganizationLevel + "/create-view-edit";

// Organization Reporting Level
export const linkOrganizationReporting = linkOrganizationStructure + "/reporting";
export const linkOrganizationReportingCreateViewEdit =
  linkOrganizationReporting + "/create-view-edit";

// Organization Department Level
export const linkOrganizationDepartment = linkOrganizationStructure + "/department";
export const linkOrganizationDepartmentCreateViewEdit =
  linkOrganizationDepartment + "/create-view-edit";

// Organization Approval Level
export const linkOrganizationApproval = linkOrganizationStructure + "/approval";
export const linkOrganizationApprovalCreateViewEdit =
  linkOrganizationApproval + "/create-view-edit";

// Organization Designation Level
export const linkOrganizationReportingDesignation = linkOrganizationStructure + "/designation";
export const linkOrganizationDesignationCreateViewEdit =
  linkOrganizationReportingDesignation + "/create-view-edit";

// Organization Branch Level
export const linkOrganizationBranch = linkOrganizationStructure + "/branch";
export const linkOrganizationBranchCreateViewEdit = linkOrganizationBranch + "/create-view-edit";

// User Management Level
export const linkUserManagementBase = "/";
export const linkUserManagement = linkMasterDataBase + "/user-management";
export const linkUserManagementCreateViewEdit = linkUserManagement + "/create-view-edit";

// Access Control
export const linkAccessControlBase = "/";
export const linkAccessControl = linkMasterDataBase + "/role-list";
export const linkAccessControlCreateViewEdit = linkAccessControl + "/create-view-edit";

//links
export const linkAuthRoute = "/console";
export const linkPageView1 = "/view1";
export const linkPageView2 = "/view2";
export const linkPageView3 = "/view3";
export const linkPageView5 = "/view5";
export const linkPageView4 = "/view4";
export const linkPageView6 = "/view6";

//Link Names

//Auth
export const linkNamePageLogin = "Login";
export const linkNamePageView1 = "View1";
export const linkNamePageView2 = "View2";
export const linkNamePagePasswordReset = "PasswordReset";
export const linkNamePagePasswordResetOTP = "PasswordResetOTP";
export const linkNamePageNewPassword = "New Password";
export const linkNamePagePasswordResetSuccess = "Password Reset Success";

//Static Master

export const linkNameStaticMasterData = "Static Master Data";
export const linkNameStaticMasterTypes = "Static Master Types";
export const linkNameStaticMasterTypesCreateViewEdit = "Static Master Types - Create, View, Edit";
export const linkNameStaticMasterDataCreateViewEdit = "Static Master Data - Create, View, Edit";

//Approval Request
export const linkNameApprovalRequests = "Approval Requests";
export const linkNameApprovalRequest = "Approval Request";

// System Config
export const linkNameSystemConfig = "System Configuration";
export const linkNameSystemConfigCreateViewEdit = "System Configuration - Create, View, Edit";

// Product Config
export const linkNameProductConfig = "Product Configuration";
export const linkNameProductConfigFees = "Product Configuration-Fees";
export const linkNameProductConfigFeesCreateViewEdit =
  "Product Configuration-Fees - Create, View, Edit";
export const linkNameProductConfigurationDeviationCategory =
  "Product Configuration-Deviation Category";
export const linkNameProductConfigDeviationCategoryCreateViewEdit =
  "Product Configuration-Deviation Category - Create, View, Edit";
export const linkNameProductConfigurationDeviation = "Product Configuration-Deviation Category";
export const linkNameProductConfigDeviationCreateViewEdit =
  "Product Configuration-Deviation - Create, View, Edit";
export const linkNameProductConfigBusinessRule = "Product Configuration-Business Rule";
export const linkNameProductConfigBusinessRuleCreateViewEdit =
  "Product Configuration-Business Rule - Create, View, Edit";
export const linkNameProductConfigChecklist = "Product Configuration- Checklist";
export const linkNameProductConfigChecklistCreateViewEdit =
  "Product Configuration-Checklist - Create, View, Edit";
export const linkNameProductConfigurationTransactionApproval = "Product Configuration-Transaction";
export const linkNamePageTransactionApprovalCMScreen =
  "Product Configuration-Transaction - Create, View, Edit";
export const linkNameProductConfigIncomeSchemeCreateViewEdit =
  "Product Configuration-Income Scheme - Create, View, Edit";
export const linkNameProductConfigProducts = "Products";
export const linkNameProductConfigProductsCreateViewEdit = "Products - Create, View, Edit";

// System Date
export const linkNameSystemDate = "System Date";
export const linkNameSystemDateCreateViewEdit = "System Date - Create, View, Edit";

// Partner User
export const linkNamePartnerUser = "Partner User";
export const linkNamePartnerUserCreateViewEdit = "Partner User - Create, View, Edit";

// Partner Onboarding

export const linkNameOnboarding = "Partner Onboarding";
export const linkNamePartnerOnboardingCreateViewEdit = "Partner Onboarding - Create, View, Edit";

// Location
export const linkNameState = "State";
export const linkNameStateCreateViewEdit = "State - Create, View, Edit";

export const linkNameCity = "City";
export const linkNameCityCreateViewEdit = "City - Create, View, Edit";

export const linkNamePincode = "Pincode";
export const linkNamePincodeCreateViewEdit = "Pincode - Create, View, Edit";

// Organization Level
export const linkNameOrganizationLevel = "Organization Levels";
export const linkNameOrganizationLevelCreateViewEdit = "Organization Levels - Create, View, Edit";

export const linkNameOrganizationReportingLevel = "Organization Reporting Levels";
export const linkNameOrganizationReportingLevelCreateViewEdit =
  "Organization Reporting Levels - Create, View, Edit";

export const linkNameOrganizationDepartmentLevel = "Organization Department Levels";
export const linkNameOrganizationDepartmentLevelCreateViewEdit =
  "Organization Department Levels - Create, View, Edit";

export const linkNameOrganizationApprovalLevel = "Organization Approval Levels";
export const linkNameOrganizationApprovalLevelCreateViewEdit =
  "Organization Approval Levels - Create, View, Edit";

export const linkNameOrganizationReportingDesignationLevel = "Organization Designation Levels";
export const linkNameOrganizationDesignationCreateViewEdit =
  "Organization Designation Levels - Create, View, Edit";

export const linkNameOrganizationBranchLevel = "Organization Branch Levels";
export const linkNameOrganizationBranchLevelCreateViewEdit =
  "Organization Branch Levels - Create, View, Edit";

// User Management
export const linkNameUserManagement = "User Management";
export const linkNameUserManagementCreateViewEdit = "User Management - Create, View, Edit";

//imports
const View1 = React.lazy(() => import("./views/View1"));
const View2 = React.lazy(() => import("./views/View2"));
const View3 = React.lazy(() => import("./views/View3"));
const View5 = React.lazy(() => import("./views/view5"));
const View4 = React.lazy(() => import("./views/View4"));
const View6 = React.lazy(() => import("./views/view6"));

//Auth
const PasswordReset = React.lazy(() => import("./views/auth/PagePasswordReset"));
const PasswordResetOTP = React.lazy(() => import("./views/auth/PagePasswordResetOTP"));
const NewPassword = React.lazy(() => import("./views/auth/PageNewPassword"));
const PasswordResetSuccess = React.lazy(() => import("./views/auth/PagePasswordResetSuccess"));

//MasterData

//Static Master
const StaticMasterTypes = React.lazy(
  () => import("./views/masterData/staticMaster/PageStaticMasterTypes")
);

const StaticMasterTypesCreateViewEdit = React.lazy(
  () => import("./views/masterData/staticMaster/PageStaticMasterTypesCreateViewEdit")
);

const StaticMasterData = React.lazy(
  () => import("./views/masterData/staticMaster/PageStaticMasterData")
);

const StaticMasterDataCreateViewEdit = React.lazy(
  () => import("./views/masterData/staticMaster/PageStaticMasterDataCreateViewEdit")
);

//Static Master
const ApprovalRequestsCreateViewEdit = React.lazy(
  () => import("./views/masterData/approvalRequest/PageApprovalRequestListing")
);

const ApprovalRequestDefaultScreen = React.lazy(
  () => import("./views/masterData/approvalRequest/PageApprovalRequest")
);

const SystemConfigCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/SystemConfig/PageSystemConfigurationCreateViewEdit/PageSystemConfigurationCreateViewEdit"
    )
);

const ProductConfigFeesCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/productConfiguration/PageProductConfigurationFees/PageProductConfigurationFeesCreateViewEdit/PageProductConfigurationFeesCreateViewEdit"
    )
);

const PageProductConfigurationFee = React.lazy(
  () =>
    import(
      "./views/masterData/productConfiguration/PageProductConfigurationFees/PageProductConfigurationFees"
    )
);

const PageProductConfigurationDeviation = React.lazy(
  () =>
    import(
      "./views/masterData/productConfiguration/productConfigurationDeviation/PageDeviationListing/PageDeviationListing"
    )
);

const PageProductConfigurationDeviationCategory = React.lazy(
  () =>
    import(
      "./views/masterData/productConfiguration/productConfigurationDeviationCategory/PageDeviationCategoryListing/PageDeviationCategoryListing"
    )
);

const ProductConfigDeviationCategoryCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/productConfiguration/productConfigurationDeviationCategory/PageDeviationCategoryCM"
    )
);

const ProductConfigDeviationCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/productConfiguration/productConfigurationDeviation/PageDeviationCM/PageDeviationCM"
    )
);

const ProductConfigBusinessRule = React.lazy(
  () =>
    import(
      "./views/masterData/productConfiguration/BusinessRules/PageBusinessRulesListing/PageBusinessRulesListing"
    )
);

const ProductConfigBusinessRuleCreateViewEdit = React.lazy(
  () => import("./views/masterData/productConfiguration/BusinessRules/PageBusinessRulesCM")
);

const ProductConfigChecklist = React.lazy(
  () => import("./views/masterData/productConfiguration/Checklist/PageChecklist/")
);

const ProductConfigChecklistCreateViewEdit = React.lazy(
  () => import("./views/masterData/productConfiguration/Checklist/ChecklistCreateViewEdit")
);

const PageTransactionApprovalListingScreen = React.lazy(
  () =>
    import(
      "./views/masterData/productConfiguration/productConfigTransaction/PageTransactionApprovalListingScreen"
    )
);

const PageTransactionApprovalCMScreen = React.lazy(
  () =>
    import(
      "./views/masterData/productConfiguration/productConfigTransaction/PageTransactionApprovalCMScreen"
    )
);
const PageProductIncomeSchemeList = React.lazy(
  () =>
    import("./views/masterData/productConfiguration/ProductIncomeScheme/ProductIncomeSchemeList")
);
const PageProductIncomeSchemeCM = React.lazy(
  () => import("./views/masterData/productConfiguration/ProductIncomeScheme/ProductIncomeSchemeCM")
);

const ProductConfigProducts = React.lazy(
  () => import("./views/masterData/productConfiguration/Product/PageProductListing")
);

const ProductConfigProductsCreateViewEdit = React.lazy(
  () => import("./views/masterData/productConfiguration/Product/PageProductCM/PageProductCM")
);

const PageSystemConfig = React.lazy(
  () => import("./views/masterData/SystemConfig/SystemConfig/PageSystemConfigListing")
);

const PageSystemDate = React.lazy(() => import("./views/masterData/SystemDate/PageSystemDate"));

const SystemNameCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/SystemDate/PageSystemDateCreateViewEdit/PageSystemDateCreateViewEdit"
    )
);
//partner Management

const PartnerUser = React.lazy(
  () => import("./views/masterData/PartnerManagement/PagePartnerUser/PagePartnerUserListing")
);
const PartnerUserCreateViewEdit = React.lazy(
  () => import("./views/masterData/PartnerManagement/PagePartnerUserCreateViewEdit")
);
const PartnerOnboarding = React.lazy(
  () =>
    import(
      "./views/masterData/PartnerManagement/PagePartnerOnboarding/PagePartnerOnboardingListing"
    )
);
const PartnerOnboardingCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/PartnerManagement/PagePartnerOnboardingCreateViewEdit/PagePartnerOnboardingCM"
    )
);

const State = React.lazy(() => import("./views/masterData/Location/State/PageState"));

const StateCreateViewEdit = React.lazy(
  () => import("./views/masterData/Location/State/StateCreateViewEdit")
);

const City = React.lazy(() => import("./views/masterData/Location/City/PageCity"));

const CityCreateViewEdit = React.lazy(
  () => import("./views/masterData/Location/City/CityCreateViewEdit")
);

const Pincode = React.lazy(() => import("./views/masterData/Location/Pincode/PagePincode"));

const PincodeCreateViewEdit = React.lazy(
  () => import("./views/masterData/Location/Pincode/PincodeCreateViewEdit")
);

const OrganizationLevel = React.lazy(
  () =>
    import("./views/masterData/OrganizationLevel/OrganizationLevelType/PageOrganizationLevelType")
);

const OrganizationLevelCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/OrganizationLevel/OrganizationLevelType/OrganizationLevelCreateViewEdit/OrganizationLevelTypeCreateViewEdit"
    )
);

const OrganizationReportingLevelCreateViewEdit = React.lazy(
  () => import("./views/masterData/OrganizationLevel/OrganizationReportingLevel")
);

const OrganizationDepartmentLevel = React.lazy(
  () =>
    import(
      "./views/masterData/OrganizationLevel/OrganizationLevelDepartment/OrganizationLevelDepartment"
    )
);

const OrganizationDepartmentLevelCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/OrganizationLevel/OrganizationLevelDepartment/OrganizationLevelDepartmentCreateViewEdit"
    )
);

const OrganizationApprovalLevel = React.lazy(
  () =>
    import(
      "./views/masterData/OrganizationLevel/OrganizationApproval/OrganizationApprovalLevel/PageOrganizationStructureApprovalLevelListing"
    )
);

const OrganizationApprovalLevelCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/OrganizationLevel/OrganizationApproval/OrganizationLevelApprovalLevelCreateViewEdit/PageOrganizationStructureApprovalLevelCM"
    )
);

const OrganizationDesignationLevel = React.lazy(
  () =>
    import(
      "./views/masterData/OrganizationLevel/OrganizationReportingDesignation/OrganizationReportingDesignation"
    )
);

const OrganizationDesignationLevelCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/OrganizationLevel/OrganizationReportingDesignation/OrganizationReportingCreateViewEdit/OrganizationReportingCreateViewEdit"
    )
);

const OrganizationBranchLevel = React.lazy(
  () =>
    import(
      "./views/masterData/OrganizationLevel/OrganizationReportingBranch/PageOrganizationReportingBranch"
    )
);

const OrganizationBranchLevelCreateViewEdit = React.lazy(
  () =>
    import(
      "./views/masterData/OrganizationLevel/OrganizationReportingBranch/OrganizationReportingCreateViewEdit"
    )
);

const UserManagementCreateViewEdit = React.lazy(
  () =>
    import("./views/masterData/UserManagement/UserManagementCreateViewEdit/PageUserManagementCM")
);
const UserManagementListing = React.lazy(
  () => import("./views/masterData/UserManagement/PageUserManagementList")
);

const PageRoleList = React.lazy(
  () => import("./views/masterData/AccessControl/PageRoleList/PageRoleListing")
);
const PageRoleCreateMaintain = React.lazy(
  () => import("./views/masterData/AccessControl/PageRoleCMScreen")
);

//routes
const routes: IRouteObj[] = [
  ...transactionRoutes,
  {
    path: linkPageView1,
    exact: true,
    name: linkNamePageView1,
    component: View1,
  },
  { path: linkPageView2, name: linkNamePageView2, component: View2 },
  { path: linkPageView3, component: View3 },
  { path: linkPageView5, component: View5 },
  { path: linkPageView4, component: View4 },
  { path: linkPageView6, component: View6 },

  { path: linkPagePasswordReset, name: linkNamePagePasswordReset, component: PasswordReset },
  {
    path: linkPagePasswordResetOTP,
    name: linkNamePagePasswordResetOTP,
    component: PasswordResetOTP,
  },
  { path: linkPageNewPassword, name: linkNamePageNewPassword, component: NewPassword },
  {
    path: linkPagePasswordResetSuccess,
    name: linkNamePagePasswordResetSuccess,
    component: PasswordResetSuccess,
  },
  {
    path: linkStaticMasterTypes,
    name: linkNameStaticMasterTypes,
    component: StaticMasterTypes,
  },
  {
    path: linkPartnerUser,
    name: linkNamePartnerUser,
    component: PartnerUser,
  },
  {
    path: linkPartnerUserCreateViewEdit,
    name: linkNamePartnerUserCreateViewEdit,
    component: PartnerUserCreateViewEdit,
  },
  {
    path: linkPartnerOnboarding,
    name: linkNamePartnerUser,
    component: PartnerOnboarding,
  },
  {
    path: linkPartnerOnboardingCreateViewEdit,
    name: linkNamePartnerOnboardingCreateViewEdit,
    component: PartnerOnboardingCreateViewEdit,
  },
  {
    path: linkStaticMasterTypesCreateViewEdit,
    name: linkNameStaticMasterTypesCreateViewEdit,
    component: StaticMasterTypesCreateViewEdit,
  },
  {
    path: linkStaticMasterData,
    name: linkNameStaticMasterData,
    component: StaticMasterData,
  },
  {
    path: linkStaticMasterDataCreateViewEdit,
    name: linkNameStaticMasterDataCreateViewEdit,
    component: StaticMasterDataCreateViewEdit,
  },
  {
    path: linkApprovalRequests,
    name: linkNameApprovalRequests,
    component: ApprovalRequestsCreateViewEdit,
  },
  {
    path: linkApprovalRequest,
    name: linkNameApprovalRequest,
    component: ApprovalRequestDefaultScreen,
  },
  {
    path: linkSystemConfigCreateViewEdit,
    name: linkNameSystemConfigCreateViewEdit,
    component: SystemConfigCreateViewEdit,
  },
  {
    path: linkProductConfigurationFees,
    name: linkNameProductConfigFees,
    component: PageProductConfigurationFee,
  },
  {
    path: linkProductConfigurationFeesCreateViewEdit,
    name: linkNameProductConfigFeesCreateViewEdit,
    component: ProductConfigFeesCreateViewEdit,
  },
  {
    path: linkSystemConfig,
    name: linkNameSystemConfig,
    component: PageSystemConfig,
  },
  {
    path: linkSystemDate,
    name: linkNameSystemDate,
    component: PageSystemDate,
  },
  {
    path: linkSystemDateCreateViewEdit,
    name: linkNameSystemDateCreateViewEdit,
    component: SystemNameCreateViewEdit,
  },

  {
    path: linkCity,
    name: linkNameCity,
    component: City,
  },
  {
    path: linkState,
    name: linkNameState,
    component: State,
  },
  {
    path: linkStateCreateViewEdit,
    name: linkNameStateCreateViewEdit,
    component: StateCreateViewEdit,
  },
  {
    path: linkCityCreateViewEdit,
    name: linkNameCityCreateViewEdit,
    component: CityCreateViewEdit,
  },
  {
    path: linkPincode,
    name: linkNamePincode,
    component: Pincode,
  },
  {
    path: linkPincodeCreateViewEdit,
    name: linkNamePincodeCreateViewEdit,
    component: PincodeCreateViewEdit,
  },
  {
    path: linkOrganizationLevel,
    name: linkNameOrganizationLevel,
    component: OrganizationLevel,
  },
  {
    path: linkOrganizationLevelCreateViewEdit,
    name: linkNameOrganizationLevelCreateViewEdit,
    component: OrganizationLevelCreateViewEdit,
  },
  {
    path: linkOrganizationReportingCreateViewEdit,
    name: linkNameOrganizationReportingLevelCreateViewEdit,
    component: OrganizationReportingLevelCreateViewEdit,
  },
  {
    path: linkOrganizationDepartmentCreateViewEdit,
    name: linkNameOrganizationDepartmentLevelCreateViewEdit,
    component: OrganizationDepartmentLevelCreateViewEdit,
  },
  {
    path: linkOrganizationDepartment,
    name: linkNameOrganizationDepartmentLevel,
    component: OrganizationDepartmentLevel,
  },
  {
    path: linkOrganizationApproval,
    name: linkNameOrganizationApprovalLevel,
    component: OrganizationApprovalLevel,
  },
  {
    path: linkOrganizationApprovalCreateViewEdit,
    name: linkNameOrganizationApprovalLevelCreateViewEdit,
    component: OrganizationApprovalLevelCreateViewEdit,
  },
  {
    path: linkOrganizationBranch,
    name: linkNameOrganizationBranchLevel,
    component: OrganizationBranchLevel,
  },
  {
    path: linkOrganizationBranchCreateViewEdit,
    name: linkNameOrganizationBranchLevelCreateViewEdit,
    component: OrganizationBranchLevelCreateViewEdit,
  },
  {
    path: linkOrganizationReportingDesignation,
    name: linkNameOrganizationReportingDesignationLevel,
    component: OrganizationDesignationLevel,
  },
  {
    path: linkOrganizationDesignationCreateViewEdit,
    name: linkNameOrganizationDesignationCreateViewEdit,
    component: OrganizationDesignationLevelCreateViewEdit,
  },
  {
    path: linkUserManagementCreateViewEdit,
    name: linkNameUserManagementCreateViewEdit,
    component: UserManagementCreateViewEdit,
  },
  {
    path: linkUserManagement,
    name: linkNameUserManagement,
    component: UserManagementListing,
  },
  {
    path: linkProductConfigurationDeviationCategory,
    name: linkNameProductConfigurationDeviationCategory,
    component: PageProductConfigurationDeviationCategory,
  },
  {
    path: linkProductConfigurationDeviationCategoryCreateViewEdit,
    name: linkNameProductConfigDeviationCategoryCreateViewEdit,
    component: ProductConfigDeviationCategoryCreateViewEdit,
  },
  {
    path: linkProductConfigurationDeviation,
    name: linkNameProductConfigurationDeviation,
    component: PageProductConfigurationDeviation,
  },
  {
    path: linkProductConfigurationDeviationCreateViewEdit,
    name: linkNameProductConfigDeviationCreateViewEdit,
    component: ProductConfigDeviationCreateViewEdit,
  },
  {
    path: linkProductConfigurationBusinessRule,
    name: linkNameProductConfigBusinessRule,
    component: ProductConfigBusinessRule,
  },
  {
    path: linkProductConfigurationBusinessRuleCreateViewEdit,
    name: linkNameProductConfigBusinessRuleCreateViewEdit,
    component: ProductConfigBusinessRuleCreateViewEdit,
  },
  {
    path: linkProductConfigurationChecklist,
    name: linkNameProductConfigChecklist,
    component: ProductConfigChecklist,
  },
  {
    path: linkProductConfigurationChecklistCreateViewEdit,
    name: linkNameProductConfigChecklistCreateViewEdit,
    component: ProductConfigChecklistCreateViewEdit,
  },
  {
    path: linkProductConfigurationTransaction,
    name: linkNameProductConfigurationTransactionApproval,
    component: PageTransactionApprovalListingScreen,
  },
  {
    path: linkProductConfigurationTransactionCreateViewEdit,
    name: linkNamePageTransactionApprovalCMScreen,
    component: PageTransactionApprovalCMScreen,
  },
  {
    path: linkProductIncomeSchemeList,
    component: PageProductIncomeSchemeList,
  },
  {
    path: linkProductIncomeSchemeCM,
    component: PageProductIncomeSchemeCM,
  },
  {
    path: linkProductConfigurationProducts,
    name: linkNameProductConfigProducts,
    component: ProductConfigProducts,
  },
  {
    path: linkProductConfigurationProductsCreateViewEdit,
    name: linkNameProductConfigProductsCreateViewEdit,
    component: ProductConfigProductsCreateViewEdit,
  },
  {
    path: linkAccessControl,
    component: PageRoleList,
  },
  {
    path: linkAccessControlCreateViewEdit,
    component: PageRoleCreateMaintain,
  },
];

export default routes;
