import {
  USER_PROFILE_BEGIN,
  USER_PROFILE_FAILURE,
  USER_PROFILE_SUCCESS,
} from "../actions/1_ActionConstants";

const initialState = {
  loading: false,
  error: null,
  profileObj: {},
};

export default function CounterReducer(state = initialState, action) {
  switch (action.type) {
    case USER_PROFILE_BEGIN:
      return {
        ...state,
      };

    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileObj: { ...action.payload },
      };

    case USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "FAILED TO LOAD,..,.", //action.payload.error,
        counter: 0,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
