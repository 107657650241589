import BaseApiService from "../../BaseApiService";

const PUB_DEFAULT_PATH = "/custom/api/pub/user/profile";

const DEFAULT_PATH = "/custom/api/user/profile";

class UserService extends BaseApiService {
  getAll = (start = 0, limit = 50, search = "") => {
    const url = `${PUB_DEFAULT_PATH}/name?${search}&start=${start}&limit=${limit}`;
    return this.makeGetRequestWithAuth(url);
  };
  getAllReportingManager = (start = 0, limit = 50, search = "") => {
    const url = `${DEFAULT_PATH}/search?start=${start}&limit=${limit}${search}`;
    return this.makeGetRequestWithAuth(url);
  };
  getAllPartnerUser = (start = 0, limit = 50, search = "") => {
    const url = `${DEFAULT_PATH}/search?&start=${start}&limit=${limit}&${search}`;
    return this.makeGetRequestWithAuth(url);
  };
  getAllUsers = (start = 0, limit = 50, search = "") => {
    const url = `${DEFAULT_PATH}/role/search?&start=${start}&limit=${limit}&${search}`;
    return this.makeGetRequestWithAuth(url);
  };

  getPartnerUserByID = (uid = "") => {
    const url = `${DEFAULT_PATH}/manage/uid/${uid}`;
    return this.makeGetRequestWithAuth(url);
  };
  getUserByBranchAndName = (start = 0, limit = 50, branch = "", name = "") => {
    const url = `${DEFAULT_PATH}/manage/filter?branchUid=${branch}&name=${name}`;
    return this.makeGetRequestWithAuth(url);
  };

  getEmployee = (start = 0, limit = 50, search = "") => {
    const url = `${DEFAULT_PATH}/search?name:lk=${search}&start=${start}&limit=${limit}`;
    return this.makeGetRequestWithAuth(url);
  };

  getPrivateAll = (start = 0, limit = 50, search = "") => {
    const url = `${DEFAULT_PATH}/search?start=${start}&limit=${limit}&${search}`;
    return this.makeGetRequestWithAuth(url);
  };
  save = (obj: any) => {
    const url = `${DEFAULT_PATH}/manage`;
    return this.makePostRequestWithAuth(url, obj);
  };
  update = (uid: string | undefined, obj: any) => {
    const url = `${DEFAULT_PATH}/manage/${uid}`;
    return this.makePutRequestWithAuth(url, obj);
  };
  findById = (id: string) => {
    const url = `${DEFAULT_PATH}/uid/${id}`;
    return this.makeGetRequestWithAuth(url);
  };
  activate = (uid: string) => {
    const url = `${DEFAULT_PATH}/manage/activate/${uid}`;
    return this.makePutRequestWithAuth(url);
  };
  Inactivate = (uid: string) => {
    const url = `${DEFAULT_PATH}/manage/inactivate/${uid}`;
    return this.makePutRequestWithAuth(url);
  };
  getUserDetail = () => {
    const url = `${DEFAULT_PATH}/manage/me`;
    return this.makeGetRequestWithAuth(url);
  };
}

export default new UserService();
