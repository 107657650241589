/**
 * Author : Kaviraj RM
 * Created on : 25 May, 2023
 **/

/*
    --------------------------------------------------------------------------------------------------------------------
    Imports:
    --------------------------------------------------------------------------------------------------------------------
  */
import sahayyaIllustration from "assets/img/common/Sahayya_Illustration.svg";
import sahayyaLogo from "assets/img/common/Sahayya_Logo.svg";
import InputPassword from "components/InputPassword";
import SimpleButton from "components/SimpleButton";
import Text from "components/Text/Text";
import { RouteProps, withRouter } from "hoc/withRouter";
import { useEffect, useRef, useState } from "react";
import { linkPagePasswordResetSuccess } from "routes";
import SimpleReactValidator from "simple-react-validator";
import { getStandardValidator, messageReplace } from "utils/CommonUtil";
import { BUTTON_STYLE_TYPE } from "utils/Constant";
import queryString from "query-string";
import AuthService from "services/AuthService";

// -------------------------------------------------- IProps ----------------------------------------------------- //
interface IProps extends RouteProps {}

function PasswordNewPassword(props: IProps) {
  /*
    ----------------------------------------------------------------------------------------------------------------------
    States
    ----------------------------------------------------------------------------------------------------------------------
  */

  
    const [pageObj, setPageObj] = useState({
      channel: "EMAIL",
      actionType: "RESET_PASSWORD",
      emailId: "",
      otp: "",
    });
  
    const [userData, setUserData] = useState({
      username:"",
      password: ""
    })
    const [error, setError] = useState([]);
  const [, forceUpdate] = useState<undefined | boolean>(undefined);
  const passwordRef = useRef(null);
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: { ...getStandardValidator() },
      ...messageReplace(),
      autoForceUpdate: { forceUpdate: () => forceUpdate((prev) => !prev) },
    })
  );

  useEffect(() => {
    let parsed: {
      email?: string;
      otp?:string;
    } = queryString.parse(props.location.search);

    if (parsed?.otp && parsed?.otp !== undefined && parsed?.email && parsed?.email !== undefined) {
      setPageObj({ ...pageObj, otp: parsed?.otp, emailId: parsed?.email });
      setUserData({...userData, username: parsed?.email})
    }
  }, []);

  /*
    ----------------------------------------------------------------------------------------------------------------------
    Handlers: Event handler for onChange, onBlur etc
    ----------------------------------------------------------------------------------------------------------------------
  */

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserData((prevPageObj) => ({
      ...prevPageObj,
      [name]: value,
    }));
  };

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Validators:
    -----------------------------------------------------------------------------------------------------------------------
  */

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Helper Functions:
    -----------------------------------------------------------------------------------------------------------------------
  */

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Submit / Save Data Functions:
    -----------------------------------------------------------------------------------------------------------------------
  */

  const onSavePassword = async (e: any) => {
    // e.preventDefault();
    if (simpleValidator?.current?.allValid()) {
        AuthService.verifyOTP(pageObj, userData)
        .then((res) => {
          if (res.status === 201) {
      props.navigateNoAuth(linkPagePasswordResetSuccess);
    }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setError(err?.response?.data?.message);
          }
        });
    } else {
      simpleValidator?.current?.showMessages();
      forceUpdate((prev) => !prev);
    }
  };
  const { username, password } = userData;

  return (
    <div className="flex justify-center relative h-screen">
      <div className="absolute left-6 top-6">
        <img src={sahayyaLogo} alt="Sahayya Logo" />
      </div>
      <div className="w-102 z-10 px-9 mt-49 mb-35">
        <div className="flex justify-center">
          <Text
            label="heading.auth.resetPassword"
            className="text-primary-primaryTextColor text-h1 font-Roboto font-bold"
          />
        </div>
        <div className="my-6 flex justify-center text-center">
          <Text
            label="text.auth.newPasswordOTP"
            className="text-primary-primaryTextColor text-xs font-Roboto"
            args={{ email: username }}
          />
        </div>
        <div className=" mb-3">
          <InputPassword
            label="label.auth.login.password"
            placeholder={"placeholder.auth.login.password"}
            name="password"
            value={password}
            onChange={handleInputChange}
            validation={simpleValidator?.current?.message("password", password, "mandatory")}
            showPasswordVerification
            ref={passwordRef}
          />
        </div>

        <div className="mt-4 w-full ">
          <SimpleButton
            label="component.button.save"
            variant={BUTTON_STYLE_TYPE.PRIMARY}
            onClickWithLoader={onSavePassword}
            //disabled={!verifyMinChar || !verifyNumeric || !verifyLowCase || !verifyUppCase}
          />
        </div>
      </div>
      <div className="absolute -bottom-20 ">
        <img src={sahayyaIllustration} alt="search-icon" className="max-w-fit w-full h-61" />
      </div>
    </div>
  );
}

export default withRouter(PasswordNewPassword);
