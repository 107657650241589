import BaseButton from "components/base/BaseButton/BaseButton";
import * as routes from "routes";
import AuthService from "services/AuthService";

function UnAuthorized(props: any) {
  // On Click Event
  const onLogout = () => {
    console.log("Logout");
    AuthService.logOut();
    window.location.href = `${window.location.origin}${routes.linkBasePath}`;
  };

  return (
    <div className="absolute w-full z-999999 h-9 flex flex-row bg-red-400 opacity-95 text-xs text-white  items-center justify-center ">
      Your session has expired. Please
      {/* <a href={routes.linkBasePath} className="mx-1 underline">
        Login
      </a> */}&nbsp;
      <BaseButton label="Login" className="mx-1 underline" onClick={onLogout} />
       &nbsp;again
    </div>
  );
}

export default UnAuthorized;
