const ALL_CONST = {
  welcome: "Welcome to Viamagus' Kaiju React Boilerplate!",
  message: " Hello!! This is Home - VIEW-1!",
  "label.view1.post_heading": "Post Heading",
  "label.view1.post_description": "Post Description",
  "label.view2.user_name": "Enter Name",
  "label.user_name": "Enter Name",
  "message.success": "Enter Name",
  "validator.mandatory": "This is a mandatory field",
  "component.button.save": "Save",
  "component.button.cancel": "Cancel",
  "dialog.apiError": "There was an SQL exception !",
  "dialog.apiError.details": "Error Details",
  "label.noDataFound": "No data found",
  "label.response": "Response",
  "component.button.remove.reference": "Remove Reference Details",

  // -------------------------------------------- Auth ---------------------------------------------//
  "component.button.logIn": "Log in",
  "label.auth.login": "Login",
  "label.auth.login.googleLogin": "If you have a sahayya.com email ID, Log in with Google",
  "component.button.googleLogin": "Log in with Google",
  "label.auth.login.OR": "OR",
  "label.auth.login.partnerLogin":
    "If you are a partner or do not have a sahayya.com email ID, Log in with your registered email ID and password",
  "label.auth.login.emailID": "Email ID",
  "placeholder.auth.login.emailID": "Enter your email ID",
  "placeholder.auth.login.password": "Enter your password",
  "label.auth.login.password": "Password",
  "link.auth.login.password": "Forgot Password",

  //Reset Password
  "heading.auth.resetPassword": "Reset your password",
  "text.auth.resetPassword":
    "Enter the email ID associated with your account and we'll send a 6 digit OTP",
  "component.button.resetPassword": "Send OTP",

  //OTP
  "text.auth.resetPasswordOTP":
    "If {{email}} is registered, you will receive an OTP in your email. Please check your Inbox & Spam folders.",
  "label.text.didn'tReceive.otp": "Didn't Receive OTP? ",
  "component.button.resend.otp": "Didn't Receive OTP? Resend OTP",
  "label.text.otp.expired": "OTP has expired",
  "component.button.verify": "Verify",
  "link.auth.resetPasswordOTP.email": "Change email ID",

  //New Password
  "text.auth.newPasswordOTP": "Please enter your new password",

  //-------Password----------//
  "label.text.password.suggestions": "Suggestions",
  "label.text.password.characters": "At least 8 characters in length",
  "label.text.password.smallLetter": "At least one small letter",
  "label.text.password.capitalLetter": "At least one capital letter",
  "label.text.password.number": "At least one number",

  //-------Password-Reset-Success----------//
  "heading.auth.resetPasswordSuccess": "Password Reset",
  "text.auth.passwordResetSuccess": "Your password has been successfully reset.",

  // ------------ Profile Picture ---------- //
  "component.profilePicUpload.mandatory": "Profile Picture*",
  "component.profilePicUpload": "Profile Picture",

  // ------------ OCR --------------- //
  "component.pre.populate.OCR": "Pre - populate using KYC",
  "label.populate.OCR": "Populate with OCR",

  // ------------CAM Link --------------- //
  "component.btn.link": "Link",

  //-------Master Data----------//

  "label.common.active": "Activate",
  "label.common.deactivate": "Deactivate",

  //--------------Notifications-----------------------//
  "label.notifications.title": "Title",
  "label.notifications.message": "Message",
  "label.notifications.action": "Action",

  //Layout
  "text.sidebar.search": "Search",
  "text.sidebar.home": "Home",
  "text.sidebar.masterData": "Master Data",
  "header.label.reviewMode": "Review Mode: {{reviewMode}} Value (Request Id: {{id}})",
  "text.sidebar.systemConfiguration": "System Configuration",
  "text.sidebar.systemDate": "System Date",

  //Static Master Types
  "heading.masterData.types": "Static Master Types",
  "text.masterData.types.search": "Search",
  "input.masterData.staticMaster.displayName": "Display Name*",
  "input.masterData.staticMaster.displayNameNoMandatory": "Display Name",
  "input.masterData.staticMaster.systemCode": "System Code*",
  "input.masterData.staticMaster.systemCodeNoMandatory": "System Code",
  "input.masterData.staticMaster.description": "Description",
  "input.masterData.staticMaster.isActive": "Is Active",
  "input.masterData.staticMaster.createdFrom": "Created Date - From",
  "input.masterData.staticMaster.createdTo": "Created Date - To",
  "input.masterData.staticMaster.createdBy": "Created By",
  "input.masterData.staticMaster.updatedFrom": "Updated Date - From",
  "input.masterData.staticMaster.updatedTo": "Updated Date - To",
  "input.masterData.staticMaster.updatedBy": "Updated By",

  // "dialog.staticMaster.deactivate": "Deactivate Master Type",
  // "dialog.staticMaster.activate": "Activate Master Type",

  "dialog.staticMaster.deactivate": "Deactivate ",
  "dialog.staticMaster.activate": "Activate ",

  "dialog.product.deactivate": "Deactivate Product",
  "dialog.product.activate": "Activate Product",

  "dialog.fee.deactivate": "Deactivate Fee",
  "dialog.fee.activate": "Activate Fee",

  "dialog.staticMaster.message": "Are you sure you want to Deactivate?",
  "dialog.staticMaster.message.activate": " Are you sure you want to Activate?",
  //Static Master Types - Create
  "heading.masterData.types.create": "Static Master Types - Create",
  "input.staticMaster.displayName": "Display Name*",
  "input.staticMaster.systemCode": "System Code*",
  "input.staticMaster.description": "Description",
  "input.staticMaster.reasons": "Reasons/Comments*",
  //Static Master Data - Create
  "heading.masterData.data": "Static Master Data",
  "heading.masterData.data.create": "Static Master Data - Create",
  "input.staticMaster.data.masterType": "Static Master Type",
  "input.staticMaster.data.displayName": "Display Name*",
  "input.staticMaster.data.systemCode": "System Code*",
  "input.staticMaster.data.sequence": "Sequence*",
  "input.staticMaster.data.description": "Description",
  "input.staticMaster.data.reasons": "Reasons/Comments*",
  "input.masterData.staticMaster.displayNameFilter": "Display Name",
  "input.masterData.staticMaster.systemCodeFilter": "System Code",

  //Approval Request

  "heading.masterData.approvalRequests": "Approval Requests",
  "input.masterData.approvalRequestSearch.entity": "Entity",
  "input.masterData.approvalRequestSearch.name": "Name",
  "input.masterData.approvalRequestSearch.maker": "Maker",
  "input.masterData.approvalRequestSearch.operation": "Operation",
  "input.masterData.staticMaster.checker": "Checker",
  "input.masterData.staticMaster.checkedFrom": "Checked From",
  "input.masterData.staticMaster.checkedTo": "Checked To",
  "input.masterData.approvalRequestSearch.status": "Status",
  "tableHeader.masterData.approvalRequestSearch.requestId": "Request Id",
  "tableHeader.masterData.approvalRequestSearch.checkedOn": "Checked On",
  "tableHeader.masterData.approvalRequestSearch.status": "Status",
  "heading.masterData.approvalRequest": "Approval Request",
  "sectionTitle.masterData.approvalRequest.summary": "Summary",
  "sectionTitle.masterData.approvalRequest.reason": "Reason For Change",
  "sectionTitle.masterData.approvalRequest.data": "Data",
  "sectionTitle.masterData.approvalRequest.newValue": "New Value",
  "sectionTitle.masterData.approvalRequest.oldValue": "Old Value",
  "dialogTitle.masterData.approvalRequest.reject": "Reason For Rejection",
  "dialogTitle.masterData.approvalRequest.approval": "Reason For Approval",
  "dialogTitle.masterData.approvalRequest.reason": "Reason*",

  // System Configuration - Create
  "heading.systemConfiguration": "System Configuration",
  "heading.systemConfiguration.create": "System Configuration - Create",
  "input.systemConfiguration.displayName": "Display Name*",
  "input.systemConfiguration.displayNameNoMandatory": "Display Name",
  "input.systemConfiguration.systemCode": "System Code*",
  "input.systemConfiguration.systemCodeNoMandatory": "System Code",
  "input.systemConfiguration.value": "Value*",
  "input.systemConfiguration.encrypted": "Encrypted",
  "input.systemConfiguration.description": "Description",
  "input.systemConfiguration.reasons": "Reasons/Comments*",

  // System Date - Create
  "heading.masterData.systemDate": "System Date",
  "heading.systemDate.create": "System Date - Create",
  "input.systemDate.systemDate": "System Date",
  "input.systemDate.description": "Description",
  "input.systemDate.reasons": "Reasons/Comments*",
  // partner onboarding
  "heading.partnerOnboarding.title.heading": "Partner On-boarding",
  "title.partnerOnboarding.CreateScreen": "Partner On-boarding - Create",
  "title.partnerOnboarding.ViewScreen": "Partner On-boarding - View",
  "title.partnerOnboarding.EditScreen": "Partner On-boarding - Edit",
  "label.partnerOnboarding.partnerType": "Partner Type*",
  "label.partnerOnboarding.filter.partnerType": "Partner Type",
  "label.partnerOnboarding..filter.partnerType": "Partner Type",
  "label.partnerOnboarding.channelType": "Channel Type",
  "label.partnerOnboarding.address": "Address*",
  "label.partnerOnboarding.city": "City*",
  "label.partnerOnboarding.companyProfile": "Company Profile*",
  "label.partnerOnboarding.rcuComments": "RCU comments",
  "label.partnerOnboarding.businessConstitution": "Business Constitution*",

  "label.partnerOnboarding.name": "Name*",
  "label.partnerOnboarding.filter.name": "Name",
  "label.partnerOnboarding.filter.businessConstitution": "Business Constitution",
  "label.partnerOnboarding.dateOfAgreement": "Date of Agreement",
  "label.partnerOnboarding.AgreementInitiationDate": "Agreement Initiation Date",
  "label.partnerOnboarding.AgreementExpiryDate": "Agreement Expiry Date",
  "label.partnerOnboarding.inSystemForm": "In system form and upload report",
  "label.partnerOnboarding.uploadFile": "Only upload File",
  "label.partnerOnboarding.dataEntryType": "Data Entry Type",
  "label.partnerOnboarding.partnerData": "Partners Data",
  "label.partnerOnboarding.expanelmentInfo": "Expanelment Information",
  "label.partnerOnboarding.partnerAgreementDetail": "Partner Agreement Detail",
  "label.partnerOnboarding.partnerContactDetail": "Partner Contact Detail",
  "label.partnerOnboarding.keyPerson": "Key Person",
  "label.partnerOnboarding.reference": "Reference",
  "label.partnerOnboarding.rateCard": "Rate Card*",
  "label.partnerOnboarding.bankDetail": "Bank Details",
  "label.partnerOnboarding.gstDetail": "GST Details",
  "label.partnerOnboarding.gstNumber": "GST Number",
  "label.partnerOnboarding.gstState": "GST State",
  "label.partnerOnboarding.attachments": "Attachments",
  "label.partnerOnboarding.organisationReportingLevelWhoHaveAccessToThisPartner":
    "Organisation Reporting Levels Who Can Access To This Partner",

  // Partner User - Create
  "heading.partnerUser.create": "Partner User - Create",
  "input.partnerUser.partnerName": "Partner Name*",
  "input.partnerUser.name": "First Name*",
  "input.partnerUser.lastName": "Last Name*",
  "input.partnerUser.email": "Email Id*",
  "input.partnerUser.password": "Password*",
  "placeholder.partnerUser.password": "Password",
  "input.partnerUser.description": "Description",
  "input.partnerUser.reasons": "Reasons/Comments*",

  // Location State - Create
  "heading.state": "State",
  "heading.state.create": "State - Create",
  "input.state.state": "State",
  "input.state.displayName": "Display Name",
  "input.state.country": "Country",
  "input.state.description": "Description",
  "input.state.reasons": "Reasons/Comments*",
  "input.state.isActive": "Is Active",
  "input.state.createdFrom": "Created Date - From",
  "input.state.createdTo": "Created Date - To",
  "input.state.createdBy": "Created By",
  "input.state.updatedFrom": "Updated Date - From",
  "input.state.updatedTo": "Updated Date - To",
  "input.state.updatedBy": "Updated By",

  // Location State - Create
  "heading.city": "City",
  "heading.city.create": "City - Create",
  "input.city.displayName": "Display Name",
  "input.city.city": "City",
  "input.city.state": "State",
  "input.city.country": "Country",
  "input.city.alternative_name": "Alternative Names",
  "input.city.description": "Description",
  "input.city.reason": "Reason",
  "input.city.isActive": "Is Active",
  "input.city.createdFrom": "Created Date - From",
  "input.city.createdTo": "Created Date - To",
  "input.city.createdBy": "Created By",
  "input.city.updatedFrom": "Updated Date - From",
  "input.city.updatedTo": "Updated Date - To",
  "input.city.updatedBy": "Updated By",

  // Location Pincode - Create
  "heading.pincode": "Pincode",
  "heading.pincode.create": "Pincode - Create",
  "input.pincode.pincode": "Pincode",
  "input.pincode.latitude": "Latitude",
  "input.pincode.longitude": "Longitude",
  "input.pincode.latitudeM": "Latitude*",
  "input.pincode.longitudeM": "Longitude*",
  "input.pincode.city": "City",
  "input.pincode.state": "State",
  "input.pincode.country": "Country",
  "input.pincode.alternative_name": "Alternative Names",
  "input.pincode.description": "Description",
  "input.pincode.reason": "Reason",
  "input.pincode.isActive": "Is Active",
  "input.pincode.createdFrom": "Created Date - From",
  "input.pincode.createdTo": "Created Date - To",
  "input.pincode.createdBy": "Created By",
  "input.pincode.updatedFrom": "Updated Date - From",
  "input.pincode.updatedTo": "Updated Date - To",
  "input.pincode.updatedBy": "Updated By",

  // Organization Structure - Create
  "heading.organizationLevel": "Organization Level",
  "heading.organizationLevel.create": "Organization Level - Create",
  "input.organizationLevel.displayName": "Display Name*",
  "input.organizationLevel.filter.displayName": "Display Name",
  "input.organizationLevel.isActive": "Is Active",
  "input.organizationLevel.systemCode": "System Code*",
  "input.organizationLevel.filter.systemCode": "System Code",
  "input.organizationLevel.value": "Value",
  "input.organizationLevel.encrypted": "Encrypted",
  "input.organizationLevel.description": "Description",
  "input.organizationLevel.reasons": "Reasons/Comments*",
  "input.organizationLevel.createdFrom": "Created Date - From",
  "input.organizationLevel.createdTo": "Created Date - To",
  "input.organizationLevel.createdBy": "Created By",
  "input.organizationLevel.updatedFrom": "Updated Date - From",
  "input.organizationLevel.updatedTo": "Updated Date - To",
  "input.organizationLevel.updatedBy": "Updated By",

  // Organization Department - Create
  "heading.organizationLevelDepartment": "Organization Department",
  "heading.organizationLevelDepartment.create": "Organization Department - Create",
  "input.organizationLevelDepartment.displayName": "Display Name*",
  "input.organizationLevelDepartment.systemCode": "System Code*",
  "input.organizationLevelDepartment.description": "Description",
  "input.organizationLevelDepartment.reasons": "Reasons/Comments*",
  "input.organizationLevelDepartment.isActive": "Is Active",
  "input.organizationLevelDepartment.createdFrom": "Created Date - From",
  "input.organizationLevelDepartment.createdTo": "Created Date - To",
  "input.organizationLevelDepartment.createdBy": "Created By",
  "input.organizationLevelDepartment.updatedFrom": "Updated Date - From",
  "input.organizationLevelDepartment.updatedTo": "Updated Date - To",
  "input.organizationLevelDepartment.updatedBy": "Updated By",

  // Organization Approval - Create
  "heading.organizationLevelApproval": "Approval Levels",
  "heading.organizationLevelApproval.create": "Approval Level - Create",
  "heading.organizationLevelApproval.edit": "Approval Level - Edit",
  "heading.organizationLevelApproval.view": "Approval Level - View",
  "input.organizationLevelApproval.filter.displayName": " Name",
  "input.organizationLevelApproval.Name": " Name*",
  "input.organizationLevelApproval.sequence": "Sequence*",
  "input.organizationLevelApproval.filter.sequence": "Sequence",
  "input.organizationLevelApproval.description": "Description",
  "input.organizationLevelApproval.reasons": "Reasons/Comments*",
  "input.organizationLevelApproval.isActive": "Is Active",
  "input.organizationLevelApproval.createdFrom": "Created Date - From",
  "input.organizationLevelApproval.createdTo": "Created Date - To",
  "input.organizationLevelApproval.createdBy": "Created By",
  "input.organizationLevelApproval.updatedFrom": "Updated Date - From",
  "input.organizationLevelApproval.updatedTo": "Updated Date - To",
  "input.organizationLevelApproval.updatedBy": "Updated By",

  // Product Configuration - Fees
  "heading.productConfiguration.fee": "Fee",
  "heading.productConfiguration.fee.create": "Fee - Create",
  "text.productConfiguration.fees": "Fees Configuration",
  "input.masterData.productConfiguration.changeName": "Charge Name*",
  "input.masterData.productConfiguration.productType": "Product Type*",
  "input.masterData.productConfiguration.chargeType": "Charge Type*",
  "input.masterData.productConfiguration.changeNameFilter": "Charge Name",
  "input.masterData.productConfiguration.productTypeFilter": "Product Type",
  "input.masterData.productConfiguration.loanTypeFilter": "Loan Type",
  "input.masterData.productConfiguration.chargeTypeFilter": "Charge Type",
  "input.masterData.productConfiguration.configuration": "Configuration",
  "input.masterData.productConfiguration.isActive": "Is Active",
  "input.masterData.productConfiguration.createdFrom": "Created Date - From",
  "input.masterData.productConfiguration.createdTo": "Created Date - To",
  "input.masterData.productConfiguration.createdBy": "Created By",
  "input.masterData.productConfiguration.updatedFrom": "Updated Date - From",
  "input.masterData.productConfiguration.updatedTo": "Updated Date - To",
  "input.masterData.productConfiguration.updatedBy": "Updated By",

  // Product Configuration - income Scheme
  "heading.productConfiguration.incomeScheme.productIncomeScheme": "Product Income Schemes",
  "heading.productConfiguration.incomeScheme.create": "Product Income Scheme - Create",
  "input.productConfiguration.incomeScheme.filter.product": "Product",
  "input.productConfiguration.incomeScheme.product": "Product*",
  "input.productConfiguration.incomeScheme.filter.incomeScheme": "Income Scheme",
  "input.productConfiguration.incomeScheme.incomeScheme": "Income Scheme*",
  "label.productConfiguration.incomeScheme.filter.name": "Name",
  "label.productConfiguration.incomeScheme.name": "Name*",
  "label.productConfiguration.incomeScheme.filter.bankOrFinancier": "Bank/Financier",
  "label.productConfiguration.incomeScheme.bankOrFinancier": "Bank/Financier*",
  "label.productConfiguration.incomeScheme.filter.lendingType": "Lending Type",
  "label.productConfiguration.incomeScheme.lendingType": "Lending Type*",
  "label.productConfiguration.incomeScheme.filter.minIncome": "Minimum Income",
  "input.productConfiguration.incomeScheme.name": "Name*",
  "input.productConfiguration.incomeScheme.bank": "Bank/Financier*",
  "input.productConfiguration.incomeScheme.lendingType": "Lending Type*",
  "input.productConfiguration.incomeScheme.salientFeatures": "Salient Features",
  "input.productConfiguration.incomeScheme.note": "Note",
  "input.productConfiguration.incomeScheme.description": "Description",
  "input.productConfiguration.incomeScheme.isActive": "Is Active",
  "input.productConfiguration.incomeScheme.reasons": "Reasons/Comments*",

  // Product Configuration - Deviation Category
  "heading.productConfiguration.deviation.category": "Deviation Category",
  "heading.productConfiguration.deviation.category.create": "Deviation Category - Create",
  "text.productConfiguration.approval": "Approval Function",
  "input.productConfiguration.deviation.category.name": "Name*",
  "input.productConfiguration.deviation.category.description": "Description",
  "input.productConfiguration.deviation.category.isActive": "Is Active",
  "input.productConfiguration.deviation.category.reasons": "Reasons/Comments*",

  // Product Configuration - Deviation
  "heading.productConfiguration.deviation": "Deviations",
  "heading.productConfiguration.deviation.create": "Deviation - Create",
  "input.productConfiguration.deviation.category": "Deviation Category*",
  "input.productConfiguration.deviation.categoryWithoutStar": "Deviation Category",
  "input.productConfiguration.deviation.typeOfDeviation": "Type Of Deviation",
  "input.productConfiguration.deviation.typeOfDeviationWithStar": "Type Of Deviation*",
  "input.productConfiguration.deviation.name": "Name*",
  "input.productConfiguration.deviation.displayName": "Display Name",
  "input.productConfiguration.deviation.productName": "Product Name",
  "input.productConfiguration.deviation.productScheme": "Product - Income Scheme",
  "input.productConfiguration.deviation.description": "Description",
  "input.productConfiguration.deviation.isActive": "Is Active",
  "input.productConfiguration.deviation.reasons": "Reasons/Comments*",
  "input.productConfiguration.deviation.createdFrom": "Created Date - From",
  "input.productConfiguration.deviation.createdTo": "Created Date - To",
  "input.productConfiguration.deviation.createdBy": "Created By",
  "input.productConfiguration.deviation.updatedFrom": "Updated Date - From",
  "input.productConfiguration.deviation.updatedTo": "Updated Date - To",
  "input.productConfiguration.deviation.updatedBy": "Updated By",

  // Business Rule
  "heading.productConfiguration.businessRule": "Business Rule ",
  "heading.productConfiguration.businessRule.create": "Business Rule - Create",
  "input.productConfiguration.businessRule.name": "Rule Name*",
  "input.productConfiguration.businessRule.rule": "Rule*",
  "input.productConfiguration.businessRule.description": "Description",
  "input.productConfiguration.businessRule.isActive": "Is Active",
  "input.productConfiguration.businessRule.reasons": "Reasons/Comments*",
  "input.productConfiguration.businessRule.createdFrom": "Created Date - From",
  "input.productConfiguration.businessRule.createdTo": "Created Date - To",
  "input.productConfiguration.businessRule.createdBy": "Created By",
  "input.productConfiguration.businessRule.updatedFrom": "Updated Date - From",
  "input.productConfiguration.businessRule.updatedTo": "Updated Date - To",
  "input.productConfiguration.businessRule.updatedBy": "Updated By",

  // Checklist
  "heading.productConfiguration.checklist": "Checklist",
  "heading.productConfiguration.checklist.create": "Checklist - Create",
  "input.productConfiguration.checklist.event": "Event*",
  "input.productConfiguration.checklist.name": "Name*",
  "input.productConfiguration.checklist.systemCode": "System Code",
  "input.productConfiguration.checklist.product": "Product Income - Scheme*",
  "input.productConfiguration.checklist.description": "Description",
  "input.productConfiguration.checklist.isActive": "Is Active",
  "input.productConfiguration.checklist.reasons": "Reasons/Comments*",

  "label.header.panel.loanData": "Loan Data",
  "label.header.panel.masterData": "Master Data",
  "label.header.panel.profileSetting": "Profile Setting",
  "label.header.panel.logout": "Logout",
  // Transaction

  "heading.productConfiguration.transaction": "Transaction Approval Rules",
  "heading.productConfiguration.transaction.create": "Transaction Approval Rules - Create",
  "input.productConfiguration.transaction.name": "Name*",
  "input.productConfiguration.transaction.rule": "Rule*",
  "input.productConfiguration.transaction.ruleName": "Rule Name",
  "input.productConfiguration.transaction.ruleAppliedTo": "Rule Applied to",
  "input.productConfiguration.transaction.event": "Rule*",
  "input.productConfiguration.transaction.ruleApplied": "Rule Applied to",
  "input.productConfiguration.transaction.productName": "Product Name",
  "input.productConfiguration.transaction.product": "Product",
  "input.productConfiguration.transaction.productScheme": "Product - Income Scheme",
  "input.productConfiguration.transaction.description": "Description",
  "input.productConfiguration.transaction.isActive": "Is Active",
  "input.productConfiguration.transaction.reasons": "Reasons/Comments*",
  "input.productConfiguration.transaction.createdFrom": "Created Date - From",
  "input.productConfiguration.transaction.createdTo": "Created Date - To",
  "input.productConfiguration.transaction.createdBy": "Created By",
  "input.productConfiguration.transaction.updatedFrom": "Updated Date - From",
  "input.productConfiguration.transaction.updatedTo": "Updated Date - To",
  "input.productConfiguration.transaction.updatedBy": "Updated By",

  // Product Configuration - Products
  "heading.productConfiguration.products": "Products",
  "heading.productConfiguration.product.create": "Products - Create",
  "heading.productConfiguration.product.review": "Products - Review",
  "heading.productConfiguration.product.view": "Products - View",
  "heading.productConfiguration.product.edit": "Products - Edit",
  "input.productConfiguration.loanType": "Loan Type*",
  "input.productConfiguration.productType": "Product Type*",
  "input.productConfiguration.productName": "Product Name*",
  "heading.productConfiguration.applicationStages": "Application Stages",
  "label.productConfiguration.QC": "QC",
  "label.productConfiguration.PD": "PD",
  "label.productConfiguration.technical": "Technical",
  "label.productConfiguration.Legal": "Legal",
  "label.productConfiguration.RCU": "RCU",
  "label.productConfiguration.financialApproval": "Financial Approval",
  "label.productConfiguration.inPrincipalApproval": "In-principal Approval",
  "label.productConfiguration.finalApproval": "Final Approval",
  "label.productConfiguration.docket": "Docket",
  "label.productConfiguration.disbursement": "Disbursement",
  "label.productConfiguration.productDetails": "Product Details",
  "label.productConfiguration.products.productShortNameFilter": "Product Short Name",

  // Tables - Headers
  "table.header.productConfiguration.productDetails.bank": "Bank/Financier*",
  "table.header.productConfiguration.productDetails.lendingType": "Lending Type*",
  "table.header.productConfiguration.productDetails.interestType": "Interest Type*",
  "table.header.productConfiguration.productDetails.minLoan": "Min Loan Amount*(₹)",
  "table.header.productConfiguration.productDetails.maxLoan": "Max Loan Amount*(₹)",
  "table.header.productConfiguration.productDetails.PLR": "PLR*(%)",
  "table.header.productConfiguration.productDetails.minROI": "Min ROI*(%)",
  "table.header.productConfiguration.productDetails.maxROI": "Max ROI*(%)",
  "table.header.productConfiguration.productDetails.minTenor": "Min Tenor(months)*",
  "table.header.productConfiguration.productDetails.maxTenor": "Max Tenor(months)*",
  "table.header.productConfiguration.productDetails.minAge": "Min Age(Years)*",
  "table.header.productConfiguration.productDetails.maxAge": "Max Age(Years)*",
  "table.header.productConfiguration.productDetails.minYearlyIncome": "Min Yearly Income*(₹)",
  "table.header.productConfiguration.productDetails.coApplicant": "Co Applicant",
  "table.header.productConfiguration.productDetails.guarantor": "Guarantor",
  "table.header.productConfiguration.productDetails.ltv": "LTV Applicable",
  "table.header.productConfiguration.productDetails.maxLtv": "Max LTV(%)",
  "table.header.productConfiguration.productDetails.maxFOIR": "Max FOIR(%)",
  "table.header.productConfiguration.productDetails.minResidualAge": "Minimal Residual Age(years)",
  "table.header.productConfiguration.productDetails.preEMI": "Pre EMI",
  "table.header.productConfiguration.productDetails.autoReject": "Auto Reject Condition(Days)*",
  "table.header.productConfiguration.productDetails.applicableFrom": "Applicable From*",
  "table.header.productConfiguration.productDetails.applicableTo": "Applicable To",
  "table.header.productConfiguration.productDetails.actions": "Actions",
  "table.header.productConfiguration.productDetails.installmentType": "Installment Type*",
  "table.header.productConfiguration.productDetails.dueDay": "Due Day(of month)*",
  "table.header.productConfiguration.productDetails.customerType": "Customer Type*",
  "table.header.productConfiguration.productDetails.propertyType": "Property Type*",
  "table.header.productConfiguration.productDetails.transactionType": "Transaction Type*",
  "table.header.productConfiguration.productDetails.attachment": "Attachment",
  "table.header.productConfiguration.productDetails.uploadedDate": "Uploaded Date",
  "table.header.productConfiguration.productDetails.status": "Status",

  //Tab Headers
  "table.header.productConfiguration.productDetails.loan": "Loan and Interest*",
  "table.header.productConfiguration.productDetails.installment": "Installment*",

  "table.header.productConfiguration.productDetails.attachments": "Attachments",

  // Organization Reporting - Create
  "heading.organizationLevelReporting.create": "Organization Reporting - Create",
  "input.organizationLevelReporting.displayName": "Display Name*",
  "input.organizationLevelReporting.oragnizationLevelType": "Oragnization Level Type",
  "input.organizationLevelReporting.description": "Description",
  "input.organizationLevelReporting.isActive": "Is Active",
  "input.organizationLevelReporting.reasons": "Reasons/Comments*",
  "input.organizationLevelReporting.createdFrom": "Created Date - From",
  "input.organizationLevelReporting.createdTo": "Created Date - To",
  "input.organizationLevelReporting.createdBy": "Created By",
  "input.organizationLevelReporting.updatedFrom": "Updated Date - From",
  "input.organizationLevelReporting.updatedTo": "Updated Date - To",
  "input.organizationLevelReporting.updatedBy": "Updated By",

  // Organization Designation - Create
  "heading.organizationLevelDesignation": "Organization Designation",
  "heading.organizationLevelDesignation.create": "Organization Designation - Create",
  "input.organizationLevelDesignation.displayName": "Display Name*",
  "input.organizationLevelDesignation.department": "Department*",
  "input.organizationLevelDesignation.description": "Description",
  "input.organizationLevelDesignation.isActive": "Is Active",
  "input.organizationLevelDesignation.reasons": "Reasons/Comments*",
  "input.organizationLevelDesignation.createdFrom": "Created Date - From",
  "input.organizationLevelDesignation.createdTo": "Created Date - To",
  "input.organizationLevelDesignation.createdBy": "Created By",
  "input.organizationLevelDesignation.updatedFrom": "Updated Date - From",
  "input.organizationLevelDesignation.updatedTo": "Updated Date - To",
  "input.organizationLevelDesignation.updatedBy": "Updated By",

  // Organization organizationLevelBranch - Create
  "heading.organizationLevelBranch": "Branch",
  "heading.organizationLevelBranch.create": "Branch - Create",
  "input.organizationLevelBranch.Name": " Name*",
  "input.organizationLevelBranch.filter.Name": "Name",
  "input.organizationLevelBranch.systemCode": "System Code*",
  "input.organizationLevelBranch.filter.systemCode": "System Code",
  "input.organizationLevelBranch.parentBranch": "Parent Branch",
  "input.organizationLevelBranch.org_reporting_level": "Organisation Reporting Level",
  "input.organizationLevelBranch.date_of_branch_opening": "Date of Branch Opening*",
  "input.organizationLevelBranch.date_of_branch_closing": "Date of Branch Closing",
  "input.organizationLevelBranch.spoke": "Spoke",
  "input.organizationLevelBranch.branch": "Branch",
  "input.organizationLevelBranch.ro": "RO",
  "input.organizationLevelBranch.ho": "HO",
  "input.organizationLevelBranch.address": "Address*",
  "input.organizationLevelBranch.filter.address": "Address",
  "input.organizationLevelBranch.pincode": "Pincode*",
  "input.organizationLevelBranch.city": "City*",
  "input.organizationLevelBranch.filter.city": "City",
  "input.organizationLevelBranch.state": "State*",
  "input.organizationLevelBranch.latitude": "Latitude*",
  "input.organizationLevelBranch.longitude": "Longitude*",
  "input.organizationLevelBranch.filter.state": "State",
  "label.organizationLevelBranch.productMapping": "Product Mapping",

  "input.organizationLevelBranch.description": "Description",
  "input.organizationLevelBranch.isActive": "Is Active",
  "input.organizationLevelBranch.reasons": "Reasons/Comments*",
  "input.organizationLevelBranch.createdFrom": "Created Date - From",
  "input.organizationLevelBranch.createdTo": "Created Date - To",
  "input.organizationLevelBranch.createdBy": "Created By",
  "input.organizationLevelBranch.updatedFrom": "Updated Date - From",
  "input.organizationLevelBranch.updatedTo": "Updated Date - To",
  "input.organizationLevelBranch.updatedBy": "Updated By",

  // User Management - Create
  "heading.userManagement.userListing": "User",
  "heading.userManagement.create": "User Management - Create",
  "heading.userManagement.edit": "User Management - Edit",
  "heading.userManagement.view": "User Management - View",
  "label.userManagement.create": "User - Create",
  "input.userManagement.filter.name": "Name",
  "input.userManagement.name": "Name*",
  "input.userManagement.lastName": "Last Name*",
  "input.userManagement.filter.lastName": "Last Name",
  "input.userManagement.email": "Email Id*",
  "input.userManagement.filter.email": "Email Id",
  "label.userManagement.department": "Department*",
  "label.userManagement.filter.department": "Department",
  "label.userManagement.designation": "Designation*",
  "label.userManagement.filter.designation": "Designation",
  "label.userManagement.roles": "Roles",
  "input.userManagement.reportingManager": "Reporting Manager",
  "placeholder.userManagement.password": "Password",
  "input.userManagement.isActive": "Is Active",
  "input.userManagement.description": "Description",
  "input.userManagement.reasons": "Reasons/Comments*",

  // Source Details
  "heading.sourceDetails": "Sourcing Details",
  "input.sourceDetails.loanApplicationNo": "Loan Application No.",
  "input.sourceDetails.product": "Product*",
  "input.sourceDetails.productNotMandatory": "Product",
  "input.sourceDetails.loanAmount": "Loan Amount(₹)*",
  "input.sourceDetails.loanAmountWords": "Loan Amount in Words (₹)",
  "input.sourceDetails.loanAmountGreaterThan": "Loan Amount >= (₹)",
  "input.sourceDetails.loanAmountLessThan": "Loan Amount ",
  "input.sourceDetails.loanTenure": "Loan Tenure (Months)*",
  "input.sourceDetails.loanTenureWords": "Loan Tenure in Years",
  "input.sourceDetails.salesAgent": "Sales Agent*",
  "input.sourceDetails.branch": "Branch*",
  "input.sourceDetails.salesManager": "Sales Manager",
  "input.sourceDetails.creditManager": "Credit Manager",
  "input.sourceDetails.stage": "Stage",
  "input.sourceDetails.status": "Status",
  "input.sourceDetails.fileStatus": "File Status",
  "input.sourceDetails.stageStatus": "Stage Status",
  "input.sourceDetails.isActive": "Is Active",

  //documents
  "document.upload.optionTypes": "Attach Documents (PDF, JPG, JPEG or PNG)",

  // KYC Documents
  "heading.kycdocuments": "KYC Documents",
  "heading.kycDocuments.nameContact": "Name & Contact",
  "input.basicInformation.document": "Document No*",
  "heading.dde.organisation.documents": "Organisation Documents",

  // Basic Information
  "label.heading.DOB.proof": "DOB Proof",
  "label.heading.DOB.proof.mandatory": "DOB Proof *",
  "label.heading.residential.address": "Residential Address",
  "input.basicInformation.typeOfResidential": "Type of Residential Address*",
  "heading.basicInformation": "Basic Information",
  "input.basicInformation.salutation": "Salutation*",
  "input.basicInformation.gender": "Gender*",
  "input.basicInformation.relationship": "Relationship with Applicant*",
  "input.basicInformation.ageAtOrigination": "Age at Origination",
  "input.basicInformation.firstName": "First Name*",
  "input.basicInformation.firstNameNotMandatory": "First Name",
  "input.basicInformation.middleName": "Middle Name",
  "input.basicInformation.lastName": "Last Name*",
  "input.basicInformation.lastNameNotMandatory": "Last Name",
  "input.basicInformation.fathersName": "Father's Name",
  "input.basicInformation.spouseName": "Spouse's Name",
  "input.basicInformation.dob.mandatory": "DOB*",
  "input.basicInformation.dob": "DOB",
  "input.basicInformation.mobile.mandatory": "Mobile Number*",
  "input.basicInformation.mobile": "Mobile Number",
  "input.basicInformation.phone": "Phone Number",

  "label.loanDetail.pointOfInterest": "Point of Interest",
  "label.pointOfInterest.Map": "Map",
  "label.pointOfInterest.keyDistance": "Key Distance",
  "label.pointOfInterest.particulars": "Particulars",
  "label.pointOfInterest.distance": "Point to Point Distance (km)",

  //Static Master Types - View
  "heading.masterData.types.view": "Static Master Types - View",
  //Static Master Types - Review
  "heading.masterData.types.review": "Static Master Types - Review",
  //Static Master Types - Edit
  "heading.masterData.types.edit": "Static Master Types - Edit",
  //Static Master Data - Edit
  "heading.masterData.data.edit": "Static Master Data - Edit",
  //Static Master Data - View
  "heading.masterData.data.view": "Static Master Data - View",
  // System Configuration - Edit
  "heading.systemConfiguration.edit": "System Configuration - Edit",
  // System Configuration - View
  "heading.systemConfiguration.view": "System Configuration - View",
  // System Date - View
  "heading.systemDate.view": "System Date - View",
  // System Date - Edit
  "heading.systemDate.edit": "System Date - Edit",
  // Partner User - Create
  "heading.partnerUser.view": "Partner User - View",
  // Partner User - Create
  "heading.partnerUser.edit": "Partner User - Edit",
  // Location State - Edit
  "heading.state.edit": "State - Edit",
  // Location State - View
  "heading.state.view": "State - View",

  // Location City - View
  "heading.city.view": "City - View",

  // Location City - Edit
  "heading.city.edit": "City - Edit",

  // Location Pincode - Create
  "heading.pincode.edit": "Pincode - Edit",

  // Location Pincode - Create
  "heading.pincode.view": "Pincode - View",

  // Organization Structure - Edit
  "heading.organizationLevel.edit": "Organization Level - Edit",

  // Organization Structure - View
  "heading.organizationLevel.view": "Organization Level - View",

  // Organization Structure - Edit
  "heading.organizationLevelDepartment.edit": "Organization Department - Edit",

  // Organization Structure - View
  "heading.organizationLevelDepartment.view": "Organization Department - View",

  // Organization Department - View
  // "heading.organizationLevelApproval.view": "Organization Approval - View",

  // Organization Department - Edit
  // "heading.organizationLevelApproval.edit": "Organization Approval - Edit",

  // Organization Reporting - Edit
  "heading.organizationLevelReporting.edit": "Organization Reporting - Edit",

  // Organization Reporting - View
  "heading.organizationLevelReporting.view": "Organization Reporting - View",

  // Organization Designation - View
  "heading.organizationLevelDesignation.view": "Organization Designation - View",

  // Organization Designation - Edit
  "heading.organizationLevelDesignation.edit": "Organization Designation - Edit",

  // Organization organizationLevelBranch - Create
  "heading.organizationLevelBranch.edit": "Branch - Edit",

  // Organization organizationLevelBranch - Create
  "heading.organizationLevelBranch.view": "Branch - View",

  // User Management - View
  "label.userManagement.view": "User Management - View",

  // User Management - Edit
  "label.userManagement.edit": "User Management - Edit",
  // Product Configuration - Deviation
  "heading.productConfiguration.deviation.view": "Deviation - View",
  // Product Configuration - Deviation
  "heading.productConfiguration.deviation.edit": "Deviation - Edit",
  // Product Configuration - Deviation
  "heading.productConfiguration.deviation.category.view": "Deviation Category - View",
  // Product Configuration - Deviation
  "heading.productConfiguration.deviation.category.edit": "Deviation Category - Edit",

  // Business Rule
  "heading.productConfiguration.businessRule.view": "Business Rule - View",

  // Business Rule
  "heading.productConfiguration.businessRule.update": "Business Rule - Edit",
  // Checklist
  "heading.productConfiguration.checklist.edit": "Checklist - Edit",
  // Checklist
  "heading.productConfiguration.checklist.view": "Checklist - View",

  // Transaction
  "heading.productConfiguration.transaction.edit": "Transaction - Edit",

  // --------------------------------------------- section ---------------------------------------------- //
  "heading.personal.discussion": "Personal Discussion",
  "heading.initiation.info": "Initiation Info",
  "heading.personal.discussion.details": "Personal Discussion Details",
  "heading.family.details": "Family or Household Details",
  "heading.employment.details": "Employment Details",
  "heading.reference.details": "Reference Details",
  "heading.reference.details.self.employed.rental.income":
    "Reference Details for Self Employed Rental Income",
  "heading.other.income": "Other Income",
  "heading.writeup.on.work.profile": "Writeup on Work Profile*",
  "heading.writeup.on.business.model": "Detail Writeup on Business Model*",
  "heading.writeup.on.work.profile.business.model": "Writeup on Business / Work Profile*",
  "heading.investmentDetails": "Investment Details",
  "heading.repayment.history": "Repayment History",
  "heading.pd.recordings": "Personal Discussion Recordings",
  "heading.pd.recordingDoc": "Add Audio, Video and Pictures",
  "placeholder.pd.enterTenureOfRentInMonths": "Enter Tenure of Rent (in months)",

  // Transaction
  "heading.productConfiguration.transaction.view": "Transaction - View",

  "heading.productConfiguration.fee.view": "Fee - View",

  "heading.productConfiguration.fee.edit": "Fee - Edit",

  "heading.transaction.loanListing": "Loan Listing",
  "heading.transaction.TaskListing": "Task Listing",
  "heading.transaction.QcNonInitiatedListing": "Qc Non Initiated Listing",
  "heading.transaction.partnerTaskListing": "Partner Task Listing",
  "heading.transaction.receiptListing": "Receipt Listing",
  "heading.transaction.depositListing": "Deposit Listing",
  "heading.transaction.reconciliationListing": "Reconciliation Listing",

  "label.loanListing.loanApplicationNo": "Loan Application No",
  "label.loanListing.status": "File Status",
  "label.loanListing.product": "Product",
  "label.loanListing.state": "State",
  "label.loanListing.branch": "Branch",
  "label.loanListing.lendingType": "Lending Type",
  "label.loanListing.bankFinancier": "Bank/Financier",
  "label.loanListing.applicationName": "Applicant Name",
  "label.loanListing.loanAmount": "Loan Amount",
  "label.loanListing.actions": "Actions",
  "label.loanListing.linkedLoan": "Linked Loan",
  "label.loanListing.salesManager": "Sales Manager",
  "label.loanListing.sales": "Sales",
  "label.loanListing.creditManager": "Credit Manager",
  "label.loanListing.login": "Login",
  "label.loanListing.creditStage": "Credit Stage",
  "label.loanListing.qc": "QC",
  "label.loanListing.dedupe": "Dedupe",
  "label.loanListing.pd": "PD",
  "label.loanListing.technical": "Technical",
  "label.loanListing.legal": "Legal",
  "label.loanListing.rcu": "RCU",
  "label.loanListing.finalApproval": "Final Approval",
  "label.loanListing.financialApproval": "Financial Approval",
  "label.loanListing.inPrincipal": "In-principal Approval",

  // -------------------------------------------- ScoreCard ---------------------------------------------//
  "label.component.text.score": "Score",
  "label.component.text.poor": "Poor",
  "label.component.text.average": "Average",
  "label.component.text.good": "Good",
  "label.component.text.very.good": "Very Good",

  //Cibil details
  "heading.cibilDetails": "CIBIL Detail",
  "heading.cibilDetails.inquiryInfo": "Inquiry Input Information",
  "label.cibilDetails.name": "Name",
  "label.cibilDetails.dob": "DOB",
  "label.cibilDetails.gender": "Gender",
  "label.cibilDetails.father": "Father",
  "label.cibilDetails.spouse": "Spouse",
  "label.cibilDetails.mother": "Mother",
  "label.cibilDetails.phone": "Phone",
  "label.cibilDetails.emailId": "Email ID",
  "label.cibilDetails.id": "Id",
  "label.cibilDetails.Address": "Address",
  "label.cibilDetails.otherAddress": "Other Address",
  "label.cibilDetails.memberName": "Member Name",
  "label.cibilDetails.openedOn": "Opened On",
  "label.cibilDetails.highCrOrSanctioned": "High CR /Sanctioned",
  "label.cibilDetails.accNo": "Acc No",
  "label.cibilDetails.currBalance": "Curr Balance",
  "label.cibilDetails.type": "Type",
  "label.cibilDetails.closedOn": "Closed On",
  "label.cibilDetails.creditLimit": "Credit Limit",
  "label.cibilDetails.ownership": "Ownership",
  "label.cibilDetails.reportedAndCertifiedValue": "Reported and Certified Value",
  "label.cibilDetails.cashLimit": "Cash Limit",
  "label.cibilDetails.collateralValue": "Collateral Value",
  "label.cibilDetails.pmtHistStart": "PMT Hist Start",
  "label.cibilDetails.pmtHistEnd": "PMT Hist End",
  "label.cibilDetails.overdue": "Overdue",
  "label.cibilDetails.collateralType": "Collateral Type",
  "label.cibilDetails.emi": "EMI",
  "label.cibilDetails.pmtFreq": "PMT Freq",
  "label.cibilDetails.repaymentTenure": "Repayment Tenure",
  "label.cibilDetails.interest": "Interest",
  "label.cibilDetails.actualPayment": "Actual Payment",
  "label.cibilDetails.SuitFiledWrittenOffStatus": "Suit filed/Willful/Written off status",
  "label.cibilDetails.writtenOff(Total)Status": "Written off (total) status",
  "label.cibilDetails.settlementStatus": "Settlement status",
  "label.cibilDetails.creditFacilityStatus": "Credit facility status",
  "label.cibilDetails.daysPastDue":
    "Days past due (36 Months)/ Asset Classification (upto 36 Months; Left to right)",
  "label.cibilDetails.std": "STD",
  "heading.cibilDetails.personalInfoVariation": "Personal Information Variation",
  "heading.cibilDetails.personalInfoVariation.identificationVariation": "Identification Variation",
  "heading.cibilDetails.personalInfoVariation.phoneNumberVariation": "Phone Number Variation",
  "heading.cibilDetails.personalInfoVariation.addressVariation": "Address Variation",
  "heading.cibilDetails.personalInfoVariation.emailVariation": "Email ID Variation",
  "label.cibilDetails.personalInfoVariation.thead.identificationType": "Identification type",
  "label.cibilDetails.personalInfoVariation.thead.identificationNumber": "Identification number",
  "label.cibilDetails.personalInfoVariation.thead.issueDate": "Issue date",
  "label.cibilDetails.personalInfoVariation.thead.expirationDate": "Expiration date",
  "label.cibilDetails.personalInfoVariation.thead.telephoneType": "Telephone type",
  "label.cibilDetails.personalInfoVariation.thead.telephoneNumber": "Telephone number",
  "label.cibilDetails.personalInfoVariation.thead.telephoneExtension": "Telephone extension",
  "label.cibilDetails.personalInfoVariation.thead.address": "Address",
  "label.cibilDetails.personalInfoVariation.thead.category": "Category",
  "label.cibilDetails.personalInfoVariation.thead.residenceCode": "Residence Code",
  "label.cibilDetails.personalInfoVariation.thead.dateReported": "Date Reported",
  "label.cibilDetails.personalInfoVariation.thead.variation": "Variation",

  "heading.cibilDetails.accountSummary": "Account Summary",
  "label.cibilDetails.accountType": "Account type",
  "label.cibilDetails.totalAccounts": "Total Accounts",
  "label.cibilDetails.zeroBalanceAccounts": "Zero Balance Accounts",
  "label.cibilDetails.overdueAccounts": "Overdue Accounts",
  "label.cibilDetails.currentBalance": "Current Balance",
  "label.cibilDetails.overdueBalance": "Overdue Balance",
  "label.cibilDetails.amountDsbd/HighCredit": "Amount Dsbd/ High Credit",
  "label.cibilDetails.oldestDateOpened": "Oldest Date Opened",
  "label.cibilDetails.recentDateOpened": "Recent Date Opened",

  "heading.cibilDetails.score": "Score",
  "label.cibilDetails.score.thead.scoreName": "Score Name",
  "label.cibilDetails.score.thead.score": "Score",
  "label.cibilDetails.score.thead.scoringFactor": "Scoring Factor",

  "heading.cibilDetails.employmentDetails": "Employment Details",
  "label.cibilDetails.employmentDetails.thead.accType": "Account Type",
  "label.cibilDetails.employmentDetails.thead.dateReported": "Date Reported",
  "label.cibilDetails.employmentDetails.thead.occCode": "Occupation Code",
  "label.cibilDetails.employmentDetails.thead.income": "Income",
  "label.cibilDetails.employmentDetails.thead.grossIncome": "Net/Gross income indicator",
  "label.cibilDetails.employmentDetails.thead.monthlyAnnualIncome":
    "Monthly/Annual income indicator",

  "heading.cibilDetails.enquirySummary": "Enquiry Summary",
  "label.cibilDetails.enquirySummary.thead.enqPurpose": "Enquiry Purpose",
  "label.cibilDetails.enquirySummary.thead.total": "Total",
  "label.cibilDetails.enquirySummary.thead.total/avg": "Total / Average",
  "label.cibilDetails.enquirySummary.thead.past30Days": "Past 30 Days",
  "label.cibilDetails.enquirySummary.thead.past12Months": "Past 12 Months",
  "label.cibilDetails.enquirySummary.thead.past24Months": "Past 24 Months",
  "label.cibilDetails.enquirySummary.thead.recent": "Recent",

  "heading.cibilDetails.enquiries": " Enquiries",
  "label.cibilDetails.enquiries.thead.member": "Member",
  "label.cibilDetails.enquiries.thead.enquiryDate": "Enquiry Date",
  "label.cibilDetails.enquiries.thead.enquiryPurpose": "Enquiry Purpose",
  "label.cibilDetails.enquiries.thead.enquiryAmount": "Enquiry Amount",

  "heading.headerSegment.headerSegment": "Header Segment",
  "heading.headerSegment.chmRef#": "CHM Ref #",
  "heading.headerSegment.preparedFor": "Prepared For",
  "heading.headerSegment.dateOfRequest": "Date of Request",
  "heading.headerSegment.dateOfIssue": "Date of Issue",
  "heading.headerSegment.applicationId": "Application ID",

  "heading.crifDetails.CRIF": "Credit Information Report (CRIF Highmark) - {{applicantName}}",

  "heading.crifDetails.scoringFactor": " Scoring Factor",
  "label.crifDetails.scoringFactor.point1": "No Minimal recent missed payment",
  "label.crifDetails.scoringFactor.point2":
    "No Proportion of outstanding balance to disbursed amount",
  "label.crifDetails.scoringFactor.point3": "No Minimal Missed Payment in recent past",
  "label.crifDetails.scoringFactor.point4":
    "Decent utilisation of credit limit on self overall revolving account",

  "heading.crifDetails.personalInfoVar": " Personal Information Variation",
  "heading.crifDetails.personalInfoVariation.nameVariation": "Name Variation",
  "heading.crifDetails.personalInfoVariation.dobVariation": "DOB Variation",
  "heading.crifDetails.personalInfoVariation.addressVariation": "Address Variation",
  "heading.crifDetails.personalInfoVariation.idVariation": "ID Variation",
  "heading.crifDetails.personalInfoVariation.emailIDVariation": "Email ID Variation",
  "heading.crifDetails.personalInfoVariation.phoneVariation": "Phone Variation",
  "label.crifDetails.personalInfoVar.thead.variation": "Variation",
  "label.crifDetails.personalInfoVar.thead.reportedOn": "Reported On",

  "heading.crifDetails.score": "Score",
  "label.crifDetails.score.thead.scoreName": "Score Name",
  "label.crifDetails.score.thead.scoreRange": "Score Range",
  "label.crifDetails.score.thead.score": "Score",
  "label.crifDetails.score.thead.scoringFactor": "Scoring Factor",

  "heading.crifDetails.accSummary": "Account Summary",
  "label.crifDetails.accSummary.accountSummaryType": "Account Summary Type",
  "label.crifDetails.accSummary.numberOfAcc": "No of Accounts",
  "label.crifDetails.accSummary.currentBalance": "Current Balance",
  "label.crifDetails.accSummary.enquiryLast24Month": "Inquiries in last 24 Months",
  "label.crifDetails.accSummary.newDelAcc6Months": "New Delinquent Account in last 6 Months",
  "label.crifDetails.accSummary.activeAccounts": "Active Accounts",
  "label.crifDetails.accSummary.overdueAccounts": "Overdue Accounts",
  "label.crifDetails.accSummary.amtDsbdHighCredit": "Amount Dsbd/ High Credit",
  "label.crifDetails.accSummary.newAccLast6Months": "New Account in last 6 months",

  "heading.crifDetails.enquiryByLenders":
    "Inquiry Done By Lenders In (Reported for past 24 Months)",
  "label.crifDetails.enquiryByLenders.thead.lendertype": "Lender Type",
  "label.crifDetails.enquiryByLenders.thead.lendername": "Lender name",
  "label.crifDetails.enquiryByLenders.thead.dateofEnquiry": "Date of Inquiry",
  "label.crifDetails.enquiryByLenders.thead.purpose": "Purpose",
  "label.crifDetails.enquiryByLenders.thead.ownershipType": "Ownership type",
  "label.crifDetails.enquiryByLenders.thead.amt": "Amount",
  "label.crifDetails.enquiryByLenders.thead.remark": "Remark*",

  "heading.crifDetails.comments": "Comments",
  "heading.crifDetails.appendix": "Appendix",

  "label.crifDetails.accountSummary": "Account Summary",
  "label.crifDetails.accountInformationCreditGrantor": "Account Information - Credit Grantor",
  "label.crifDetails.accountInformationAccount#": "Account Information - Account #",
  "label.crifDetails.additionalStatus": "Additional Status",
  "label.crifDetails.inquiryDetails": "Inquiry Details",
  "label.crifDetails.relationshipDetails": "Relationship Details",
  "label.crifDetails.paymentHistory/AssetClassification": "Payment History / Asset Classification",
  "label.crifDetails.crifHighmarkScores": "CRIF HIGH MARK SCORE(S)",

  "label.crifDetails.numberOfDelinquentAccounts": "Number of Delinquent Accounts",
  "label.crifDetails.XXXX": "XXXX",
  "label.crifDetails.xxxx": "xxxx",
  "label.crifDetails.o/s": "O/S",
  "label.crifDetails.pan": "PAN",
  "label.crifDetails.dob": "DOB",
  "label.crifDetails.XXX": "XXX",
  "label.crifDetails.std": "STD",
  "label.crifDetails.sub": "SUB",
  "label.crifDetails.dbt": "DBT",
  "label.crifDetails.los": "LOS",
  "label.crifDetails.sma": "SMA",
  "label.crifDetails.performConsumer": "PERFORM-Consumer",
  "label.crifDetails.ciCeasedMembershipTerminated": "CI-Ceased/Membership Terminated",

  "description.crifDetails.numberOfDelinquentAccounts":
    "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  "description.crifDetails.XXXX":
    "Name of grantor undisclosed as credit grantor is different from inquiring institution",
  "description.crifDetails.xxxx":
    "Account Number undisclosed as credit grantor is different from inquiring institution",
  "description.crifDetails.o/s": "Outstanding",
  "description.crifDetails.pan": "Permanent Account Number",
  "description.crifDetails.dob": "Date of Birth",
  "description.crifDetails.XXX": "Data not reported by institution",
  "description.crifDetails.-": "Not applicable",
  "description.crifDetails.std": "Account Reported as STANDARD Asset",
  "description.crifDetails.sub": "Account Reported as SUB-STANDARD Asset",
  "description.crifDetails.dbt": "Account Reported as DOUBTFUL Asset",
  "description.crifDetails.los": "Account Reported as LOSS Asset",
  "description.crifDetails.sma": "Account Reported as SPECIAL MENTION",
  "description.crifDetails.performConsumer":
    "Score has reckoned from credit history, pursuit of new credit, payment history, type of credit in use and outstanding debt.",
  "description.crifDetails.ciCeasedMembershipTerminated":
    "Credit Institution has Ceased to Operate or Membership Terminated.",

  "label.appendix.section": "Section",
  "heading.appendix.code": "Code",
  "heading.appendix.Description": "Description",

  "label.crifDetails.comments.thead.description": "Description",
  "label.crifDetails.comments.thead.date": "Date",

  "label.crifDetails.account": "Account",
  "label.crifDetails.accountInfo": "Account Information",
  "label.crifDetails.accountType": "Account Type",
  "label.crifDetails.bank": "bank",
  "label.crifDetails.accountNo": "Account No",
  "label.crifDetails.infoAsOf": "Info as of",
  "label.crifDetails.ownerShip": "Ownership",
  "label.crifDetails.disbursedDate": "Disbursed Date",
  "label.crifDetails.amountDsbdOrHighCredit": "Amount Dsbd/ High Credit",
  "label.crifDetails.creditLimit": "Credit Limit",
  "label.crifDetails.lastPaymentDate": "Last Payment Date",
  "label.crifDetails.currentBalance": "Current Balance",
  "label.crifDetails.cashLimit": "Cash Limit",
  "label.crifDetails.closedDate": "Closed Date",
  "label.crifDetails.lastPaidAmount": "Last Paid Amount",
  "label.crifDetails.instaOrAmtOrFreq": "Insta/Amt/Freq",
  "label.crifDetails.tenure(month)": "Tenure (month)",
  "label.crifDetails.paymentHistory": "Payment History",
  "label.crifDetails.overdueAmount": "Overdue Amount",
  "label.crifDetails.creditGrantor": "Credit Grantor",
  "label.crifDetails.creditGrantorType": "Credit Grantor Type",
  "label.crifDetails.accountStatus": "Account Status",
  "label.crifDetails.originalTerm": "Original Term",
  "label.crifDetails.termToMaturity": "Term To Maturity",
  "label.crifDetails.interestRate": "Interest Rate",
  "label.crifDetails.writeOffAmount": "WriteOff Amount",
  "label.crifDetails.settlementAmount": "Settlement Amount",
  "label.crifDetails.jan": "Jan",
  "label.crifDetails.feb": "Feb",
  "label.crifDetails.mar": "Mar",
  "label.crifDetails.apr": "Apr",
  "label.crifDetails.may": "May",
  "label.crifDetails.jun": "Jun",
  "label.crifDetails.jul": "Jul",
  "label.crifDetails.aug": "Aug",
  "label.crifDetails.sept": "Sept",
  "label.crifDetails.oct": "Oct",
  "label.crifDetails.nov": "Nov",
  "label.crifDetails.dec": "Dec",
  "label.crifDetails.linkedAccounts": "Linked Accounts",
  "label.crifDetails.accountNumber": "Account Number",
  "label.crifDetails.amountOverdue": "Amount Overdue",
  "label.crifDetails.collateralDetails": "Collateral Details",
  "label.crifDetails.securityType": "Security Type",
  "label.crifDetails.typeOfCharge": "Type of charge",
  "label.crifDetails.securityValue": "Security Value",
  "label.crifDetails.dateOfValue": "Date of Value",

  "label.heading.loanData": "Loan Data",
  "label.heading.selectLocation": "Select Location",
  "label.heading.searchForLocation": "Search for Location",

  "label.heading.otherDocuments": "Other Documents",

  //Employment Information
  "label.employmentInformation.employmentNature": "Employment Nature*",
  "label.employmentInformation.industry": "Industry*",
  "label.employmentInformation.occupation": "Occupation*",
  "label.employmentInformation.nameOfOrganization": "Name of the Organisation*",
  "label.employmentInformation.yearStartOperation": "Year of Start of Operation*",
  "label.employmentInformation.totalBusinessExperience": "Total Business Experience* (Years)",
  "label.employmentInformation.totalIncome": "Total Income ₹(Monthly)*",
  "label.employmentInformation.totalExpenses": "Total Expenses ₹(Monthly)*",
  "label.employmentInformation.otherIncome": "Other Income ₹(Monthly)*",
  "label.employmentInformation.netIncome": "Net Income ₹(Monthly)*",
  "label.employmentInformation.netIncomeAnnual": "Net Income ₹(Annual)*",
  "label.employmentInformation.businessManagedBy": "Business Managed By*",
  "label.employmentInformation.businessSeasonality": "Business Seasonality*",
  "label.employmentInformation.noOfEmployees": "No of Employees*",
  "label.employmentInformation.employmentAddress": "Employment Address*",
  "label.checkbox.residentialAddress": "Same as applicants residential address",
  "label.checkbox.permanentAddress": "Same as applicants permanent address",

  //Bank Account Information
  "label.bankDetails.bankAccountDetails": "Bank Account Details",
  "label.bankDetails.employeeDetails": "Bank Details",
  "label.bankDetails.accountHolderName": "Account Holder Name*",
  "label.bankDetails.IFSCCode": "IFSC Code*",
  "label.bankDetails.branchName": "Branch Name*",
  "label.bankDetails.bankName": "Bank Name*",
  "label.bankDetails.accountType": "Account Type*",
  "label.bankDetails.accountNumber": "Account Number*",

  //References
  "heading.references": "References",
  "label.reference": "Reference {{listIndex}}",
  "label.references.referenceDetails": "Reference Details",
  "label.references.firstName": "First Name*",
  "label.references.middleName": "Middle Name",
  "label.references.lastName": "Last Name*",
  "label.employmentInformation.mobileNo": "Mobile Number*",
  "label.employmentInformation.relationship": "Relationship with Reference*",
  "label.employmentInformation.referenceAddress": "Reference Address",

  //Historical Financial Details
  "heading.hfd.details": "Historical Financial Details",
  "label.hfd.fy": "Financial Year",
  "label.hfd.turnOver": "Turnover (₹)",
  "label.hfd.profit": "Profit as per P & L (₹)",
  "label.hfd.netWorth": "Capital/Networth (₹)",
  "label.hfd.action": "Action",

  //Investment Information
  "heading.investmentInformation": "Investment Information",
  "label.investmentInfo.assets": "Assets",
  "label.investmentInfo.addAssets": "Add Asset",
  "label.investmentInfo.addDetails": "Add Detail",
  "label.investmentInfo.comments": "Comments on Investments",
  "label.investmentInfo.investProof": "Investment Proof",
  "label.investmentInfo.table.colTitle.amt*": "Amount (₹)*",
  "label.investmentInfo.table.colTitle.amt": "Amount (₹)",
  "label.investmentInfo.table.colTitle.assetType*": "Asset Type*",
  "label.investmentInfo.table.colTitle.assetType": "Asset Type",
  "label.investmentInfo.table.colTitle.action": "Action",

  //Task list in transaction
  "heading.task": "Tasks",
  "label.task.pipeline": "Pipeline",
  "label.task.assignedTo": "Assigned to",
  "label.task.myApplications": "My Applications",
  "label.task.me": "Me",
  "label.task.all": "All",
  "label.task.advancedSearch": "Advanced Search",

  "label.partnerTask.loanApplicationNumber": "Loan Application Number",
  "label.partnerTask.customerName": "Customer Name",
  "label.partnerTask.product": "Product",
  "label.partnerTask.branch": "Branch",
  "label.partnerTask.status": "Status",
  "label.partnerTask.initiatedOnGreaterThan": "Initiated On >=",
  "label.partnerTask.initiatedOnLessThan": "Initiated On <=",
  "label.partnerTask.headingPendingGreaterThan": "Days Pending >=",
  "label.partnerTask.headingPendingLessThan": "Days Pending <=",

  "label.partnerTask.heading": "Partner Task",
  "label.partnerTask.loanApplicationNo": "Loan Application No.",
  "label.partnerTask.stage": "Stage",
  "label.partnerTask.linkedApplication": "Linked Application",
  "label.partnerTask.initiatedOn": "Initiated On",
  "label.partnerTask.initiatedBy": "Initiated By",
  "label.partnerTask.daysPending": "Days Pending",

  //Task
  "label.task.salesManager": "Sales Manager",
  "label.task.creditManager": "Credit Manager",
  "label.task.initiatedOnGreaterThan": "Initiated On >=",
  "label.task.initiatedOnLessThan": "Initiated On <=",
  "label.task.headingPendingGreaterThan": "Days Pending >=",
  "label.task.headingPendingLessThan": "Days Pending <=",
  "label.task.applicationNumber": "Application Number",
  "label.task.stage": "Stage",
  "label.task.stageStatus": "Stage Status",
  "label.task.status": "Status",
  "label.task.loanAmount": "Loan Amount(₹)",
  "label.task.initiatedOn": "Initiated On",
  "label.task.initiatedBy": "Initiated By",
  "label.task.customerName": "Customer Name",
  "label.task.product": "Product",
  "label.task.branch": "Branch",
  "label.task.state": "State",
  "label.task.bank/Financier": "Bank/Financier",
  "label.task.lendingType": "Lending Type",
  "label.task.heading": "Partner Task",
  "label.task.loanApplicationNo": "Loan Application No.",
  "label.task.linkedApplication": "Linked Application",
  "label.task.daysPending": "Days Pending",

  // QC Non Initiated Tasks
  "label.QCInitiated.heading": "QC Non-Initiated Tasks",
  "label.QCInitiated.myApplication": "My Applications",
  "label.QCInitiated.applicationNumber": "Application Number",
  "label.QCInitiated.customerName": "Customer Name",
  "label.QCInitiated.daysPendingGreaterThan": "Days Pending >=",
  "label.QCInitiated.daysPendingLessThan": "Days Pending <=",
  "label.QCInitiated.salesManager": "Sales Manager",
  "label.QCInitiated.salesAgent": "Sales Agent",
  "label.QCInitiated.creditManager": "Credit Manager",
  "label.QCInitiated.loanAmountGreaterThan": "Loan Amount >=",
  "label.QCInitiated.LoanAmountLessThan": "Loan Amount <=",
  "label.QCInitiated.product": "Product",
  "label.QCInitiated.state": "State",
  "label.QCInitiated.LoginDate": "Login Date",
  "label.QCInitiated.branch": "Branch",
  "label.QCInitiated.daysPending": "Days Pending",
  "label.QCInitiated.lendingType": "Lending Type",
  "label.QCInitiated.bank/Financier": "Bank/Financier",

  //DDE Details
  "label.dde.channelType": "Channel Type*",
  "label.dde.channelName": "Channel Name",
  "label.dde.channelNameMandatory": "Channel Name*",
  "label.dde.loanRequestDetails": "Loan Request Details",
  "label.dde.requestHighmarkDetails": "Request CRIF Highmark Details",
  "label.dde.requestCibilScore": "Request CIBIL Score",

  //DDE Applicant
  "heading.dde.creditReport": "Credit Report",
  "label.dde.crifHighmark": "CRIF Highmark",
  "label.dde.noCrifHighmarkMessage": "Insufficient information on the bureau to score the customer",
  "label.dde.noCrifHighmarkMessageHeader": "No Highmark Score Available",
  "label.crifHighmarks.title": "CRIF Highmark Detail",
  "label.crifHighmarks.crifHighmarkDetailsView": "CRIF Highmark Details View",
  "label.dde.cibil": "CIBIL",

  //DDE Applicant
  "label.dde.creditParameters": "Credit Parameters",
  "label.dde.crif": "CRIF",
  "label.dde.cbil": "CIBIL",
  "label.dde.viewDetailedReport": "View Detailed Report",
  "heading.dde.otherDetails": "Other Details",
  "subHeading.dde.permanentAddress": "Permanent Address",
  "label.dde.permanentAddressCheckboxMessage": "Same as residential address",
  "label.dde.emailAddress": "Email Address",
  "label.dde.emailAddress*": "Email Address*",
  "label.dde.martialStatus*": "Martial Status*",
  "label.dde.father/spouseName": "Father/ Spouse Name*",
  "label.dde.noOfDependants": "No. of dependants",
  "label.dde.education*": "Education*",
  "label.dde.institute/University*": "Institute / University *",
  "label.dde.birthPlace*": "Birth Place *",
  "label.dde.religion": "Religion*",
  "label.dde.category*": "Category*",

  "placeholder.dde.emailAddress": "Enter Email Address",
  "placeholder.dde.martialStatus": "Select Status",
  "placeholder.dde.father/spouseName": "Enter Name",
  "placeholder.dde.noOfDependants": "Enter no of dependants",
  "placeholder.dde.education": "Select Education",
  "placeholder.dde.institute/University": "Enter Institute / University",
  "placeholder.dde.birthPlace": "Enter birth place",
  "placeholder.dde.religion": "Select Religion",
  "placeholder.dde.category": "Select Category",

  "heading.dde.employmentInformation": "Employment Information",
  "label.dde.employmentDetails": "Employment Detail {{count}}",
  "label.dde.employeeBasicDetails": "Employee Basic Details",
  "label.dde.add.another.employmentDetail": "Add Another Employment Detail",

  "heading.dde.bankAccount.details": "Bank Account Details",
  "label.dde.bankAccount": "Bank Account {{count}}",
  "label.dde.bankDetails": "Bank Details",
  "label.dde.add.another.bankAccount.details": "Add Another Banking Information",
  "label.dde.employmentDetails.file.proof": "Business Proof / Payslip / ITR Proof",
  "label.dde.businessProof*": "Business Proof / Payslip / ITR Proof *",
  "label.dde.corporate.businessProof*": "Business Proof *",
  "label.dde.bankAccountDetails.file.proof": "Bank Statements *",

  "heading.dde.financial.details": "Financial Details",
  "label.dde.financial.historicalDetails": "Historical Financial Details",
  "label.dde.financial.investmentDetails": "Investment Details",
  "label.dde.financial.comments": "Comments on Investments",

  "heading.dde.documents": "Documents",
  "heading.dde.salesRecommendation": "Sales Recommendation",
  "heading.dde.otherDocuments": "Other Documents",

  // DDE -> Fees
  "heading.dde.fees": "Fees",
  "heading.dde.receipts": "Receipts",
  "heading.dde.receiptsID": "Receipt ID",
  "heading.dde.instrumentType": "Instrument type",
  "placeholder.dde.instrumentType": "Select Instrument Type",
  "heading.dde.instrumentDate": "Instrument Date",
  "heading.dde.chequeTxId": "Cheque/ TX Id",
  "heading.dde.amount": "Amount (₹)",
  "heading.dde.Remarks": "Remarks",
  "heading.dde.updateDate": "Updated Date",
  "heading.dde.status": "Status",
  "heading.dde.balance": "Balance",
  "label.dde.propertyDetails": "Property {{count}}",
  "label.dde.propertySelected": "Property Selected",
  "label.dde.yes": "Yes",
  "label.dde.no": "No",
  "label.dde.basicDetails": "Basic Details",
  "label.dde.bankingDetails": "Banking Details",
  "label.dde.propertyDocuments": "Property Documents *",
  "label.dde.propertyType": "Property Type",
  "heading.dde.fees.receiptDelete": "Are you sure to delete this receipt?",
  "description.dde.fees.receiptDelete": "This action cannot be undone.",

  // dde sales recommendation
  "label.dde.recommendation": "Recommendation {{count}}",
  "input.dde.document.recommendation": "Recommendation*",
  "placeholder.dde.document.recommendation": "Enter recommendation",
  "input.dde.document.recommendation.date": "Date of Recommendation*",
  "input.dde.document.recommended.by": "Recommended by*",
  "heading.dde.propertyDetails": "Property Details",
  "label.dde.propertyAddress": "Property Address",
  "label.dde.addProperty": "Add Another Property",

  // DDE fees
  "label.dde.sharePaymentDetails": "Share Payment Details",
  "label.dde.newReceipts": "New Receipt",
  "label.dde.editReceipts": "Edit Receipt",
  "label.dde.loanApplicationNo": "Loan Application No.*",
  // label.loanListing.applicationName
  "label.dde.instrumentType": "Instrument Type*",
  "label.dde.instrumentDate": "Instrument Date*",
  "label.dde.UTR*": "UTR*",
  "label.dde.ChqNo*": "Chq No.*",
  "label.dde.amount": "Amount (₹)*",
  "label.dde.remark": "Remark",
  "label.dde.sahayyaBankAccount": "Sahayya Bank Account*",
  "label.dde.accountNo": "Account no.",
  // label.loanListing.branch
  "label.dde.receiptsProof*": "Receipts Proof*",
  "label.dde.add.another.sales.recommendation": "Add Another Recommendation",

  "label.dde.documents.storyBoard": "Sales Story Board",
  "label.dde.documents.storyBoard*": "Sales Story Board *",

  // organisation details
  "heading.dde.organisation.details": "Organisation Details",
  "label.dde.organisation.details.basic.information": "Basic Information",
  "label.organisation.details.nameOf.firm": "Name of Firm/Company/Proprietor*",
  "label.organisation.details.constitution.business": "Constitution of Business*",
  "label.organisation.details.website.URL": "Website URL",
  "label.organisation.details.emailId": "Email",
  "label.organisation.details.employment.registration/license.No":
    "Employment Registration/License No.*",
  "label.organisation.details.udyam.registration.certificate.No.mandatory":
    "Udyam Registration Certificate No.*",
  "label.organisation.details.udyam.registration.certificate.No":
    "Udyam Registration Certificate No.",
  "label.organisation.details.incorporation.date": "Incorporation Date*",
  "label.organisation.details.segment": "Segment*",
  "label.organisation.details.noOfEmployees": "No. of Employees*",
  "label.organisation.details.ownership.business": "Ownership of Business Premises*",
  "label.organisation.details.registered.GST": "Whether Registered Under GST",
  "label.organisation.details.registered.GST*": "Whether Registered Under GST*",
  "label.organisation.details.GST.exemption": "GST Exemption",
  "label.organisation.details.GST.exemption.mandatory": "GST Exemption*",
  "label.organisation.details.GST.registration": "GST Registration",
  "label.organisation.details.GST.registration.mandatory": "GST Registration*",
  "label.organisation.details.GSTIN": "GSTIN",
  "label.organisation.details.GSTIN.mandatory": "GSTIN*",
  "label.organisation.details.years.current.business": "Years in Current Business Premises",
  "label.organisation.details.total.work.experience": "Total Work Experience* (Years)",
  "label.organisation.details.years.business.city/area": "Years of Business in Current City/Area*",
  "label.organisation.details.years.city/area": "Years in Current City/Area*",
  "label.organisation.details.factory.branches": "Factory/Branches",
  "label.organisation.details.estimated.gross.margin": "Estimated Gross Margin (%)*",
  "label.organisation.details.net.margin": "Net Margin (%)*",
  "label.dde.otherDetails": "Other Details",
  "label.dde.otherDetails.product.service.editor": "Products/Services Offered",
  "label.dde.otherDetails.raw.material.editor": "Raw Materials",
  "label.dde.otherDetails.business.model.editor": "Detailed Writeup on Business Model*",
  // Access Control->Roles
  "label.accessControl.roles.roles": "Roles",
  "heading.accessControl.role.create": "Roles - Create",
  "heading.accessControl.role.deviceType": "Device Type",
  "heading.accessControl.role.deviceType.mob": "Mobile App",
  "heading.accessControl.role.deviceType.web": "Web App",
  "heading.accessControl.roles.review": "Roles - Review",
  "heading.accessControl.roles.view": "Roles - View",
  "heading.accessControl.roles.edit": "Roles - Edit",

  // residential address
  "label.dde.address.same.GST": "Same as registered GST address",
  "subHeading.dde.red.address.organisation": "Registered Address of Organisation",

  // partner director details
  "heading.dde.partner.director.details": "Partner/Director Details",
  "label.dde.partner.director.detail": "Partner/Director Detail  {{count}}",
  "label.dde.partner.director.kycDetails": "KYC Details",
  "label.dde.partner.director.BasicDetails": "Basic Details",
  "label.dde.add.another.partner.director.details": "Add Another Partner/Director Detail",

  // address
  "label.title.address": "Address",
  "label.text.address.proof": "Address Proof",
  "label.text.address.proof*": "Address Proof *",
  "label.dde.partner.director.remarks": "Remarks",
  "input.text.title.remark": "Remark",
  "label.partner.director.partner.name": "Name of Partner*",
  "label.partner.director.relationship": "Relationship*",
  "label.partner.director.dob": "DOB*",
  "label.partner.director.DIN": "DIN",
  "label.partner.director.aadhar": "Aadhaar*",
  "label.partner.director.pan": "PAN*",
  "label.partner.director.profitSharing": "Profit Sharing (%)",
  "label.partner.director.mobileNo": "Mobile Number*",
  "label.dde.addReceipt": "Add Receipt",
  "label.dde.bankAccountDetails": "Bank and Account Details",

  "label.loan.cancelCreation": "Cancel Loan Creation ?",
  "description.loan.cancelCreation": "Data will not be saved if you cancel the process.",

  // stages - stage and status
  "heading.transaction.stages": "Stages",
  "label.stages.stage": "Stage",
  "label.stages.current.status": "Current Status",
  "label.stages.initiated.by": "Initiated by",
  "label.stages.assigned.to": "Assigned to",
  "label.stages.delegated.to": "Delegated to",
  "label.stages.external.vendor": "External Vendor",
  "label.stages.completed.by": "Completed By",
  "label.stages.initiated.on": "Initiated On",
  "label.stages.completed.on": "Completed On",
  "label.stages.actions": "Actions",
  "label.stages.documents.verification": "Document Verification",
  "label.stages.documents.verification*": "Document Verification*",
  "label.stages.documents": "Documents",
  "label.stages.delegate.delegateTo": "Delegate to",
  "label.stages.delegate.delegateTo*": "Delegate to*",

  "heading.transaction.stage.history": "Stage History",
  "label.stages.history.stage": "Stage",
  "label.stages.history.status": "Status",
  "label.stages.history.initiated.by": "Initiated by",
  "label.stages.history.assigned.to": "Assigned to",
  "label.stages.history.delegated.to": "Delegated to",
  "label.stages.history.external.vendor": "External Vendor",
  "label.stages.history.action.date": "Action Date",
  "label.stages.history.description": "Description",

  "heading.stages.initiate.new.stage": "Initiate New Stage",
  "input.text.title.description": "Description",
  "label.text.stage.initiate.stage": "Stage*",
  "label.text.stage.initiate.RCU.type": "RCU Type*",
  "label.text.stage.initiate.vendor.type": "Vendor Type*",
  "label.text.stage.initiate.legal.type": "",
  "label.text.stage.initiate.PD.type": "Type of PD*",
  "label.text.stage.initiate.assign.to*": "Assign to*",
  "label.text.stage.initiate.delegate": "Delegate",
  "label.text.stage.initiate.cc": "CC",
  "label.text.stage.initiate.internal.assignee": "Internal Assignee*",
  "label.text.stage.initiate.external.vendor": "External Vendor",
  "label.text.stage.initiate.internal.vendor": "Internal Vendor",

  "input.stage.initiate.meeting.date": "Meeting date and time*",
  "input.stage.initiate.mobile": "Guests Mobile Number*",
  "label.stages.initiate.select": "Select",
  "label.stages.initiate.applicantType": "Applicant Type",
  "label.stages.initiate.addressType": "Address Type",
  "label.stages.initiate.propertyType": "Property Type",
  "placeholder.stages.initiate.addressType": "Select Address Type",
  "label.stages.initiate.address": "Address",
  "label.stages.initiate.pincode": "Pincode",
  "label.stages.initiate.city": "City",
  "label.stages.initiate.state": "State",
  "label.stages.initiate.owner.name": "Owners Name",
  "label.stages.initiate.customer.type": "Customer type",
  "label.stages.initiate.customer.name": "Customer name",

  "label.table.heading.stage.profile.check": "Profile Check",
  "label.table.heading.stage.properties.evaluation": "Properties for Evaluation (select only one)*",
  "label.table.heading.stage.property.details": "Property Details*",
  "label.table.heading.stage.customer.selection": "Customer Selection (Audio/video not included)",
  "label.heading.stage.re-appeal": "Re-Appeal Loan Application",
  "label.text.stage.assigned.to": "Assigned to*",
  "label.text.stage.assign.to*": "Assign to*",
  "label.text.stage.delegate.to": "Delegate to",
  "label.text.reason.to.re-appeal": "Reason to Re-appeal*",
  "label.text.rejectLoanApplication": "Reject Loan Application",
  "label.text.reasonToReject*": "Reason to Reject*",
  "label.text.description*": "Description",
  "label.text.descriptionMandatory": "Description*",

  // DDE QC
  "input.qc.status": "Status*",
  "input.qc.remark": "Remark",
  "header.qc.qualityCheck": "Quality Checklist",
  "label.bulletPoint": "\u2022",
  "description.qc.qualityCheck":
    "Please check all the below items for Applicant, Co-Applicant, Guarantor and Dependent",
  "label.qc.basicDetails": "Basic Details",
  "label.qc.referenceDetails": "Reference Details",
  "label.qc.otherDocuments": "Other Documents",
  "label.qc.collateralPropertyDetails": "Collateral Property Details",
  "label.qc.personalDetails": "Personal Details",
  "label.qc.employmentDetails": "Employment Details",
  "label.qc.bankingAnalysis": "Banking Analysis",
  "label.qc.sourcingDetails": "Sourcing Details",
  "label.qc.bankAccountDetails": "Bank Account Details",
  "label.qc.existingLoans": "Existing Loans",
  "label.qc.incomeAndExpenseAnalysis": "Income and Expense Analysis",
  "label.qc.storyBoard": "Story Board",
  "label.qc.assetsAndDocument": "Asset/ Document Checks",
  "label.qc.DocumentName": "{{documentName}}",
  "label.qc.initiateApiCheck": "Initiate API Check",
  "heading.qc.rawApiResponse": "{{documentName}} - Raw API Response",
  "label.qc.jsonResponse": "Response (JSON)",

  //DDE Credit Query
  "header.cq.creditQuery": "Credit / Query",
  "label.cq.viewDetails": "View Details",
  "label.cq.queryListing": "Query Listing",
  "label.cq.stage": "Stage",
  "label.cq.query": "Query",
  "label.cq.raisedOn": "Raised On",
  "label.cq.updatedOn": "Updated On",
  "label.cq.raisedBy": "Raised By",
  "label.cq.updatedBy": "Updated By",
  "label.cq.respondedOn": "Responded On",
  "label.cq.respondedBy": "Responded By",
  "label.cq.status": "Status",
  "label.cq.action": "Action",
  "label.cq.responseToQuery": "Response to Query",
  "label.cq.reply": "Reply",
  "label.cq.submitQueries": "Submit Queries",

  // existing document uploader - component
  "label.heading.upload.existing.file": "Upload Existing File",
  "label.text.search.document.category": "Search for Document Category",
  "label.existing.doc.preview": "Preview",
  "label.existing.doc.category": "Doc. Category",
  "label.existing.doc.customerType": "Customer Type",
  "label.existing.doc.customerName": "Customer Name",

  "label.existing.doc.type": "Doc. Type",
  "label.existing.doc.section": "Doc. Section",
  "label.existing.doc.file.name": "File name",
  "label.existing.doc.stage.section": "Stage & Section",
  "label.existing.doc.uploaded.on": "Uploaded On",
  "label.existing.doc.action": "Action",

  "label.existing.document.category": "Document Category",
  "label.existing.document.type": "Document Type",
  "label.existing.document.section": "Document Section",
  "label.existing.document.action": "Action",

  // initiation info
  "label.initiationInfo.date.of.initiation": "Date of Initiation",
  "placeholder.initiationInfo.date.of.initiation": "dd-mmm-yyyy",
  "label.initiationInfo.stage": "Stage",
  "label.initiationInfo.vendorType": "Vendor Type",
  "label.initiationInfo.external.vendor": "External Vendor",
  "label.initiationInfo.internal.assignee": "Internal Assignee",
  "label.initiationInfo.assign.to": "Assign to*",
  "label.initiationInfo.PD.type": "Type of PD*",
  "label.initiationInfo.cc": "CC",
  "label.initiationInfo.description": "Description",
  "placeholder.initiationInfo.description": "Enter Description here",
  "label.initiationInfo.property.details": "Property Details",

  // family or household details
  "label.family.type.mandatory": "Family Type*",
  "label.family.type": "Family Type",
  "label.family.no.household.members": "No. of Household Members",
  "label.family.no.household.members.mandatory": "No. of Household Members*",
  "label.family.no.dependants.member.mandatory": "No. of Dependant Members*",
  "label.family.no.dependants.member": "No. of Dependant Members",
  "label.family.ownership.current.residence": "Ownership of current residence",
  "label.family.ownership.current.residence.mandatory": "Ownership of current residence*",
  "label.family.area.current.residence": "Area of Current Residence (sqft)",
  "label.family.area.current.residence.mandatory": "Area of Current Residence (sqft)*",
  "label.family.years.current.city": "No. of years in current city",
  "label.family.years.current.city.mandatory": "No. of years in current city*",
  "label.family.details.neighbour": "Details on neighbourhood",
  "label.family.details.neighbour.mandatory": "Details on neighbourhood (min 300 characters)*",
  "label.family.storyboard": "Storyboard (min 300 characters)",
  "label.family.storyboard.mandatory": "Storyboard (min 300 characters)*",

  // pd details
  "label.pd.type.applicant.mandatory": "Type of Applicant*",
  "label.pd.customer.name.mandatory": "Customer Name*",
  "label.pd.place.of.visit.mandatory": "Place of Visit*",
  "label.pd.date.of.pd.mandatory": "Date of PD*",
  "label.pd.ITR.availability.mandatory": "ITR Availability*",

  // employment details
  "title.employment.details": "Employment Details {{count}}",

  "label.employment.details.employment.type": "Employment Type*",
  "label.employment.details.industry": "Industry*",
  "label.employment.details.core.business.category": "Core Business Category*",
  "label.employment.details.business.type": "Business Type*",

  "label.employment.details.salaried.employee.details": "Salaried Employee Details",
  "label.employment.details.name.of.organization": "Name of Organization*",
  "label.employment.details.total.year.of.experience": "Total year of experience*",
  "label.employment.details.date.of.joining": "Date of joining*",
  "placeholder.employment.details.date.of.joining": "dd-mmm-yyyy",
  "label.employment.details.years.in.current.employment": "Years in Current Employment*",
  "label.employment.details.number.of.employees": "Number of Employees*",
  "label.employment.details.constitution.of.business": "Constitution of Business*",
  "label.employment.details.designation": "Designation*",
  "label.employment.details.gross.monthly.salary": "Gross Monthly Salary (₹)*",
  "label.employment.details.monthly.deductions": "Monthly Deductions (₹)*",
  "label.employment.details.monthly.net.salary": "Monthly Net Salary (₹)*",
  "label.employment.details.annual.bonus": "Annual Bonus (₹)*",
  "label.employment.details.performance.linked.incentive": "Performance Linked - Incentive (₹)*",

  "label.employment.details.self.employed.details": "Self Employed Details",
  "label.employment.details.name.of.establishment": "Name of Establishment*",
  "label.employment.details.ownership.of.business.premises": "Ownership of Business Premises*",
  "label.employment.details.years.in.current.business": "Years in current Business*",
  "label.employment.details.total.work.experience": "Total Work Experience (years)*",
  "label.employment.details.business.in.current.location": "Business in current location (years)*",
  "label.employment.details.business.managed.by": "Business Managed by*",
  "label.employment.details.resi.cum.office": "Resi cum office*",
  "label.employment.details.size.of.business.premises": "Size of the business premises (sqft)*",
  "label.employment.details.business.location": "Business Location*",
  "label.employment.details.type.of.structure": "Type of Structure*",
  "label.employment.details.no.of.employees.seen.at.the.time.of.visit":
    "No of Employees seen at the time of visit",
  "label.employment.details.no.of.days.operational": "No. of days operational (monthly)*",
  "label.employment.details.business.seasonality": "Business Seasonality*",
  "label.employment.details.first.generation.business": "First Generation Business*",
  "label.employment.details.amount.of.salary.paid.employees": "Amount of salary paid to employees*",
  "label.employment.details.credit.periods.creditor.cycle": "Credit Periods / Creditor Cycle*",
  "label.employment.details.product.or.services.offered": "Product or Services Offered*",
  "label.employment.details.raw.materials": "Raw Materials*",
  "label.employment.details.average.daily.sales": "Average Daily Sales (₹)*",
  "label.employment.details.average.monthly.sales.last.six.months":
    "Average Monthly Sales (Last 6 mnth) (₹)*",
  "label.employment.details.highest.monthly.sales.last.six.months":
    "Highest Monthly Sales (Last 6 mnth) (₹)*",
  "label.employment.details.lowest.monthly.sales.last.six.months":
    "Lowest Monthly Sales (Last 6 mnth) (₹)*",
  "label.employment.details.avg..monthly.purchase.last.six.months":
    "Avg. Monthly Purchase (Last 6 mnth) (₹)*",
  "label.employment.details.average.expense": "Average Expense (₹)*",
  "label.employment.details.estimate.gross.margin": "Estimate Gross Margin (%)*",
  "label.employment.details.net.margin": "Net Margin (%)*",
  "label.employment.details.stock.seen.at.time.of.visit": "Stock seen at the time of visit*",
  "label.employment.details.approach.road": "Approach Road*",
  "label.employment.details.assets.seen.in.business.premises":
    "Assets seen in the Business Premises*",
  "label.employment.details.comments.interior/exterior.of.business":
    "Comments on the interior/exterior of the business*",

  "label.employment.details.lessee.details": "Lessee Details",
  "label.employment.details.tenant.name": "Tenant Name*",
  "label.employment.details.mobile.number": "Mobile Number*",
  "label.employment.details.property.owned.by": "Property owned by*",
  "label.employment.details.type.of.structures": "Type of structures*",
  "label.employment.details.type.of.lease.or.tenant": "Type of lease or tenant*",
  "label.employment.details.area.of.property": "Area of property (sqft)*",
  "label.employment.details.rent.amount.agreement": "Rent Amount (₹) as per agreement*",
  "label.employment.details.rent.paid.since.in.months": "Rent paid since (in months)",
  "label.employment.details.rent.agreement": "Rent Agreement*",
  "label.employment.details.rental.agreement.date": "Rental Agreement Date*",
  "placeholder.employment.details.rental.agreement.date": "dd-mmm-yyyy hh:mm:ss",
  "label.employment.details.rental.start.date": "Rental Start Date",
  "placeholder.employment.details.rental.start.date": "dd-mmm-yyyy hh:mm:ss",
  "label.employment.details.rent.mode": "Rent Mode*",
  "label.employment.details.rentReceiptBank.name": "Bank Name*",
  "label.employment.details.rentReceiptBank.account.number": "Bank Account Number*",
  "label.employment.details.amount.collected.last.one.month": "Amount Collected (last one  month)*",
  "label.employment.details.amount.collected.second.last.month":
    "Amount Collected (second last month)*",
  "label.employment.details.amount.collected.third.last.month":
    "Amount Collected (third last month)*",
  "label.employment.details.tenure.of.rent.in.months": "Tenure of Rent (in months)*",
  "label.employment.details.tenure.of.rent.in.months.placeholder": "Enter Tenure In Months",
  "label.employment.details.escalation": "Escalation in %*",
  "label.employment.details.remark": "Remark*",

  "label.radioButton.yes": "Yes",
  "label.radioButton.no": "No",

  "label.document.salary.proof": "Salary Proof*",
  "label.document.recordings.and.documents": "Recordings and Documents*",
  "label.document.recordings.and.documentsNotMandatory": "Recordings and Documents",
  "label.document.business.proof": "Business Proof*",
  "label.document.business.proofNotMandatory": "Business Proof",

  // reference details
  "title.reference.details": "Reference Details {{count}}",
  "label.reference.details.name": "Reference Name*",
  "label.reference.details.known.how.many.years": "Known for how many years*",
  "label.reference.details.mobile.number": "Mobile Number*",
  "label.reference.details.nature.of.relationship": "Nature of relationship",
  "label.dde.add.another.referenceDetail": "Add Another Reference details",

  // other income
  "label.other.income.total.rental.income": "Total Monthly Rental Income (₹)*",
  "label.other.income.agricultural.income": "Monthly Agricultural Income (₹)*",
  "label.other.income.FD.interest": "Other Monthly Income - FD Interest/ Dividend (₹)*",

  // repayment history
  "label.repayment.history.customer.type": "Customer Type*",
  "label.repayment.history.customer.name": "Customer Name*",
  "label.repayment.history.financier.type": "Financier Type*",
  "label.repayment.history.purpose": "Purpose*",
  "label.repayment.history.name.of.institution": "Name of Institution*",
  "label.repayment.history.sanction.amount": "Sanction Amount(₹)*",
  "label.repayment.history.outstanding.amount": "Outstanding Amount(₹)*",
  "label.repayment.history.balance.tenor": "Balance Tenor (Months)*",
  "label.repayment.history.emi": "EMI (₹)*",
  "label.repayment.history.past.defaults": "Past Defaults*",
  "label.repayment.history.sanction.tenor": "Sanction Tenor (Months)*",
  "label.repayment.history.no.of.bounces": "No. Of Bounces*",
  "label.repayment.history.roi": "ROI (%)*",
  "label.repayment.history.actions": "Actions",

  // legal
  "heading.legal.legalReport": "Legal Report",
  "description.legal.info":
    "To complete a report fill up all sections marked with *. Once shared, only the Sahayya representative can reopen it for changes. To communicate with them use the [chat icon] chat on the bottom left. To share new documents use the 'Documents Exchange' tab at the top of the screen.",
  "header.legal.initiationInfo": "Initiation Info",
  "label.legal.dateOfInitiation": "Date of Initiation",
  "label.legal.vendorType": "Vendor Type",
  "label.legal.externalVender": "External Vendor",
  "label.legal.internalAssignee": "Internal Assignee",
  "label.legal.cc": "CC",
  "placeholder.legal.description": "Enter Description here",
  "label.legal.documentVerification": "Document Verification",
  "label.legal.loanSummary": "Loan Summary",
  "label.legal.loanNoMandatory": "Loan No.*",
  "label.legal.agencyMandatory": "Agency*",
  "label.legal.dateOfReporting": "Date of Reporting*",
  "header.legal.propertyDetails": "Property Details",
  "label.legal.typeOfLoanMandatory": "Type of Loan*",
  "placeholder.legal.typeOfLoanMandatory": "Select Type Of Loan",
  "label.legal.natureOfTransactionMandatory": "Nature of Transaction*",
  "placeholder.legal.natureOfTransactionMandatory": "Select Nature Of Transaction*",
  "label.legal.subRegistrarOffice": "Sub Registrar Office*",
  "label.legal.nameOfPresentOwnerMandatory": "Name of Present Owner*",
  "label.legal.areaOfPropertyMandatory": "Area of property (as per document)*",
  "label.legal.unitsMandatory": "Units*",
  "placeholder.legal.unitsMandatory": "Select Units",
  "label.legal.typeOfMortgage*": "Type of Mortgage*",
  "placeholder.legal.typeOfMortgage*": "Select Type Of Mortgage",
  "label.legal.natureOfPropertyMandatory": "Nature of Property*",
  "placeholder.legal.natureOfPropertyMandatory": "Select Nature Of Property",
  "Heading.legal.PropertyAddressAsDocument": "Property Address as per document",
  "label.legal.boundaryDetails": "Boundary details",
  "label.legal.eastBy*": "East By*",
  "label.legal.westBy*": "West By*",
  "label.legal.northBy*": "North By*",
  "label.legal.southBy*": "South By*",
  "heading.legal.descriptionOfDocument*": "Description Of The Documents Scrutinised*",
  "label.legal.dateOfDocument*": "Date of Document*",
  "label.legal.dateOfDocument": "Date of Document",
  "label.legal.nameOfDocument*": "Name of Document*",
  "label.legal.documentCopyType*": "Document Copy Type*",
  "label.legal.preDisbursementCollection*": "Pre Disbursement Collection",
  "label.legal.postDisbursementCollection*": "Post Disbursement Collection",
  "label.legal.action*": "Action",
  "label.legal.flowOfProperty": "Flow Of Title Of Property/Observation*",
  "heading.legal.flowOfProperty": "Flow Of Title Of Property/Observation*",
  "heading.legal.titleSearch*": "Title Search*",
  "heading.legal.finalOpinion*": "Final Opinion*",
  "label.legal.status*": "Status*",
  "placeholder.legal.status*": "Select Status",
  "label.legal.remarks*": "Remarks*",
  "label.legal.certificate/Opinion*": "Certificate / Opinion*",
  "heading.legal.otherProvisions*": "Other Provisions*",
  "label.legal.otherProvision.question1":
    "Whether provisions of Urban Land Ceiling Act are applicable?",
  "label.legal.otherProvision.question2":
    "Whether property to be given as security is subject to any minor’s claim / Share ?",
  "label.legal.otherProvision.question3":
    "Whether the property is affected by any revenue and tenancy regulations?",
  "label.legal.otherProvision.question4":
    "Whether the user land has been converted under Land Revenue Law?",
  "label.legal.otherProvision.question5":
    "Whether residential E Khatha is issued by the competent authority ?",
  "label.legal.otherProvision.question6":
    "Whether all original chain documents of the property have been scrutinized?",
  "label.legal.otherProvision.question7":
    "Whether required documents are available for creating mortgage?",
  "label.legal.otherProvision.question8":
    "Whether all previous owners had the right/competency to transfer the property?",
  "label.legal.otherProvision.question9":
    "Whether proposed mortgage by deposit of title deeds is possible?",
  "label.legal.otherProvision.question10":
    "Whether the property is leased and, If yes, then what is the tenure of the lease?",
  "label.legal.otherProvision.question11": "Whether the land/property is joint family property?",
  "label.legal.otherProvision.question12":
    "Is the land/property/flat is subject to any reservation/acquisition/requisition?",
  "label.legal.otherProvision.question13":
    "Whether provisions of Urban Land Ceiling Act are applicable?",
  "placeholder.legal.documentCopyType": "Select Document Copy Type",
  "label.legal.addRow": "Add Row",
  "heading.legal.documentsPriorToDisbursement":
    "Documents Required To Be Collected Prior To Disbursement",
  "heading.legal.documentsPostToDisbursement":
    "Documents Required To Be Collected Post To Disbursement",
  "heading.legal.uploadLegalReport": "Upload Legal Report",
  "heading.legal.legalDocumentExchange": "Legal Document Exchange",
  "label.legal.files": "Files",
  "label.legal.deviations": "Deviations",
  "label.legal.add": "Add",
  "label.legal.legalDeviation": "Legal Deviations",
  "label.legal.deviationType*": "Type of Deviation*",
  "placeholder.legal.deviationType": "Select Deviation Type",
  "label.legal.deviation*": "Deviation*",
  "placeholder.legal.deviation": "Select Deviation Name",
  "label.legal.populateAutoDeviations": "Populate Auto Deviations",
  "label.legal.addDocument": "Add Document",
  "label.legal.addInsurance": "Add Insurance",
  "label.legal.actualDeviation*": "Actual Deviation*",
  "label.legal.mitigants*": "Mitigants*",

  //rcu
  "heading.rcu.rcuCompletion": "RCU Completion",
  "heading.rcu.initiationInfo": "Initiation Info",
  "heading.rcu.loanSummary": "Loan Summary",
  "heading.rcu.applicantDetails": "Applicant Details",
  "heading.rcu.rcuStatus": "RCU Status",
  "heading.rcu.profileCheck": "Profile Check",
  "description.rcu.info":
    "General Information required for the entire page. It can be accommodated here. General Information required for the entire page. It can be accommodated here. General Information required for the entire page. It can be accommodated here.",

  "label.rcu.rcuType": "RCU Type",
  "label.rcu.addedDocs.status*": "Status*",
  "label.rcu.dateOfInitiation": "Date of Initiation",
  "label.rcu.externalVendor": "External Vendor",
  "label.rcu.internalAssignee": "Internal Assignee",
  "label.rcu.cc": "CC",
  "label.rcu.initiation.addressType": "Address Type",
  "label.rcu.loanNoMandatory": "Loan No.*",
  "label.rcu.salesManager": "Sales Manager",
  "label.rcu.creditManager": "Credit Manager",
  "label.rcu.agencyMandatory": "Agency*",
  "label.rcu.dedupeCheck": "Dedupe Check*",
  "label.rcu.comments": "Provide comments, If ‘Yes’",
  "label.rcu.dateOfReporting": "Date of Reporting/Sampling*",
  "label.rcu.rcuStatus": "Status*",
  "label.rcu.rcuStatusRemarks": "Overall Remarks*",
  "placeholder.rcu.description": "Enter Description here",
  "label.rcu.rcuDeviations": "RCU Deviations",
  "label.rcu.rcuDocumentExchange": "RCU Document Exchange",
  "label.rcu.firstName": "First Name",
  "label.rcu.middleName": "Middle Name",
  "label.rcu.lastName": "Last Name",
  "label.rcu.coApplicantsDetails": "Co-Applicants Details",
  // label.heading.residential.address
  "label.rcu.nameOfOrganisation/Establishment": "Name of Organisation/Establishment",
  "label.rcu.employmentAddress": "Employment Address",
  "label.rcu.employmentDetails": "Employment Details",
  "label.rcu.collateralPropertyAddress": "Collateral Property Address",
  "label.rcu.documentVerification": "Document Verification",
  // label.existing.doc.file.name
  "label.rcu.documentType": "Document Type",
  "label.rcu.documentType*": "Document Type*",
  "label.rcu.documentType.uploadReport": "Document Type*",
  "label.rcu.customer": "Customer",
  "label.rcu.viewFile": "View File",
  "label.rcu.verificationStatus": "Verification Status*",
  // label.crifDetails.enquiryByLenders.thead.remark
  "label.rcu.additionalDocumentsIfAny": "Additional Documents (if any)",
  "label.rcu.dateOfUpload": "Date of Upload",
  "label.rcu.profileVerification*": "Profile Verification*",
  "label.rcu.uploadRCUReport": "Upload RCU Report",
  "label.rcu.profile": "Profile",
  "label.rcu.addedDocuments": "Added Documents",
  //"label.loanListing.actions"

  // Technical  Report
  "heading.technical.technicalReport": "Technical Report",
  "heading.technical.TechnicalDocumentExchange": "Technical Document Exchange",
  "heading.technical.technicalDeviations": "Technical Deviations",
  "heading.technical.generalDetails": "General Details",
  "label.technical.dateOfVisit*": "Date of Visit*",
  "label.technical.typeOfCase*": "Type of case*",
  "label.technical.ownershipName*": "Ownership Name*",
  "label.technical.addressAsPerActualSite": "Address as per actual site*",
  "heading.technical.documentDetails": "Document Details",
  "label.technical.layoutPlanProvided*": "Layout Plan Provided*",
  "label.technical.approvingAuthority": "Approving Authority",
  "label.technical.approvalNo.": "Approval No.",
  "label.technical.buildingConstructionAsPerPlanned*": "Building Construction as Per Planned*",
  "label.technical.planProvided*": "Plan Provided*",
  "label.technical.addressType": "Address Type",

  "label.technical.holdingType*": "Holding Type*",
  "placeholder.technical.holdingType": "Select Holding Type",
  "heading.technical.surroundingLocalityDetails": "Surrounding Locality Details",
  "heading.technical.propertyDetails": "Property Details",
  "label.technical.classOfLocality*": "Class of Locality*",
  "placeholder.technical.classOfLocality": "Select Class of Locality",
  "label.technical.distanceFromCityCenter*": "Distance from City Center (in km)*",
  "label.technical.nearbyBusStop*": "Nearby Bus Stop (in km)*",
  "label.technical.developmentInTheVicinity": "Development in the vicinity (%)*",
  "label.technical.boundariesOnSite*": "Boundaries On Site*",
  "label.technical.direction": "Direction",
  "label.technical.asPerDoc": "As Per Doc",
  "label.technical.actualOnSite": "Actual On Site",
  "label.technical.east": "East",
  "label.technical.west": "West",
  "label.technical.north": "North",
  "label.technical.south": "South",

  "label.technical.boundariesMatching?*": "Boundaries Matching?*",
  "label.technical.landUse*": "Land Use*",

  "label.technical.propertyDescription(G+2,G+3..)*": "Property Description (G+2, G+3..)*",
  "label.technical.noOfFloors*": "No. of Floors*",
  "label.technical.noOfLifts*": "No. of Lifts*",
  "label.technical.noOfFlats/Floor*": "No. of Flats / Floor*",
  "label.technical.noOfWings*": "No. of Wings*",
  "label.technical.structureDescription*": "Structure Description*",
  "label.technical.conditionOfProperty*": "Condition of Property*",
  "label.technical.yearOfConstruction*": "Year of Construction*",
  "label.technical.AgeOfTheProperty*": "Age of the Property*",
  "label.technical.approachRoadType*": "Approach Road Type*",
  "label.technical.Kachha": "Kachha",
  "label.technical.pakka": "Pakka",
  "label.technical.approachRoadWidth(ft)*": "Approach Road Width (ft)*",
  "label.technical.propertySituatedNearHighTensionLine*":
    "Property situated near High Tension Line (HT)*",
  "label.technical.distance(ft)*": "Distance (ft)*",
  "label.technical.marketFeedback*":
    "Market Feedback (similar properties on 99 acres, magicbricks, broker name, number)*",
  "label.technical.marketability*": "Marketability*",
  "label.technical.buildingParameters": "Building Parameters",
  "label.technical.planAsPerDCRNorms*": "Plan as per DCR norms*",
  "label.technical.numberOfFloorsPermitted*": "Number of floors Permitted*",
  "label.technical.fsiAchieved*": "FSI achieved*",
  "label.technical.riskOfDemolition*": "Risk of demolition*",
  "label.technical.setbackDeviation(%)*": "Setback deviation (%)*",
  "label.technical.horizontalDeviation(%)*": "Horizontal Deviation (%)*",
  "label.technical.valuation(AsPerActual)*": "Valuation (As Per Actual)*",
  "label.technical.propertyValuation": "Property Valuation",
  "label.technical.areaType": "Area Type",
  "label.technical.constructionType": "constructionType",
  "label.technical.asPerPlan(InSqft)": "As per plan (in sqft)",
  "label.technical.asPerSite(InSqft)": "As per site (in sqft)",
  "label.technical.deviationIn%": "Deviation in %",
  "label.technical.rate/Sqft": "Rate/Sqft",
  "heading.technical.amenities": "Amenities",
  "label.technical.%Completed": "% Completed",
  "label.technical.%Recommended": "% Recommended",
  "label.technical.valuation(₹)": "Valuation (₹)",
  "label.technical.fairMarketValue(₹)": "Fair Market Value (₹)",
  "label.technical.totalPropertyValueOn100%Completion(₹)*":
    "Total Property value on 100% completion (₹)*",
  "label.technical.forcedSaleValue(₹)At75%*": "Forced sale value (₹) at 75%*",
  "heading.technical.stage.construction": "Stage of Construction - If Applicable (Builder Case)",
  "label.technical.descriptionOfStage*": "Description of Stage*",
  "label.technical.%Technical*": "% Technical*",
  "label.technical.%Recommended*": "% Recommended*",
  "label.technical.governmentGuidelines": "Government Guidelines (all values per Govt rate)",
  "label.technical.circleRateOfLand": "Circle Rate of Land (₹/Sqft)*",
  "label.technical.circleRateOfBuilding": "Circle rate of building (₹/Sqft)*",
  "label.technical.landValue(₹)": "Land value  (₹)*",
  "label.technical.totalValuationOfProperty*": "Total valuation of property*",
  "label.technical.flat/AptValue(₹)*": "Flat/Apt value  (₹)*",
  "heading.technical.occupancyDetails": "Occupancy Details",
  "label.technical.statusOfOccupancy*": "Status of Occupancy*",
  "label.technical.occupiedByAndRelationshipWithCustomer*":
    "Occupied By and Relationship with customer*",
  "label.technical.occupiedSince*": "Occupied Since*",
  "label.technical.personAvailableAtSite*": "Person available at Site*",
  "heading.technical.propertyPhotos": "Property Photos",
  "label.technical.technicalStatus": "Technical Status",
  "label.technical.overallRemarks*": "Overall Remarks*",
  "heading.technical.declaration*": "Declaration*",
  "label.technical.declaration.pont1Part1": "1. Our engineer",
  "label.technical.declaration.pont1Part2":
    "personally inspected in the presence of the owner of the property.",
  "label.technical.declaration.pont2":
    "2. The information furnished in my valuation report is true and correct to the best of my knowledge and belief and I have made an impartial and true valuation of the property.",
  "label.technical.declaration.pont3":
    "3. I have no direct or indirect interest in the property valued.",
  "label.technical.declaration.pont4":
    "4. This is to certify that the property of the present Market value of the property is worth the property is situated in commercial area. This will help the Bank to enforce the Security through SARFAESI Act in case of need",
  "heading.technical.uploadTechnicalReport": "Upload Technical Report",
  "heading.technical.property.photos": "Property Photos",

  "heading.technical.status": "Technical Status",
  "label.technical.status.mandatory": "Status*",
  "label.technical.overall.remarks*": "Overall Remarks*",
  "label.technical.declaration": "Declaration*",
  "label.technical.declaration.content1": "Our engineer",
  "label.technical.declaration.content2":
    "personally inspected in the presence of the owner of the property.",
  "label.technical.declaration.content3":
    "The information furnished in my valuation report is true and correct to the best of my knowledge and belief and I have made an impartial and true valuation of the property.",
  "label.technical.declaration.content4":
    "I have no direct or indirect interest in the property valued.",
  "label.technical.declaration.content5":
    "This is to certify that the property of the present Market value of the property is worth the property is situated in commercial area. This will help the Bank to enforce the Security through SARFAESI Act in case of need",

  "heading.technical.upload.technical.report": "Upload Technical Report",

  "heading.technical.OccupancyDetails": "Occupancy Details",
  "label.technical.OccupancyDetails.stateOfOccupancy*": "Status of Occupancy*",
  "label.technical.OccupancyDetails.occupiedBy*": "Occupied By and Relationship with customer*",
  "label.technical.OccupancyDetails.occupiedSince*": "Occupied Since (in years)*",
  "label.technical.OccupancyDetails.personAtSite*": "Person available at Site*",

  "heading.technical.governmentGuideLines": "Government Guidelines (all values per Govt rate)",
  "label.technical.governmentGuideLines.circleRateOfLand*": "Circle Rate of Land (₹/Sqft)*",
  "label.technical.governmentGuideLines.circleRateOfBuilding*": "Circle rate of building (₹/Sqft)*",
  "label.technical.governmentGuideLines.landValue*": "Land value  (₹)*",
  "label.technical.governmentGuideLines.totalValuationOfProperty*": "Total valuation of property*",
  "label.technical.governmentGuideLines.flatOrAptValue*": "Flat/Apt value  (₹)*",
  "label.technical.governmentGuideLines.remark*": "Remark",
  "placeholder.governmentGuideLines.description": "Enter Description here",

  "heading.technical.stageOfConstruction": "Stage of Construction - If Applicable (Builder Case)",
  "label.technical.stageOfConstruction.structureDescription*": "Structure Description*",
  "label.technical.stageOfConstruction.descriptionOfStage*": "Description of Stage*",
  "label.technical.stageOfConstruction.technical*": "% Technical*",
  "label.technical.stageOfConstruction.recommended*": "% Recommended*",

  "heading.section.valuation*": "Valuation (As Per Actual)*",
  "label.section.propertyValuation*": "Property Valuation*",
  "label.section.propertyValuation.areaType": "Area Type*",
  "label.section.propertyValuation.constructionType": "Construction Type*",
  "label.section.propertyValuation.asPerPlan": "As per plan (in sqft)*",
  "label.section.propertyValuation.asPerSite": "As per site (in sqft)*",
  "label.section.propertyValuation.deviationIn": "Deviation in %*",
  "label.section.propertyValuation.rate": "Rate/Sqft*",
  "label.section.propertyValuation.completed": "% Completed*",
  "label.section.propertyValuation.recommended": "% Recommended*",
  "label.section.propertyValuation.valuation": "Valuation (₹)*",

  "label.section.amenities*": "Amenities",
  "label.section.amenities.areaType": "Area Type",
  "label.section.amenities.rate": "Rate/Sqft",
  "label.section.amenities.completed": "% Completed",
  "label.section.amenities.recommended": "% Recommended",
  "label.section.amenities.valuation": "Valuation (₹)",
  "label.section.amenities.fairMarketValue": "Fair Market Value (₹)",
  "label.section.amenities.totalPropertyCompletion*":
    "Total Property value on 100% completion (₹)*",
  "label.section.amenities.forcedSaleValue*": "Forced sale value (₹) at 75%*",

  "label.section.propertyDetails.buildingParameters": "Building Parameters",
  "label.section.propertyDetails.boundariesOnSite*": "Boundaries On Site*",
  "label.section.propertyDetails.planAsPerDCRNorms*": "Plan as per DCR norms*",
  "label.section.propertyDetails.numberOfFloorsPermitted*": "Number of floors Permitted*",
  "label.section.propertyDetails.numberOfFloorsConstructed*": "Number of floors Constructed*",
  "label.section.propertyDetails.FSIAchieved*": "FSI achieved*",
  "label.section.propertyDetails.riskOfDemolition*": "Risk of demolition*",
  "label.section.propertyDetails.setbackDeviation*": "Setback deviation (%)*",
  "label.section.propertyDetails.horizontalDeviation*": "Horizontal Deviation (%)*",
  "label.section.propertyDetails.approachRoadType*": "Approach Road Type*",
  "label.section.propertyDetails.kacha": "Kacha",
  "label.section.propertyDetails.pakka": "Pakka",
  "label.section.propertyDetails.approachRoadWidth*": "Approach Road Width (ft)*",
  "label.section.propertyDetails.propertySituatedNearHighTensionLine*":
    "Property situated near High Tension Line (HT)*",
  "label.section.propertyDetails.distance*": "Distance (ft)*",
  "label.section.propertyDetails.marketFeedback*":
    "Market Feedback (similar properties on 99 acres, magicbricks, broker name, number)*",
  "label.section.propertyDetails.marketability*": "Marketability*",
  "label.section.propertyDetails.boundariesMatching*": "Boundaries Matching?*",
  "label.section.propertyDetails.landUse*": "Land Use*",
  "label.section.propertyDetails.propertyDescription*": "Property Description (G+2, G+3..)*",
  "label.section.propertyDetails.noOfFloors*": "No. of Floors*",
  "label.section.propertyDetails.noOfLifts*": "No. of Lifts*",
  "label.section.propertyDetails.noOfFloor*": "No. of Flats / Floor*",
  "label.section.propertyDetails.noOfWings*": "No. of Wings*",
  "label.section.propertyDetails.structureDescription*": "Structure Description*",
  "label.section.propertyDetails.conditionOfProperty*": "Condition of Property*",
  "label.section.propertyDetails.yearOfConstruction*": "Year of Construction*",
  "label.section.propertyDetails.ageOfTheProperty*": "Age of the Property*",

  // DEDUPE
  "heading.dedupe": "Dedupe",
  "label.dedupe.action": "Action",
  "label.dedupe.status": "Status",
  "label.dedupe.matchResults": "Match Results",
  "label.dedupe.details": "Details",

  "heading.section.nameAndMobileSearch": "Name and Mobile No. Search",
  "label.section.nameSearch.nameAndMobileDetails": "Name and Mobile No. Details",
  "label.section.nameSearch.firstName": "First Name",
  "label.section.nameSearch.middleName": "Middle Name",
  "label.section.nameSearch.lastName": "Last Name",
  "label.section.nameSearch.mobileNo": "Mobile No.",
  "label.section.nameSearch.loanApplicationNo": "Loan Application no.",
  "label.section.nameSearch.applicantType": "Applicant Type",
  "label.section.nameSearch.gender": "Gender",
  "label.section.nameSearch.matchingList": "Matching List",
  "label.section.nameSearch.fileStatus": "File Status",
  "label.section.nameSearch.dateofSearch": "Date Of Search",
  "label.section.nameSearch.prevStatus": "Previous Status",

  "heading.section.kycSearch": "KYC Search",
  "label.section.kycSearch.kycDetails": "KYC Details",
  "label.section.kycSearch.documentType": "Document Type",
  "label.section.kycSearch.loanApplicationNo": "Loan Application no.",
  "label.section.kycSearch.applicantType": "Applicant Type",
  "label.section.kycSearch.firstName": "First Name",
  "label.section.kycSearch.middleName": "Middle Name",
  "label.section.kycSearch.lastName": "Last Name",
  "label.section.kycSearch.gender": "Gender",
  "label.section.kycSearch.documentNo": "Document No.",
  "label.section.kycSearch.matchingList": "Matching List",
  "label.section.kycSearch.fileStatus": "File Status",
  "label.section.kycSearch.dateOfSearch": "Date Of Search",

  "heading.section.finalStatus": "Final Status",
  "label.section.finalStatus.status*": "Status*",

  "heading.section.addressSearch": "Address Search",
  "label.section.addressSearch.addressDetails": "Address Details",
  "label.section.addressSearch.addressType": "Type of address",
  "label.section.addressSearch.address": "Address",
  "label.section.addressSearch.city": "City",
  "label.section.addressSearch.state": "State",
  "label.section.addressSearch.pincode": "Pincode",
  "label.section.addressSearch.loanApplicationNo": "Loan Application no.",
  "label.section.addressSearch.stage": "Stage",
  "label.section.addressSearch.applicantType": "Applicant Type",
  "label.section.addressSearch.firstName": "First Name",
  "label.section.addressSearch.middleName": "Middle Name",
  "label.section.addressSearch.lastName": "Last Name",
  "label.section.addressSearch.inRadius": "In Radius(km)",
  "label.section.addressSearch.matchingList": "Matching List",
  "label.section.addressSearch.fileStatus": "File Status",
  "label.section.addressSearch.dateOfSearch": "Date Of Search",
  "label.section.addressSearch.prevStatus": "Previous Status",

  "label.section.addressDetails": "Address Details",
  "label.section.addressDetails.address": "Address",
  "label.section.addressDetails.city": "City",
  "label.section.addressDetails.state": "State",
  "label.section.addressDetails.pincode": "Pincode",
  "label.section.addressDetails.loanApplicationNo": "Loan Application no.",
  "label.section.addressDetails.linkType": "Link Type",
  "label.section.addressDetails.fileStatus": "File Status",
  "label.section.addressDetails.propertyID": "Property ID",
  "label.section.addressDetails.addressType": "Type of address",

  "heading.section.linkProperty": "Linked Property",
  "label.section.linkProperty.linkType": "Link Type",
  "label.section.linkProperty.loanApplicationNo": "Loan Application no.",
  "label.section.linkProperty.fileStatus": "File Status",
  "label.section.linkProperty.addressType": "Type of address",
  "label.section.linkProperty.propertyID": "Property ID",
  "label.section.linkProperty.address": "Address",
  "label.section.linkProperty.city": "City",
  "label.section.linkProperty.state": "State",
  "label.section.linkProperty.pincode": "Pincode",
  "label.section.linkProperty.matchingList": "Matching List",

  "label.section.basicDetails": "Basic Details",
  "label.section.basicDetails.applicantType": "Applicant Type",
  "label.section.basicDetails.customerID": "Customer ID",
  "label.section.basicDetails.firstName": "First Name",
  "label.section.basicDetails.middleName": "Middle Name",
  "label.section.basicDetails.lastName": "Last Name",
  "label.section.basicDetails.gender": "Gender",
  "label.section.basicDetails.dob": "DOB",

  "heading.section.linkCustomer": "Linked Customers",
  "label.section.linkCustomer.linkType": "Link Type",
  "label.section.linkCustomer.loanApplicationNo": "Loan Application no.",
  "label.section.linkCustomer.fileStatus": "File Status",
  "label.section.linkCustomer.applicantType": "Applicant Type",
  "label.section.linkCustomer.customerID": "Customer ID",
  "label.section.linkCustomer.firstName": "First Name",
  "label.section.linkCustomer.middleName": "Middle Name",
  "label.section.linkCustomer.lastName": "Last Name",
  "label.section.linkCustomer.gender": "Gender",
  "label.section.linkCustomer.dob": "DOB",
  "label.section.linkCustomer.matchingList": "Matching List",

  "label.search.searchCustomer": "Search LAN, Customer Name or Customer ID.",
  "label.search.searchProperty": "Search LAN, Address or Property ID",
  "label.search.selectOnlyOne": "Select (Only 1)",
  "label.search.matchingList": "Matching List",
  "label.search.loanApplicationNo": "Loan Application no.",
  "label.search.branch": "Branch",
  "label.search.applicantType": "Applicant Type",
  "label.search.customerID": "Customer ID",
  "label.search.firstName": "First Name",
  "label.search.middleName": "Middle Name",
  "label.search.lastName": "Last Name",
  "label.search.gender": "Gender",
  "label.search.dob": "DOB",
  "label.search.mobileNo": "Mobile No.",
  "label.search.applicantName": "Applicant Name",
  "label.search.propertyID": "Property ID",
  "label.search.address": "Address",
  "label.search.city": "City",
  "label.search.state": "State",
  "label.search.pincode": "Pincode",

  "label.button.MarkUsingMap": "Mark Using Map",

  // cam
  "label.cam.bankStatementAnalysisSummary*":
    "Bank Statement Analysis Summary (Please paste the consolidated summary)*",
  "label.cam.cam&Recommendation": "CAM & Recommendation",
  "label.cam.documentProof": "Document Proof",
  "label.cam.documentProof*": "Document Proof*",
  "label.cam.documentYearFrom": "Document Year From",
  "label.cam.documentYearFrom*": "Document Year From*",
  "label.cam.systemRuleViolations": "System Rule Violations",
  "label.cam.checkSysViolations": "Check Sys Violations",
  "label.cam.documentYearTo": "Document Year to",
  "label.cam.documentYearTo*": "Document Year to*",
  "label.cam.Remark": "Remark",
  "label.cam.Remark*": "Remark*",
  "label.cam.businessProofDocument": "Business Proof/Income Document",
  "label.cam.insuranceDetails": "Insurance Details",
  "label.cam.premiumDetails": "Premium Details",
  "label.cam.typeOfApplicant": "Type of Applicant",
  "label.cam.typeOfApplicant*": "Type of Applicant*",
  "placeholder.cam.typeOfApplicant": "Select Type Of Applicant",
  "label.cam.typeOfInsurance": "Type of Insurance",
  "placeholder.cam.typeOfInsurance": "Select Type Of Insurance",
  "label.cam.vendor": "Vendor",
  "placeholder.cam.vendor": "Select Type Of Vender",
  "placeholder.cam.Tags": "Select Tags",
  "label.cam.premiumAmount": "Premium Amount (incl Of GST in ₹)",
  "label.cam.attachment": "Attachment",
  "label.cam.attachment*": "Attachment*",
  // label.investmentInfo.table.colTitle.action
  // label.cibilDetails.enquirySummary.thead.total
  "label.cam.vendorName": "Vendor Name",
  "label.cam.valuation": "Valuation",
  "label.cam.dateOfValuation": "Date of Valuation",
  "label.cam.totalValuation(₹)": "Total Valuation (₹)",
  "label.cam.eligibility": "Eligibility",
  "label.cam.schemeDetails": "Scheme Details",
  "label.cam.productIncomeScheme*": "Product Income Scheme*",
  "placeholder.cam.productIncomeScheme*": "Select Product Income Scheme",
  "label.cam.Product*": "Product*",
  "label.cam.incomeScheme*": "Income Scheme*",
  "label.cam.bankOrFinancierName*": "Bank/Financier Name*",
  "label.cam.lendingType*": "Lending Type*",
  "label.cam.feeDetails": "Fee Details",
  "label.cam.chargeName": "Charge Name",
  "label.cam.chargeName*": "Charge Name*",
  "placeholder.cam.chargeName": "Select Charge Name",
  "label.cam.chargeType": "Charge Type",
  "label.cam.configuration": "Configuration",
  "label.cam.waiver": "Waiver",
  "label.cam.default": "Default",
  "label.cam.amount/percentage": "Amount (₹)/ Percentage %",
  "label.cam.amount/percentage*": "Amount (₹)/ Percentage %*",
  "label.cam.amountIncludingGst(₹)": "Amount Including GST (₹)",
  "label.cam.balance": "Balance",
  "label.cam.viewReceipts": "View Receipts",
  "label.cam.receipts": "Receipts",
  "label.cam.totalValuationBeingConsidered":
    "Total Property Valuation being considered {{properties}}",
  "label.cam.populateDefaultFees": "Populate Default Fees",
  "label.cam.addFee": "Add Fee",
  "label.cam.totalFeeReceivable": "Total Fees Receivable",
  "label.cam.totalAmountReceived": "Total Amount Received",
  "label.cam.loanApplicationNo": "Loan Application No",
  "label.cam.receiptId": "Receipt Id",
  "label.cam.InstrumentType": "Instrument Type",
  "label.cam.InstrumentDate": "Instrument Date",
  "label.cam.cheque/TxId": "Cheque / Tx Id",
  "label.cam.amount": "Amount",
  "label.cam.remarks": "Remarks",
  "label.cam.updatedDate": "updatedDate",
  "label.cam.status": "Status",
  "label.cam.recommendedLoanAmt*": "Recommended Loan Amount*",
  "label.cam.insurancePremium*": "Insurance Premium*",
  "label.cam.totalLoanAmount*": "Total Loan Amount*",
  "label.cam.roi*": "Roi*",
  "label.cam.ROI*": "ROI*",
  "label.cam.tenure(Months)*": "Tenure (Months)*",
  "label.cam.interestType": "Interest Type",
  "label.cam.interestType*": "Interest Type*",
  "placeholder.cam.interestType": "Select Interest Type",
  "label.cam.emi*": "EMI*",
  "label.cam.TotalDeductions*": "Total Deductions*",
  "label.cam.netDisbursal*": "Net Disbursal*",
  "label.cam.transactionType*": "Transaction Type*",
  "placeholder.cam.transactionType": "Select Transaction Type",
  "label.cam.balanceTransfer(Months)*": "Balance Transfer (Months)*",
  "label.cam.employeeLoan*": "Employee Loan*",
  "placeholder.cam.employeeLoan": "Select Employee Loan",
  "label.cam.employeeID*": "Employee ID*",
  "label.cam.employeeID": "Employee ID",
  "label.cam.employeeName*": "Employee Name*",
  "label.cam.employeeName": "Employee Name",
  "label.cam.loanTags": "Loan Tags",
  "label.cam.addTags": "Add Tag",
  "label.cam.tags": "Tags",
  "label.cam.yes/no": "Yes/No",
  "label.cam.comment": "Comment",
  "label.cam.financierLoanNumbers": "Financier Loan Numbers",
  "label.cam.financierLoanNumber": "Financier Loan Number",
  "label.cam.description": "Description",
  "label.cam.add": "Add",
  "label.cam.incomeDetails": "Income Details (Customer wise)",
  "heading.cam.incomeDetails": "Income Details",
  "label.cam.applicantType": "Applicant Type",
  "placeholder.cam.applicantType": "Select Applicant Type",
  "label.cam.customerName": "Customer Name",
  "placeholder.cam.customerName": "Select Customer Name",
  "label.cam.incomeScheme": "Income Scheme",
  "placeholder.cam.incomeScheme": "Select Income Scheme",

  "label.cam.appraisedNetMonthlyIncome": "Appraised Net Monthly Income",
  "label.cam.appraisedNetMonthlyIncome*": "Appraised Net Monthly Income*",
  "label.cam.isIncomeConsidered": "Is Income Considered",
  "label.cam.isIncomeConsidered*": "Is Income Considered*",
  "label.cam.monthlyObligation": "Monthly Obligation",
  "label.cam.foir(AsPerPolicy)": "FOIR (As per policy)",
  "label.cam.maxEmi": "Max EMI",
  "label.cam.totalMaxEmi": "Total Max EMI",
  "label.cam.summary": "Summary",
  "label.cam.totalAppraisedNetMonthlyIncome": "Total Appraised Net Monthly Income",
  "label.cam.emiFactor": "EMI Factor",
  "label.cam.emi": "EMI",
  "label.cam.actualLtv(%)": "Actual LTV (%)",

  "label.cam.recommendation.purposeOfLoan*": "Purpose of Loan (End Use)*",
  "label.cam.recommendation.overallComments*": "Overall Comments*",
  "label.cam.recommendation.riskStrength": "Risk & Strength",
  "label.cam.recommendation.deviations*": "Deviations*",
  "label.cam.recommendation.sanctionConditions": "Sanction Conditions",
  "label.cam.recommendation.salesRecommendation": "Sales Recommendation",
  "label.cam.recommendation.recommendation": "Recommendation {{count}}",
  "label.cam.recommendation.dateofrecommendation*": "Date of Recommendation*",
  "label.cam.recommendation.recommendedBy*": "Recommended by*",
  "label.cam.recommendation.recommendation*": "Recommendation*",
  "label.cam.recommendation.query": "Query",
  "label.cam.recommendation.creditDoc": "Credit Document",
  "label.cam.recommendation.creditDocs": "Documents",
  "label.cam.recommendation.creditVideo": "Video Recordings",
  "label.cam.recommendation.creditAudio": "Audio Recordings",

  "label.cam.recommendation.table.riskStrength": "Risk/Strength",
  "label.cam.recommendation.table.riskStrength*": "Risk/Strength*",
  "label.cam.recommendation.table.desc": "Description",
  "label.cam.recommendation.table.desc*": "Description*",
  "label.cam.recommendation.table.remarkMitigants": "Remark/Mitigants",
  "label.cam.recommendation.table.remarkMitigants*": "Remark/Mitigants*",
  "label.cam.recommendation.table.action": "Action",

  "label.cam.recommendation.deviations.populateAutoDeviations": "Populate Auto Deviations",
  "label.cam.recommendation.table.typeofdeviation": "Type Of Deviation",
  "label.cam.recommendation.table.typeofdeviation*": "Type Of Deviation*",
  "label.cam.recommendation.table.deviationCategory": "Deviation Category",
  "label.cam.recommendation.table.deviationCategory*": "Deviation Category*",
  "label.cam.recommendation.table.deviation": "Deviation",
  "label.cam.recommendation.table.deviation*": "Deviation*",
  "label.cam.recommendation.table.deviationlevel": "Deviation Level",
  "label.cam.recommendation.table.deviationlevel*": "Deviation Level*",
  "label.cam.recommendation.table.actualDeviation": "Actual Deviation",
  "label.cam.recommendation.table.actualDeviation*": "Actual Deviation*",
  "label.cam.recommendation.table.mitigants": "Mitigants",
  "label.cam.recommendation.table.mitigants*": "Mitigants*",
  "label.cam.recommendation.table.sancCondition": "Sanction Condition",
  "label.cam.recommendation.table.type": "Type",
  "label.cam.recommendation.table.category": "Category",
  "label.cam.recommendation.table.remark": "Remark",
  "label.cam.bankingAnalysis": "Banking Analysis",
  "label.cam.bankFinancierName*": "Bank/ Financier Name*",
  "placeholder.cam.bankFinancierName*": "Bank/ Financier Name*",
  "label.cam.accountNumber*": "Account Number*",
  "placeholder.cam.accountNumber*": "Select Account Number",
  "label.cam.applicantType*": "Applicant Type*",
  "label.cam.customerName*": "Customer Name*",
  "label.cam.accountHolderName*": "Account Holder Name*",
  "label.cam.accountHolderName": "Account Holder Name",
  "label.cam.accountType*": "Account Type*",
  "placeholder.cam.accountType*": "Select Account Type",
  "label.cam.ifscCode": "IFSC Code",
  "label.cam.branchName*": "Branch Name*",
  "label.cam.branchName": "Branch Name",
  "label.cam.odLimit*": "OD limit (if any)*",
  "label.cam.dateOfBankAccountOpening*": "Date Of Bank Account Opening*",
  "label.cam.bankStatementFrom*": "Bank Statement From*",
  "label.cam.bankStatementTo*": "Bank Statement To*",
  "label.cam.noOfMonths*": "No Of Months*",
  "label.cam.noOfMonths": "No Of Months",
  "label.cam.minChargeDetails*": "Min Charge Details*",
  "label.cam.remarks*": "Remarks*",
  "label.cam.day/month": "Day/Month",
  "label.cam.creditAndDebit": "Credit & Debit Transactions",
  "label.cam.total": "Total",
  "label.cam.month": "Month",
  "label.cam.creditTransaction": "Credit Transactions",
  "label.cam.debitTransaction": "Debit Transactions",
  "label.cam.noOfChequeBounces": "No of Cheque Bounces",
  "label.cam.noOfInward": "No of Inward",
  "label.cam.noOfOutward": "No of outward",
  "label.cam.no": "No",
  "label.cam.bankAccountStatement": "Bank Account Statement",
  "label.cam.bankStatementAnalysis": "Bank Statement Analysis*",
  "label.cam.bankingSummary": "Banking Summary",
  // "label.cam.typeOfApplicant"
  "label.cam.accountNameHolder": "Account Name Holder",
  "label.cam.bankName": "Bank Name",
  "label.cam.accountType": "Account Type",
  "label.cam.accountNumber": "Account Number",
  "label.cam.od/ccLimit": "OD/CC Limit",
  "label.cam.12MonthAbb": "12 Month ABB",
  "label.cam.6MonthAbb": "6 Month ABB",
  "label.cam.3MonthAbb": "3 Month ABB",
  "label.cam.12MonthsCreditAverage": "12 Months Credit Average",
  "label.cam.current6MonthsCredit": "Current 6 Months Credit",
  "label.cam.previous6MonthsCredit": "Previous 6 Months Credit",
  "label.cam.creditRate(both)": "Credit Rate (Both)",
  "label.cam.inwardChequeReturn%": "Inward Cheque Return%",
  "label.cam.outwardChequeReturn%": "Outward Cheque Return%",
  "label.cam.incomeEligibilityCalculation&BriefSummary":
    "Income Eligibility Calculation & Brief Summary",
  "label.cam.monthlyHouseholdExpense": "Monthly Household Expense",
  "label.cam.customerType": "Customer Type",
  "label.cam.expenseType": "Expense Type",
  "label.cam.expenseType*": "Expense Type*",
  "label.cam.expenseFrequency": "Expense Frequency",
  "label.cam.expenseFrequency*": "Expense Frequency*",
  "label.cam.amount(₹)": "Amount (₹)",
  // label.cam.amount(₹)
  //  label.cam.remarks
  "label.cam.repaymentHistory": "Repayment History",
  //label.cam.typeOfApplicant
  //label.cam.customerName
  "label.cam.source(Bank/Financier)": "Source (Bank/Financier)",
  "label.cam.purpose(home/personal)": "Purpose (home/personal)",
  "label.cam.nameOfInstitution": "Name Of Institution",
  "label.cam.sanctionAmount": "Sanction Amount",
  "label.cam.outstandingAmount": "Outstanding Amount",
  "label.cam.balanceTenor": "Balance Tenor",
  "label.cam.emi(₹)": "EMI (₹)",
  "label.cam.pastDefaults": "Past Defaults",
  "label.cam.sanctionTenor": "Sanction Tenor",
  "label.cam.noOfBounces": "No of  Bounces",
  "label.cam.roi(%)": "ROI(%)",
  "label.cam.emiPaid": "EMI Paid",
  "label.cam.obligated(y/n)": "Obligated(Y/N)",
  "label.cam.highestDpd's": "Highest DPD'S",
  "label.cam.highestDpd'sMonth": "Highest DPD'S Month",
  "label.cam.overdueAmount(₹)": "Overdue Amount (₹)",
  "label.cam.remarks/clarifications": "Remarks/Clarification",
  "label.cam.considerForBt(yes/no)": "Consider For BT (Yes/No)",
  "label.cam.action": "Action",
  "placeholder.cam.customerType": "Select Customer Type",
  "placeholder.cam.expenseType": "Select Expense Type",
  "placeholder.cam.expenseFrequency": "Select Expense Frequency",
  "placeholder.cam.purposeOfLoan": "Select Purpose",
  // "placeholder.cam.applicantType":"Select Expense Frequency",
  "placeholder.cam.pastDefaults": "Select Past Defaults",
  "placeholder.cam.obligated": "Select Yes/No",
  "placeholder.cam.consideredForBt": "Select Yes/No",
  "label.cam.commentsOnRepaymentHistory": "Comments on repayment history & bureau",
  "label.cam.monthlyHouseHoldExpense": "Monthly Household Expenses",
  "label.cam.monthlyBankingAnalysis": "Monthly Banking Analysis",
  "label.cam.selectVendor": "Select Vendor",

  // Summary
  "label.heading.summary": "Summary",
  "label.dataSource": "Data Source:",
  "label.dataSource.login": "Data Source: Login",
  "label.dataSource.cam": "Data Source: CAM & Recommendations",
  "label.dataSource.pd": "Data Source: Personal Discussion",
  "label.dataSource.credit": "Data Source: Credit",
  "label.dataSource.pointOfInterest": "Data Source: Point of Interest",
  "label.summary.repaymentHistoryComment": "Comments on Repayment History & Bureau",
  "label.summary.documentNo": "Document No",

  "heading.section.summary.datacaptured": "Data Captured By",
  "heading.section.summary.loanCustomerDetails": "Loan Customer Details",
  "heading.section.summary.distanceDetails": "Distance Details",
  "heading.section.summary.famHouseholdDetails": "Family or Household Details",
  "heading.section.summary.writeupOnWorkProfile": "Writeup on Work Profile",
  "heading.section.summary.purposeOfLoan": "Purpose of Loan (End Use)",
  "heading.section.summary.businessProof/IncDoc": "Business Proof/Income Document",
  "heading.section.summary.incomeEligibilityCalcBriefSummary":
    "Income Eligibility Calculation & Brief Summary",
  "heading.section.summary.repaymentHistory": "Repayment History",
  "heading.section.summary.bureauAnalysis": "Bureau Analysis",
  "heading.section.summary.bankingSummary": "Banking Summary",
  "heading.section.summary.eligibility": "Eligibility",
  "heading.section.summary.propertyValuation": "Property Valuation",
  "heading.section.summary.incomeDetails": "Income Details",
  "heading.section.summary.summary": "Summary",
  "heading.section.summary.overallComments": "Overall Comments",
  "heading.section.summary.salesRecommendation": "Sales Recommendation",
  "heading.section.summary.asset/docChecks": "Asset/Document Checks",
  "heading.section.summary.riskStrength": "Risk & Strength",
  "heading.section.summary.deviations": "Deviations",
  "heading.section.summary.sanctionConditions": "Sanction Conditions",
  "heading.section.summary.query": "Query",

  //table row headings
  "table.summary.dataCapturedBy.dataCapture": "Data Capture",
  "table.summary.dataCapturedBy.login": "Login",
  "table.summary.dataCapturedBy.pd": "Personal Discussion",
  "table.summary.dataCapturedBy.camRecommendation": "CAM & Recommendations",
  "table.summary.dataCapturedBy.technical": "Technical",

  "table.summary.loanCustomerDetails.applicant": "Applicant",
  "table.summary.loanCustomerDetails.coapplicant": "Co-Applicant",
  "table.summary.loanCustomerDetails.indGuarantor": "Individual Guarantor",

  "table.summary.distanceDetails.particulars": "Particulars",
  "table.summary.distanceDetails.distanceSysGen": "Distance (km)-System Generated",
  "table.summary.distanceDetails.distanceUser": "Distance (km)-User",

  "table.summary.businessProof/IncDoc.docProof": "Document Proof",
  "table.summary.businessProof/IncDoc.docYearFrom": "Document Year From",
  "table.summary.businessProof/IncDoc.docYearTo": "Document Year to",
  "table.summary.businessProof/IncDoc.docNo": "Document No",
  "table.summary.businessProof/IncDoc.remark": "Remark",
  "table.summary.businessProof/IncDoc.action": "Action",

  "table.summary.repaymentHistory.typeApplicant": "Type of Applicant",
  "table.summary.repaymentHistory.customerName": "Customer Name",
  "table.summary.repaymentHistory.sourceBankFin": "Source(Bank/Financier)",
  "table.summary.repaymentHistory.purpose": "Purpose(Home/Personal)",
  "table.summary.repaymentHistory.nameOfIns": "Name of Institution",
  "table.summary.repaymentHistory.sanctionAmt": "Sanction Amount",
  "table.summary.repaymentHistory.outstandingAmt": "Outstanding Amount",
  "table.summary.repaymentHistory.balanceTenor": "Balance Tenor",
  "table.summary.repaymentHistory.emi": "EMI(₹)",
  "table.summary.repaymentHistory.pastDefaults": "Past Defaults",
  "table.summary.repaymentHistory.sanctionTenor": "Sanction Tenor",
  "table.summary.repaymentHistory.noOfBounces": "No. of Bounces",
  "table.summary.repaymentHistory.roi": "ROI (%)",
  "table.summary.repaymentHistory.emiPaid": "EMI Paid (₹)",
  "table.summary.repaymentHistory.obligated": "Obligated (Y/N)",
  "table.summary.repaymentHistory.highestDPD": "Highest DPD's",
  "table.summary.repaymentHistory.highestDPDMonth": "Highest DPD's Month",
  "table.summary.repaymentHistory.overdueAmt": "Overdue Amount (₹)",
  "table.summary.repaymentHistory.remarks/clarification": "Remarks/Clarification",
  "table.summary.repaymentHistory.considerForBT": "Consider for BT (Yes/No)",

  "table.summary.bankingSummary.typeApplicant": "Type of Applicant",
  "table.summary.bankingSummary.accHolderName": "Account Holder Name",
  "table.summary.bankingSummary.bankName": "Bank Name",
  "table.summary.bankingSummary.accType": "Account Type",
  "table.summary.bankingSummary.accNumber": "Account Number",
  "table.summary.bankingSummary.odCCLimit": "Od/CC Limit",
  "table.summary.bankingSummary.12monthAbb": "12 months ABB",

  "label.summary.famHouseholdDetails.familyType": "Family Type",
  "label.summary.famHouseholdDetails.noOfHouseholdMembers": "No. of Household Members",
  "label.summary.famHouseholdDetails.noOfDependantMembers": "No. of Dependant Members",
  "label.summary.famHouseholdDetails.ownershipOfCurrentResidence": "Ownership of Current Residence",
  "label.summary.famHouseholdDetails.areaOfCurrentResidence": "Area of Current Residence(sqft)",
  "label.summary.famHouseholdDetails.yearsInCurrentCity": "No. of years in current city",
  "label.summary.famHouseholdDetails.detailsOnNeighborhood": "Details on neighbourhood",
  "label.summary.famHouseholdDetails.storyboard": "Storyboard(min 300 words)",

  "label.summary.eligibility.schemeDetails": "Scheme Details",
  "label.summary.eligibility.productIncomeScheme": "Product Income Scheme",
  "label.summary.eligibility.incomeScheme": "Income Scheme",
  "label.summary.eligibility.bankFinancierName": "Bank/Financier Name",
  "label.summary.eligibility.lendingType": "Lending Type",
  "label.summary.eligibility.finalLoanDetails": "Final Loan Details",
  "label.summary.eligibility.recommendedLoanAmt": "Recommended Loan Amount",
  "label.summary.eligibility.insurancePremium": "Insurance Premium",
  "label.summary.eligibility.totalLoanAmt": "Total Loan Amount",
  "label.summary.eligibility.roi": "ROI",
  "label.summary.eligibility.tenure": "Tenure(Months)",
  "label.summary.eligibility.interestType": "Interest Type",
  "label.summary.eligibility.emi": "EMI",
  "label.summary.eligibility.totalDeductions": "Total Deductions",
  "label.summary.eligibility.netDisbursal": "Net Disbursal",
  "label.summary.eligibility.transactionType": "Transaction Type",
  "label.summary.eligibility.balanceTransfer": "Balance Transfer (Months)",
  "label.summary.eligibility.employeeLoan": "Employee Loan",
  "label.summary.eligibility.employeeId": "Employee ID",
  "label.summary.eligibility.employeeName": "Employee Name",
  "label.summary.eligibility.loanTags": "Loan Tags",
  "label.summary.eligibility.tags": "Tags",
  "label.summary.eligibility.yesNo": "Yes/No",
  "label.summary.eligibility.comment": "Comment",
  "label.summary.eligibility.financierLoanNumbers": "Financier Loan Numbers",
  "label.summary.eligibility.financierLoanNumber": "Financier Loan Number",
  "label.summary.eligibility.financierLoanNumber.description": "Description",

  "label.summary.repaymentHistory.comments": "Comments on Repayment History & Bureau",

  "label.summary.propertyValuation.propertyAddress": "Property Address",
  "label.summary.propertyValuation.pincode": "Pincode",
  "label.summary.propertyValuation.city": "City",
  "label.summary.propertyValuation.state": "State",
  "label.summary.propertyValuation.valuations": "Valuations",
  "label.summary.propertyValuation.totalProp": "Total Property Valuation being considered",
  "table.summary.propertyValuation.vendorName": "Vendor Name",
  "table.summary.propertyValuation.dateOfval": "Date of Valuation",
  "table.summary.propertyValuation.totalVal": "Total Valuation (₹)",
  "table.summary.propertyValuation.average": "Average",

  "label.summary.incDetails.customerWise": "Income Details (Customer wise)",
  "table.summary.incDetails.applicantType": "Applicant Type",
  "table.summary.incDetails.cusName": "Customer Name",
  "table.summary.incDetails.incScheme": "Income Scheme",
  "table.summary.incDetails.appNetMonth": "Appraised Net Monthly Income",
  "table.summary.incDetails.isIncCons": "Is Income Considered",
  "table.summary.incDetails.monthlyObl": "Monthly Obligation",
  "table.summary.incDetails.foir": "FOIR (As per policy)",
  "table.summary.incDetails.maxEmi": "Max EMI",

  "label.summary.summ.totalAppNetMonInc": "Total Appraised Net Monthly Income",
  "label.summary.summ.maxEmi": "Max. EMI",
  "label.summary.summ.emiFactor": "EMI Factor",
  "label.summary.summ.eligibility": "Eligibility",
  "label.summary.summ.emi": "EMI",
  "label.summary.summ.actualLtv": "Actual LTV",

  "label.summary.salesRecommendation.recommend": "Recommendation",
  "label.summary.salesRecommendation.recommendBy": "Recommended by",
  "label.summary.salesRecommendation.dateOfrecommend": "Date of Reccommendation",

  "table.summary.riskStrength.riskStrength": "Risk/Strength",
  "table.summary.riskStrength.desc": "Description",
  "table.summary.riskStrength.remarkMitigants": "Remark/Mitigants",

  "table.summary.deviations.deviationType": "Type Of Deviation",
  "table.summary.deviations.deviationCat": "Deviation Category",
  "table.summary.deviations.deviation": "Deviation",
  "table.summary.deviations.actualDeviation": "Actual Deviation",
  "table.summary.deviations.mitigants": "Mitigants",

  "table.summary.sanctionConditions.sancCondition": "Sanction Condition",
  "table.summary.sanctionConditions.type": "Type",
  "table.summary.sanctionConditions.cat": "Category",
  "table.summary.sanctionConditions.remark": "Remark",

  "table.summary.query.sNo": "S No",
  "table.summary.query.query": "Query",
  "table.summary.query.raisedOn": "Raised On",
  "table.summary.query.raisedBy": "Raised by",
  "table.summary.query.respondedOn": "Responded on",
  "table.summary.query.respondedBy": "Responded by",
  "table.summary.query.status": "Status",
  "table.summary.query.action": "Action",

  "label.comparison.dataCapturedBy": "Data Captured By",
  "label.comparison.manger": "Manager",
  "label.comparison.dateOfInitiation": "Date of Initiation",
  "label.comparison.customerId": "Customer ID",
  "label.comparison.salutation": "Salutation",
  "label.comparison.firstName": "First Name",
  "label.comparison.middleName": "Middle Name",
  "label.comparison.lastName": "Last Name",
  "label.comparison.gender": "Gender",
  "label.comparison.birthPlace": "Birth Place",
  "label.comparison.dob": "DOB",
  "label.comparison.age": "Age(Years)",
  "label.comparison.category": "Category",
  "label.comparison.mobileNo": "Mobile No.",
  "label.comparison.phoneNo": "Phone No.",
  "label.comparison.email": "Email",
  "label.comparison.noOfYearInCurrentCity": "No. of years in current city",
  "label.comparison.highestEducation": "Highest Education",
  "label.comparison.institute/University": "Institute/University",
  "label.comparison.religion": "Religion",
  "label.comparison.OwnershipOfCurrentResidence": "Ownership of Current Residence",
  "label.comparison.kycType": "Asset Type",
  "label.comparison.kycNumber": "Amount",
  "label.comparison.pan": "PAN Card",
  "label.comparison.aadhaar": "Aadhaar Card",
  "label.comparison.voterId": "Voter ID",
  "label.comparison.passport": "Passport",
  "label.comparison.drivingLicense": "Driving License",
  "label.comparison.rationCard": "Ration Card",
  "label.comparison.other": "Other",
  "label.comparison.addressType": "Address Type",
  "label.comparison.address": "Address",
  "label.comparison.city": "City",
  "label.comparison.state": "State",
  "label.comparison.pincode": "Pincode",
  "label.comparison.latitude": "Latitude",
  "label.comparison.longitude": "Longitude",

  "label.comparison.maritalStatus": "Marital Status",
  "label.comparison.relationshipWithApplicant": "Relationship with Applicant",
  "label.comparison.fatherSpouseName": "Father/Spouse Name",
  "label.comparison.familyType": "Family Type: Joint/Nuclear",
  "label.comparison.noOfHouseHoldMembers": "No. of Household Members",
  "label.comparison.noOfDependentMembers": "No. of Dependents",
  "label.comparison.ownershipOfCurrentResidence": "Ownership of Current Residence",
  "label.comparison.numberOfYearsInCurrentCity": "No. of years in current city",
  "label.comparison.areaOfCurrentResidence": "Area of Current Residence(sqft)",
  "label.comparison.detailsOnNeighbourhood": "Details on neighbourhood",
  "label.comparison.storyboard": "Storyboard(min 300 words)",

  "label.comparison.employmentNature": "Employment Nature",
  "label.comparison.industry": "Industry",
  "label.comparison.lineOfWork": "Line of Work",
  "label.comparison.natureOfWork": "Nature of Work",

  "label.comparison.nameOfOrg": "Name of Organization/Establishment",
  "label.comparison.totalBusinessExp": "Total Business/Work Experience",
  "label.comparison.yearsInCurrentEmployment": "Years in Current Employment",
  "label.comparison.constitutionOfBusiness": "Constitution of Business",
  "label.comparison.numberOfEmployeesInCompany": "Number of Employees in Company",
  "label.comparison.dateOfJoining": "Date of Joining",
  "label.comparison.designation": "Designation",
  "label.comparison.occupation": "Occupation",
  "label.comparison.totalIncomeMonthly": "Total Income (Monthly)",
  "label.comparison.totalExpensesMonthly": "Total Expenses (Monthly)",
  "label.comparison.netIncome": "Net Income (Monthly)",
  "label.comparison.annualBonus": "Annual Bonus",
  "label.comparison.performanceLinkedIncentive": "Performance Linked Incentive",
  "label.comparison.writeUpOfWorkProfile": "Write up Work Profile",
  "label.comparison.yearOfStartOfOperation": "Year of Start of Operation",
  "label.comparison.yearOfBusinessInCurrentLocation": "Year of Business in Current Location",
  "label.comparison.productServiceOffered": "Product/Service Offered",
  "label.comparison.highestMonthlySalesInLast6Months": "Hgst. Monthly Sales in last 6 months(₹)",
  "label.comparison.lowestMonthlySalesInLast6Months": "Lwst. Monthly Sales in last 6 months(₹)",
  "label.comparison.avgDailySales": "Avg. Daily Sales(₹)",
  "label.comparison.avgMonthlySalesInLast6Months": "Avg. Monthly Sales in last 6 months(₹)",
  "label.comparison.estimateGrossMargin": "Estimate Gross Margin(%)",
  "label.comparison.netMargin": "Net Margin(%)",
  "label.comparison.businessSeasonality": "Business Seasonality",
  "label.comparison.businessMangedBy": "Business Managed By",
  "label.comparison.ownershipOfPremises": "Ownership of Premises",
  "label.comparison.firstGenerationBusiness": "First Generation Business(Yes/No)",
  "label.comparison.noOfDaysBusinessOperationalInAMonth":
    "Number of Days Business Operational in a month",
  "label.comparison.amountOfSalaryPaidToEmployees": "Amount of Salary Paid to Employees(₹)",
  "label.comparison.creditPeriods/CreditorCycle": "Credit Periods/Creditor Cycle",
  "label.comparison.rawMaterials": "Raw Materials(₹)",
  "label.comparison.residenceCumOffice": "Residence Cum Office",
  "label.comparison.employeeSeenAtTheTimeOfVisit": "Employee Seen at the time of Visit",
  "label.comparison.noOfEmployees": "No. of Employees",
  "label.comparison.sizeOfBusinessPremises": "Size of Business Premises(Sqft)",
  "label.comparison.typeOfStructure": "Type of Structure",
  "label.comparison.businessLocation": "Business Location",
  "label.comparison.stockSeenAtTheTimeOfVisit": "Stock Seen at the time of Visit",
  "label.comparison.approachRoad": "Approach Road",
  "label.comparison.commentsOnTheInterior/ExteriorOfTheBusiness":
    "Comments on the Interior/Exterior of the Business",
  "label.comparison.assetSeenInTheBusinessPremises": "Asset Seen in the Business Premises",
  "label.comparison.tenantName": "Tenant Name",
  "label.comparison.propertyOwnedBy": "Property Owned By",
  "label.comparison.typeOfLease/Tenant": "Type of Lease/Tenant",
  "label.comparison.areaOfProperty": "Area of Property(Sqft)",
  "label.comparison.rentAmountAsPerAgreement": "Rent Amount(₹) as per Agreement",
  "label.comparison.rentPaidSince": "Rent Paid Since(in months)",
  "label.comparison.rentAgreement": "Rent Agreement",
  "label.comparison.rentAgreementDate": "Rent Agreement Date",
  "label.comparison.rentalStartDate": "Rental Start Date",
  "label.comparison.rentMode": "Rent Mode",
  "label.comparison.bankAccountNumber": "Bank Account Number",
  "label.comparison.amountCollected(last 1 month)": "Amount Collected(last 1 month)(₹)",
  "label.comparison.amountCollected(last 2 month)": "Amount Collected(2nd last month)(₹)",
  "label.comparison.amountCollected(last 3 month)": "Amount Collected(3rd last month)(₹)",
  "label.comparison.tenureOfRent": "Tenure of Rent(in months)",
  "label.comparison.escaLation": "Escalation in %",
  "label.comparison.remarks": "Remarks",
  "label.comparison.assetType": "Asset Type",
  "label.comparison.amount": "Amount(₹)",
  "label.comparison.comments": "Comments",

  "label.comparison.otherIncome": "Other Income(Monthly)",
  "label.comparison.rentalIncome": "Rental Income",
  "label.comparison.agriculturalIncome": "Agricultural Income",
  "label.comparison.FDDividend": "F/D Dividend",
  "label.comparison.accountHolderName": "Account Holder Name",
  "label.comparison.ifscCode": "IFSC Code",
  "label.comparison.bankName": "Bank Name",
  "label.comparison.branchName": "Branch Name",
  "label.comparison.typeOfAccount": "Type of Account",
  "label.comparison.accountNumber": "Account Number",

  // repayment History
  "label.comparison.customerName": "Customer Name",
  "label.comparison.CoApplicant": "Customer Type",
  "label.comparison.purpose": "Purpose(home/Personal)",
  "label.comparison.source": "Source(bank/FI)",
  "label.comparison.nameOfInstitution": "Name of Institution",
  "label.comparison.sanctionAmount": "Sanction Amount",
  "label.comparison.outStandingAmount": "Outstanding Amount",
  "label.comparison.balanceTenor": "Balance Tenor",
  "label.comparison.Emi": "EMI",
  "label.comparison.PastDefaults": "Past Defaults",
  "label.comparison.sanctionTenor": "Sanction Tenor",
  "label.comparison.noOfBounces": "No. of bounces",
  "label.comparison.ROI": "ROI",

  // "label.comparison.customerType": "Co-applicant",
  "label.comparison.customerType": "Customer Type",
  // Financial/ In Principal/ Final Approval
  "heading.approval.financial": "Financial Approval",
  "heading.approval.inPrincipal": "In Principal Approval",
  "heading.approval.final": "Final Approval",
  "label.approval.query": "Query",
  "label.approval.confirmApprove": "Confirm Approval",
  "label.approval.rejectApp": "Reject Application",
  "label.approval.rejectionReason*": "Rejection Reason*",
  "label.approval.enterMessage*": "Enter Message",
  "label.approval.select": "Select",
  "label.approval.remarks*": "Remarks*",
  "label.approval.notes": "Notes",
  "table.approval.query.sNo": "S No",
  "table.approval.query.query": "Query",
  "table.approval.query.query*": "Query*",
  "table.approval.query.reply*": "Reply*",
  "table.approval.query.raisedOn": "Raised On",
  "table.approval.query.raisedBy": "Raised By",
  "table.approval.query.respondedOn": "Responded On",
  "table.approval.query.respondedBy": "Responded By",
  "table.approval.query.status": "Status",
  "table.approval.query.action": "Action",
  "label.approval.responder": "Responder",

  // fees
  "label.fees.applicationNo": "Application No.",
  "label.fees.branch": "Branch",
  "label.fees.applicantName": "Applicant Name",
  "label.fees.receiptID": "Receipt ID",
  "label.fees.instrumentType": "Instrument Type",
  "placeholder.fees.instrumentType": "Select Instrument Type",
  "label.fees.instrumentDate": "Instrument Date",
  "label.fees.instrumentDate<=": "Instrument Date<=",
  "label.fees.instrumentDate>=": "Instrument Date>=",
  "label.fees.utr/Chq.No.": "UTR / Chq. No.",
  "label.fees.amount": "Amount(₹)",
  "label.fees.remarks": "Remarks",
  "label.fees.status": "Status",
  "label.fees.actions": "Actions",
  "label.fees.amountCollected>=": "Amount Collected>=",
  "label.fees.amountCollected<=": "Amount Collected<=",
  "placeholder.fees.status": "Select Status",
  "label.fees.receipts": "Receipts",
  "label.fees.deposits": "Deposits",
  "label.fees.depositsId": "Deposits ID",
  "label.fees.depositAmount>=": "Deposit Amount >=",
  "label.fees.dateOfDeposit>=": "Date of Deposit >=",
  "label.fees.sahayyaBankAccount": "Sahayya Bank account",
  "label.fees.accountNo": "Account No.",
  "label.fees.loanApplicationNo.": "Loan Application No.",
  "label.fees.updatedOn>=": "Updated On >=",
  "label.fees.depositAmount": "Deposit Amount",
  "label.fees.dateOfDeposit": "Date of Deposit",
  "label.fees.sahayyaBankAccountAccountNo": "Sahayya Bank Account",
  "label.fees.noOfReceipt": "No. of Receipt",
  "label.fees.loanApplicationNo(s)": "Loan Application No.(s)",
  "label.fees.updatedOn": "Updated On",
  "label.fees.depositReceipt": "Deposit Receipt",
  "label.fees.customerName": "Customer Name",
  "label.fees.receiptId": "Receipt ID",
  "label.fees.collectedBy": "Collected By",
  "label.fees.dateOfReceipt>=": "Date of Receipt >=",
  "label.fees.receiptAmount>=": "Receipt Amount >=",
  "label.fees.reconciliationStatus": "Reconciliation Status",
  "label.fees.receivedBy": "Received By",
  "label.fees.dateOfReceipt": "Date Of Receipt",
  "label.fees.receiptAmount": "Receipt Amount",
  "label.fees.depositAccount": "Deposit Account",
  "label.fees.collectionReceipt": "Collection Receipt",
  "label.fees.dateOfClearance": "Date Of Clearance/Bounce",
  "label.fees.reconRemark": "Recon Remark",
  "label.fees.reconStatus": "Recon Remark",
  "label.fees.updateStatus": "Update Status",
  "label.fees.status*": "Status*",
  "label.fees.dateOfVisit*": "Date of Visit*",
  "label.fees.remark*": "Remark*",
  "label.fees.remarkNote": "Note: Once status is submitted, it cannot be edited or deleted. ",
  "label.fees.reconciliation": "Reconciliation",
  "label.fees.makeDeposit": "Make a Deposit",
  "label.fees.sahayyaBankAccount*": "Sahayya Bank Account *",
  "label.fees.accountBranch": "Account Branch",
  "label.fees.branchAccountNumber": "Branch Account Number",
  "label.fees.applicantNameOrApplicationNo": "Search Applicant Name or Application No.",
  "label.fees.action": "Action",
  "label.fees.depositAmount(₹)*": "Deposit Amount (₹)*",
  "label.fees.amountInWords": "Amount in words",
  "label.fees.dateOfDeposit*": "Date of Deposit*",
  "label.fees.depositProof": "Deposit Proof",
  "label.fees.editDeposit": "Edit Deposit",
  "label.fees.dateOfStatus*": "Date Of Status",
  "placeholder.fees.dateOfStatus*": "Select Date Of Status",

  //Application Media
  "label.applicationMedia": "Application Media",
  "label.applicationMedia.login": "Login",
  "label.applicationMedia.pd": "Personal Discussion",
  "label.applicationMedia.cam": "CAM",
  "label.applicationMedia.legal": "Legal",
  "label.applicationMedia.technical": "Technical",
  "label.applicationMedia.rcu": "RCU",
  "label.rcu.remarkNotMandatory": "Remark",
  "label.dde.relationDetailsInfo": "Either Father’s or Spouse’s name is mandatory",
  "label.cam.opsDocuments": "Ops Documents",
  "label.conversation.showMore": "Show More",
  "label.conversation.showLess": "Show Less",
  "label.cam.loanDisbursement": "Loan Disbursement",
  "label.cam.loanDisbursementStatus": "Disbursement Status",
  "label.cam.loanNumber": "Loan Number",
  "label.cam.miFinLoanNumber": "MiFin Loan Number",
  // "placeHolder.cam.minFinLoanNumber": "MinFin Loan Number",
};
export default ALL_CONST;
