import {
  LOAN_DETAIL_BEGIN,
  LOAN_DETAIL_FAILURE,
  LOAN_DETAIL_SUCCESS,
} from "../actions/1_ActionConstants";

const initialState = {
  loading: false,
  error: null,
  loanObj: {},
};

export default function LoanReducer(state = initialState, action) {
  switch (action.type) {
    case LOAN_DETAIL_BEGIN:
      return {
        loading: true,
        ...state,
      };

    case LOAN_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loanObj: action.payload,
      };

    case LOAN_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: "FAILED TO LOAD,..,.",
        loanObj: {},
      };
    default:
      return state;
  }
}
