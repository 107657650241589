import { ROUNDING_MODE } from "utils/Constant";
const DEFAULT_ROUNDING_MODE = ROUNDING_MODE.ROUND;

export const dateFormatter = (date: string, locale: string) => {
  const formattedDate = new Date(date);
  return new Intl.DateTimeFormat(locale).format(formattedDate);
};

export const numberFormatter = (
  number = 0,
  locale = window.locale?.defaultLocale,
  decimals = 2,
  roundingRule = DEFAULT_ROUNDING_MODE
) => {
  if (number === null || number === undefined || isNaN(number)) return 0;
  roundingRule(number);
  return parseFloat(number.toString()).toLocaleString(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export const numberFormatterIncludeNull = (
  number:number|null,
  locale = window.locale?.defaultLocale,
  decimals = 2,
  roundingRule = DEFAULT_ROUNDING_MODE
) => {
  if (number === 0 ) return 0;
  if (number === null || number === undefined || isNaN(number)) return null;
  roundingRule(number);
  return parseFloat(number.toString()).toLocaleString(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export const intlNumberFormatter = (
  number = 0,
  locale = window.locale?.defaultLocale,
  decimals = 2,
  roundingRule = DEFAULT_ROUNDING_MODE
) => {
  if (number === null || number === undefined || isNaN(number)) return 0;
  const roundedNumber = roundingRule(number);
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(roundedNumber);
};

export const currencyFormatter = (
  number = 0,
  locale = window.locale?.defaultLocale,
  decimals = 2,
  roundingRule = DEFAULT_ROUNDING_MODE
) => {
  const roundedNumber = roundingRule(number);
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    // style: "currency",
    currency: window.locale?.defaultCurrency ?? "INR",
  }).format(roundedNumber);
};
