import { getLocalStorage } from "utils/CommonUtil";
import { LOCAL_STORAGE } from "utils/Constant";

class HelperService {
  getUserInfo() {
    if (getLocalStorage(LOCAL_STORAGE.USER_INFO))
      return JSON.parse(getLocalStorage(LOCAL_STORAGE.USER_INFO));

    return false;
  }

  getHeadersWithAuth() {
    return {
      Authorization: "Bearer " + this.getUserInfo().accessToken,
      "content-type": "application/json",
    };
  }
  getHeaders(contentType?: string): object {
    return {
      "content-type": contentType ?? "application/json",
    };
  }
}

export default new HelperService();
