import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Languages from "lang";
import { LANGUAGES } from "utils/Constant";
/* eslint-disable */

/* eslint-enable */

const resources = Languages;
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: window?.locale?.defaultLanguage || LANGUAGES.EN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
