import Loader from "components/Loader/Loader";
import { RouteProps, withRouter } from "hoc/withRouter";
import queryString from "query-string";
import { useEffect, useState } from "react";
import AuthService from "services/AuthService";
import MenuService, { preparePathWithCode } from "services/staticService/MenuService";
import { LINK_DDE_LOGIN, LINK_LOAN_DETAIL } from "transactionRoutes";
import { setLocalStorage } from "utils/CommonUtil";
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  SIDE_MENU_ROOT_CODES,
  WEB_VIEW_SCREEN,
  WEB_VIEW_SCREEN_PATH,
} from "utils/Constant";
import Page403 from "views/Page403/Page403";

const PageValidate = (props: RouteProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const parsed: {
      code?: string;
      state?: string | undefined;
      loanUid?: string;
      pageName?: string;
      applicantUid?:string;
      applicantName?:string|undefined;
    } = queryString.parse(props.location.search);
    //let { status, msg, code, state } = queryString.parse(props.location.search);
    console.log(parsed, "===");
    if (parsed.code && parsed.loanUid && parsed.pageName) {
      AuthService.getTokenForWebView(parsed.code)
        .then((res) => {
          if (Object.keys(res.data).length > 0) {
            const userInfo = JSON.stringify(res.data);
            setLocalStorage(LOCAL_STORAGE.USER_INFO, userInfo);
            setLoading(false);
            MenuService.getMenu(SIDE_MENU_ROOT_CODES.LOAN_APPLICATION_MENU, parsed.loanUid)
              .then((res) => {
                if (res) {
                  const allScreenMenu = preparePathWithCode(
                    res.filter((item: any) => item.isScreen)
                  );
                  setLocalStorage(LOCAL_STORAGE.MENU_LIST_MAPPING, JSON.stringify(allScreenMenu));
                  // console.log(allScreenMenu, "allScreenMenu");
                  const stageInfo = res.filter((item: any) => item.code === parsed.pageName);
                  console.log(stageInfo);
                  let path = parsed.pageName;
                  if (parsed.pageName === WEB_VIEW_SCREEN.FINANCIAL_APPROVAL) {
                    path = WEB_VIEW_SCREEN_PATH.FINANCIAL_APPROVAL_ASSIGNEE_NAME;
                  } else if (parsed.pageName === WEB_VIEW_SCREEN.FINAL_APPROVAL) {
                    path = WEB_VIEW_SCREEN_PATH.FINAL_APPROVAL_ASSIGNEE_NAME;
                  } else if (parsed.pageName === WEB_VIEW_SCREEN.IN_PRINCIPAL_APPROVAL) {
                    path = WEB_VIEW_SCREEN_PATH.IN_PRINCIPAL_APPROVAL_ASSIGNEE_NAME;
                  } else if (parsed.pageName === WEB_VIEW_SCREEN.SUMMARY) {
                    path = WEB_VIEW_SCREEN_PATH.SUMMARY;
                  } else if (parsed.pageName === WEB_VIEW_SCREEN.HIGHMARK_DETAIL_REPORT) {
                    path = WEB_VIEW_SCREEN_PATH.HIGHMARK_DETAILED_REPORT;
                  }
                  setLocalStorage(LOCAL_STORAGE.STAGE_DETAIL, JSON.stringify(stageInfo?.stage));
                  sessionStorage.setItem(SESSION_STORAGE.IS_WEB_VIEW, "true");
                  if(parsed.pageName===WEB_VIEW_SCREEN.HIGHMARK_DETAIL_REPORT){
                    const URL = `${LINK_LOAN_DETAIL}${path}/${parsed.loanUid}/${parsed.applicantUid}${parsed?.applicantName?`/${parsed?.applicantName}`:""}`;
                    props.navigate(URL);
                    return;
                  }
                  if (stageInfo[0]?.stage?.uid) {
                    const URL = `${LINK_LOAN_DETAIL}${path}/${parsed.loanUid}/${stageInfo[0]?.stage?.uid}/`;
                    props.navigate(URL);
                  } else {
                    const URL = `${LINK_LOAN_DETAIL}${path}/${parsed.loanUid}`;
                    props.navigate(URL);
                  }
                }
              })
              .catch((error) => {
                setError(true);
              });
          } else {
            setError(true);
          }
        })
        .catch((error) => {
          setError(true);
        });
    } else {
      setError(true);
    }
  }, []);

  if (error) {
    return <Page403 />;
  }

  return (
    <div className="">
      <Loader />
    </div>
  );
};

export default withRouter(PageValidate);
