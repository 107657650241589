import Loader from "components/Loader/Loader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { linkAuthRoute } from "routes";
import MenuService, { preparePathWithCode } from "services/staticService/MenuService";
import { LINK_TASK_LIST } from "transactionRoutes";
import { SIDE_MENU_ROOT_CODES } from "utils/Constant";
import Page403 from "views/Page403/Page403";

const PageSetUp = () => {
  const [isNoAccess, setIsNoAccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const menuForLoanData = await MenuService.getMenu(SIDE_MENU_ROOT_CODES.LOAN_LISTING);
    const allScreensForLoan = preparePathWithCode(
      menuForLoanData.filter((item: any) => item.isScreen)
    );
    if (allScreensForLoan.length > 0) {
      const isTaskPipeline = allScreensForLoan.find((item: any) => item.code === "TASKS_PIPELINE");
      if (isTaskPipeline) {
        navigate(`${linkAuthRoute}/${LINK_TASK_LIST}`);
      } else {
        const isPageHasAccess = allScreensForLoan.find((item: any) => item.roleFeature.read);

        navigate(`${linkAuthRoute}/${isPageHasAccess.path}`);
      }
    } else {
      // history.push(routes.linkPageLogin);
      const menuForMasterData = await MenuService.getMenu(SIDE_MENU_ROOT_CODES.MASTER_DATA);
      console.log(menuForMasterData);
      const allScreenMenuForMasterData = preparePathWithCode(
        menuForMasterData.filter((item: any) => item.isScreen)
      );
      const isSystemDate = allScreenMenuForMasterData.find(
        (item: any) => item.code === "SYSTEM_DATE"
      );
      if (isSystemDate) {
        navigate(`${linkAuthRoute}/${isSystemDate.path}`);
      } else {
        const isPageHasAccess = allScreensForLoan.find((item: any) => item.roleFeature.read);
        navigate(`${linkAuthRoute}/${isPageHasAccess.path}`);
      }
    }

    setIsNoAccess(true);
  };

  if (isNoAccess) {
    return (
      <div>
        <Page403 />
      </div>
    );
  }
  return (
    <div>
      <Loader />
    </div>
  );
};

export default PageSetUp;
