import BaseApiService from "services/BaseApiService";

const DEFAULT_PATH = "/custom/api";

class DeviceAccessService extends BaseApiService {
  save = (payload: any) => {
    const endPoint = DEFAULT_PATH + "/device/access";
    return this.makePostRequestWithAuth(endPoint, payload);
  };
}

export default new DeviceAccessService();
