/*
 *   Author     : Kaviraj Manampadi
 *   created on : June 19, 2023
 */

import { SIMPLE_BUTTON_STYLES } from "assets/base-theme";
import primaryLoader from "assets/img/common/Loading.svg";
import { IBaseButtonProps } from "components/base/BaseButton";
import { withAuthButton } from "components/hoc/withAuthButton";
import { IButtonCommonStyleProps } from "components/interface/Button";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { generateClasses } from "utils/Util";

interface IButtonBoxProps extends IBaseButtonProps, IButtonCommonStyleProps {
  loadFromParent?: boolean;
}

const SimpleButton: React.FC<IButtonBoxProps> = (props: IButtonBoxProps) => {
  const [showLoader, setShowLoader] = useState(false);
  const getClassNames = (isLoading: boolean) => {
    const styleObject = {
      ...SIMPLE_BUTTON_STYLES,
      overrideStyle: props.styleObj,
      overrideClasses: props.buttonIcon ? props.className + " pl-10 " : props.className,
      isDisabled: isLoading || props.disabled,
      removeStyleProperty: [],
      removeClasses: [],
    };

    return generateClasses(styleObject, props.variant);
  };

  const {
    color,
    variant,
    onClickWithLoader,
    args,
    buttonIcon,
    isIgnoreAccessLevel,
    isDisabledByAcl,
    buttonIconClassName,
    ...primeReactProps
  } = props;

  const { value, name, label, iconPos, onClick, disabled, loadingIcon, icon, loadFromParent } =
    primeReactProps;

  const { t } = useTranslation();

  let translatedLabel: string;

  if (label !== undefined) {
    translatedLabel = t(label, args);
  } else {
    // Handle the case when the label is undefined
    translatedLabel = ""; // Or provide a default value or appropriate handling
  }

  const _loadingIcon = (
    <div className="w-5 h-5 animate-spin absolute right-2.5">
      <img src={primaryLoader} className="" alt="loading" />
    </div>
  );

  const onButtonClick = async (e: any) => {
    if (onClick) {
      onClick(e);
    } else if (onClickWithLoader) {
      const res = onClickWithLoader();
      if (res && Promise.resolve(res) === res) {
        setShowLoader(true);
        res
          .then((message: any) => {
            setShowLoader(false);
          })
          .catch(() => {
            setShowLoader(false);
          });
      }
    }
  };
  let disableField = disabled;
  if (!isIgnoreAccessLevel) {
    disableField = isDisabledByAcl;
  } else {
    disableField = disabled;
  }
  return (
    <div className="relative">
      <Button
        {...primeReactProps}
        color={color}
        className={getClassNames(showLoader)}
        value={value}
        name={name}
        label={translatedLabel}
        loadingIcon={loadingIcon ?? _loadingIcon}
        iconPos={iconPos ?? "right"}
        icon={icon}
        onClick={onButtonClick}
        loading={showLoader || loadFromParent}
        disabled={showLoader || disableField}
      />
      {props.buttonIcon && (
        <div className="absolute w-8 h-8 bg-white top-0.5 left-0.5 flex items-center justify-center rounded-googleIconRadius">
          <img src={props.buttonIcon} alt="search-icon" className={buttonIconClassName} />
        </div>
      )}
    </div>
  );
};

// Memoization

export default withAuthButton(SimpleButton);
// redux wiring
