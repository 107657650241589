import { useEffect } from "react";
import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import { linkAuthRoute } from "routes";
import ACLService from "services/ACLService";

export interface RouteProps {
  navigate?: any;
  navigateNoAuth?: any;
  location?: any;
  goBack?: any;
  history?: any;
}
export const withRouter = (Component: any, isIgnoreEventRegister?: boolean) => {
  const ViewWrapper = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (location.pathname) {
        onNavigate();
      }
    }, [window.location.pathname]);

    useEffect(() => {
      const { name } = Component;

      if (!isIgnoreEventRegister) {
        window.addEventListener("beforeunload", onNavigate);
      }

      return () => {
        if (!isIgnoreEventRegister) {
          window.removeEventListener("beforeunload", onNavigate);
        }
      };
    }, []);

    const onNavigateWithAuth = (path: string, options?: NavigateOptions) => {
      navigate(`${linkAuthRoute}/${path}`, options);
    };

    const onGoBack = () => {
      navigate(-1);
    };

    const onNavigate = (event?: any) => {
      // console.log("On Navigate");
      // const isFormDirty = ACLService.isFormDirty();
      // if (isFormDirty) {
      //   const res = window.confirm("Sure ?");
      //   if (res) {
      //     ACLService.clearFormDirty();
      //   } else {
      //     event?.preventDefault();
      //   }
      // }
    };

    return (
      <Component
        navigate={onNavigateWithAuth}
        navigateNoAuth={navigate}
        goBack={onGoBack}
        location={location}
        {...props}
      />
    );
  };

  return ViewWrapper;
};
