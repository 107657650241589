import { generateBorderStyle } from "utils/Util";

export const COMMON_INPUT_STYLE = {
  inputTextAlign: "text-left",
  inputMarginRight: "",
  inputMarginLeft: "",
  inputMarginTop: "",
  inputMarginBottom: "",
  inputFontSize: "text-xs",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",

  inputWidth: "w-full",
  borderStyle: " border ",
  inputBorderColor: "border-secondary-gray-300",
  inputBorderRadius: "rounded-md",
  inputFocusRingWidth: "outline-none",
  inputBackgroundColor: "bg-white",
  inputFocusBackgroundColor: "focus:bg-white",
  inputFocusBorderColor: "focus:border-semantic-informativeAccent",
  inputFocusBorderWidth: "focus:border focus:border-opacity-40",
  inputFocusShadow: "",
  inputHoverBackgroundColor: "hover:bg-white",
  inputHoverBorderColor: "hover:border-primary-sapphireBlue-600",
  inputTextColor: "text-primary-ptext-1000",

  inputPlaceHolderTextColor: "placeholder-secondary-gray-500",
  inputBoxShadow: "",
  disabled: {
    inputBorderColor: "border-primary-gray-300",
    inputBackgroundColor: "bg-secondary-disabledBGColor",
    inputPlaceHolderTextColor: "placeholder-secondary-gray-500",
    inputTextColor: "text-primary-gray-800",
    inputHoverBorderColor: "hover:border-primary-gray-300",
  },

  error: {
    inputBorderColor: "border-primary-errorColor",
    inputTextColor: "text-primary-navy-800",
    inputBackgroundColor: "bg-white",
    inputHoverBorderColor: "hover:border-primary-red-800",
    inputFocusBorderColor: "focus:border-primary-red-600",
  },
};

// INPUT Box Variants
export const INPUT_BOX_PRIMARY_STYLE = {
  ...COMMON_INPUT_STYLE,
  inputBackgroundColor: "bg-white",
  inputHeight: "h-9",
};

export const INPUT_BOX_SECONDARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-secondary-gray-300",
  inputHoverBorderColor: "hover:border-primary-sapphireBlue-600",
  inputFocusBackgroundColor: "focus:bg-white",
  inputFocusColor: "outline-none",
  inputTextColor: "text-primary-ptext-1000",
  inputPlaceHolderTextColor: "placeholder-secondary-gray-500",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-sapphireBlue-600",
  inputBoxShadow: "",
  inputDisabledBackgroundColor: "bg-secondary-disabledBGColor",
  inputDisabledBorderColor: "border-primary-gray-300",

  // Input:Size
  inputFontSize: "text-xs",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-6",
  inputWidth: "w-full",
  inputFocusBorderWidth: "focus:border",
  inputTextAlign: "text-left",
  error: {
    inputBorderColor: "!border-primary-errorColor",
  },
};

//Styling Component incase of error.
export const INPUT_BOX_ERROR = {};

export const INPUT_BOX_TERTIARY_STYLE = {
  ...COMMON_INPUT_STYLE,
  inputPaddingRight: "pr-9",
  inputHeight: "h-6",
  inputTextColor: "text-white",
  inputBackgroundColor: "bg-transparent",
  inputFocusBackgroundColor: "focus:bg-transparent",
  inputBorderColor: "border-secondary-gray-500",
  inputHoverBorderColor: "hover:border-secondary-disabledBGColor",
  inputHoverBackgroundColor: "hover:bg-transparent",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-transparent",
    inputPlaceHolderTextColor: "placeholder-secondary-gray-500",
  },
};

export const INPUT_BOX_STYLES = {
  primaryStyle: INPUT_BOX_PRIMARY_STYLE,
  secondaryStyle: INPUT_BOX_SECONDARY_STYLE,
  tertiaryStyle: INPUT_BOX_TERTIARY_STYLE,
};

// INPUT Number Variants
export const INPUT_NUMBER_PRIMARY_STYLE = {
  ...COMMON_INPUT_STYLE,
};

export const INPUT_NUMBER_SECONDARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-violet-600",
  inputHoverBorderColor: "hover:border-primary-gray-400",
  inputFocusBackgroundColor: "focus:bg-white",
  inputFocusColor: "outline-none",
  inputTextColor: "text-primary-gray-800",
  inputPlaceHolderTextColor: "placeholder-primary-gray-400",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "",
  inputDisabledBackgroundColor: "bg-primary-gray-200",
  inputDisabledBorderColor: "border-secondary-gray-500",

  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
};

export const INPUT_NUMBER_TERTIARY_STYLE = {
  ...COMMON_INPUT_STYLE,
};

export const INPUT_NUMBER_STYLES = {
  primaryStyle: INPUT_NUMBER_PRIMARY_STYLE,
  secondaryStyle: INPUT_NUMBER_SECONDARY_STYLE,
  tertiaryStyle: INPUT_NUMBER_TERTIARY_STYLE,
};

export const COMMON_LABEL_STYLE = {
  inputLabelColor: "text-primary-labelColor",
  inputLabelFontSize: "text-xs",
  inputTextAlign: "text-left",
  disabledStyle: {
    inputLabelColor: "text-primary-gray-200",
  },
  errorStyle: {
    inputLabelColor: "text-primary-red-500",
  },
};

export const LABEL_STYLE_PRIMARY = {
  ...COMMON_LABEL_STYLE,
};

export const LABEL_STYLE_SECONDARY = {
  ...COMMON_LABEL_STYLE,
  inputLabelColor: "text-secondary-labelColor",
};

export const LABEL_STYLES = {
  primaryStyle: LABEL_STYLE_PRIMARY,
};

//Styling Error Text
export const COMMON_ERROR_STYLE = {
  inputErrorColor: "text-primary-errorColor",
  inputErrorTextSize: "text-xs",
};

export const BASE_INPUT_TEXT_AREA_STYLE = {
  ...COMMON_INPUT_STYLE,
  inputHeight: "h-62.5",
};

export const INPUT_PRIMARY_TEXT_AREA_STYLE = {
  ...BASE_INPUT_TEXT_AREA_STYLE,
};

export const INPUT_SECONDARY_TEXT_AREA_STYLE = {
  ...BASE_INPUT_TEXT_AREA_STYLE,
  inputBorderColor: "border-secondary-gray-300",
  inputHoverBorderColor: "hover:border-primary-sapphireBlue-600",
  inputFocusBackgroundColor: "focus:bg-white",
  inputFocusColor: "outline-none",
  inputTextColor: "text-primary-ptext-1000",
  inputPlaceHolderTextColor: "placeholder-secondary-steelBlue-500",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-sapphireBlue-600",
  inputBoxShadow: "",
  inputDisabledBackgroundColor: "bg-secondary-disabledBGColor",
  inputDisabledBorderColor: "border-primary-gray-300",

  // Input:Size
  inputFontSize: "text-xs",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-18",
  inputWidth: "w-full",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
  error: {
    inputBorderColor: "!border-primary-errorColor",
  },
};

export const TEXT_AREA_BOX_STYLES = {
  primaryStyle: INPUT_PRIMARY_TEXT_AREA_STYLE,
  secondaryStyle: INPUT_SECONDARY_TEXT_AREA_STYLE,
};

export const BASE_PANEL_STYLE = {
  borderStyle: " border border-t-0",
  inputBorderColorPanel: "border-secondary-steelBlue-300",
  inputTextColorPanel: "text-primary-ptext-1000",
  inputPaddingVPanel: "",
  inputPaddingHPanel: "",
  inputBorderRadiusPanel: "rounded-md",
  inputBackgroundColorPanel: "bg-white",
  inputFontSizePanel: "text-xs",
  inputBoxShadow: "shadow-autoCompletePanel",
  inputTextAlignPanel: "text-left",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-primary-gray-100",
    inputHoverBackgroundColor: "hover:bg-primary-gray-100",
  },
};

export const PANEL_PRIMARY_STYLE = {
  ...BASE_PANEL_STYLE,
  inputHeightPanel: "h-39",

  inputMarginRightPanel: "mr-6",
  inputMarginLeftPanel: "",
  inputMarginTopPanel: "",
  inputMarginBottomPanel: "",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-secondary-disabledBGColor",
    inputPlaceHolderTextColor: "placeholder-text-primary-gray-400",
  },
};

export const BASE_DROPDOWN_STYLE = {
  ...COMMON_INPUT_STYLE,
  inputWidth: "w-full",
  inputTextColor: "text-primary-ptext-1000",
  inputPlaceHolderTextColor: "placeholder-secondary-gray-500",
};

export const DROPDOWN_PRIMARY_STYLE = {
  ...BASE_DROPDOWN_STYLE,
  //inputHoverBorderColor: "hover:border-primary-sapphireBlue-600",
  inputHeight: "h-6",
  inputPaddingV: " px-2 ",
  inputPaddingH: "!py-0.5",
};

export const DROPDOWN_SECONDARY_STYLE = {
  ...BASE_DROPDOWN_STYLE,
  inputPaddingH: "!py-0.5",
  inputBackgroundColor: "bg-white",
  inputMarginBottom: "!mb-1",
};

export const DROPDOWN_TERTIARY_STYLE = {
  ...BASE_DROPDOWN_STYLE,
};

export const DROPDOWN_QUATERNARY_STYLE = {
  ...BASE_DROPDOWN_STYLE,
  inputBorderColor: "border-primary-gray-600",
};

export const DROP_DOWN_STYLES = {
  primaryStyle: DROPDOWN_PRIMARY_STYLE,
  secondaryStyle: DROPDOWN_SECONDARY_STYLE,
  tertiaryStyle: DROPDOWN_TERTIARY_STYLE,
  quaternaryStyle: DROPDOWN_QUATERNARY_STYLE,
};

//OTPBoxStyle

export const OTP_BOX_PRIMARY_STYLE = {
  borderStyle: "border",
  inputFontSize: "text-xsm",
  inputHeight: "!h-9",
  inputWidth: "!w-9",
  inputBorderRadius: "!rounded-md",
  inputBackGroundColor: "bg-primary-sapphireBlue-100",
  inputMarginRight: "!mr-3",
  inputHoverBorderColor: "hover:border-semantic-informativeAccent",
  inputFocusColor: "outline-none",
  inputTextColor: "text-primary-ptext-1000",
  error: {
    inputBorderColor: "border-primary-errorColor",
  },
};

export const OTP_BOX_SECONDARY_STYLE = {
  borderStyle: "border",
  inputBorderColor: "border-primary-gray-400",
  inputTextColor: "text-primary-gray-800",
  inputFontSize: "text-sm",
};

export const OTP_BOX_STYLES = {
  primaryStyle: OTP_BOX_PRIMARY_STYLE,
  secondaryStyle: OTP_BOX_SECONDARY_STYLE,
};

export const OTP_RESEND_PRIMARY_STYLE = {
  inputTextColor: "text-primary-navy-800",
  inputFontSize: "text-xsm",
};

export const OTP_RESEND_SECONDARY_STYLE = {
  borderStyle: "border",
  inputBorderColor: "border-primary-gray-400",
  inputTextColor: "text-primary-gray-800",
  inputFontSize: "text-sm",
};

export const OTP_RESEND_STYLES = {
  primaryStyle: OTP_RESEND_PRIMARY_STYLE,
  secondaryStyle: OTP_RESEND_SECONDARY_STYLE,
};

// Autocomplete Variants
export const BASE_AUTOCOMPLETE_STYLE = {
  ...COMMON_INPUT_STYLE,
  inputMarginRight: "!mr-0",
  inputMarginLeft: "!ml-0",
  inputMarginTop: "!mt-0",
  inputMarginBottom: "!mb-0",

  inputPlaceHolderTextColor: "placeholder-text-primary-gray-800",
  // inputBackgroundColor: "bg-white",
  inputBorderColor: "border-transparent",
  inputWidth: "w-full",
  inputTextAlign: "text-right",
  inputTextColor: "text-primary-gray-800",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-primary-gray-200",
    inputPlaceHolderTextColor: "placeholder-text-secondary-gray-500",
    inputTextColor: "text-primary-gray-800",
  },

  error: {
    inputBorderColor: "border-primary-errorColor",
    inputTextColor: "text-primary-navy-800",
    inputBackgroundColor: "bg-white",
  },
};

export const AUTOCOMPLETE_PRIMARY_STYLE = {
  ...BASE_AUTOCOMPLETE_STYLE,
  //Input: Color
  inputBorderColor: "border-primary-gray-600",
  inputHoverBorderColor: "hover:border-primary-sapphireBlue-600",
  inputFocusColor: "",
  inputTextColor: "text-primary-ptext-1000",
  inputPlaceHolderTextColor: "placeholder-primary-gray-800",
  // inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "",
  inputBoxShadow: "",

  // Input:Size
  inputFontSize: "text-xs",
  inputPaddingLeft: "pl-6",
  inputPaddingH: "py",
  inputHeight: "h-6",
  inputFocusBorderWidth: "",
  inputTextAlign: "text-left",
  inputMarginRight: "!mr-0",
  inputMarginLeft: "!ml-0",
  error: {
    inputBorderColor: "!border-primary-errorColor",
  },
};

export const AUTOCOMPLETE_SECONDARY_STYLE = {
  ...BASE_AUTOCOMPLETE_STYLE,
  //Input: Color
  inputBorderColor: "border-primary-violet-600",
  inputHoverBorderColor: "hover:border-primary-gray-400",
  inputFocusColor: "",
  inputTextColor: "text-primary-gray-800",
  inputPlaceHolderTextColor: "placeholder-primary-gray-400",
  inputBackgroundColor: "bg-primary-gray-200",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "",
  inputDisabledBackgroundColor: "bg-primary-gray-200",
  inputDisabledBorderColor: "border-primary-gray-500",

  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
};

export const AUTOCOMPLETE_STYLES = {
  primaryStyle: AUTOCOMPLETE_PRIMARY_STYLE,
  secondaryStyle: AUTOCOMPLETE_SECONDARY_STYLE,
};

// RadioButton Variants
export const RADIO_BUTTON_PRIMARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-gray-600",
  inputHoverBorderColor: "hover:border-primary-gray-800",
  inputFocusColor: "",
  inputIconTextColor: "",
  inputPlaceHolderTextColor: "",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "",

  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-5",
  inputWidth: "w-5",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "",
  inputBorderRadius: "rounded-2xl",
};

export const RADIO_BUTTON_SECONDARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-gray-200",
  inputHoverBorderColor: "hover:border-primary-gray-100",
  inputFocusColor: "plum-200",
  inputIconTextColor: "text-primary-gray-400",
  inputPlaceHolderTextColor: "placeholder-primary-gray-400",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-violet-300",
  inputBoxShadow: "",
  // Input:Size
  inputIconFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
};

export const RADIO_BUTTON_PRIMARY_STYLE_CHECKED = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-gray-600",
  inputHoverBorderColor: "hover:border-primary-gray-800",
  inputFocusColor: "",
  inputHoverBackgroundColor: "hover:bg-primary-sapphireBlue-700",
  inputBackgroundColor: "bg-primary-sapphireBlue-600",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "",
  inputIconTextColor: "text-white",

  // Input:Size
  inputIconFontSize: "text-sm",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-5",
  inputWidth: "w-5",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-2xl",

  disabled: {
    inputBorderColor: "border-primary-gray-300",
    inputHoverBorderColor: "hover:border-primary-gray-300",
    inputBackgroundColor: "!bg-secondary-steelBlue-500",
    inputPlaceHolderTextColor: "placeholder-secondary-gray-500",
    inputTextColor: "text-primary-gray-800",
  },
};

export const RADIO_BUTTON_STYLES = {
  primaryStyle: RADIO_BUTTON_PRIMARY_STYLE,
  secondaryStyle: RADIO_BUTTON_SECONDARY_STYLE,
};

// Checkbox Variants
export const CHECKBOX_PRIMARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-secondary-gray-300",
  inputHoverBorderColor: "hover:border-primary-sapphireBlue-600",
  inputFocusColor: "focus:border-semantic-informativeAccent",
  inputIconTextColor: "",
  inputPlaceHolderTextColor: "",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-sapphireBlue-600",
  inputBoxShadow: "",

  borderStyle: "border",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-primary-gray-100",
  },
  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-6",
  inputWidth: "w-6",
  inputFocusBorderWidth: "",
  inputTextAlign: "",
  inputBorderRadius: "rounded-lg",
};

export const CHECKBOX_SECONDARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-gray-200",
  inputHoverBorderColor: "hover:border-primary-gray-100",
  inputFocusColor: "plum-200",
  inputIconTextColor: "text-primary-gray-400",
  inputPlaceHolderTextColor: "placeholder-primary-gray-400",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-violet-300",
  inputBoxShadow: "",

  // Input:Size
  inputIconFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
};

export const CHECKBOX_PRIMARY_STYLE_CHECKED = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-sapphireBlue-600",
  inputHoverBorderColor: "hover:border-primary-gray-800",
  inputFocusColor: "",
  inputHoverBackgroundColor: "hover:bg-primary-sapphireBlue-600",
  inputBackgroundColor: "bg-primary-sapphireBlue-600",
  inputFocusBorderColor: "focus:border-primary-sapphireBlue-600",
  inputBoxShadow: "",
  inputCheckMarkCenter: " flex justify-center items-center",

  // Input:Size
  inputIconFontSize: "text-sm",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-6",
  inputWidth: "w-6",
  inputFocusBorderWidth: "",
  inputBorderRadius: "rounded-lg",
  inputIconTextColor: "text-white",

  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-secondary-disabledBGColor",
    inputIconTextColor: "text-primary-gray-500",
    inputHoverBorderColor: "hover:border-primary-gray-500",
    inputFocusColor: "focus:bg-secondary-disabledBGColor",
    inputHoverBackgroundColor: "hover:bg-secondary-disabledBGColor",
    inputFocusBorderColor: "focus:border-primary-gray-500",
  },
};

export const CHECK_BOX_STYLES = {
  primaryStyle: CHECKBOX_PRIMARY_STYLE,
  secondaryStyle: CHECKBOX_SECONDARY_STYLE,
};

// Toast Variants
export const TOAST_PRIMARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  borderStyle: "  border-l-4",
  inputBorderColor: "border-semantic-green-900",
  inputHoverBorderColor: "hover:border-primary-gray-800",
  inputFocusBackgroundColor: "focus:bg-primary-violet-600",
  inputFocusColor: "outline-none",
  inputTextColor: "text-white",
  inputBackgroundColor: "bg-primary-primaryToastColor",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "shadow-toast",
  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2.5",
  inputHeight: "h-12",
  inputWidth: "w-80",
  inputTextAlign: "text-center",
};

export const TOAST_SECONDARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  borderStyle: "  border-l-4",
  inputBorderColor: "border-semantic-deepSkyBlue-800",
  inputHoverBorderColor: "hover:border-primary-gray-800",
  inputFocusBackgroundColor: "focus:bg-primary-violet-600",
  inputFocusColor: "outline-none",
  inputTextColor: "text-white",
  inputBackgroundColor: "bg-primary-infoToastColor",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "shadow-toast",
  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2.5",
  inputHeight: "h-12",
  inputWidth: "w-80",
  inputTextAlign: "text-center",
};

export const TOAST_TERTIARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  borderStyle: "  border-l-4",
  inputBorderColor: "border-semantic-yellow-800",
  inputHoverBorderColor: "hover:border-primary-gray-800",
  inputFocusBackgroundColor: "focus:bg-primary-violet-600",
  inputFocusColor: "outline-none",
  inputTextColor: "text-white",
  inputBackgroundColor: "bg-primary-warningToastColor",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "shadow-toast",
  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2.5",
  inputHeight: "h-12",
  inputWidth: "w-80",
  inputTextAlign: "text-center",
};

export const TOAST_QUATERNARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  borderStyle: "  border-l-4",
  inputBorderColor: "border-primary-errorToastBorderColor-800",
  inputHoverBorderColor: "hover:border-primary-gray-800",
  inputFocusBackgroundColor: "focus:bg-primary-violet-600",
  inputFocusColor: "outline-none",
  inputTextColor: "text-white",
  inputBackgroundColor: "bg-primary-errorToastColor",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "shadow-toast",
  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2.5",
  inputHeight: "h-12",
  inputWidth: "w-80",
  inputTextAlign: "text-center",
};

export const TOAST_STYLES = {
  primaryStyle: TOAST_PRIMARY_STYLE,
  secondaryStyle: TOAST_SECONDARY_STYLE,
  tertiaryStyle: TOAST_TERTIARY_STYLE,
  quaternaryStyle: TOAST_QUATERNARY_STYLE,
};

/*
Button Component - ALL STYLE OBJECTS ARE WRITTEN BELOW
*/

//This will be common for all buttons:
export const BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT = {
  buttonBorderRadius: "rounded-full",
  buttonFontSize: "text-xsm",
  buttonMarginRight: "",
  buttonMarginLeft: "",
  buttonMarginTop: "",
  buttonMarginBottom: "",
  buttonPaddingV: "",
  buttonPaddingH: "",
  buttonHeight: "h-12",
  buttonWidth: "w-12",
  buttonTextAlign: "text-center",
  borderStyle: generateBorderStyle(
    { inputBorderWidth: "border-1.5" },
    { inputBorderDirection: "" }
  ),
};

// PRIMARY BUTTON - All Objects below:
//Severity - PLUM/DEFAULT
export const BUTTON_PRIMARY_COLOR_PLUM = {
  buttonBorderColor: "border-primary-sapphireBlue-600",
  buttonHoverBorderColor: "hover:border-primary-gray-800",
  buttonFocusBackgroundColor: "focus:bg-primary-sapphireBlue-600",
  buttonTextColor: "text-white",
  buttonBackgroundColor: "bg-primary-sapphireBlue-600",
  buttonFocusBorderColor: "focus: border-semantic-informativeAccent",
  buttonHoverBackgroundColor: "hover:bg-primary-buttonPrimaryHoverColor",
  buttonBoxShadow: "shadow-iconButton",
  disabled: {
    inputBorderColor: "border-primary-gray-300",
    inputBackgroundColor: "bg-secondary-disabledBGColor",
    buttonTextColor: "text-primary-disabledButtonTextColor",
  },
};

//Severity - RED
export const BUTTON_PRIMARY_COLOR_RED = {
  buttonHoverBorderColor: "hover:border-primary-gray-300",
  buttonActiveBorderColor: "",
  buttonTextColor: "text-primary-sapphireBlue-600",
  buttonFocusBorderColor: "",
  buttonBoxShadow: "shadow-iconButton",
  buttonBackgroundColor: "bg-white",
  buttonBorderColor: " border border-secondary-gray-300",
  buttonActiveBackgroundColor: "",
  buttonFocusBackgroundColor: "focus:white",
  buttonFocusColor: "focus:border-2 focus:border-semantic-informativeAccent",
  buttonHoverBackgroundColor: "hover:bg-primary-tableRowColor",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-primary-gray-500",
  },
};

export const BUTTON_PRIMARY_CRIMSON = {
  buttonHoverBorderColor: "hover:border-primary-gray-300",
  buttonActiveBorderColor: "",
  buttonTextColor: "text-primary-sapphireBlue-600",
  buttonFocusBorderColor: "",
  buttonBoxShadow: "shadow-iconButton",
  buttonBackgroundColor: "bg-semantic-crimson-600",
  buttonBorderColor: " border border-secondary-gray-300",
  buttonActiveBackgroundColor: "",
  buttonFocusBackgroundColor: "focus:white",
  buttonFocusColor: "focus:border-2 focus:border-semantic-informativeAccent",
  buttonHoverBackgroundColor: "hover:bg-primary-crimson-800",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-primary-gray-500",
  },
  ...BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT,
};

//Default Primary Button - Plum
export const BUTTON_PRIMARY_PLUM = {
  ...BUTTON_PRIMARY_COLOR_PLUM,
  ...BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT,
};

//Primary Button - Red
export const BUTTON_PRIMARY_RED = {
  ...BUTTON_PRIMARY_COLOR_RED,
  ...BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT,
};

//IconText

export const BUTTON_PRIMARY_ICON_TEXT_ROW = {
  ...BUTTON_PRIMARY_RED,
  buttonHeight: "h-6",
  buttonWidth: "w-6",
  buttonFontSize: "text-xs",
  buttonPaddingV: "px-0",
  buttonPaddingH: "py-0",
  buttonBorderColor: "border border-1.5 border-primary-sapphireBlue-600",
  buttonHoverBorderColor: "hover:border-1.5 hover:border-primary-sapphireBlue-600",
  buttonFocusColor: "focus:border focus:border-semantic-informativeAccent",
  disabled: {
    buttonBorderColor: "border border-1.5 border-primary-gray-500",
  },
};

export const BUTTON_PRIMARY_ICON_BACK_TO_LOGIN = {
  ...BUTTON_PRIMARY_RED,
  buttonHeight: "h-6",
  buttonWidth: "w-6",
  buttonFontSize: "text-xs",
  buttonPaddingV: "px-0",
  buttonPaddingH: "py-0",
  buttonBorderColor: "border border-white",
  buttonHoverBorderColor: " hover:border-primary-sapphireBlue-600",
  buttonBoxShadow: "shadow-none",
  buttonMarginTop: "!mt-0",
  buttonFocusColor: "focus:border-semantic-informativeAccent",
};

export const BUTTON_PRIMARY_NEW_VALUE = {
  ...BUTTON_PRIMARY_RED,
  buttonHeight: "h-6",
  buttonWidth: "w-6",
  buttonFontSize: "text-xs",
  buttonPaddingV: "px-0",
  buttonPaddingH: "py-0",
  buttonBorderColor: "border-none",
  buttonBoxShadow: "shadow-none",
  buttonMarginTop: "!mt-0",
};

export const BUTTON_PRIMARY_TEXT_BUTTON = {
  buttonHeight: "h-6",
  buttonWidth: "w-24",
  buttonFontSize: "text-xs",
  buttonPaddingV: "px-0",
  buttonPaddingH: "py-0",
  buttonBorderColor: "border-none",
  buttonBoxShadow: "shadow-none",
  buttonMarginTop: "!mt-0",
  buttonTextColor: "text-primary-sapphireBlue-600",
  buttonHoverColor: "hover:text-primary-buttonPrimaryHoverColor",
  buttonFocusColor: "focus:text-primary-gray-500",
};

//Primary Button
export const BUTTON_PRIMARY = {
  default: BUTTON_PRIMARY_PLUM,
  plum: BUTTON_PRIMARY_PLUM,
  outline: BUTTON_PRIMARY_RED,
  icontextrow: BUTTON_PRIMARY_ICON_TEXT_ROW,
  icontextbacktologin: BUTTON_PRIMARY_ICON_BACK_TO_LOGIN,
  newvalue: BUTTON_PRIMARY_NEW_VALUE,
  textbutton: BUTTON_PRIMARY_TEXT_BUTTON,
  crimson: BUTTON_PRIMARY_CRIMSON,
};

export const BUTTON_SECONDARY_STYLE = {
  buttonBorderColor: "border border-semantic-negative-600",
  buttonHoverBorderColor: "hover:border-primary-plum-800",
  buttonActiveBorderColor: "active:border-primary-navy-800",
  buttonActiveBackgroundColor: "active:bg-white",
  buttonFocusBackgroundColor: "focus:white",
  buttonFocusColor: "focus:outline outline-primary-plum-700",
  buttonTextColor: "text-primary-plum-700",
  buttonBackgroundColor: "bg-white",
  buttonFocusBorderColor: "",
  buttonHoverBackgroundColor: "hover:bg-white",
  buttonBoxShadow: "",
  buttonBorderRadius: "rounded-lg",

  // Input:Size
  buttonFontSize: "text-sm",
  buttonPaddingV: "px-2",
  buttonPaddingH: "py-4",
  buttonHeight: "h-10",
  buttonWidth: "w-27",
  buttonTextAlign: "text-center",
  buttonMarginRight: "!mr-2",
  buttonMarginLeft: "!ml-2",
  buttonMarginTop: "mt-2",
  buttonMarginBottom: "!mb-2",
};

// SECONDARY BUTTON - All Objects below:
//Severity - PLUM/DEFAULT
export const BUTTON_SECONDARY_COLOR_PLUM = {
  buttonBorderColor: " border border-semantic-negative-600",
  buttonHoverBorderColor: "hover:border-semantic-negative-800",
  buttonActiveBorderColor: "",
  buttonActiveBackgroundColor: "",
  buttonFocusBackgroundColor: "focus:bg-semantic-negative-600",
  buttonTextColor: "text-white",
  buttonBackgroundColor: "bg-semantic-negative-600",
  buttonFocusBorderColor: "focus:border-semantic-informativeAccent",
  buttonHoverBackgroundColor: "hover:bg-semantic-negative-800",
  buttonBoxShadow: "shadow-iconButton",
  borderStyle: generateBorderStyle(
    { inputBorderWidth: "border-1.5" },
    { inputBorderDirection: "" }
  ),
  disabled: {
    inputBorderColor: "!border-primary-gray-300",
    inputBackgroundColor: "!bg-secondary-disabledBGColor",
    buttonTextColor: "!text-primary-disabledButtonTextColor",
  },
};

//Severity - RED
export const BUTTON_SECONDARY_COLOR_RED = {
  buttonHoverBorderColor: "hover:border-primary-gray-300",
  buttonActiveBorderColor: "",
  buttonTextColor: "text-semantic-negative-600",
  buttonFocusBorderColor: "",
  buttonBoxShadow: "shadow-iconButton",
  buttonBackgroundColor: "bg-white",
  buttonBorderColor: " border border-secondary-gray-300",
  buttonActiveBackgroundColor: "",
  buttonFocusBackgroundColor: "focus:white",
  buttonFocusColor: "focus:border-2 focus:border-semantic-informativeAccent",
  buttonHoverBackgroundColor: "hover:bg-primary-tableRowColor",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-primary-gray-500",
  },
};

//Secondary Button - Plum
export const BUTTON_SECONDARY_PLUM = {
  ...BUTTON_SECONDARY_COLOR_PLUM,
  ...BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT,
};

//Secondary Button - Red
export const BUTTON_SECONDARY_RED = {
  ...BUTTON_SECONDARY_COLOR_RED,
  ...BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT,
};

//Secondary Button - Icon

export const BUTTON_SECONDARY_ICON = {
  buttonBorderColor: "border-none",
  buttonHoverBorderColor: "",
  buttonActiveBorderColor: "",
  buttonActiveBackgroundColor: "active:bg-white",
  buttonFocusBackgroundColor: "focus:white",
  buttonFocusColor: "",
  buttonTextColor: "text-primary-plum-700",
  buttonBackgroundColor: "",
  buttonFocusBorderColor: "",
  buttonHoverBackgroundColor: "hover:bg-primary-gray-300",
  buttonBoxShadow: "",
  buttonBorderRadius: "rounded-full",

  // Input:Size
  buttonFontSize: "text-xxxs",
  buttonPaddingV: "px-0",
  buttonPaddingH: "py-0",
  buttonHeight: "h-6",
  buttonWidth: "w-6",
  buttonTextAlign: "text-center",
  buttonMarginRight: "mr-0",
  buttonMarginLeft: "ml-0",
  buttonMarginTop: "mt-0",
  buttonMarginBottom: "mb-0",
  disabled: {
    inputBackgroundColor: "!bg-secondary-disabledBGColor",
    inputPlaceHolderTextColor: "!placeholder-secondary-gray-500",
  },
};

export const BUTTON_SECONDARY = {
  default: BUTTON_SECONDARY_PLUM,
  plum: BUTTON_SECONDARY_PLUM,
  outline: BUTTON_SECONDARY_RED,
  icon: BUTTON_SECONDARY_ICON,
};

export const BUTTON_BOX_STYLES = {
  primary: BUTTON_PRIMARY,
  secondary: BUTTON_SECONDARY,
};

//SimpleTab Styles

export const SIMPLE_TAB_PRIMARY_STYLE = {
  ...COMMON_INPUT_STYLE,
};

export const SIMPLE_TAB_SECONDARY_STYLE = {
  ...COMMON_INPUT_STYLE,
};

export const SIMPLE_TAB_TERTIARY_STYLE = {
  ...COMMON_INPUT_STYLE,
};

export const SIMPLE_TAB_STYLES = {
  primaryStyle: SIMPLE_TAB_PRIMARY_STYLE,
  secondaryStyle: SIMPLE_TAB_SECONDARY_STYLE,
  tertiaryStyle: SIMPLE_TAB_TERTIARY_STYLE,
};

//SimpleTab Header Styles

export const SIMPLE_TAB_HEADER_PRIMARY_STYLE = {
  headerHeight: "",
  headerWidth: "",
  headerMarginRight: "!mr-6",
  headerBorderRadius: "cursor-pointer",
  headerHoverBackgroundColor: "",
  inputPaddingY: "pb-3",
  inputPaddingX: "px-2",
  inputFocusRingWidth: "focus:outline-none",
  inputTextColor: "text-secondary-gray-500",
  disabled: {
    color: "",
  },
};

export const SIMPLE_TAB_HEADER_SECONDARY_STYLE = {};

export const SIMPLE_TAB_HEADER_TERTIARY_STYLE = {};

export const SIMPLE_TAB_HEADER_STYLES = {
  primaryStyle: SIMPLE_TAB_HEADER_PRIMARY_STYLE,
  secondaryStyle: SIMPLE_TAB_HEADER_SECONDARY_STYLE,
  tertiaryStyle: SIMPLE_TAB_HEADER_TERTIARY_STYLE,
};

// Button Variants
export const COMMON_MESSAGE_STYLE = {
  //Input: Color
  messageBorderColor: "border-primary-plum-700",
  messageHoverBorderColor: "hover:border-primary-gray-800",
  messageBackgroundColor: "bg-white",
  messageHoverBackgroundColor: "hover:bg-white",
  messageBoxShadow: "",
  messageBorderRadius: "rounded-lg",
  borderStyle: generateBorderStyle(
    { inputBorderWidth: "border-2" },
    { inputBorderDirection: "-b" }
  ),
  messageMarginRight: "!mr-2",
  messageMarginLeft: "!ml-2",
  messageMarginTop: "!mt-2",
  messageMarginBottom: "!mb-2",
  messagePaddingV: "px-2",
  messagePaddingH: "py-4",
  messageHeight: "h-10",
  messageWidth: "w-27",
};

//SimpleButton

export const COMMON_SIMPLE_BUTTON_STYLE = {
  inputMarginRight: "",
  inputMarginLeft: "",
  inputMarginTop: "",
  inputMarginBottom: "",
  inputFontSize: "text-xs",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-9",
  inputWidth: "w-full",
  borderStyle: " border ",
  inputBorderRadius: "rounded-md",

  inputFocusShadow: "",
  inputBoxShadow: "",
  buttonTextColor: "text-white",
  disabled: {
    inputBorderColor: "!border-primary-gray-300",
    inputBackgroundColor: "!bg-secondary-disabledBGColor",
    buttonTextColor: "!text-primary-disabledButtonTextColor",
  },
};

export const SIMPLE_INPUT_PRIMARY_STYLE = {
  ...COMMON_SIMPLE_BUTTON_STYLE,
  buttonBorderColor: "border-primary-primaryBlue",
  buttonBackgroundColor: "bg-primary-primaryBlue",
  buttonHoverBackgroundColor: "hover:bg-primary-buttonPrimaryHoverColor",
};
export const SIMPLE_INPUT_SECONDARY_STYLE = {
  ...COMMON_SIMPLE_BUTTON_STYLE,
  buttonBorderColor: "border-primary-copyTextColor",
  buttonBackgroundColor: "bg-white",
  buttonTextColor: "text-primary-copyTextColor",
  // buttonHoverBackgroundColor: "hover:bg-while",
};

export const SIMPLE_INPUT_TERTIARY_STYLE = {
  inputMarginRight: "",
  inputMarginLeft: "",
  inputMarginTop: "",
  inputMarginBottom: "",
  inputFontSize: "text-xs",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-9",
  inputWidth: "w-full",
  borderStyle: "",
  inputBorderRadius: "",
  inputFocusRingWidth: "outline-none",
  inputFocusShadow: "",
  inputBoxShadow: "",
  buttonTextColor: "text-primary-sapphireBlue-600",
  disabled: {
    //inputBorderColor: "border-primary-gray-300",
    //inputBackgroundColor: "bg-secondary-disabledBGColor",
    buttonTextColor: "text-secondary-gray-500",
  },
  buttonBorderColor: "border-none",
  buttonBackgroundColor: "bg-none",
  buttonHoverBackgroundColor: "hover:bg-none",
};
export const SIMPLE_BUTTON_STYLES = {
  primaryStyle: SIMPLE_INPUT_PRIMARY_STYLE,
  secondaryStyle: SIMPLE_INPUT_SECONDARY_STYLE,
  tertiaryStyle: SIMPLE_INPUT_TERTIARY_STYLE,
};

export const DIALOG_PRIMARY_STYLE = {
  ...COMMON_MESSAGE_STYLE,
  //Input: Color
  messageBorderColor: "border-primary-plum-700",
  messageHoverBorderColor: "hover:border-primary-gray-800",
  messageBackgroundColor: "bg-white",
  messageHoverBackgroundColor: "hover:bg-white",
  messageBoxShadow: "",
  messageBorderRadius: "rounded-lg",

  messageMarginRight: "",
  messageMarginLeft: "",
  messageMarginTop: "",
  messageMarginBottom: "",
  messagePaddingV: "px-5",
  messagePaddingH: "py-6",
  messageHeight: "",
  messageWidth: "w-76.5",
};

export const DIALOG_SECONDARY_STYLE = {
  ...COMMON_MESSAGE_STYLE,
  //Input: Color
  messageBorderColor: "border-primary-plum-700",
  messageHoverBorderColor: "hover:border-primary-gray-800",
  messageBackgroundColor: "bg-white",
  messageHoverBackgroundColor: "hover:bg-white",
  messageBoxShadow: "",
  messageBorderRadius: "rounded-lg",

  messageMarginRight: "!mr-2",
  messageMarginLeft: "!ml-2",
  messageMarginTop: "!mt-2",
  messageMarginBottom: "!mb-2",
  messagePaddingV: "px-2",
  messagePaddingH: "py-8",
  messageHeight: "h-auto",
  messageWidth: "w-132",
};

export const DIALOG_BOX_STYLES = {
  primaryStyle: DIALOG_PRIMARY_STYLE,
  secondaryStyle: DIALOG_SECONDARY_STYLE,
};

// ----------------------- breadCrumb styles ----------------------- //

export const BREADCRUMB_STYLE = Object.freeze({
  previous: "text-primary-ptext-1000 font-Roboto text-xs ",
  active: "text-primary-gray-500 font-Roboto text-xs",
});
