import { withAuthButton } from "components/hoc/withAuthButton";
import { Button, ButtonProps } from "primereact/button";
import React from "react";
import { IBaseButtonCommonProps } from "../../interface/Button";

export interface IBaseButtonProps extends ButtonProps, IBaseButtonCommonProps {
  buttonType?: any;
  disabledIcon?: any;
  context?: any;
  buttonElement?: any;
}

const BaseButton: React.FC<IBaseButtonProps> = (props: IBaseButtonProps) => {
  const {
    className,
    loading,
    iconPos,
    loadingIcon,
    icon,
    disabled,
    isDisabledByAcl,
    onClick,
    isEnableInReadMode,
    isIgnoreAccessLevel,
    disabledIcon,
    buttonType,
    context,
    buttonElement,
    ...primeReactProps
  } = props;

  let disableButton = false;
  if (!isIgnoreAccessLevel && buttonElement) {
    if (isEnableInReadMode ? false : isDisabledByAcl) {
      disableButton = true;
    }
    if (buttonElement["isCreate"] && !context.create) {
      disableButton = true;
    }
    if (buttonElement["isUpdate"] && !context.update) {
      disableButton = true;
    }
    if (buttonElement["isDelete"] && !context.delete) {
      disableButton = true;
    }
    if (buttonElement["isSoftDelete"] && !context.softDelete) {
      disableButton = true;
    }
    if (buttonElement["isApprove"] && !context.approve) {
      disableButton = true;
    }
  }
  if (disabled) {
    disableButton = true;
  }

  return (
    <Button
      {...primeReactProps}
      className={
        disableButton ? `${className} disabled:border disabled:border-primary-gray-500` : className
      }
      loadingIcon={loadingIcon}
      iconPos={iconPos}
      onClick={onClick}
      icon={disableButton ? disabledIcon : icon}
      disabled={disableButton}
      loading={loading}
    />
  );
};

export default withAuthButton(BaseButton);
