import { LABEL_STYLES } from "assets/base-theme";
import Text from "components/Text";
import { generateClasses } from "utils/Util";
import { memo } from "react";

const Label = (props: any) => {
  const { label, hideLabel, labelClassNames, labelStyleObj, disabled, variant, isInvalid } = props;
  const getClassNames = () => {
    const styleObject = {
      ...LABEL_STYLES,
      overrideStyle: labelStyleObj,
      overrideClasses: labelClassNames,
      isInvalid: isInvalid,
      isDisabled: disabled,
    };

    return generateClasses(styleObject, variant);
  };
  let content = null;

  if (label) {
    content = <Text className={`text-sm ${getClassNames()}`} label={label} />;
  } else if (!hideLabel) {
    content = <div className={`h-4 mb-1`} />;
  }

  return <>{content}</>;
};

export default memo(Label);
