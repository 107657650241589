import { DIALOG_BOX_STYLES } from "assets/base-theme";
import { Dialog, DialogProps } from "primereact/dialog";
import { generateClasses } from "utils/Util";
interface DialogBoxProps extends DialogProps {
  styleObj?: object;
  variant?: string;
  // maskClassName?s
}
const DialogBox = (props: any) => {
  const getClassNames = () => {
    const styleObject = {
      ...DIALOG_BOX_STYLES,
      overrideStyle: props.styleObj,
      overrideClasses: props.className,
      removeStyleProperty: [],
      removeClasses: [],
    };

    return generateClasses(styleObject, props.variant);
  };

  const {
    onHide,
    visible,
    closable,
    appendTo,
    onShow,
    id,
    baseZIndex,
    modal,
    dismissableMask,
    maskClassName,
    ...primeReactProps
  } = props;
  return (
    <Dialog
      {...primeReactProps}
      className={getClassNames()}
      onHide={onHide}
      onShow={onShow}
      visible={visible}
      modal={modal}
      closable={closable}
      appendTo={appendTo}
      baseZIndex={baseZIndex}
      dismissableMask={dismissableMask}
      maskClassName={maskClassName}
      id={id}
    />
  );
};

// memoziation

// exports
export default DialogBox;
