import Icon403 from "assets/img/403.png";

const Page403 = () => {
  return (
    <div className=" h-screen w-full flex justify-center">
      <div className="bg-white rounded-2xl h-[250px] w-[388px] flex flex-col gap-3  py-8 px-4">
        <img src={Icon403} alt="403" className="w-20 h-20 mx-auto" />
        <span className="font-bold text-2xl text-primary-primaryTextColor text-center">
          Access Denied
        </span>
        <span className="text-secondary-steelBlue-600 font-normal text-base text-center">
          You don't have access to this page, please contact admin to get access
        </span>
      </div>
    </div>
  );
};

export default Page403;
