import React, { useContext, useEffect, useState } from "react";
import { createNewRouteAccessModel, isAclApplicable } from "../utils/Helper";
import { AclContext, IAclContext } from "components/AclGuard";

export const withAuthButton = (Component: React.FC) => {
  const ButtonComponentWrapper = (props: any) => {
    const aclContext: IAclContext = useContext(AclContext);

    const context = {
      read: aclContext.isOn ? aclContext.read : createNewRouteAccessModel().read,
      create: createNewRouteAccessModel().create,
      update: createNewRouteAccessModel().update,
      delete: createNewRouteAccessModel().delete,
      approve: createNewRouteAccessModel().approve,
      softDelete: createNewRouteAccessModel().softDelete,
    };
    // console.log("context===", context);

    const [isDisabled, setIsDisabled] = useState(props.disabled ?? false);

    useEffect(() => {
      if (isAclApplicable()) {
        if (!props.isIgnoreAccessLevel) {
          setIsDisabled(context.read);
        }
      }
    }, []);

    useEffect(() => {
      if (isAclApplicable()) {
        if (!props.isIgnoreAccessLevel) {
          setIsDisabled(context.read);
        } else {
          setIsDisabled(false);
        }
      }
    }, [context]);

    return (
      <Component
        {...props}
        isIgnoreAccessLevel={props.isIgnoreAccessLevel ?? false}
        isDisabledByAcl={!isDisabled}
        context={context}
      />
    );
  };

  return ButtonComponentWrapper;
};
