//List Actions
export const FETCH_LISTDEMO_BEGIN = "FETCH_LISTDEMO_BEGIN";
export const FETCH_LISTDEMO_SUCCESS = "FETCH_LISTDEMO_SUCCESS";
export const FETCH_LISTDEMO_FAILURE = "FETCH_LISTDEMO_FAILURE";
//Other Actions...

export const COUNT_BEGIN = "COUNT_BEGIN";
export const COUNT_SUCCESS = "COUNT_SUCCESS";
export const COUNT_FAILURE = "COUNT_FAILURE";

export const LOAN_DETAIL_BEGIN = "LOAN_DETAIL_BEGIN";
export const LOAN_DETAIL_SUCCESS = "LOAN_DETAIL_SUCCESS";
export const LOAN_DETAIL_FAILURE = "LOAN_DETAIL_FAILURE";

export const USER_PROFILE_BEGIN = "USER_PROFILE_BEGIN";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";

export const LOAN_SIDEBAR_BACK_URL_BEGIN = "LOAN_SIDEBAR_BACK_URL_BEGIN";
export const LOAN_SIDEBAR_BACK_URL_SUCCESS = "LOAN_SIDEBAR_BACK_URL_SUCCESS";
export const LOAN_SIDEBAR_BACK_URL_FAILURE = "LOAN_SIDEBAR_BACK_URL_FAILURE";

export const IS_STATIC_MASTER_AVAILABLE_BEGIN = "IS_STATIC_MASTER_AVAILABLE_BEGIN";
export const IS_STATIC_MASTER_AVAILABLE_SUCCESS = "IS_STATIC_MASTER_AVAILABLE_SUCCESS";
export const IS_STATIC_MASTER_AVAILABLE_FAILURE = "IS_STATIC_MASTER_AVAILABLE_FAILURE";

export const REFRESH_SIDEBAR_BEGIN = "REFRESH_SIDEBAR_BEGIN";
export const REFRESH_SIDEBAR_SUCCESS = "REFRESH_SIDEBAR_SUCCESS";
export const REFRESH_SIDEBAR_FAILURE = "REFRESH_SIDEBAR_FAILURE";
