import { LINK_LOAN_LIST } from "transactionRoutes";
import {
  REFRESH_SIDEBAR_BEGIN,
  REFRESH_SIDEBAR_FAILURE,
  REFRESH_SIDEBAR_SUCCESS,
} from "../actions/1_ActionConstants";

const initialState = {
  loading: false,
  error: null,
  refreshSidebar: false,
};

export default function SidebarRefreshReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_SIDEBAR_BEGIN:
      return {
        ...state,
      };

    case REFRESH_SIDEBAR_SUCCESS:
      return {
        ...state,
        loading: false,
        refreshSidebar: action.payload,
      };

    case REFRESH_SIDEBAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: "FAILED TO LOAD,..,.", //action.payload.error,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
