/**
 * Author : Kaviraj RM
 * Created on : 13 July, 2023
 **/

/*
    --------------------------------------------------------------------------------------------------------------------
    Imports:
    --------------------------------------------------------------------------------------------------------------------
  */
import { saveUserProfile } from "actions/UserProfileAction";
import ErrorDialog from "components/ErrorDialog/ErrorDialog";
import Loader from "components/Loader/Loader";
import { RouteProps, withRouter } from "hoc/withRouter";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as routes from "routes";
import DeviceAccessService from "services/AccessControl/DeviceAccessService";
import AuthService from "services/AuthService";
import UserService from "services/masterdata/User/UserService";
import { parseStandardAPIErrorMessage, setLocalStorage } from "utils/CommonUtil";
import { LOCAL_STORAGE } from "utils/Constant";

// -------------------------------------------------- IProps ----------------------------------------------------- //

function PageLoginLoader(props: RouteProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [showDialogAPIError, setShowDialogAPIError] = useState(false);

  const dispatch = useDispatch();
  /*
    -----
    LifeCycle Methods/Hooks: 
    -----
  */

  useEffect(() => {
    const parsed: {
      status?: string;
      msg?: string;
      code?: string;
      state?: string | undefined;
    } = queryString.parse(props.location.search);
    //let { status, msg, code, state } = queryString.parse(props.location.search);
    if (parsed.status && parsed.status === "error") {
      props.navigateNoAuth(routes.linkPageLogin);
    } else {
      try {
        if (parsed.code && parsed.code !== undefined) {
          getToken(parsed.code);
        } else {
          props.navigateNoAuth(routes.linkPageLogin);
        }
      } catch (error) {
        props.navigateNoAuth(routes.linkPageLogin);
      }
    }
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setShowDialogAPIError(true);
    }
  }, [errorMessage]);

  /*
    ----------------------------------------------------------------------------------------------------------------------
    Handlers: Event handler for onChange, onBlur etc
    ----------------------------------------------------------------------------------------------------------------------
  */

  const getToken = async (code: string) => {
    const res = await AuthService.generateToken(code);
    try {
      if (res.status === 200) {
        let deviceAccessApiFailure = false;

        const userInfo = JSON.stringify(res.data);
        setLocalStorage(LOCAL_STORAGE.USER_INFO, userInfo);

        const accessResp = await DeviceAccessService.save({});

        try {
          setLocalStorage(LOCAL_STORAGE.IS_WEB_ACCESS, `${accessResp?.data?.isWebAccess ?? false}`);
        } catch (error) {
          deviceAccessApiFailure = true;
          console.error(error);
          setErrorMessage(parseStandardAPIErrorMessage(error));
        }

        if (deviceAccessApiFailure) {
          setTimeout(() => {
            props.navigateNoAuth(routes.linkPageLogin);
          }, 3000);
          return null;
        } else {
          props.navigateNoAuth(routes.linkPageSetup);
          return UserService.getUserDetail().then((res) => {
            if (res?.status === 200) {
              dispatch(saveUserProfile(res.data));
              props.navigateNoAuth(routes.linkPageSetup);
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
      props.navigateNoAuth(routes.linkPageLogin);
    }
  };

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Validators:
    -----------------------------------------------------------------------------------------------------------------------
  */

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Submit / Save Data Functions:
    -----------------------------------------------------------------------------------------------------------------------
  */

  return (
    <React.Fragment>
      <div className="w-full h-screen text-center">
        <Loader />
      </div>

      <ErrorDialog
        onHide={() => {
          setShowDialogAPIError(false);
          setErrorMessage("");
        }}
        visible={showDialogAPIError}
        errorMessage={errorMessage}
      />
    </React.Fragment>
  );
}

export default withRouter(PageLoginLoader);
